import React, { useState, useReducer, createContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Navbar from "../components/common/Navbar";
import NavbarBatch from "../components/common/NavbarBatch";
import Navbar_admin from "../components/common/Navbar_admin";
import HeroClean from "../components/common/HeroClean";
import Produk from "../components/common/MenuProduk";
import ProdukBatch from "../components/common/MenuBatch";
import LoginPage from "../components/front/LoginPage";
import ChangePassword from "../components/front/ChangePassword";
import ChangeLogo from "../components/front/ChangeLogo";
import Category from "../components/front/Category/Index";
import ListProduk from "../components/front/ListProduk";
import Price from "../components/front/Price/Index";
import DenomPrepaid from "../components/front/DenomPrepaid";
import Users from "../components/front/Users";
import UserVerify from "../components/front/UserVerify";
import LogoTagline from "../components/front/LogoTagline";
import Chektransaksi from "../components/front/Chektransaksi";
import Checkoutv2 from "../components/front/Checkoutv2";
import Payment from "../components/front/Payment";
import Invoice from "../components/front/Invoice.js";
import Dashboard from "../components/front/Dashboard";
import DashboardAdmin from "../components/front/DashboardAdmin";
import ClosingDetail from "../components/front/ClosingDetail";
import Cart from "../components/front/Cart";
import Breadcrumb from "../components/common/Breadcrumb";
import PanelMobile from "../components/common/PanelMobile";

import NotFoundPage from "../components/eror/eror404";

import SetMenu from "../components/front/SetMenu";
import * as Middleware from "../middelware/App";
import ClosingList from "../components/front/ClosingList";
import RiwayatTopupSaldo from "../components/front/RiwayatTopupSaldo";
import ManualTopupSaldo from "../components/front/Transaksi/ManualTopupSaldo";
import Transactions from "../components/front/Transactions";
import BiayaLoket from "../components/front/BiayaLoket";
import RequestSaldo from "../components/front/RequestSaldoForm";
import RequestSaldoPay from "../components/front/RequestSaldoPay";
import ImportIdpel from "../components/front/ImportIdpel";
import MultiInquiry from "../components/front/MultiInquiry";
import ManualAdvice from "../components/front/ManualAdvice";
import UpdateStatusTrx from "../components/front/UpdateStatusTrx";
import IdpelFavorit from "../components/front/IdpelFavorit";
import PertanyaanUmum from "../components/front/PusatBantuan/PertanyaanUmum";
import AdPertanyaanUmum from "../components/front/PusatBantuan/AdPertanyaanUmum";
import SyaratKetentuan from "../components/front/PusatBantuan/SyaratKetentuan";
import AdSyaratKetentuan from "../components/front/PusatBantuan/AdSyaratKetentuan";
import HubungiKami from "../components/front/PusatBantuan/HubungiKami";
import AdHubungiKami from "../components/front/PusatBantuan/AdHubungiKami";
import TransaksiRevisi from "../components/front/TransaksiRevisi/TransaksiRevisi";
import TransaksiTransferDana from "../components/front/UpdateStatusTransferDana";
import ResetPassword from "../components/front/ResetPassword/Index";
import Done from "../components/front/ResetPassword/Done";
import ProdukSetting from "../components/front/ProdukSetting/Index";
import PaymentMethods from "../components/front/PaymentMethods";
import ChangePin from "../components/front/ChangePin";

// import { CartContext } from "../components/front/CartContext";
//Context
export const AuthContext = createContext();
export const CartContext = createContext();
//Inisiasi state
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  mitra: null,
  balance: null,
  tokenExpires: 0,
  role: 0,
  cart: "kosong",
};

const reducer = (state, action) => {
  // console.warn("payload", action.payload);
  switch (action.type) {
    case "LOGIN":
      let userid_cabang = action.payload.userid_cabang;

      localStorage.setItem("isBranch", userid_cabang != "" ? true : false);
      localStorage.setItem(
        "type",
        JSON.stringify(action.payload.type_saldo).replace(/"/g, "")
      );
      localStorage.setItem(
        "logo",
        JSON.stringify(action.payload.image_logo).replace(/"/g, "")
      );
      localStorage.setItem(
        "tagline",
        JSON.stringify(action.payload.tagline).replace(/"/g, "")
      );
      localStorage.setItem(
        "user",
        JSON.stringify(action.payload.username).replace(/"/g, "")
      );
      localStorage.setItem(
        "token",
        JSON.stringify(action.payload.token).replace(/"/g, "")
      );
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.username,
        fullname: action.payload.fullname,
        role: action.payload.user_roles,
        type_saldo: action.payload.type_saldo,
        token: action.payload.token,
        mitra: action.payload.information_plg.account,
        balance: action.payload.information_plg.balance,
      };

    case "REDATA":
      // localStorage.removeItem("reqTopup");
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.username,
        fullname: action.payload.fullname,
        role: action.payload.user_roles,
        type_saldo: action.payload.type_saldo,
        token: action.payload.token,
        mitra: action.payload.information_plg.account,
        balance: action.payload.information_plg.balance,
      };

    case "CART":
      return {
        ...state,
        cart: localStorage.getItem("allcart"),
      };

    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

function Router() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, setValue] = useState(0);
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <CartContext.Provider value={{ value, setValue }}>
        <Switch>
          <Route exact path="/">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb />
                <Produk />
                {/* <HomePage/> */}
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/riwayat/pembayaran">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Cari Transaksi" />
                <Chektransaksi />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/changepassword">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Ganti Password" />
                <ChangePassword />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/changepin">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Ganti PIN" />
                <ChangePin />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/changelogo">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Ganti Logo Loket" />
                <ChangeLogo />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/login">
            <LoginPage />
          </Route>

          <Route exact path="/checkoutV1">
            <Middleware.Autenticated>
              <Navbar>
                {/* <HeroClean /> */}
                <Breadcrumb path="checkoutV1" title="Detail Tagihan" />
                <Checkoutv2 />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/cart">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb path="cart" title="Daftar Tagihan" />
                <Cart />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/transaksi/requestsaldo">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Request Topup Saldo" />
                <RequestSaldo />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/transaksi/requestsaldopay">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Konfirmasi Topup Saldo" />
                <RequestSaldoPay />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/transaksi/importidpel">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb title="Impor ID Pelanggan" />
                <ImportIdpel />
              </Navbar>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/idpel/favorite">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb title="ID Pelanggan Favorite" />
                <IdpelFavorit />
              </Navbar>
            </Middleware.Autenticated>
          </Route>
          <Route
            exact
            path="/users/transaksi/importidpel/cektagihan/:produk/:idpel"
          >
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb title="Cek Tagihan Multi Idpel" />
                <MultiInquiry />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/transaksi/manualadvice">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Cek Status Manual" />
                <ManualAdvice />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/transaksi/updatestatustrx">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Edit Status Transaksi" />
                <UpdateStatusTrx />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/dashboard">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Riwayat Transaksi" />
                {state.role == "2" ? (
                  <>
                    <Dashboard />
                  </>
                ) : (
                  <>
                    <DashboardAdmin />
                  </>
                )}
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/transaksi/closing/detail/:Id">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Detail Closing Transaksi" />
                <ClosingDetail />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/transaksi/closing/list">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Riwayat Closing Transaksi" />
                <ClosingList />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/riwayat/topupsaldo">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Riwayat Topup Saldo" />
                <RiwayatTopupSaldo state={state} />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/transaksi/manualtopup">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Manual Topup Saldo" />
                <ManualTopupSaldo state={state} />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/panel">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb
                  page="admin"
                  title={state.role == "1" ? "Menu Lain" : "Panel Pengguna"}
                />
                <PanelMobile />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/payment">
            <Middleware.Autenticated>
              <Navbar>
                <HeroClean />
                <Payment />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/invoice/:Id">
            <Middleware.Autenticated>
              <Navbar>
                {/* <HeroClean /> */}
                <Breadcrumb path="invoice" title="Detail Pembayaran" />
                <Invoice />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          {/* ROUTE PRODUK CUAN PELANGI */}

          <Route exact path="/pln">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb path="home" />
                <Produk />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/bpjs">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb path="home" />
                <Produk />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/pulsa">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb path="home" />
                <Produk />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/telkom">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb path="home" />
                <Produk />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/pdam">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb path="home" />
                <Produk />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/data">
            <Middleware.Autenticated>
              <Navbar>
                <Breadcrumb path="home" />
                <Produk />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/game">
            <Middleware.Autenticated>
              <Navbar>
                <Produk />
              </Navbar>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/category">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Daftar Category" />
                <Category />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/produk/listharga">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Daftar Harga" />
                <ListProduk />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/produk/setting">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Produk Setting" />
                <ProdukSetting />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/produk/price">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Price Product" />
                <Price />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/setting/denomprepaid">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Denom PLN Prepaid" />
                <DenomPrepaid />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/setting/adminpulsa">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Setting Admin Pulsa" />
                <SetMenu />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/setting/biayaloket">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Setting Biaya Loket" />
                <BiayaLoket />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/setting/akunpengguna">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Setting Akun Petugas" />
                <Users state={state} />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/setting/verifikasi">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Verifikasi Akun Petugas" />
                <UserVerify state={state} />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/setting/logo">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" />
                <LogoTagline />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          {/* batch payment      */}
          <Route exact path="/transaction/pln">
            <Middleware.Autenticated>
              <NavbarBatch>
                <ProdukBatch />
              </NavbarBatch>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/transaction/bpjs">
            <Middleware.Autenticated>
              <NavbarBatch>
                <ProdukBatch />
              </NavbarBatch>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/transactions">
            <Middleware.Autenticated>
              {/* <Navbar> */}
              <Transactions />
              {/* </Navbar> */}
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/PertanyaanUmum">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Pertanyaan Umum" />
                <PertanyaanUmum />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/AdPertanyaanUmum">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Admin Pertanyaan Umum" />
                <AdPertanyaanUmum />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/SyaratKetentuan">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Syarat Dan Ketentuan" />
                <SyaratKetentuan />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/AdSyaratKetentuan">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Admin Syarat Dan Ketentuan" />
                <AdSyaratKetentuan />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/HubungiKami">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Hubungi Kami" />
                <HubungiKami />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/AdHubungiKami">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Admin Hubungi Kami" />
                <AdHubungiKami />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>
          <Route exact path="/users/setting/payment-methods">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Metode Pembayaran" />
                <PaymentMethods />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/transaksi/transaksirevisi">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Transaksi Revisi" />
                <TransaksiRevisi />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/users/transaksi/transaksiTransferDana">
            <Middleware.Autenticated>
              <Navbar_admin>
                <Breadcrumb page="admin" title="Transfer Dana" />
                <TransaksiTransferDana />
              </Navbar_admin>
            </Middleware.Autenticated>
          </Route>

          <Route exact path="/ResetPassword">
            <ResetPassword />
          </Route>
          <Route exact path="/ResetPassword/Done">
            <Done />
          </Route>

          <Route component={NotFoundPage} />
        </Switch>
      </CartContext.Provider>
    </AuthContext.Provider>
  );
}

export default Router;
