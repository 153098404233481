import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../router/Router';
import { FileUploader } from "react-drag-drop-files";

function LogoTagline(props) {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [chaptha, setChaptha] = useState(false);
  const [file, setFile] = useState();
  const [uploaded, setUpload] = useState(false);
  const recaptchaRef = React.createRef();

  const { dispatch, state } = useContext(AuthContext);
  const fileTypes = ["JPG", "PNG", "GIF"];


  const onSubmit = (data, e) => {
    if (chaptha) {
      setMessage({
        data: "Login is in progress...",
        type: "alert-warning",
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Barier ' + state.token,
          'Accept': 'application/json'
        }
      }
      Axios.post(`${process.env.REACT_APP_URL}/users/change/password`, JSON.stringify(data), config)
        .then((res) => {
          console.log(res.data);
          if (res.data.status == 200) {
            setMessage({
              data: res.data.data,
              type: "alert-success",
            });

            setTimeout(function () {
              dispatch({
                type: "LOGOUT",
                payload: res.data.data
              })
            }, 3000);


          } else {
            setMessage({
              data: res.data.message,
              type: "alert-danger",
            });
          }
          !res.data.status && e.target.reset();
          window.grecaptcha.reset();
        });
    } else {
      setMessage({
        data: "Chek Rechaptha",
        type: "alert-warning",
      });
    }
  };

  const onChange = (value) => {
    if (value) {
      setChaptha(true)
    }

    console.log(chaptha)
  }
  const handleChange = async (file) => {
    await setFile(file)
    // console.warn("file",this.state.file)
  };
  const update = async () => {

  }

  return (
    <div className="bg-primary-admin">
      <section className="section p-0px-t">
        <div className="container pb-5">
          <div className="card shadow">
            <div className="card-header bg-light d-flex justify-content-between">
              <div>
                <table>
                  <tr>
                    <td rowspan="2"><i className="fa fa-image text-primary h3 mr-2"></i></td>
                    <td>
                      <span className="font-weight-bold text-primary">LOGO DAN TAGLINE</span> <br />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="card-body text-center">
              <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Pilih Logo</label>
                      <FileUploader name="file" handleChange={handleChange} types={fileTypes} classes="col-md-12 py-5" maxSize="2" onSizeError="Ukuran file maksimal 2 MB" />
                      <p>{file ?
                        (
                          <div>
                            Filename: {file.name}<br />
                            File siap di upload
                          </div>
                        ) : (
                          <div>
                            {/* Pilih Logo */}
                          </div>
                        )}</p>
                    </div>

                    <div className="form-group mt-5">
                      <input
                        type="text"
                        name="tagline"
                        className="form-control"
                        placeholder="Tagline"
                      />
                    </div>

                    <div className="text-center w-100 text-center d-flex">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfHMCEaAAAAAKjWZtDNjzWaaClaj1G2KHyAD1iA"
                        onChange={onChange}
                        className="py-3 m-auto "
                      />
                    </div>

                    <button type="submit" className="btn btn-block  btn-primary">Simpan Perubahan</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LogoTagline;