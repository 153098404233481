import React, { Component, useRef, useEffect } from "react";
import Swal from 'sweetalert2'
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import { Redirect } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'; 
import ReactLoading from "react-loading";

const Parsetanggal = ({ periode }) => {
  if (periode == '01') {
      return 'January';
  }else if (periode == '02') {
      return 'Februari';
  }else if (periode == '03') {
      return 'Maret';
  }else if (periode == '04') {
      return 'April';
  }else if (periode == '05') {
      return 'Mei';
  }else if (periode == '06') {
      return 'Juni';
  }else if (periode == '07') {
      return 'Juli';
  }else if (periode == '08') {
      return 'Agustus';
  }else if (periode == '09') {
      return 'September';
  }else if (periode == '10') {
      return 'Oktober';
  }else if (periode == '11') {
      return 'November';
  }else if (periode == '12') {
      return 'Desember';
  }
}

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);

const OptionKesehatan = () => {
  var moment = require('moment');
  var text = '';

  function parsedate(periode){
    if (periode == '01') {
      return 'January';
        }else if (periode == '02') {
            return 'Februari';
        }else if (periode == '03') {
            return 'Maret';
        }else if (periode == '04') {
            return 'April';
        }else if (periode == '05') {
            return 'Mei';
        }else if (periode == '06') {
            return 'Juni';
        }else if (periode == '07') {
            return 'Juli';
        }else if (periode == '08') {
            return 'Agustus';
        }else if (periode == '09') {
            return 'September';
        }else if (periode == '10') {
            return 'Oktober';
        }else if (periode == '11') {
            return 'November';
        }else if (periode == '12') {
            return 'Desember';
        }
  }

  for(let i = 0; i < 12; i++){
    // var futureMonth = moment().add(i, 'M').format('MM - YYYY');
    var futureMonth = moment().add(i, 'M').format('M');
    var futureMonths = moment().add(i, 'M').format('MM');
    var futureYear = moment().add(i, 'M').format('YYYY');
    text +="<option value='"+futureMonths+"'>  "+parsedate(futureMonths)+" "+futureYear+" </option>";
    // console.log(futureMonth+" - "+futureYear );
  }
  
  return(
    <div>
      <div className="form-group">
       <label>Bayar Hingga </label>
        <select className="form-control">
            { ReactHtmlParser (text) }
        </select>
      </div>
    </div>
  )
}

const OptionKetenagakerjaan = (props) => (
  <div>
    <div className="form-group">
      <label>Bayar Hingga</label>
      <select className="form-control">
        <option>1 Bulan</option>
        <option>3 Bulan</option>
        <option className="" selected>
          6 Bulan
        </option>
        <option>12 Bulan</option>
      </select>
    </div>
  </div>
);

class bpjs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      showToken: true,
      loading: false,
      product_id:null,
      trx_type:'2100',
      periode_payment:'01',
      idpel: null,
      message: null,
      token:localStorage.getItem('token'),
      inquiry:false,
      idpelStatus:false,
      nominal: null,
      produk: "",
      list_product:[],
    };
  }

  GetProduct()
  {
      fetch(`${process.env.REACT_APP_URL}/products/79`,{
          method: "GET",
          headers:{
              'Content-Type': 'application/json',
              'Authorization':'Barier '+ this.state.token,
              'Accept': 'application/json'
          }
      }).then((response)=>{
          response.json().then((result)=>{
              console.log(result.data[0].code)
              this.setState({list_product: result.data})

              if(result.data[0].code !== null){
                this.setState({
                  produk: result.data[0].code,
                  product_id: result.data[0].prod_id
                })
              }
          })
      })
  }

  componentDidMount() {
    this.GetProduct();
  }

  HitData(){
    this.setState({loading:true})
    console.log(JSON.stringify(this.state))
    fetch(`${process.env.REACT_APP_URL}/loket/transactions`,{
        method: "POST",
        headers: { 
        'Content-Type': 'application/json',
        'Authorization':'Barier '+ this.state.token,
        'Accept': 'application/json'
        },
        body:JSON.stringify(this.state)
    }).then((response)=>{
        response.json().then((result)=>{
            console.warn("result",result);
            //inq berhasil
            if(result.status=="200"){
              if((this.state.trx_type=='2100') && (result.data.rc=="0000")){
                localStorage.setItem('private_inquiry',JSON.stringify({
                  data:result.data,
                  produk:this.state.product_id
                }))
                this.setState({inquiry:true,inqres:result.data});
              }else{
                this.setState({ message: result.data.desc });
                Swal.fire({
                  title: 'Upss!',
                  text: result.data.desc,
                  icon: 'info',
                  confirmButtonText: 'oke'
                })
              }
            }else{
              this.setState({ message: result.message.desc });
                Swal.fire({
                  title: 'Upss!',
                  text: result.message.desc,
                  icon: 'info',
                  confirmButtonText: 'oke'
                })
            }
        })
        this.setState({loading:false})
    })
  }


  Readproduct(){
    this.setState({loading:true})
    console.log(JSON.stringify(this.state))
    fetch(`${process.env.REACT_APP_URL}/loket/transactions`,{
        method: "POST",
        headers: { 
        'Content-Type': 'application/json',
        'Authorization':'Barier '+ this.state.token,
        'Accept': 'application/json'
        },
        body:JSON.stringify(this.state)
    }).then((response)=>{
        response.json().then((result)=>{
            console.warn("result",result);
            //inq berhasil
            if(result.status=="200"){
              if((this.state.trx_type=='2100') && (result.data.rc=="0000")){
                localStorage.setItem('private_inquiry',JSON.stringify({
                  data:result.data,
                  produk:this.state.product_id
                }))
                this.setState({inquiry:true,inqres:result.data});
              }else{
                this.setState({ message: result.data.desc });
                Swal.fire({
                  title: 'Upss!',
                  text: result.data.desc,
                  icon: 'info',
                  confirmButtonText: 'oke'
                })
              }
            }else{
              this.setState({ message: result.message.desc });
                Swal.fire({
                  title: 'Upss!',
                  text: result.message.desc,
                  icon: 'info',
                  confirmButtonText: 'oke'
                })
            }
        })
        this.setState({loading:false})
    })
  }

  setTypeProduct(event) {
    this.setState({
      produk: event.target.value,
      product_id: event.target.id,
    });
  }

  handleChangeIdpel(e) {
    let inIdpel = e.target.value;
    if(inIdpel=="" || inIdpel==null){
      this.setState({message:null});
    }
    this.setState({inquiry:false,trx_type:'2100',idpelStatus:false});
    if (inIdpel.length >= 11 && inIdpel.length <= 17) {
      this.setState({ message: null });
      this.setState({ idpel: inIdpel,idpelStatus:true });
      console.log(this.state.idpel)
    } else {
      this.setState({ message: "idPel mempunyai panjang 11 digit ",inquiry:false,trx_type:'2100',idpelStatus:false });
    }
  }



  render() {
    return (
      <div>
        {
          !this.state.produk?( 
            <div  className="w-100 h-100  d-flex justify-content-center">
              <Loading type="bubbles" color="#008aff" className="m-auto" />
            </div>
          ):(
            <div>
                <div>
            {
              this.state.inquiry?
              <><Redirect to='/checkoutv1' /></>
              :
              <></>
            }

              <h6 className="text-black">
                Bayar Iuran BPJS Kesehatan
              </h6>

              <div className="row mt-4">
                <div className="col-md-12" onChange={this.setTypeProduct.bind(this)}>
                {this.state.list_product.map(function (value, i) {
                    return (
                      <label className="font-weight-bold d-inline mr-3">
                      <input
                        key={i}
                        type="radio"
                        value={value.code}
                        id={value.prod_id}
                        name="bpjsType"
                        className="mr-2"
                        defaultChecked={(i === 0) ? 'check' : ''}
                        />
                      {value.name}
                    </label>
                    )
                })}
                </div>
              </div>

              <div className="row mt-4" id="durasi_kesehatan">
                <div className="col-md-12">
                  {this.state.produk == "BPJSTK" ? (
                    <OptionKetenagakerjaan />
                  ) : null}

                  {this.state.produk == "BPJSK" ? <OptionKesehatan /> : null}

                  {
                  this.state.message!=null?(
                  <div className="alert alert-warning" role="alert">
                    <label className="text-warning small">{this.state.message}</label>
                  </div>):(<></>)
                  }

                  <div className="form-group">
                    <label>Nomor Virtual Account Keluarga / Perusahaan</label>
                    <div className="row">
                      <div className="col-md-9">
                      <input
                      type="number"
                      disabled={!this.state.produk}
                      className="form-control border-bold"
                      defaultValue=""
                      placeholder="Nomor Virtual Account Keluarga / Perusahaan"
                      onKeyUp={(e) => {
                        this.handleChangeIdpel(e);
                      }}
                    />
                      </div>
                      <div className="col-md-3">
                        <LaddaButton
                        loading={this.state.loading}
                        disabled={!this.state.idpelStatus}
                        onClick={() => {
                          this.HitData();
                        }}
                        data-color="#008aff"
                        data-size={l}
                        className="m-btn m-btn-theme m-btn-radius w-100"
                        data-style={EXPAND_RIGHT}
                      >
                        Bayar Tagihan
                        </LaddaButton>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

                        
              <div
                className="card border border-primary bg-primary-300 mt-3"
                style={{ backgroundColor: "#dbeefd" }}
              >
                <div className="card-body">
                  <p className="small">
                    <b className="text-primary">Keterangan</b>
                    <br></br>
                    Silahkan masukkan 13 digit nomor VA BPJS sesuai dengan nomor kartu
                    Anda..<br></br>
                  </p>
                </div>
              </div>
            </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default bpjs;
