import Axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../router/Router";
import { useContext, useEffect } from "react";

function Direct(props) {
  const history = useHistory();
  const { state } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  // const user_role = localStorage.getItem("user_role");

  useEffect(() => {
    if (!state.isAuthenticated) {
      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Barier " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        };
        Axios.post(`${process.env.REACT_APP_URL}/login/verify`, null, config)
          .then((res) => {
            if (res.data.status === 200) {
              // console.log("perbarui status")

              dispatch({
                type: "REDATA",
                payload: res.data.data,
              });

            } else {
              console.log("else");
              dispatch({
                type: "LOGOUT",
                payload: null,
              });
              history.push("/login");
            }
          })
          .catch((err) => {
            dispatch({
              type: "LOGOUT",
              payload: null,
            });
            history.push("/login");
          });
      } else {
        dispatch({
          type: "LOGOUT",
          payload: null,
        });
        history.push("/login");
      }
    } else {
      if (state.role == "1") {
        if (window.location.pathname == "/" || window.location.pathname == "/pln") {
          history.push("/users/dashboard");
        }
      } else {
        if (window.location.pathname == "/" || window.location.pathname == "/pln") {
          history.push("/pln");
        }
      }

    }
  });
  return props.children;
}

export default Direct;
