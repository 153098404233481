import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./styles/plugin/bootstrap/css/bootstrap.min.css";
import "./styles/style/master.css";
import "./styles/plugin/font-awesome/css/all.min.css";
import "./styles/plugin/magnific/magnific-popup.css";
import "pace-js";
import "pace-js/themes/yellow/pace-theme-minimal.css";
import "antd-v3/dist/antd-v3.css";


const render = Component => (
  ReactDOM.render(
      <BrowserRouter>
          <App />
      </BrowserRouter>,
      document.getElementById('root')
  )
)

render(App)

// if (module.hot) {
//     module.hot.accept('./App', () => { render(App) })
// }

// (function(global) {
//     var console_log = global.console.log
//     global.console.log = function() {
//         if (!(
//             arguments.length == 1 &&
//             typeof arguments[0] === 'string' &&
//             arguments[0].match(/^\[(HMR|WDS)\]/)
//         )) {
//             // console_log.apply(global.console,arguments)
//         }
//     }
// })(window)

serviceWorker.register();
