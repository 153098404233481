import React from 'react';
import NumberFormat from 'react-number-format';
export const PlnPrepaid = (props) => (
    <>

        {
            props.type == "print" ? (
                <>
                    <tr>
                        <td style={{borderRight: "1px solid black"}}>
                            {"No Meter      : " + props.struk.material_number}<br />
                            {"ID Pelanggan  : " + props.struk.subscriber_id}<br />
                            {"Nama          : " + props.struk.subscriber_name}<br />
                            {"Trf/Daya      : " + props.struk.subscriber_segmentation + "/" + props.struk.power}<br />
                            {"No Ref        : " + props.struk.switcher_refno}<br />
                            {"Materai       : "}<NumberFormat value={props.struk.meterai} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"PPN           : "}<NumberFormat value={props.struk.ppn} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"PPJ           : "}<NumberFormat value={props.struk.ppj} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Angsuran      : "}<NumberFormat value={props.struk.angsuran} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Rp Token      : "}<NumberFormat value={props.struk.power_purchase} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Jml KWH       : " + props.struk.jml_kwh}<br />
                            {"Token         : "}<br /><NumberFormat format="####-####-####-####-####" value={props.struk.token} displayType={"text"} renderText={(value) => <>{value}</>} /><br />
                            {"Denom         : "}<NumberFormat value={props.struk.amount - props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Bank      : "}<NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Loket     : "}<NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Rp Bayar      : "}<NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                        </td>
                    </tr>
                </>
            ) : (
                <>
                    <tr>
                        <td>No Meter</td>
                        <td width="3%"> : </td>
                        <td className="text-left">{props.struk.material_number}</td>
                    </tr>
                    <tr>
                        <td>ID Pelanggan</td>
                        <td> : </td>
                        <td>{props.struk.subscriber_id}</td>
                    </tr>
                    <tr>
                        <td>Nama</td>
                        <td> : </td>
                        <td>{props.struk.subscriber_name}</td>
                    </tr>

                    <tr>
                        <td>Trf/Daya</td>
                        <td> : </td>
                        <td>{props.struk.subscriber_segmentation}/{props.struk.power}</td>
                    </tr>

                    <tr>
                        <td>No Ref</td>
                        <td> : </td>
                        <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>{props.struk.switcher_refno}</td>
                    </tr>

                    <tr>
                        <td>Materai</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.meterai} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr>
                        <td>PPN</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.ppn} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr>
                        <td>PPJ</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.ppj} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr>
                        <td>Angsuran</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.angsuran} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr>
                        <td>Rp Token</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.power_purchase} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Jml KWH</td>
                        <td> : </td>
                        <td>{props.struk.jml_kwh}</td>
                    </tr>
                    <tr>
                        <td>Token</td>
                        <td> : </td>
                        <td style={{ wordWrap: "break-word", maxWidth: "100px", fontWeight: "bold" }}>
                            <NumberFormat
                                format="#### - #### - #### - #### - ####"
                                value={props.struk.token}
                                displayType={"text"}
                                renderText={(value) => <div>{value}</div>}
                            /></td>
                    </tr>

                    <tr>
                        <td>Denom</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.amount - props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr>
                        <td>Adm Bank</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Loket</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr>
                        <td>Rp Bayar</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                </>)
        }
    </>
);