import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../../router/Router";

function ResetPassword(props) {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [chaptha, setChaptha] = useState(false);
  const [registers, setRegisters] = useState(false);
  const recaptchaRef = React.createRef();

  const { dispatch, state } = useContext(AuthContext);

  // console.log(state.isAuthenticated);
  if (state.isAuthenticated) {
    return <Redirect to="/" />;
  }

  // get token from url
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("q");
  // console.log("token = > "+token);

  const onSubmit = (data, e) => {
    if (chaptha) {
      setMessage({
        data: "Save Password is in progress...",
        type: "alert-warning",
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + token,
          Accept: "application/json",
        },
      };
      Axios.post(
        `${process.env.REACT_APP_URL}/mobile/process/changepassword`,
        JSON.stringify(data),
        config
      ).then((res) => {
          // console.log(res.data);
          if (res.status == 200) {
            setMessage({
              data: res.data.message,
              type: "alert-success",
            });

            // console.warn("resp", res.data.data)
            window.location.replace('/resetpassword/done');
          } else {
            // console.log(res);
            setMessage({
              data: res.message,
              type: "alert-danger",
            });
          }
          !res.data.status && e.target.reset();
          window.grecaptcha.reset();
        })
        .catch((err) => {
          if (err.response && err.response.data.status == 500) {
            setMessage({
              data: "Maaf, website belum dapat diakses. Sedang dilakukan maintenance. Coba beberapa saat lagi",
              type: "alert-danger",
            });
          }else if(err.response.data.status == 403){
            console.log("masuk")
            setMessage({
              title: "GAGAL menyimpan kata sandi.",
              data: err.response.data.message,
              type: "alert-danger",
            });
          } else {
            setMessage({
              data: "Ganti Password Gagal. Silahkan ulangi proses ganti password",
              type: "alert-danger",
            });
          }
          // !res.data.status && e.target.reset();
          window.grecaptcha.reset();
        });
    } else {
      setMessage({
        data: "Chek Rechaptha",
        type: "alert-warning",
      });
    }
  };

  const onChange = (value) => {
    if (value) {
      setChaptha(true);
    }

    // console.log(chaptha);
  };

  return (
    <div className="bg-primary-admin">
      <div className="container">
        <div className="row vh-100">
          <div className="col-md-5 m-auto">
            <div className="card shadow">
              <div className="card-body">
                <div className="text-center p-3">
                  <h2>Atur Ulang Kata Sandi</h2>
                  <h4><span className="small">Silahkan buat kata sandi baru</span></h4>
                  {message && (
                    <div
                      className={`alert fade show d-flex ${message.type}`}
                      role="alert">
                      {message.title}
                      <br></br>
                      Keterangan :
                      <br></br>
                      {message.data}
                      <span
                        aria-hidden="true"
                        className="ml-auto cursor-pointer"
                        onClick={() => setMessage(null)}>
                        &times;
                      </span>
                    </div>
                  )}
                </div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  autoComplete="off">
                  <div className="row">
                    <div className="col-md-9 col-11 m-auto">
                      {errors.username && (
                        <span className="small text-danger text-center">
                          {errors.username.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="input-group col-md-10 col-sm-12 m-auto">
                      <div className="input-group-prepend">
                        <div className="input-group-text" id="btnGroupAddon2"><i className="fa fa-lock text-secondary"></i></div>
                      </div>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="inputForPassword"
                        placeholder="Kata Sandi Baru"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter password",
                          },
                          minLength: {
                            value: 8,
                            message: "Minimum 8 characters are allowed",
                          },
                          maxLength: {
                            value: 62,
                            message: "Maximum 62 characters are allowed",
                          },
                        })}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-9 col-11 m-auto">
                        {errors.password && (
                          <span className="small text-center text-danger">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="mt-4">
                    <div className="input-group col-md-10 col-sm-12 m-auto">
                      <div className="input-group-prepend">
                        <div className="input-group-text" id="btnGroupAddon2"><i className="fa fa-lock text-secondary"></i></div>
                      </div>
                      <input
                        type="password"
                        name="confirm_password"
                        className="form-control"
                        id="inputForConfirmPassword"
                        placeholder="Konfirmasi Kata Sandi"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter password",
                          },
                          minLength: {
                            value: 8,
                            message: "Minimum 8 characters are allowed",
                          },
                          maxLength: {
                            value: 62,
                            message: "Maximum 62 characters are allowed",
                          },
                        })}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-9 col-11 m-auto">
                        {errors.password && (
                          <span className="small text-center text-danger">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </div>

                  </div>

                  <div className="mt-4 mb-4 text-center d-flex">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LfHMCEaAAAAAKjWZtDNjzWaaClaj1G2KHyAD1iA"
                      onChange={onChange}
                      className="m-auto"
                    />
                  </div>
                  <div className="col-md-10 col-sm-12 m-auto">
                    <button type="submit" className="btn btn-block  btn-primary">
                     Simpan Kata Sandi <i className="fa fa-chevron-circle-right ml-2"></i>
                    </button>
                  </div>

                  <div className="text-center mt-3">
                    <label
                      htmlFor="copiright"
                      className="small text-mute m-auto">
                      © PT. Pelangi Indodata 2020
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
