import React, { Component } from "react";
import {
  FaAlignRight,
  FaTrash,
} from "react-icons/fa";
import { Route} from "react-router-dom";
import { NavLink } from "react-router-dom";
import { createBrowserHistory } from "history";
import { FaBolt } from "react-icons/fa";
import { FaHospitalAlt } from "react-icons/fa";
//conponent produk
import Pln from "../batch/pln";
import Pulsa from "../products/pulsa";
import Bpjs from "../batch/bpjs";
import Telkom from "../products/telkom";
import Pdam from "../products/pdam";
import Data from "../products/data";
// import Game from '../products/game';
import SideBatch from "./SideBatch";

import Info from "./info";
import Avatar from "react-avatar";

class MenuBatch extends Component {
  constructor() {
    super();
    this.state = {
      cart: [],
    };
  }

  componentDidMount() {
    this.setState({
      cart: JSON.parse(localStorage.getItem("allcart")),
    });
  }

  componentWillUnmount() {
    this.setState({
      cart: JSON.parse(localStorage.getItem("allcart")),
    });
  }
  // struk: JSON.parse(localStorage.getItem("private_inquiry")),
  render() {
    const history = createBrowserHistory();
    return (
      <div>
        {/* <SideBatch/> */}
        <section className="section g-bg-gray p-0px-t bg-section my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8 col-lg-8 col-12 m-15px-tb">
                <div className="card border-radius-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2 col-lg-1 col-sm-2 col-3 mt-2 mb-1">
                        <NavLink
                          className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0"
                          to="/transaction/pln"
                          activeClassName="bg-primary white-color theme-hover-bg"
                          style={{ minWidth: "48px" }}>
                          <div className="only-icon-30 d-inline-block w-100">
                            <FaBolt />
                          </div>
                          <b className="m-0px small mt-2">PLN</b>
                        </NavLink>
                      </div>
                      <div className="col-md-2 col-lg-1 col-sm-2 col-3 mt-2 mb-1">
                        <NavLink
                          className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                          to="/transaction/bpjs"
                          activeClassName="bg-primary white-color theme-hover-bg"
                          style={{ minWidth: "48px" }}>
                          <div className="only-icon-30 d-inline-block w-100">
                            <FaHospitalAlt />
                          </div>
                          <b className="m-0px small mt-2">BPJS</b>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>

                {history.location.pathname == "/" ? (
                  <div>
                    <Info />
                  </div>
                ) : (
                  <div className="card mt-2">
                    <div className="card-body">
                      <Route path="/transaction/pln" component={Pln} />
                      <Route path="/transaction/pulsa" component={Pulsa} />
                      <Route path="/transaction/bpjs" component={Bpjs} />
                      <Route path="/telkom" component={Telkom} />
                      <Route path="/pdam" component={Pdam} />
                      <Route path="/data" component={Data} />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-4 col-lg-4 col-12 m-15px-tb">
                <div className="card h-100">
                  <div className="card-header text-center">
                    <b>
                      Cart{" "}
                      <Avatar
                        name={"1"}
                        size={25}
                        round={true}
                        className="ml-2 m-auto"
                      />
                    </b>
                  </div>
                  <div className="card-body">
                    <div>
                      {this.state.cart ? (
                        <div className="row">
                          {this.state.cart.map((value, index) => {
                            return (
                              <div className="col-md-12 p-1">
                                <div className="card rounded-2 norder-0">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-2 m-auto text-center">
                                        <a href="">
                                          <FaTrash className="text-warning m-auto fa-1x" />
                                        </a>
                                      </div>
                                      <div className="col-9">
                                        <h6>
                                          PLN PREPAID - {value.subscriber_id}{" "}
                                        </h6>
                                        ( {value.subscriber_name} )
                                        <b>
                                          Rp
                                          {value.amount || value.admin_charge}
                                        </b>
                                      </div>
                                      <div className="col-1 m-auto text-center">
                                        <a href="">
                                          <FaAlignRight className="text-dark m-auto fa-1x" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>KOSONG</div>
                      )}
                    </div>
                  </div>

                  <div className="card-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table">
                          <tbody>
                            <tr className="table-borderless">
                              <td className="text-left">
                                <h6>Subtotal</h6>
                              </td>
                              <td className="text-right">Rp.612.000</td>
                            </tr>
                            <tr>
                              <td className="text-left">
                                <h6>Admin Bank</h6>
                              </td>
                              <td className="text-right">Rp.2.500</td>
                            </tr>
                            <tr>
                              <td className="text-left">
                                <h6>Total</h6>
                              </td>
                              <td className="text-right">Rp.614.500</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <a href="#" className="btn btn-primary btn-lg w-100">
                      PROSES PEMBELIAN
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MenuBatch;
