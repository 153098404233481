import React from 'react';
import NumberFormat from 'react-number-format';

const NamaBulan = ({data}) => {
    data =  parseInt(data);
    let bulan = ['JANUARI','FEBRUARI','MARET','APRIL','MEI','JUNI','JULI','AGUSTUS','SEPTEMBER','OKTOBER','NOVEMBER','DESEMBER',]
    return bulan[data-1];
}

export const PlnPostpaid = (props) => (
    <>

        {
            props.type == "print" ? (
                <>
                    <tr>
                        <td style={{borderRight: "1px solid black"}}>
                            {"ID Pelanggan  : " + props.struk.subscriber_id}<br />
                            {"Nama          : " + props.struk.subscriber_name}<br />
                            {"Trf/Data      : " + props.struk.subscriber_segmentation + "/" + props.struk.power}<br />
                            {"BL/TH         : " + props.struk.blth_summary}<br />
                            {"Stan Meter    : " + props.struk.stand_meter_summary.replace(" ", "").replace(" ", "")}<br />
                            {"No Ref        : " + props.struk.switcher_refno}<br />
                            {"Rp Tag        : "}<NumberFormat value={props.struk.rptag} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Bank      : "}<NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Loket     : "}<NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Rp Bayar      : "}<NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            <br/>{"Rincian Tagihan  : "}<br />
                            {parseInt(props.struk.bill_status)>0 ? (
                                <>
                                {props.struk.bills.map((dt)=>{
                                 return(
                                    <>
                                    <NamaBulan data={dt.bill_period[0].slice(4)}/>-{dt.bill_period[0].slice(0,4)} : 
                                    <NumberFormat value={parseInt(dt.penalty_fee[0])+parseInt(dt.total_electricity_bill[0])} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <>{value}</>} /><br/>
                                    </>
                                 )})  
                                }
                                </>
                            ):(<></>)}
                            {"  "}<br />

                        </td>
                    </tr>
                </>
            ) : (
                <>
                    <tr>
                        <td>ID Pelanggan</td>
                        <td width="3%"> : </td>
                        <td className="text-left">{props.struk.subscriber_id}</td>
                    </tr>
                    <tr>
                        <td>Nama</td>
                        <td> : </td>
                        <td>{props.struk.subscriber_name}</td>
                    </tr>

                    <tr>
                        <td>Trf/Daya</td>
                        <td> : </td>
                        <td>{props.struk.subscriber_segmentation}/{props.struk.power}</td>
                    </tr>

                    <tr>
                        <td>BL/TH</td>
                        <td> : </td>
                        <td>{props.struk.blth_summary}</td>
                    </tr>

                    <tr>
                        <td>Stan Meter</td>
                        <td> : </td>
                        <td>{props.struk.stand_meter_summary}</td>
                    </tr>

                    <tr>
                        <td>No Ref</td>
                        <td> : </td>
                        <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>{props.struk.switcher_refno}</td>
                    </tr>

                    <tr>
                        <td>Rp Tag</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.rptag} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Bank</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Loket</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr>
                        <td>Rp Bayar</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr>
                        <td>Rp Bayar</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    <tr style={{alignItems:'center',textAlign:'center'}}>
                        <td colSpan={3} align="center">Rincian Tagihan</td>
                    </tr>
                    
                    {/* chek apakah tagihan ada lebih dari 1 */}
                    { (parseInt(props.struk.bill_status)> 0) ? (
                    <>
                        {props.struk.bills.map((dt)=>{
                            return (<tr>
                                <td><NamaBulan data={dt.bill_period[0].slice(4)}/><br/>{dt.bill_period[0].slice(0,4)}</td>
                                <td> : </td>
                                <td class='text-left'><NumberFormat value={parseInt(dt.penalty_fee[0])+parseInt(dt.total_electricity_bill[0])} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
                            </tr>);
                        })}
                    </>

                    ):(
                        <></>
                    )}
                </>
            )}
    </>
);