import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../router/Router";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Axios from "axios";
import ReactLoading from "react-loading";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import ReactTable from "react-table";
import { Redirect } from "react-router-dom";

const createHistory = require("history").createBrowserHistory;
const token = localStorage.getItem("token");

function ManualAdvice(props) {
  const { dispatch, state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filIdpel, setFilIdpel] = useState();
  const [isAuth, setAuth] = useState(0);
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"3%"}
      width={"3%"}
      className="d-flex justify-content-center"
    />
  );
  let history = createHistory();
  const getData = async () => {
    await setIsLoading(true);
    await state;
    // console.log(state.token);
    // console.log(state);
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_URL}/transactions/check/advice/list`,
        {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        }
      );
      // console.warn('response', response.data)
      if (response.data.status == "200") {
        await setData(response.data.data);
      }
    } catch (e) {
      if (e.response.data.status == 401) {
        await setAuth(401);
      } else {
        Swal.fire({
          title: "",
          text: e.response.data.message,
          icon: "info",
        })
      }
    }
    await setIsLoading(false);
  };

  async function Filter() {
    await setIsLoading(true);
    await state;
    if (startDate == null || endDate == null) {
      Swal.fire({
        title: 'Maaf',
        text: "Lengkapi filter tanggal",
        icon: 'info',
        confirmButtonText: 'oke'
      });
    } else {
      fetch(process.env.REACT_APP_URL + '/transactions/check/advice/list?idpel=' + filIdpel + '&start_date=' + startDate + '&end_date=' + endDate, {
        method: "GET",
        headers: {
          'Authorization': "Barier " + state.token,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }).then(async (response) => {
        await response.json().then(async (result) => {
          // console.warn("result", result);
          if (result.status == "200") {
            await setData(result.data);
          } else {
            Swal.fire({
              title: 'Maaf',
              text: result.message,
              icon: 'error',
              confirmButtonText: 'oke'
            });
          }
        })
      })
    }
    await setIsLoading(false);
  };
  async function prosesAdvice(id) {
    await state;
    Swal.fire({
      title: 'Proses transaksi ini?',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Proses",
      cancelButtonText: "Batal",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        // if (result.isConfirmed) {
        return fetch(process.env.REACT_APP_URL + '/transactions/check/advice/' + id, {
          method: "POST",
          headers: {
            'Authorization': "Barier " + state.token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }).then(response => {
          return response.json();
        }).catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        await getData();
        if (result.value.status == "200") {
          await Swal.fire({
            title: "Status Transaksi",
            icon: 'success',
            text: result.value.message,
            confirmButtonText: 'oke'
          });
        } else if (result.value.status == "201") {
          await Swal.fire({
            title: "Status Transaksi",
            icon: 'info',
            text: result.value.message.data.trx.desc + " [ " + result.value.message.data.trx.rc + " ]",
            confirmButtonText: 'oke'
          });
        } else {
          Swal.fire({
            title: "Maaf",
            text: result.value.status + " [ " + result.value.message + " ]",
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
      }
    })
  }
  async function Reset() {
    await setIsLoading(true);
    // await setFilIdpel();
    // await setStartDate();
    // await setEndDate();
    await getData();
    await setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    getData();
    setIsLoading(false);
  }, []);
  const columns = [
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Tanggal Transaksi</div>
      ),
      accessor: "created_at",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <Moment format="YYYY-MM-DD - HH:mm">{row.original.created_at}</Moment>
        </div>
      ),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>ID Transaksi</div>
      ),
      accessor: "trx_id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "left", color: "black" }}>Produk</div>
      ),
      accessor: "name",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "left", color: "black" }}>ID Pelanggan</div>
      ),
      accessor: "idpel",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Jumlah Pembayaran</div>
      ),
      accessor: "info_total_bayar",
      style: {
        textAlign: "right",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Tipe Bayar</div>
      ),
      accessor: "payment_type",
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },

    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
      ),
      maxWidth: "100",
      accessor: "approve_status",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <LaddaButton
            loading={isLoading}
            className="btn btn-warning"
            data-size='s'
            onClick={() => {
              prosesAdvice(row.original.id);
            }}
          >ADVICE</LaddaButton>
        </div>
      )
    },
  ];
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  }
  return (

    <div className="bg-admin">
      <section className="section p-0px-t bg-admin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow mb-3 mt-2">
                <div className="card-header bg-light d-flex justify-content-between">
                  <div>
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                          Filter data yang akan dicari
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="text-dark">Tanggal Awal</label>
                        <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} value={startDate} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="text-dark">Tanggal Akhir</label>
                        <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} value={endDate} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="text-dark">ID Pelanggan</label>
                        <input type="text" className="form-control" onChange={(e) => setFilIdpel(e.target.value)} value={filIdpel} />
                      </div>
                    </div>
                  </div>
                {/* </div>
                <div className="card-footer"> */}
                  <div className="row mt-2">
                    <div className="col-md-2 col-6">
                      <LaddaButton
                        loading={isLoading}
                        className="btn btn-primary m-btn-radius w-100"
                        data-size='s'
                        onClick={() => Filter()}
                      >Terapkan Filter</LaddaButton>
                    </div>
                    <div className="col-md-2 col-6">
                      <LaddaButton
                        loading={isLoading}
                        className="btn btn-danger m-btn-radius w-100"
                        data-size='s'
                        onClick={Reset}
                      >Reset Filter</LaddaButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow">
                <div
                  className="card-header bg-light d-flex justify-content-between">
                  <div>
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-sync text-primary h1 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">DATA TRANSAKSI</span> <br />
                          Cek status transaksi secara manual
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="card-body pb-5">

                  {isLoading ? (
                    <Loading type="spinningBubbles" color="#91091e" />
                  ) : (
                    <div>
                      <ReactTable
                        // defaultSorted={[{ id: "tanggal", desc: true }]}
                        data={data}
                        //   defaultPageSize={isDataSaldo.length >= 10 ? 10 : 5}
                        defaultPageSize={5}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ManualAdvice;
