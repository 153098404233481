import React, { PureComponent } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading';
import LaddaButton from "react-ladda";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Select from "react-select";
import * as XLSX from 'xlsx';
import { Form } from 'antd-v3';
import Moment from "react-moment";
import moment from 'moment';
import '../CSS/App.css';

const Loading = ({ type, color }) => (
<ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);


class ManualTopupSaldo extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            dataDenom: [],
            DataProductID: [],
            product_id: "",
            loading: false,
            login: false,
            token: localStorage.getItem("token"),
            store: null,
            setIsOpen: false,
            denom: "",
            description: "",
            active: 1,
            valOptProdID: "",
            toogleBtn: true,
            typeModal: "",
            pertanyaan: "",
            jawaban: "",
            prod_id: "",
            code: "",
            vcode: "",
            name: "",
            description: "",
            product_category_id: "",
            nominal: "",
            status: "",
            disabled: false,
            f_prod_id: "",
            f_name: "",
            f_status: "",
            dataCatProd: [],
            setIsOpenMulti: false,
            selectedFile: null,
            dataMulti: null,
            prodCatMulti: "",
            jsonDataArray: [],
            tableColumns: [],
            fixData: [],
            showButton: false,
            price:null,
            price_id:null,
            dataProduk:[],
            setIsOpenTemplate: false,
            dataProdType: [],
            prodType: "",
            product_type: "",
            prodCatId: "",
            dataCatProd2: [],
            dataGenerate:[],
            showButtonGen: false,
            dataFull:[],
            username: "",
            selectedOption: null,
        };
    }

    componentDidMount = async () => {
        await this.setState({ loading: true });
        await this.fetchUsername();
        
    };

    handleSelectChange = async (selectedOption) => {
        await this.setState({ selectedOption });
        // console.log(selectedOption);
        await this.filterData();
        this.setState({nominal: ""});
    };

    formatOptionLabel = ({ label, value }) => (
        <div>
            <label>{label}</label>
        </div>
    );

    handleAmountChange = (e) => {
        const { value } = e.target;
    
        // Menghapus tanda koma dari nilai sebelumnya dan menggabungkan angka saja
        const numericValue = value.replace(/,/g, "");

        // Menerapkan pemisah ribuan dengan tanda koma setiap tiga digit
        const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        this.setState({ nominal: formattedValue });
    };
    
    fetchUsername = async () => {
        await fetch(`${process.env.REACT_APP_URL}/users?user_roles=2`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
        },
        }).then((response) => {
            response.json().then((result) => {
                // console.warn("result", result);
                this.setState({ loading: false, dataProdType: result.data });
            });
        });
    };

    filterData = () => {
        this.setState({ loading: true });
        const today = moment().format('YYYY-MM-DD');
        axios
        .get(
            `${process.env.REACT_APP_URL}/saldo/list?topup_user_id=`+this.state.selectedOption.data.id+`&start_date=`+today+`&end_date=`+today,
            
            {
            headers: {
                Authorization: "Bearer " + this.state.token,
                "Content-Type": "application/json",
            },
            },
            
        )
        .then((response) => {
            this.setState({ loading: false, dataDenom: response.data.data });
        });
    };

    prosesTopup = async () => {
        if(this.state.selectedOption==null){
            Swal.fire({
                title: 'Warning',
                text: 'Silahkan pilih reseller terlebih dahulu',
                icon: 'warning',
            });
            return;
        }
        if(this.state.nominal==null||this.state.nominal==""){
            Swal.fire({
                title: 'Warning',
                text: 'Silahkan isi nominal terlebih dahulu',
                icon: 'warning',
            });
            return;
        }
        const pNominal = this.state.nominal.replace(/,/g, "");
        Swal.fire({
            title: 'Apakah Anda yakin?',
            html: 'Melakukan proses Topup ke <br>Akun : <b>'+this.state.selectedOption.data.name+'</b><br> Nominal : <b>'+this.state.nominal+'</b>',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${process.env.REACT_APP_URL}/saldo/topup/byadmin`,
                { 
                    topup_user_id: this.state.selectedOption.data.id,
                    topup_nominal: pNominal,
                },
                {
                    headers: {
                        Authorization: "Bearer " + this.state.token,
                        "Content-Type": "application/json",
                    },
                }
                ).then((result) => {
                    if (result.data.status == 200) {
                        Swal.fire({
                            title: "Sukses",
                            text: "Topup berhasil",
                            icon: "success",
                            confirmButtonText: "Tutup",
                        });
                        this.setState({nominal: ""});
                        this.filterData();
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: result.data.message.msg,
                            icon: "error",
                            confirmButtonText: "Tutup",
                        });
                    }
                });
            } else {
        
            }
        });
    };

    reset = async () => {
        this.setState({ selectedOption: null, nominal: "", dataDenom :[]});
        // this.setState({ nominal: ""});
    };

    render() {
        const columns = [
            {
                Header: () => (
                <div style={{ textAlign: "center", color: "black" }}>USER</div>
                ),
                accessor: "username",
                maxWidth: 180,
                style: {
                    textAlign: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                },
            },
            {
                Header: () => (
                <div style={{ textAlign: "center", color: "black" }}>LOKET UTAMA</div>
                ),
                accessor: "loket_utama",
                maxWidth: 180,
                style: {
                    textAlign: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                },
            },
            {
                Header: () => (
                <div style={{ textAlign: "center", color: "black" }}>JUMLAH SALDO</div>
                ),
                accessor: "nominal",
                maxWidth: 180,
                style: {
                    textAlign: "right",
                    flexDirection: "column",
                    justifyContent: "center",
                },
                Cell: (props) =>
                new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                }).format(props.value),
            },
            {
                Header: () => (
                  <div style={{ textAlign: "center", color: "black" }}>Status</div>
                ),
                accessor: "status",
                style: {
                  textAlign: "center",
                  textTransform: "uppercase",
                  flexDirection: "column",
                  justifyContent: "center",
                },
                Cell: (row) => (
                  <div>
                    {row.original.status == "WAITING" ? (
                        <>
                            <span className="badge badge-warning">WAITING</span>
                        </>
                    ) : (
                        <>
                            {row.original.status == "REJECT" ||
                            row.original.status == "REJECTED" ? (
                            <>
                                <span className="badge badge-danger">DITOLAK</span>
                            </>
                            ) : (
                            <>
                                {row.original.status == "EXPIRED" ||
                                row.original.status == "EXPIRE" ? (
                                <>
                                    <span className="badge badge-secondary">
                                    KADALUARSA
                                    </span>
                                </>
                                ) : (
                                <>
                                    {row.original.status == "APPROVED" ||
                                    row.original.status == "APPROVE" ? (
                                    <>
                                        <span className="badge badge-success">
                                        DISETUJUI
                                        </span>
                                    </>
                                    ) : (
                                    <>N/A</>
                                    )}
                                </>
                                )}
                            </>
                        )}
                      </>
                    )}
                  </div>
                ),
            },
            {
                Header: () => (
                <div style={{ textAlign: "center", color: "black" }}>CREATE AT</div>
                ),
                accessor: "created_at",
                id: 'created_at',
                maxWidth: 180,
                style: {
                    textAlign: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                },
                Cell: (row) => (
                    <div>
                        <Moment format="DD MMM YYYY - HH:mm:ss">
                        {row.original.created_at}
                        </Moment>
                    </div>
                ),
            },
        ];

        const optUsername = this.state.dataProdType.map((d) => ({
            value: d.id,
            label: d.name.toUpperCase(),
            name: "username",
            data: d,
        }));

        const { selectedOption, nominal } = this.state;

        const currencyFormat = new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          });

        return (
            <div className="bg-admin">
                <section className="section p-0px-t bg-admin">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="card shadow mt-2 mb-2 mr-2 col-md-3">
                                        <div className="card-header bg-light">
                                            <div>
                                            <table className="text-primary">
                                                <tr>
                                                <td rowspan="2"><i className="fa fa-file text-primary h3 mr-2"></i></td>
                                                <td>
                                                    <span className="font-weight-bold">MANUAL TOPUP SALDO</span> <br />
                                                </td>
                                                </tr>
                                            </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div class="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            Reseller
                                                        </label>
                                                        <Select
                                                            name="username"
                                                            options={optUsername}
                                                            value={selectedOption}
                                                            onChange={this.handleSelectChange}
                                                            placeholder={<div>Pilih Username</div>}
                                                            isDisabled = {(this.state.disabled)? "true" : ""}
                                                            formatOptionLabel={this.formatOptionLabel}
                                                            className="top-select"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div class="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            Nominal
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="nominal"
                                                            id="nominal"
                                                            className="form-control"
                                                            required="required"
                                                            value={nominal}
                                                            onChange={this.handleAmountChange}
                                                            disabled = {(this.state.disabled)? "disabled" : ""}
                                                            min="0"
                                                            placeholder="Masukkan Nominal"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div class="col-md-6">
                                                    <div className="form-group">
                                                        <LaddaButton
                                                            loading={this.state.loading}
                                                            onClick={() => {
                                                                this.prosesTopup(this.state.typeModal);
                                                            }}
                                                            className="btn btn-primary col-md-12 hidden-button"
                                                            >
                                                            Simpan
                                                        </LaddaButton>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div className="form-group">
                                                        <LaddaButton
                                                            loading={this.state.loading}
                                                            onClick={() => {
                                                                this.reset();
                                                            }}
                                                            className="btn btn-danger col-md-12 hidden-button"
                                                            >
                                                            Reset
                                                        </LaddaButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow mt-2 mb-2 mr-2 col-md-4">
                                        <div className="card-header bg-light">
                                            <div>
                                            <table className="text-primary">
                                                <tr>
                                                <td rowspan="2"><i className="fa fa-file text-primary h3 mr-2"></i></td>
                                                <td>
                                                    <span className="font-weight-bold">INFORMASI AKUN</span> <br />
                                                </td>
                                                </tr>
                                            </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div class="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>Nama</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {selectedOption ? (
                                                                <>
                                                                    {selectedOption && (
                                                                        <div>
                                                                            {selectedOption.data.name ? (
                                                                                <p>{selectedOption.data.name.toUpperCase()}</p>
                                                                            ):(
                                                                                <strong>-</strong>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </> 
                                                            ): (
                                                                <>
                                                                    <strong>-</strong>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>Username</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {selectedOption ? (
                                                                <>
                                                                    {selectedOption && (
                                                                        <div>
                                                                            {selectedOption.data.username ? (
                                                                                <p>{selectedOption.data.username}</p>
                                                                            ):(
                                                                                <strong>-</strong>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </> 
                                                            ): (
                                                                <>
                                                                    <strong>-</strong>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>Email</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {selectedOption ? (
                                                                <>
                                                                    {selectedOption && (
                                                                        <div>
                                                                            {selectedOption.data.email ? (
                                                                                <p>{selectedOption.data.email}</p>
                                                                            ):(
                                                                                <strong>-</strong>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </> 
                                                            ): (
                                                                <>
                                                                    <strong>-</strong>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>Phone</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {selectedOption ? (
                                                                <>
                                                                    {selectedOption && (
                                                                        <div>
                                                                            {selectedOption.data.phone ? (
                                                                                <p>{selectedOption.data.phone}</p>
                                                                            ):(
                                                                                <strong>-</strong>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </> 
                                                            ): (
                                                                <>
                                                                    <strong>-</strong>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow mt-2 mb-2 col-md-4">
                                        <div className="card-header bg-light">
                                            <div>
                                            <table className="text-primary">
                                                <tr>
                                                <td rowspan="2"><i className="fa fa-file text-primary h3 mr-2"></i></td>
                                                <td>
                                                    <span className="font-weight-bold">INFORMASI HTH</span> <br />
                                                </td>
                                                </tr>
                                            </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div class="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>Accunt HTH</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {selectedOption ? (
                                                                <>
                                                                    {selectedOption && (
                                                                        <div>
                                                                            {selectedOption.data.account_username ? (
                                                                                <p>{selectedOption.data.account_username}</p>
                                                                            ):(
                                                                                <strong>-</strong>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </> 
                                                            ): (
                                                                <>
                                                                    <strong>-</strong>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>Loket Name</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {selectedOption ? (
                                                                <>
                                                                    {selectedOption && (
                                                                        <div>
                                                                            {selectedOption.data.loket_utama ? (
                                                                                <p>{selectedOption.data.loket_utama.toUpperCase()}</p>
                                                                            ):(
                                                                                <strong>-</strong>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </> 
                                                            ): (
                                                                <>
                                                                    <strong>-</strong>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="card shadow mb-2 ml-2 mr-2 col-md-11">
                                        <div className="card-header bg-light">
                                            <div>
                                            <table className="text-primary">
                                                <tr>
                                                <td rowspan="2"><i className="fa fa-file text-primary h3 mr-2"></i></td>
                                                <td>
                                                    <span className="font-weight-bold">RIWAYAT TOPUP SALDO</span> <br />
                                                </td>
                                                </tr>
                                            </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            {this.state.loading ? (
                                            <Loading
                                                type="bubbles"
                                                color="#008aff"
                                                className="text-center"
                                            />
                                            ) : (
                                            <ReactTable
                                                data={this.state.dataDenom}
                                                // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                                                defaultPageSize={this.state.dataDenom.length > 10 ? 10 : 10}
                                                columns={columns}
                                                className="mt-3 table-striped w-100"
                                                defaultSorted={[{ id: 'created_at', desc: true }]}
                                            />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
    }

    export default ManualTopupSaldo;