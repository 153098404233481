import React from "react";
// import Style from "./Style";

export function Syarat({message,index}) {
  return (
    <div 
      className={"messageinfo " + (message.open ? 'open' : '')}
      key={index}
      
    >
      <div className={"message-"+message.type}>
        {index}. {message.content}
      </div>
    </div>
  )
}
export default Syarat;