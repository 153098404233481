import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Redirect } from "react-router-dom";
import ReactLoading from "react-loading";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);

class ListProduk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
      login: false,
      token: localStorage.getItem("token"),
      store: null,
      options: [
        {
          name: "PDAM",
          value: "62",
        },
        {
          name: "PLN",
          value: "42",
        },
        {
          name: "BPJS",
          value: "79",
        },
        {
          name: "TELKOM",
          value: "43",
        },
      ],
      options_pulsa: [
        {
          name: "SMARTFREN",
          value: "61",
        },
        {
          name: "AXIS",
          value: "55",
        },
        {
          name: "INDOSAT",
          value: "58",
        },
        {
          name: "TELKOMSEL",
          value: "59",
        },
        {
          name: "THREE",
          value: "60",
        },
        {
          name: "XL",
          value: "50",
        },
      ],
      value: "62",
      size: 0,
    };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    console.log(this.state.value);
  };

  getproduct() {
    this.setState({ loading: true });
    fetch(process.env.REACT_APP_URL + "/products?product_category_id=" + this.state.value, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
      },
    }).then((response) => {
      response.json().then((result) => {
        console.warn("result", result.data.length);
        this.setState({
          loading: false,
          users: result.data,
          size: result.data.length,
        });
      });
    });
  }

  render() {
    const { options, value, options_pulsa } = this.state;
    const columns = [
      {
        Header: "#",
        maxWidth: 70,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Produk</div>
        ),
        accessor: "name",
        style: {
          textAlign: "left",
          textTransform: "uppercase",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Harga</div>
        ),
        accessor: "price",
        maxWidth: 200,
        style: {
          textAlign: "right",
        },
        Cell: (row) => (
          <div>
            {row.original.product_category_id == 61 || row.original.product_category_id == 55 || row.original.product_category_id == 58 || row.original.product_category_id == 59 || row.original.product_category_id == 60 || row.original.product_category_id == 50 ? (
              new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              }).format(row.original.price)

            ) : (
              <>-</>
            )}
          </div>
        )
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Biaya Admin</div>
        ),
        accessor: "admin_fee",
        maxWidth: 200,
        style: {
          textAlign: "right",
        },
        Cell: (props) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(props.value),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Biaya Loket</div>
        ),
        accessor: "loket_fee",
        maxWidth: 200,
        style: {
          textAlign: "right",
        },
        Cell: (props) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(props.value),
      },
    ];

    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mb-3 mt-2">
                  <div className="card-header bg-light">
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-money-bill text-primary h1 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">INFORMASI HARGA PRODUK</span> <br />
                          Daftar harga produk yang dibuat
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col-md-9">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={this.handleChange}
                            value={value}>
                            <optgroup label="PPOB">
                              {options.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.name}
                                </option>
                              ))}
                            </optgroup>

                            <optgroup label="PULSA">
                              {options_pulsa.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.name}
                                </option>
                              ))}
                            </optgroup>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div>
                          <LaddaButton
                            loading={this.state.loading}
                            onClick={() => {
                              this.getproduct();
                            }}
                            data-color="#008aff"
                            data-size={l}
                            className="m-btn m-btn-theme m-btn-radius w-100"
                            data-style={EXPAND_RIGHT}>
                            Tampilkan Harga
                          </LaddaButton>
                        </div>
                      </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                      {this.state.loading ? (
                        <Loading
                          type="bubbles"
                          color="#008aff"
                          className="text-center"
                        />
                      ) : (
                        <ReactTable
                          data={this.state.users}
                          columns={columns}
                          className="mt-3 table-striped w-100"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ListProduk;
