import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Redirect } from "react-router-dom";
import ReactLoading from "react-loading";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Kontak from "./Kontak";
import Header from "./HeaderHK";
import Style from "./Style.css";
class PertanyaanUmum extends Component{
    constructor(props){
        super(props);
        this.state = {
            messages: [],
            token: localStorage.getItem("token"),
        };
    }
    
    componentDidMount() {
        // this.setState({ loading: true });
        fetch(process.env.REACT_APP_URL + "/kontak/list", {
            method: "GET",
            headers: {
            Authorization: "Bearer " + this.state.token,
            },
        }).then((response) => {
            response.json().then((result) => {
                console.warn("result", result.data.length);
                this.setState({
                    // loading: false,
                    messages: result.data,
                    size: result.data.length,
                });
            });
        });
    }

render(){
    const {messages} = this.state;
    const toggleFAQ = index =>{
        this.setState(messages.map((message, i) =>{
            if(i === index){
                message.open = !message.open
            } else {
                message.open = false;
            }
            return message;
        }))
    }
    console.log({messages});
    return (
    <div className="App">
        <Header />
        <div className="faq">
            Layanan Pelanggan
        </div>
        <div className="messages">
            {messages.map((message,i)=>(
                message.type === 'LAYANAN' ?
                <Kontak message={message} index={i} />
                : null
            ))}
        </div>
        <div className="faq">
            Kantor Operasional
        </div>
        <div className="messages">
            {messages.map((message,i)=>(
                message.type === 'KANTOR' ?
                <Kontak message={message} index={i} />
                : null
            ))}
        </div>
    </div>
   )
 } 
}

export default PertanyaanUmum;