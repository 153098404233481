import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../router/Router";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Axios from "axios";
import ReactLoading from "react-loading";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import ReactTable from "react-table";
import { Redirect } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

const createHistory = require("history").createBrowserHistory;
const token = localStorage.getItem("token");

function UpdateStatusTrx(props) {
  const { dispatch, state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filIdpel, setFilIdpel] = useState();
  const [isAuth, setAuth] = useState(0);
  const [show, setShow] = useState(false);
  const [newMessageResponse, setNewResponse] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"3%"}
      width={"3%"}
      className="d-flex justify-content-center"
    />
  );
  let history = createHistory();
  const getData = async () => {
    await setIsLoading(true);
    await state;
    // console.log(state.token);
    // console.log(state);
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_URL}/transactions/pending/list`,
        {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        }
      );
      // console.warn('response', response.data)
      if (response.data.status == "200") {
        await setData(response.data.data);
      }
    } catch (e) {
      if (e.response.data.status == 401) {
        await setAuth(401);
      } else {
        Swal.fire({
          title: "",
          text: e.response.data.message,
          icon: "info",
        })
      }
    }
    await setIsLoading(false);
  };

  async function Filter() {
    await setIsLoading(true);
    await state;
    if (startDate == null || endDate == null) {
      Swal.fire({
        title: 'Maaf',
        text: "Lengkapi filter tanggal",
        icon: 'info',
        confirmButtonText: 'oke'
      });
    } else {
      fetch(process.env.REACT_APP_URL + '/transactions/pending/list?idpel=' + filIdpel + '&start_date=' + startDate + '&end_date=' + endDate, {
        method: "GET",
        headers: {
          'Authorization': "Barier " + state.token,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }).then(async (response) => {
        await response.json().then(async (result) => {
          // console.warn("result", result);
          if (result.status == "200") {
            await setData(result.data);
          } else {
            Swal.fire({
              title: 'Maaf',
              text: result.message,
              icon: 'error',
              confirmButtonText: 'oke'
            });
          }
        })
      }).catch((err) => {
        Swal.fire({
          title: 'Maaf',
          text: "Internal Sistem Error",
          icon: 'error',
          confirmButtonText: 'oke'
        });
      })
    }
    await setIsLoading(false);
  };

  async function updateConfirm(id) {
    await state;
    if(status === null){
      Swal.fire({
        title: 'Gagal',
        text: 'Pilih Status terlebih dahulu',
      });
      return;
    }
    if(status === '200'&& newMessageResponse === undefined){
      Swal.fire({
        title: 'Gagal',
        text: 'Isi Response Data Transaksi terlebih dahulu',
      });
      return;
    }
    Swal.fire({
      title: 'Perbarui Data Transaksi?',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Lanjut",
      cancelButtonText: "Batal",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        // console.log(detailData);
        let formatRequest = "";
        if(status==="200"){
          formatRequest = JSON.parse(newMessageResponse);
        }else{
          formatRequest = "";
        }
        let requestBody = JSON.stringify({
          status: parseInt(status),
          product_id: detailData.product_id,
          message: formatRequest,
        });
        console.log(requestBody);
        // if (result.isConfirmed) {
        return fetch(process.env.REACT_APP_URL + '/update/status/transaction/rekonsiliasi/' + detailData.trx_id, {
          method: "PUT",
          headers: {
            'Authorization': "Barier " + state.token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: requestBody,
        }).then(response => {
          return response.json();
        }).catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      await handleClose()
      if (result.isConfirmed) {
        await getData();
        if (result.value.status == "200") {
          await Swal.fire({
            title: "Berhasil",
            text: result.value.data,
            icon: 'success',
            confirmButtonText: 'oke'
          });
        } else {
          Swal.fire({
            title: "Gagal",
            text: result.value.status + " [ " + result.value.message + " ]",
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
      }
    })
  }
  async function Reset() {
    await setIsLoading(true);
    // await setFilIdpel();
    // await setStartDate();
    // await setEndDate();
    await getData();
    await setIsLoading(false);
    await getDataListStatus();
  };
  useEffect(() => {
    setIsLoading(true);
    getData();
    setIsLoading(false);
    getDataListStatus();
  }, []);

  const [status, setSelectedOption] = useState('');
  const [listStatus, setListStatus] = useState([]);
  const handleChange = (event) => {
    setSelectedOption(event.value);
  };
  const optListStatus = listStatus.map((d) => ({
    value: d.code,
    label: d.code + " - " +d.name.toUpperCase(),
  }));
  const getDataListStatus = async () => {
    await setIsLoading(true);
    await state;
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_URL}/status`,
        {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        }
      );
      console.warn('response', response.data.data)
      if (response.data.status == "200") {
        await setListStatus(response.data.data);
      }
    } catch (e) {
      if (e.response.data.status == 401) {
        await setAuth(401);
      } else {
        Swal.fire({
          title: "",
          text: e.response.data.message,
          icon: "info",
        })
      }
    }
    await setIsLoading(false);
  };

  const columns = [
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Tanggal Transaksi</div>
      ),
      accessor: "created_at",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <Moment format="YYYY-MM-DD - HH:mm">{row.original.created_at}</Moment>
        </div>
      ),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>ID Transaksi</div>
      ),
      accessor: "trx_id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "left", color: "black" }}>Produk</div>
      ),
      accessor: "name",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "left", color: "black" }}>ID Pelanggan</div>
      ),
      accessor: "idpel",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Jumlah Pembayaran</div>
      ),
      accessor: "default_amount",
      style: {
        textAlign: "right",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },

    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
      ),
      maxWidth: "100",
      accessor: "id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <LaddaButton
            loading={isLoading}
            className="btn btn-warning btn-sm"
            data-size='s'
            onClick={async () => {
              await setDetailData(row.original);
              await handleShow();
              setSelectedOption(null);
              setNewResponse(undefined);
              // await console.warn("Detail", detailData.name)
            }}
          >Cek Status</LaddaButton>
        </div>
      )
    },
  ];
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            Status Transaksi #{detailData.trx_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="alert alert-primary text-center border border-primary">
                Kode Response<br />
                <h5>{detailData.response_code}</h5>
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="alert alert-primary text-center border border-primary">
                Deskripsi Response<br />
                <h5>{detailData.descriptions}</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>
                    Status <sup className="text-danger">*</sup>
                </label>
                <Select
                  name="prod_id"
                  options={optListStatus}
                  onChange={handleChange}
                  placeholder={<div>Pilih Status</div>}
                />
              </div>
            </div>
          </div>
          {status === "200" ? (
            <>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark">Response Data Transaksi</label>
                  <textarea name="" placeholder="Masukkan response data transaksi disini" rows="5" className="form-control" onChange={(e) => setNewResponse(e.target.value)} ></textarea>
                </div>
              </div>
            </div>
            </>
          ):(
            <></>
          )}
          
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>Tutup</button>
          <button className="btn btn-primary" onClick={() => updateConfirm()}>Update Data Transaksi</button>
        </Modal.Footer>
      </Modal>
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mb-3 mt-2">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                            Filter data yang akan dicari
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Awal</label>
                          <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} value={startDate} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Akhir</label>
                          <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} value={endDate} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">ID Pelanggan</label>
                          <input type="text" className="form-control" onChange={(e) => setFilIdpel(e.target.value)} value={filIdpel} />
                        </div>
                      </div>
                    </div>
                  {/* </div>
                  <div className="card-footer"> */}
                    <div className="row mt-2">
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={isLoading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-size='s'
                          onClick={() => Filter()}
                        >Terapkan Filter</LaddaButton>
                      </div>
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={isLoading}
                          className="btn btn-danger m-btn-radius w-100"
                          data-size='s'
                          onClick={Reset}
                        >Reset Filter</LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow">
                  <div
                    className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-sync text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">UPDATE STATUS TRANSAKSI</span> <br />
                            Cek dan update status transaksi pending
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body pb-5">

                    {isLoading ? (
                      <Loading type="spinningBubbles" color="#91091e" />
                    ) : (
                      <div>
                        <ReactTable
                          // defaultSorted={[{ id: "tanggal", desc: true }]}
                          data={data}
                          //   defaultPageSize={isDataSaldo.length >= 10 ? 10 : 5}
                          defaultPageSize={5}
                          columns={columns}
                          className="mt-3 table-striped w-100"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default UpdateStatusTrx;
