import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import LaddaButton from "react-ladda";
import { AuthContext } from '../../router/Router';
import axios from "axios";
import ReactLoading from 'react-loading';


const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);

function ChangeLogo(props) {

  const logoLama = localStorage.getItem("logo");
  const taglineLama = localStorage.getItem("tagline");
  const [tagline, setTagline] = useState(localStorage.getItem("tagline"));
  const [logo, setLogo] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { dispatch, state } = useContext(AuthContext);


  const confirmUpdate = async (type) => {
    await setLoading(true)

    if (type == "update") {
      if (logo == "" || tagline == "") {
        Swal.fire({
          title: "Maaf",
          text: "Lengkapi logo dan tagline",
          icon: "info",
          confirmButtonText: "oke",
        });
      } else {
        if (logo[0].size > 3000000) {
          Swal.fire({
            title: "Error Ukuran File",
            text: "Ukuran maksimal file 3 MB",
            icon: "info",
            confirmButtonText: "oke",
          });
        } else {
          if (logo[0].type != "image/png" && logo[0].type != "image/jpg" && logo[0].type != "image/gif" && logo[0].type != "image/jpeg") {
            Swal.fire({
              title: "Error Tipe File",
              html: "Tipe file <b>" + logo[0].type + "</b> tidak diizinkan. Gunakan file PNG,JPG,GIF,JPEG",
              icon: "info",
              confirmButtonText: "oke",
            });
          } else {
            Swal.fire({
              title: "",
              html: "Perbarui logo dan tagline?",
              icon: "info",
              showCancelButton: true,
              reverseButtons: true,
              cancelButtonText: "Batal",
              confirmButtonText: "Lanjutkan",
            }).then(async (result) => {
              if (result.value) {
                await updateLogo(logo[0], tagline);

              } else if (result.dismiss === Swal.DismissReason.cancel) {

              }
            });
          }
        }
      }
    }

    if (type == "reset") {
      await setTagline("");
      await setLogo("");
      // await console.warn("tagline", tagline)
      await Swal.fire({
        title: "Konfirmasi",
        html: "Loket Anda akan menggunakan logo dan tagline bawaan ?",
        icon: "info",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Lanjutkan",
      }).then(async (result) => {
        if (result.value) {
          await updateLogo();
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
      });

    }
    await setLoading(false)
  }
  const selectFile = async (event) => {
    await setLogo(event.target.files);
  }

  const updateLogo = async (paramLogo = null, paramTagline = "") => {
    await setLoading(true);
    const formData = new FormData()

    // if (tagline == "") {
    // await setTagline("Loket Pelangi");
    // await formData.delete('tagline');
    // await formData.delete('images');
    // await formData.append('images', logo[0])
    // } else {
    await formData.append('tagline', paramTagline)
    await formData.append('images', paramLogo)
    // }
    axios
      .post(
        `${process.env.REACT_APP_URL}/images/logo`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + state.token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          // console.warn("user", json.data.data.base_url)

          if (logo == "") {
            localStorage.setItem(
              "tagline",
              JSON.stringify("").replace(/"/g, "")
            );
            localStorage.setItem(
              "logo",
              JSON.stringify("null").replace(/"/g, "")
            );
          } else {
            localStorage.setItem(
              "logo",
              JSON.stringify(json.data.data.image_logo).replace(/"/g, "")
            );
            localStorage.setItem(
              "tagline",
              JSON.stringify(paramTagline).replace(/"/g, "")
            );
          }

          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Oke",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        } else {
          Swal.fire({
            title: "Maaf",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
        // console.warn("response", json);
        setLoading(false)
      }).catch((err) => {
        // console.warn("error", err.response.data.message);
        if (err.response.data.status == 402) {
          Swal.fire({
            title: "Sukses",
            text: err.response.data.message,
            icon: "success",
            confirmButtonText: "Oke",
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch({
                type: "LOGOUT",
              });
            }
          })
          // setLoading(false)
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        setLoading(false)
      });
  };
  const handleTagline = async (e) => {
    await setTagline(e.target.value);
  }

  return (
    <div className="bg-admin">
      <section className="section p-0px-t bg-admin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow mt-2 mb-5">
                <div className="card-header bg-light d-flex justify-content-between">
                  <div>
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-image text-primary h3 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">GANTI LOGO LOKET</span> <br />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="text-center">
                          <label className="text-dark">Preview Logo</label><br />
                          {
                            logoLama != "null" ? (
                              <>
                                <img className="img-thumbnail border-0 mb-2" src={`${process.env.REACT_APP_URL}/images/${logoLama}`} width="200px" height="160px" />
                              </>
                            ) : (
                              <>
                                <img className="img-thumbnail border-0 mb-2" src="/img/loketpelangi.png" width="200px" height="160px" /><br />
                                <small className="text-secondary font-italic">(Saat ini menggunakan logo default)</small>
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <label>Pilih Logo Baru</label>
                        <input type="file" name="file" id="file" className="form-control" onChange={selectFile} />
                      </div>
                      <div className="form-group">
                        <label>
                          Tagline <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          name="tagline"
                          className="form-control"
                          required=""
                          value={tagline}
                          onChange={(e) => handleTagline(e)}
                        />
                        {taglineLama == "null" || taglineLama == "" ? (
                          <><small className="text-secondary font-italic">Saat ini menggunakan tagline default (<b>Loket Pelangi</b>)</small></>
                        ) : (<></>)}
                      </div>
                    </div>
                  </div>
                {/* </div>
                <div className="card-footer text-right mb-4"> */}
                  <div className="row mt-2">
                    <div className="col-6 col-md-3 ml-auto">
                      <LaddaButton
                        loading={isLoading}
                        onClick={() => {
                          confirmUpdate("reset");
                        }}
                        className="btn btn-danger btn-block"
                      >
                        Reset Logo
                      </LaddaButton>
                    </div>
                    <div className="col-6 col-md-3">
                      <LaddaButton
                        loading={isLoading}
                        onClick={() => {
                          confirmUpdate("update");
                        }}
                        className="btn btn-primary btn-block"
                      >
                        Simpan Logo
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChangeLogo;