import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import CurrencyInput from "react-currency-input-field";
import { FileUploader } from "react-drag-drop-files";

const apiUrl = process.env.REACT_APP_URL;
const fileTypes = ["jpg", "png", "pdf", "jpeg"];
const createHistory = require("history").createBrowserHistory;
let history = createHistory();
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>
  }
}
class RequestSaldoPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      idpel: null,
      masa_berlaku: "",
      displayMasaBerlaku: "none",
      messageError: "",
      btnDisabled: "disabled",
      dangerDisplay: "none",
      textButton: "Beli",
      message: "",
      isAuth: 0,
      nominal: parseFloat(localStorage.getItem("reqTopup")),
      file: null,
      token: localStorage.getItem("token"),
      inquiry: false,
      clone: false,
      noRek: "",
      uploaded: false,
    };
  }
  componentDidMount() {
    if (!this.state.nominal || this.state.nominal == "") {
      history.push("/users/transaksi/requestsaldo");
      let pathUrl = window.location.href;
      window.location.href = pathUrl;
    }
    this.setState({
      loading: false
    })

  }
  componentDidUpdate() {
    if (this.state.clone) {
      Swal.fire({
        position: 'top-end',
        // icon: 'success',
        html: "No Rekening <b>" + this.state.noRek + "</b> Disalin",
        showConfirmButton: false,
        timer: 1200
      })
    }
  }
  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  handleChange = async (file) => {
    await this.setState({ file: file })
    // console.warn("file",this.state.file)
  };

  sendRequest = async () => {
    const formData = new FormData()
    await formData.append('nominal', this.state.nominal)
    await formData.append('images_tf', this.state.file)
    // console.warn("file", this.state.file[0])
    axios
      .post(
        `${process.env.REACT_APP_URL}/saldo`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        this.setState({ uploaded: true });
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
          localStorage.removeItem("reqTopup")
        } else {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        // this.fetchUsers();
      }).catch((err) => {
        // console.warn("error", err.response.data.message);
        if (err.response.data.status == 402) {
          Swal.fire({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        } else if (err.response.data.status == 401) {
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      });
  }
  upload = async () => {
    await Swal.fire({
      title: "",
      html: "Upload Bukti Transfer ?",
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Upload",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.sendRequest()
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
    })
  }
  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  cloneNoRek(noRek) {
    this.setState({ clone: true, noRek: noRek })
    navigator.clipboard.writeText(noRek)
  }
  render() {
    return (
      <div className="bg-admin">
        <CheckSession isAuth={this.state.isAuth} />
        <section className="section p-0px-t">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-money-bill-wave text-primary h1 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">REQUEST TOPUP SALDO</span> <br />
                          Konfirmasi request topup saldo
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="card-body">
                    {this.state.uploaded ? (
                      <>
                        <div className="alert alert-success alert-dismissible">
                          <p className="small">Bukti transfer berhasil diupload, Jika dalam kurun waktu maksimal 15 menit kedepan saldo Anda belum bertambah, segera hubungi <i>customer service</i> kami.<br />
                            Untuk melihat riwayat dan status request saldo, Anda bisa masuk ke halaman panel pengguna</p>
                        </div>
                      </>
                    ) : (<></>)}
                    <div className="row">
                      <div className="col-md-7">
                        <div className="card mt-2" style={{ border: "2px solid #dedede" }}>
                          <div className="card-body bg-light">
                            <p className="font-weight-bold text-center text-dark">Nominal Transfer</p>
                            <p className="text-primary font-weight-bold text-center h3"> Rp. {this.rupiahFormat(this.state.nominal)}</p>
                            <p className="font-weight-bold text-center text-dark">Nomor Rekening</p>

                            <div className="row justify-content-center">
                              <div className="col-md-10 table-responsive">
                                <div className="hidden-onphone">
                                  <table className="table table-borderless" width="100%">
                                    <tbody>
                                      <tr>
                                        <td width="25%"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/BANK_BRI_logo.svg/2560px-BANK_BRI_logo.svg.png" width="100%" /></td>
                                        <td className="text-left h6 align-middle">1131-01-000211-30-4</td>
                                        <td className="text-center"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary ml-3" onClick={() => this.cloneNoRek("1131-01-000211-30-4")}>Salin <i className="fa fa-clone text-secondary"></i></a></td>

                                      </tr>
                                      <tr>
                                        <td className="text-center"><img src="https://savegame.id/wp-content/uploads/2020/02/Cara-Transfer-Virtual-Account-Mandiri-Paling-Mudah.png" width="100%" /></td>
                                        <td className="text-left h6 align-middle">1560011155332</td>
                                        <td className="text-center"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary ml-3" onClick={() => this.cloneNoRek("1560011155332")}>Salin <i className="fa fa-clone text-secondary"></i></a></td>
                                      </tr>
                                      <tr>
                                        <td className="text-center"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Bank_Central_Asia.svg/2560px-Bank_Central_Asia.svg.png" width="100%" /></td>
                                        <td className="text-left h6 align-middle">5220899880</td>
                                        <td className="text-center"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary ml-3" onClick={() => this.cloneNoRek("5220899880")}>Salin <i className="fa fa-clone text-secondary"></i></a></td>
                                      </tr>
                                      <tr>
                                        <td className="text-center"><img src="https://rekreartive.com/wp-content/uploads/2019/04/Logo-BNI-Bank-Negara-Indonesia-46-Vector-.png" width="100%" /></td>
                                        <td className="text-left h6 align-middle">428220760</td>
                                        <td className="text-center"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary ml-3" onClick={() => this.cloneNoRek("428220760")}>Salin <i className="fa fa-clone text-secondary"></i></a></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="show-onphone">
                                  <table className="table table-borderless" width="100%">
                                    <tbody>
                                      <tr>
                                        <td width="35%"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/BANK_BRI_logo.svg/2560px-BANK_BRI_logo.svg.png" width="100%" /></td>
                                        <td className="text-left align-middle" style={{fontSize:"12px", fontWeight:"bold"}}>1131-01-000211-30-4</td>
                                        <td className="text-center" width="3%"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary d-flex" onClick={() => this.cloneNoRek("1131-01-000211-30-4")}><i className="fa fa-clone text-secondary fa-xs"></i></a></td>

                                      </tr>
                                      <tr>
                                        <td className="text-center"><img src="https://savegame.id/wp-content/uploads/2020/02/Cara-Transfer-Virtual-Account-Mandiri-Paling-Mudah.png" width="100%" /></td>
                                        <td className="text-left align-middle" style={{fontSize:"12px", fontWeight:"bold"}}>1560011155332</td>
                                        <td className="text-center"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary d-flex" onClick={() => this.cloneNoRek("1560011155332")}><i className="fa fa-clone text-secondary fa-xs"></i></a></td>
                                      </tr>
                                      <tr>
                                        <td className="text-center"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Bank_Central_Asia.svg/2560px-Bank_Central_Asia.svg.png" width="100%" /></td>
                                        <td className="text-left align-middle" style={{fontSize:"12px", fontWeight:"bold"}}>5220899880</td>
                                        <td className="text-center"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary d-flex" onClick={() => this.cloneNoRek("5220899880")}><i className="fa fa-clone text-secondary fa-xs"></i></a></td>
                                      </tr>
                                      <tr>
                                        <td className="text-center"><img src="https://rekreartive.com/wp-content/uploads/2019/04/Logo-BNI-Bank-Negara-Indonesia-46-Vector-.png" width="100%" /></td>
                                        <td className="text-left align-middle" style={{fontSize:"12px", fontWeight:"bold"}}>428220760</td>
                                        <td className="text-center"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary d-flex" onClick={() => this.cloneNoRek("428220760")}><i className="fa fa-clone text-secondary fa-xs"></i></a></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <p className="text-primary text-center font-weight-bold">( PT. PELANGI INDODATA )</p>
                            <hr />
                            <small><i>*) Transfer sesuai nominal yang tertera diatas ke salah satu nomor rekening yang tersedia</i></small>
                          </div>
                        </div>

                      </div>
                      <div className="col-md-5 mt-2 table-responsive">
                        <div className="card pb-5 bg-light" style={{ border: "2px solid #dedede" }}>
                          <div className="card-body text-center">
                            <div className="mt-2"></div>
                            <FileUploader name="file" handleChange={this.handleChange} types={fileTypes} classes="col-md-12 py-5" onDrop={this.upload} onSelect={this.upload} maxSize="3" onSizeError="Ukuran file maksimal 3 MB" />
                            <p>{this.state.file ?
                              (
                                <div>
                                  File name: {this.state.file.name}<br />
                                  {/* <button className="btn btn-primary">Upload File</button> */}
                                </div>
                              ) : (
                                <div>
                                  Upload Bukti Transfer
                                </div>
                              )}</p>
                            <div className="pb-5"></div>
                            <div className="pb-5"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card border border-primary bg-primary-300 mt-4 mb-5"
                      style={{ backgroundColor: "#dbeefd" }}>
                      <div className="card-body">
                        <b className="text-primary">Keterangan</b><br />
                        <p className="small text-dark">
                          1. Request topup saldo akan proses secepatnya (maksimal 30 menit).<br />
                          2. Jika dalam kurun waktu 30 menit saldo belum masuk, harap segera menghungi customer service.<br />
                          3. Lakukan request topup sebelum kehabisan saldo agar tetap bisa melakukan transaksi.<br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </div>

    );
  }
}

export default RequestSaldoPay;
