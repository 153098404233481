import React, { Component } from "react";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import { FaCheck, FaClock, FaTimes } from "react-icons/fa";
import ReactLoading from "react-loading";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import NumberFormat from "react-number-format";

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);

class Chektransaksi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      login: false,
      store: null,
      token: localStorage.getItem("token"),
      created_at: null,
      idpel: "",
      response: [],
    };
  }

  componentDidMount() {
    this.storeCollector();
    this.getTransaction();
  }

  storeCollector() {
    // let store=JSON.parse(localStorage.getItem('login'));
    let store =
      "CUAN-REST $2a$10$a1hNujOfYRHT6bGacbBkEOXBk476HswO8cNgLSJ6e2EtLEfYavqZG";
    this.setState({ login: true, store: store });
    // if(store && store.login) {   this.setState({login:true,store:store}) }else{ }
  }

  handleChangeDate = (event, e) => {
    console.log(event);
    this.setState({ created_at: event.target.value });
    console.log(this.state.created_at);
  };

  handleChangeIdpel = (event) => {
    this.setState({ idpel: event.target.value });
    console.log(this.state.idpel);
  };

  getTransaction() {
    this.setState({ loading: true });
    let store = process.env.REACT_APP_TOKEN;
    let token = "Bearer " + this.state.token;
    fetch(`${process.env.REACT_APP_URL}/loket/transaction/check`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        created_at: this.state.created_at,
        // created_at: "2021-09-07",
        idpel: this.state.idpel,
      }),
    }).then((response) => {
      response.json().then((result) => {
        this.setState({ response: result.data });
        this.setState({ loading: false });
      });
    }).catch((err) => {
      // if(err.response.data.status == 500){
      //   Swal.fire({
      //     title: 'Maaf',
      //     text: err.response.data.message,
      //     icon: 'info',
      //     confirmButtonText: 'oke'
      //   });
      // }
    });
  }

  resendEmail(id) {
    let token = "Bearer " + this.state.token;
    Swal.fire({
      text: "Email Penerima Struk",
      input: "email",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      reverseButtons:true,
      confirmButtonText: "Kirim",
      cancelButtonText: "Batal",
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        return fetch(`${process.env.REACT_APP_URL}/loket/resend/email`, {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            transaction_id: id,
            email: email,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(result.value);

        if (result.value.status == "200") {
          Swal.fire("Success", result.value.data, "success");
        } else {
          Swal.fire("Ups.. ", result.value.data, "eror");
        }
      }
    });
  }

  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  render() {
    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-check-circle text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">STATUS PEMBAYARAN</span> <br />
                            Cek status pembayaran transaksi
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col-md-4 mb-2">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Transaksi</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(event) =>
                              this.setState({ created_at: event.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="text-dark">ID Pelanggan</label>
                          <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="ID Pelanggan"
                            name="idpel"
                            onKeyUp={(e) => {
                              this.handleChangeIdpel(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        {this.state.login ? (
                          <div>
                            <LaddaButton
                              loading={this.state.loading}
                              onClick={() => {
                                this.getTransaction();
                              }}
                              data-color="#008aff"
                              data-size={l}
                              className="m-btn m-btn-theme m-btn-radius w-100"
                              style={{ marginTop: "35px" }}
                              data-style={EXPAND_RIGHT}>
                              Cari Transaksi
                            </LaddaButton>
                          </div>
                        ) : (
                          <button className="btn btn-primary w-100">Cari</button>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3 shadow mb-5">
              <div className="card-header bg-light d-flex justify-content-between">
                <div>
                  <table>
                    <tr>
                      <td rowspan="2"><i className="fa fa-search text-primary h1 mr-2"></i></td>
                      <td>
                        <span className="font-weight-bold text-primary">HASIL PENCARIAN</span> <br />
                        Hasil pencarian status pembayaran transaksi
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="card-body text-dark">
                {this.state.loading ? (
                  <div className="w-100 text-center justify-content-center">
                    <Loading
                      type="bubbles"
                      color="#008aff"
                      className="text-center m-auto"
                    />
                  </div>
                ) : (
                  <div>
                    {this.state.response && this.state.response.length > 0 ? (
                      <div>
                        <div className="hidden-onphone">
                          {this.state.response.map((value, index) => {
                            return (
                              <>
                                <div key={value.transaction_id}>
                                  <div className="row m-2">
                                    <div className="col-md-1 col-sm-3 text-center m-auto">
                                      {value.status_transaction == "0000" ? (
                                        <div>
                                          {/* <div className="card bg-success mt-2 pt-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"> */}
                                            {/* <div className="only-icon-30 d-inline-block w-100 mb-3"> */}
                                              {/* <FaCheck className="text-white" /> */}
                                              <span className="font-weight-bold badge badge-success py-3">SUKSES</span>
                                            {/* </div> */}
                                          {/* </div> */}
                                        </div>
                                      ) : (
                                        <>
                                          {
                                            value.status_transaction == "0001" || value.status_transaction == "01" ? (
                                              <div>
                                                <div className="card bg-warning mt-2 pt-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none">
                                                  <div className="only-icon-30 d-inline-block w-100 mb-3">
                                                    {/* <FaClock className="text-white" /> */}
                                                    <span className="font-weight-bold badge badge-warning py-3">PENDING</span>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div>
                                                <div className="card bg-danger mt-2 pt-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none">
                                                  <div className="only-icon-30 d-inline-block w-100 mb-3">
                                                    {/* <FaTimes className="text-white" /> */}
                                                    <span className="font-weight-bold badge badge-danger py-3">GAGAL</span>
                                                  </div>
                                                </div>
                                              </div>)
                                          }
                                        </>

                                      )}
                                    </div>
                                    <div className="col-md-8 col-sm-9">
                                      <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                          <table width="100%">
                                            <tr>
                                              <td width="60%"><i className="fas fa-tags text-info mr-2"></i><b>{value.name.toUpperCase()} </b> - {this.rupiahFormat(
                                                parseInt(value.amount) + parseInt(value.admin_fee) + parseInt(value.loket_fee)
                                              )}</td>
                                              <td><i className="fas fa-clock text-info mr-2"></i><Moment format="DD MMM YYYY - HH:mm" tz="Asia/Jakarta">{moment(value.payment_created_datetime).add(0, "hours")}</Moment></td>
                                            </tr>
                                          </table>
                                        </li>
                                        <li class="list-group-item">
                                          <table width="100%">
                                            <tr>
                                              <td width="60%"><i className="fas fa-user text-info mr-2"></i>{value.idpel}</td>
                                              <td><i className="fas fa-store text-info mr-2"></i>{value.loket_name.toUpperCase()}</td>
                                            </tr>
                                          </table>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-md-3 col-sm-12 text-center">
                                      {value.status_transaction == "0000" ? (
                                        <div>
                                          <NavLink
                                            className="btn btn-lg btn-info btn-block mt-2"
                                            to={"/invoice/" + value.order_id}
                                            activeClassName="bg-info white-color theme-hover-bg">
                                            STRUK
                                          </NavLink>
                                          <small className="text-muted small">
                                            *struk telah dikirim ke
                                            email
                                            <a
                                              href="#"
                                              onClick={() => {
                                                this.resendEmail(
                                                  value.transaction_id
                                                );
                                              }}
                                              className="text-primary">
                                              {" "}
                                              Klik disini untuk kirim ulang
                                            </a>
                                          </small>
                                        </div>
                                      ) : (
                                        <>
                                          {
                                            value.status_transaction == "0001" || value.status_transaction == "01" ? (
                                              <div>
                                                <NavLink
                                                  className="btn btn-lg btn-warning btn-block mt-4 text-white"
                                                  to={"/invoice/" + value.order_id}>
                                                  DETAIL
                                                </NavLink>
                                              </div>
                                            ) : (
                                              <div>
                                                <NavLink
                                                  className="btn btn-lg btn-danger btn-block mt-4 text-white"
                                                  to={"/invoice/" + value.order_id}>
                                                  DETAIL
                                                </NavLink>
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              </>
                            );
                          })}
                        </div>
                        <div className="show-onphone">
                          <>
                            <div className="mb-5">
                              {this.state.response.map((value, index) => {
                                return (
                                  <>
                                    <div key={index}>
                                      <div className="row">
                                        <div className="col-10">
                                          <span className="font-weight-bold">{value.name.toUpperCase()}</span>
                                        </div>
                                        <div className="col-2">
                                          <small className="float-right">
                                          {value.status_transaction == "0000" ? (
                                            <span className="badge badge-success">SUKSES</span>
                                          ):(<>
                                          {value.status_transaction == "0001" || value.status_transaction == "01" ? (<><span className="badge badge-warning">PENDING</span></>): (<><span className="badge badge-danger">GAGAL</span></>)}
                                          </>)}
                                          </small>
                                        </div>
                                        <div className="col-12">
                                          <small>
                                            <span><Moment format="DD MMM YYYY HH:mm" tz="Asia/Jakarta">{moment(value.payment_created_datetime).add(0, "hours")}</Moment></span>
                                          </small>
                                        </div>
                                        <table className="table table-borderless ml-1 mr-1" style={{ lineHeight: "15px" }}>
                                          <tbody>
                                            <tr>
                                              <td width="40%">Loket</td>
                                              <td className="text-right">{value.loket_name}</td>
                                            </tr>
                                            <tr>
                                              <td>Idpel</td>
                                              <td className="text-right">{value.idpel}</td>
                                            </tr>
                                            <tr>
                                              <td>Jml Bayar</td>
                                              <td className="text-right">
                                                <NumberFormat
                                                  value={parseInt(value.amount) + parseInt(value.admin_fee) + parseInt(value.loket_fee)}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"Rp"}
                                                  renderText={(value) => <>{value}</>}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                      {/* <div className="card-body mb-5"> */}
                                      <div className="row">
                                        <div className="col-12">
                                          {value.status_transaction == "0000" ? (
                                            <div>
                                              <NavLink
                                                className="btn btn-sm btn-info btn-block mt-2"
                                                to={"/invoice/" + value.order_id}
                                                activeClassName="bg-info white-color theme-hover-bg">
                                                STRUK
                                              </NavLink>
                                              <small className="text-muted small">
                                                *struk telah dikirim ke
                                                email
                                                <a
                                                  href="#"
                                                  onClick={() => {
                                                    this.resendEmail(
                                                      value.transaction_id
                                                    );
                                                  }}
                                                  className="text-primary">
                                                  {" "}
                                                  Klik disini untuk kirim ulang
                                                </a>
                                              </small>
                                            </div>
                                          ) : (
                                            <>
                                              {
                                                value.status_transaction == "0001" || value.status_transaction == "01" ? (
                                                  <div>
                                                    <NavLink
                                                      className="btn btn-sm btn-warning btn-block mt-4 text-white"
                                                      to={"/invoice/" + value.order_id}>
                                                      DETAIL
                                                    </NavLink>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <NavLink
                                                      className="btn btn-sm btn-danger btn-block mt-4 text-white"
                                                      to={"/invoice/" + value.order_id}>
                                                      DETAIL
                                                    </NavLink>
                                                  </div>
                                                )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )
                              })
                              }
                            </div>

                          </>
                        </div>

                      </div>
                    ) : (
                      <div className="m-auto w-100 text-center">
                        <img
                          className="m-auto"
                          src="https://cdn.dribbble.com/users/1113690/screenshots/6231933/empty_state_bino.jpg"
                          style={{
                            width: "300px",
                            margin: "auto",
                          }}
                          alt=""
                        />
                        <h6>Tidak Ada Transaksi</h6>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Chektransaksi;
