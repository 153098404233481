import { Backdrop } from "@material-ui/core";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { AuthContext } from "../../router/Router";
import { Redirect } from "react-router-dom";
import Avatar from "react-avatar";
import Swal from "sweetalert2";

function Navbar({ children }) {
  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);

  return (
    <div>
      <header
        className="header-nav header-dark header-bg"
        style={{ minHeight: "70px" }}>
        <div className="fixed-header-bar">
          <div className="navbar navbar-main navbar-expand-lg">
            <div className="container-fluid">
              <NavLink className="navbar-brand" to="/">
                <img
                  className="logo-dark"
                  alt=""
                  width="30%"
                  title=""
                  src="/img/logo_new.png"
                />
                <img
                  className="logo-light"
                  alt=""
                  width="30%"
                  title=""
                  src="/img/logo_new.png"
                />
                <span className="badge badge-warning small text-white ml-2">
                  Direct version
                </span>
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-main-collapse"
                aria-controls="navbar-main-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse navbar-collapse-overlay"
                id="navbar-main-collapse">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item m-auto">{state.cart}</li>
                  <li className="nav-item m-auto">
                    <NavLink className="btn btn-primary m-auto" to="/listharga">
                      ADMIN
                    </NavLink>
                  </li>
                  <li className="nav-item  px-dropdown">
                    <a className="nav-link text-muted" href="#">
                      {state.user}
                      <Avatar
                        name={state.user}
                        size={32}
                        className="ml-2"
                        fgColor="#b9cbfb"
                        color="#ebf4fe"
                        round="50px"
                        textSizeRatio="2"
                      />
                    </a>
                    <ul className="px-dropdown-menu mm-dorp-in">
                      <li>
                        <NavLink to="/users/changepassword" href="#">
                          Change Password
                        </NavLink>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            Swal.fire({
                              title: "Yakin keluar  ?",
                              text: "",
                              icon: "info",
                              showCancelButton: true,
                              confirmButtonText: "Keluar",
                              cancelButtonText: "Batal",
                            }).then((result) => {
                              if (result.value) {
                                dispatch({
                                  type: "LOGOUT",
                                });
                              } else if (
                                result.dismiss === Swal.DismissReason.cancel
                              ) {
                                //cencle
                              }
                            })
                          }>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mains">{children}</div>
    </div>
  );
}

export default Navbar;
