import React from 'react';
import NumberFormat from 'react-number-format';
export const Telkom = (props) => (
    <>
        {
            props.type == "print" ? (
                <>
                    <tr>
                        <td style={{borderRight: "1px solid black"}}>
                            {"ID Pelanggan  : " + props.struk.id_pelanggan}<br />
                            {"Nama          : " + props.struk.nama_pelanggan}<br />
                            {"BL/TH         : " + props.struk.bulan_thn}<br />
                            {"No Ref        : " + props.struk.no_reference}<br />
                            {"Rp Tag        : "}<NumberFormat value={props.struk.jumlah_tagihan} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Bank      : "}<NumberFormat value={props.struk.jumlah_adm} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Loket     : "}<NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Rp Bayar      : "}<NumberFormat value={parseInt(props.struk.jumlah_bayar) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                        </td>
                    </tr>
                </>
            ) : (
                <>
                    <tr>
                        <td>ID Pelanggan</td>
                        <td width="3%"> : </td>
                        <td>{props.struk.id_pelanggan}</td>
                    </tr>
                    <tr>
                        <td>Nama</td>
                        <td> : </td>
                        <td>{props.struk.nama_pelanggan}</td>
                    </tr>
                    <tr>
                        <td>BL/TH</td>
                        <td> : </td>
                        <td>{props.struk.bulan_thn}</td>
                    </tr>
                    <tr>
                        <td>No Ref</td>
                        <td> : </td>
                        <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>{props.struk.no_reference}</td>
                    </tr>
                    <tr>
                        <td>Rp Tag</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.jumlah_tagihan} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Bank</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.jumlah_adm} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Loket</td>
                        <td> : </td>
                        <td><NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Rp Bayar</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.jumlah_bayar) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                </>
            )}
    </>
);