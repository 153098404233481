import React, { Component, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import { Redirect } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import ReactLoading from "react-loading";

const Parsetanggal = ({ periode }) => {
  if (periode == "01") {
    return "January";
  } else if (periode == "02") {
    return "Februari";
  } else if (periode == "03") {
    return "Maret";
  } else if (periode == "04") {
    return "April";
  } else if (periode == "05") {
    return "Mei";
  } else if (periode == "06") {
    return "Juni";
  } else if (periode == "07") {
    return "Juli";
  } else if (periode == "08") {
    return "Agustus";
  } else if (periode == "09") {
    return "September";
  } else if (periode == "10") {
    return "Oktober";
  } else if (periode == "11") {
    return "November";
  } else if (periode == "12") {
    return "Desember";
  }
};

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);

const OptionKesehatan = () => {
  var moment = require("moment");
  var text = "";

  function parsedate(periode) {
    if (periode == "01") {
      return "January";
    } else if (periode == "02") {
      return "Februari";
    } else if (periode == "03") {
      return "Maret";
    } else if (periode == "04") {
      return "April";
    } else if (periode == "05") {
      return "Mei";
    } else if (periode == "06") {
      return "Juni";
    } else if (periode == "07") {
      return "Juli";
    } else if (periode == "08") {
      return "Agustus";
    } else if (periode == "09") {
      return "September";
    } else if (periode == "10") {
      return "Oktober";
    } else if (periode == "11") {
      return "November";
    } else if (periode == "12") {
      return "Desember";
    }
  }

  for (let i = 0; i < 12; i++) {
    // var futureMonth = moment().add(i, 'M').format('MM - YYYY');
    var futureMonth = moment().add(i, "M").format("M");
    var futureMonths = moment().add(i, "M").format("MM");
    var futureYear = moment().add(i, "M").format("YYYY");
    text +=
      "<option value='" +
      futureMonths +
      "'>  " +
      parsedate(futureMonths) +
      " " +
      futureYear +
      " </option>";
    // console.log(futureMonth+" - "+futureYear );
  }

  return (
    <div>
      <div className="form-group">
        <h6 className="text-dark">Bayar Hingga </h6>
        <select className="form-control">{ReactHtmlParser(text)}</select>
      </div>
    </div>
  );
};

const OptionKetenagakerjaan = (props) => (
  <div>
    <div className="form-group">
      <label>Bayar Hingga</label>
      <select className="form-control">
        <option>1 Bulan</option>
        <option>3 Bulan</option>
        <option className="" selected>
          6 Bulan
        </option>
        <option>12 Bulan</option>
      </select>
    </div>
  </div>
);
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>
  }
}
class bpjs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      showToken: true,
      loading: false,
      product_id: null,
      trx_type: "2100",
      periode_payment: "01",
      idpel: props.match.params.idpel,
      message: null,
      token: localStorage.getItem("token"),
      inquiry: false,
      idpelStatus: false,
      nominal: null,
      produk: "",
      list_product: [],
      isAuth: 0,
      idpel_multi: props.match.params.idpel,
      list_idpel: JSON.parse(localStorage.getItem("list_impor_idpel")),
      last_idpel: localStorage.getItem("list_impor_idpel") ? JSON.parse(localStorage.getItem("list_impor_idpel")).slice(-1) : '',
    };
  }

  GetProduct() {
    fetch(`${process.env.REACT_APP_URL}/products?product_category_id=79`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
    }).then(async (response) => {
      response.json().then(async (result) => {
        if (result.status === 200) {
          let data = await result.data.filter((value) => {
            return (
              value.code.toString().toLowerCase().includes("bpjsk")
            );
          });
          await this.setState({ list_product: data });


          if (result.data && result.data[0].code !== null) {
            this.setState({
              produk: data[0].code,
              product_id: data[0].prod_id,
            });
            // await console.warn('response', data[0].code);
          }
        }
      });
    }).catch(function (err) {
      // console.log(err.response.data)
      Swal.fire({
        title: "Maaf",
        text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
        icon: "error",
        confirmButtonText: "Oke",
      });
      this.setState({ isAuth: 401 });
    }.bind(this));
  }

  componentDidMount() {
    let pathUrl = window.location.pathname;
    let urlImpor = pathUrl.split('/');
    // console.log("path", urlImpor)
    if (urlImpor[1] == 'bpjs') {
      if (urlImpor[1] != "users" && urlImpor[2] != "transaksi" && urlImpor[3] != "importidpel" && urlImpor[4] != "cektagihan") {
        if (this.state.list_idpel) {
          localStorage.removeItem("list_impor_idpel");
        }
      }
    } else {
      let cekIdpel = this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
      // console.warn("cek", cekIdpel)
      if (cekIdpel.length > 0) {
        this.handleChangeIdpel(this.state.idpel);
      } else {
        Swal.fire({
          title: "Maaf",
          text: "ID pelanggan tidak ada di daftar yang diimpor",
          icon: "info",
          confirmButtonText: "oke",
        });
      }
    }

    this.GetProduct();
  }

  HitData() {
    this.setState({ loading: true });
    // console.log(JSON.stringify(this.state));
    fetch(`${process.env.REACT_APP_URL}/loket/transactions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((response) => {
      response.json().then(async (result) => {
        // console.warn("result", result);
        //inq berhasil
        if (result.status == "200") {
          if (this.state.trx_type == "2100" && result.data.rc == "0000") {
            localStorage.setItem(
              "private_inquiry",
              JSON.stringify({
                data: result.data,
                periode_payment: result.data.periode,
                produk: this.state.produk,
                product_id: this.state.product_id,
                prev_idpel: this.state.idpel
              })
            );
            this.setState({ inquiry: true, inqres: result.data });
          } else {
            this.setState({ message: result.data.desc });
            Swal.fire({
              title: "Maaf",
              text: result.data.desc || "Silahkan dicoba beberapa saat lagi",
              icon: "info",
              confirmButtonText: "oke",
            });
          }
        } else if (result.status == "401") {
          Swal.fire({
            title: "Maaf",
            text: result.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "info",
            confirmButtonText: "Tutup",
          });
          this.setState({ isAuth: 401 });
        } else {
          this.setState({ message: result.message.desc });
          Swal.fire({
            title: "Maaf",
            text: result.message.desc || "Silahkan dicoba beberapa saat lagi",
            icon: "info",
            confirmButtonText: "oke",
          });

          // if (this.state.list_idpel) {
          //   let indexOfIdpel = await this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
          //   if (this.state.last_idpel[0].idpel == this.state.idpel) {
          //     await this.props.history.push("/cart");
          //     let pathUrl = await window.location.href;
          //     window.location.href = await pathUrl;
          //   } else {
          //     let newProduk = await this.state.list_idpel[indexOfIdpel[0].key + 1];

          //     let nextProduk = await newProduk.produk;
          //     let nextIdpel = await newProduk.idpel;
          //     let pathProduk = "";
          //     if (nextProduk.toString().toUpperCase().includes("PREPAID")) {
          //       pathProduk = 'plnprepaid/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("POSTPAID")) {
          //       pathProduk = 'plnpostpaid/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("BPJS")) {
          //       pathProduk = 'bpjs/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("SPEEDY") || nextProduk.toString().toUpperCase().includes("TELEPON")) {
          //       pathProduk = 'telkom/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("PDAM")) {
          //       pathProduk = 'pdam/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("PULSA")) {
          //       pathProduk = 'pulsa/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("DATA")) {
          //       pathProduk = 'data/' + nextIdpel
          //     }

          //     await this.props.history.push(this.props.history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
          //     let pathUrl = await window.location.href;
          //     window.location.href = await pathUrl;
          //   }
          // }
        }
      });
      this.setState({ loading: false });
    }).catch(function (err) {
      // console.log(err.response.data)
      Swal.fire({
        title: "Maaf",
        text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
        icon: "error",
        confirmButtonText: "Oke",
      });
      this.setState({ isAuth: 401 });
    }.bind(this));
  }

  Readproduct() {
    this.setState({ loading: true });
    console.log(JSON.stringify(this.state));
    fetch(`${process.env.REACT_APP_URL}/loket/transactions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((response) => {
      response.json().then((result) => {
        console.warn("result", result);
        //inq berhasil
        if (result.status == "200") {
          if (this.state.trx_type == "2100" && result.data.rc == "0000") {
            localStorage.setItem(
              "private_inquiry",
              JSON.stringify({
                data: result.data,
                produk: this.state.product_id,
              })
            );
            this.setState({ inquiry: true, inqres: result.data });
          } else {
            this.setState({ message: result.data.desc });
            Swal.fire({
              title: "Maaf",
              text: result.data.desc,
              icon: "info",
              confirmButtonText: "oke",
            });
          }
        } else {
          this.setState({ message: result.message.desc });
          Swal.fire({
            title: "Maaf",
            text: result.message.desc,
            icon: "info",
            confirmButtonText: "oke",
          });
        }
      });
      this.setState({ loading: false });
    });
  }

  setTypeProduct(event) {
    this.setState({
      produk: event.target.value,
      product_id: event.target.id,
    });
  }

  handleChangeIdpel(e) {
    let inIdpel = "";
    if (this.state.idpel_multi) {
      inIdpel = this.state.idpel_multi;
    } else {
      inIdpel = e.target.value;
    }
    if (inIdpel == "" || inIdpel == null) {
      this.setState({ message: null });
    }
    this.setState({ inquiry: false, trx_type: "2100", idpelStatus: false });
    if (inIdpel.length >= 11 && inIdpel.length <= 17) {
      this.setState({ message: null });
      this.setState({ idpel: e.target.value, idpelStatus: true });
      console.log(this.state.idpel);
    // } else if(inIdpel.length > 17){
    //   this.setState({
    //     message: "ID pelanggan panjang maksimal 17 digit ",
    //     inquiry: false,
    //     trx_type: "2100",
    //     idpelStatus: false,
    //   });
    } else {
      this.setState({
        message: "ID pelanggan mempunyai minimal panjang 11 digit dan maksimal panjang 17 digit ",
        inquiry: false,
        trx_type: "2100",
        idpelStatus: false,
        idpel: e.target.value,
      });
    }
    // console.log('e => '+e.target.value);
  }

  render() {
    return (
      <div>
        <CheckSession isAuth={this.state.isAuth} />
        {!this.state.produk ? (
          <div className="w-100 h-100  d-flex justify-content-center">
            <Loading type="bubbles" color="#008aff" className="m-auto" />
          </div>
        ) : (
          <div>
            <div>
              {this.state.inquiry ? (
                <>
                  <Redirect to="/checkoutv1" />
                </>
              ) : (
                <></>
              )}

              <h5 className="text-dark h6">Bayar Iuran BPJS Kesehatan</h5>

              {/* <div className="row mt-4">
                <div
                  className="col-md-12"
                  onChange={this.setTypeProduct.bind(this)}>
                  {this.state.list_product.map(function (value, i) {
                    return (
                      <label className="font-weight-bold d-inline mr-3" key={i}>
                        <input
                          type="radio"
                          value={value.code}
                          id={value.prod_id}
                          name="bpjsType"
                          className="mr-2"
                          defaultChecked={i === 0 ? "check" : ""}
                        />
                        {value.name}
                      </label>
                    );
                  })}
                </div>
              </div> */}

              <div className="row mt-4" id="durasi_kesehatan">
                <div className="col-md-12">
                  {this.state.message != null ? (
                    <div className="alert alert-danger" role="alert">
                      <label className="small">
                        {this.state.message}
                      </label>
                    </div>
                  ) : (
                    <></>
                  )}

                  <OptionKesehatan />

                  <div className="form-group mt-4">
                    <div className="row">
                      <div className="col-md-8">
                        <h6 className="text-dark">Nomor Virtual Account Keluarga / Perusahaan</h6>
                        <input
                          type="number"
                          disabled={this.state.list_idpel ? true : false}
                          value={this.state.idpel}
                          className="form-control border-bold"
                          placeholder="Nomor Virtual Account Keluarga / Perusahaan"
                          onChange={(e) => {
                            this.handleChangeIdpel(e);
                          }}
                        />
                      </div>
                      <div className="col-md-4 hidden-onphone">
                        <LaddaButton
                          loading={this.state.loading}
                          disabled={!this.state.idpelStatus}
                          onClick={() => {
                            this.HitData();
                          }}
                          data-color="#008aff"
                          data-size={l}
                          style={{ marginTop: "25px" }}
                          className="m-btn m-btn-theme m-btn-radius w-100"
                          data-style={EXPAND_RIGHT}>
                          Cek Tagihan
                        </LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="show-onphone">
                <div className="row">
                  <div className="col-md-12">
                    <LaddaButton
                      loading={this.state.loading}
                      disabled={!this.state.idpelStatus}
                      onClick={() => {
                        this.HitData();
                      }}
                      data-color="#008aff"
                      data-size={l}
                      className="m-btn m-btn-theme m-btn-radius w-100 btn-block shadow"
                      data-style={EXPAND_RIGHT}>
                      Cek Tagihan
                    </LaddaButton>
                  </div>
                </div>
              </div>
              <div
                className="card border border-primary bg-primary-300 mt-3 mb-3"
                style={{ backgroundColor: "#dbeefd" }}>
                <div className="card-body">
                  <p className="small">
                    <b className="text-primary">Keterangan</b>
                    <br></br>
                    Silahkan masukkan 13 digit nomor VA BPJS sesuai dengan nomor
                    kartu Anda..<br></br>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default bpjs;
