import React from 'react'

function Header() {
  return (
    <header>
      <h1>pusat bantuan</h1>
    </header>
  )
}

export default Header