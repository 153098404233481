import React from "react";
import NumberFormat from "react-number-format";
export const PulsaData = (props) => {
  if (props.struk.code == "0000") {
    return (
      <>
        {
          props.type == "print" ? (
            <>
              <tr>
                <td style={{borderRight: "1px solid black"}}>
                  {"Produk    : " + props.struk.product_name}<br />
                  {"Nomor Hp  : " + props.struk.msisdn}<br />
                  {"No Ref    : " + props.struk.sn}<br />
                  {"Harga     : "}<NumberFormat value={parseFloat(props.struk.price)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                  {"Adm Bank  : "}<NumberFormat value={parseFloat(props.struk.admin_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                  {"Adm Loket : "}<NumberFormat value={parseFloat(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                  {"Rp Bayar  : "}<NumberFormat value={parseFloat(props.struk.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>Produk</td>
                <td width="3%"> : </td>
                <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>{props.struk.product_name}</td>
              </tr>
              <tr>
                <td>Nomor Hp</td>
                <td> : </td>
                <td>{props.struk.msisdn}</td>
              </tr>
              <tr>
                <td>No Ref</td>
                <td> : </td>
                <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>{props.struk.sn}</td>
              </tr>
              <tr>
                <td>Harga</td>
                <td> : </td>
                <td><NumberFormat value={parseFloat(props.struk.price)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
              </tr>
              <tr>
                <td>Adm Bank</td>
                <td> : </td>
                <td><NumberFormat value={parseFloat(props.struk.admin_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
              </tr>
              <tr>
                <td>Adm Loket</td>
                <td> : </td>
                <td><NumberFormat value={parseFloat(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
              </tr>
              <tr>
                <td>Rp Bayar</td>
                <td> : </td>
                <td><NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
              </tr>
            </>
          )}
      </>
    )
  } else {
    return (
      <>
        <tr className="table-borderless">
          <td colSpan="2" className="text-muted">
            {props.struk.desc}
          </td>
        </tr>
      </>
    );
  }

}
