import React from 'react';
import NumberFormat from 'react-number-format';


export const PlnPrepaidInq = (props) => (
<>
<tr className="table-borderless">
    <td>NOMOR METER</td>
    <td className="text-right">{props.struk.data.material_number}</td>
</tr>
<tr>
    <td>ID PELANGGAN</td>
    <td className="text-right">{props.struk.data.subscriber_id}</td>
</tr>
<tr>
    <td>NAMA</td>
    <td className="text-right">{props.struk.data.subscriber_name}</td>
</tr>
<tr>
    <td>TARIF/DAYA</td>
    <td className="text-right">{props.struk.data.subscriber_segmentation}/{props.struk.data.power}</td>
</tr>
<tr>
    <td>MAX KWH</td>
    <td className="text-right">{props.struk.data.max_kwh_unit}</td>
</tr>
<tr>
    <td>DENOM</td>
    <td className="text-right"><NumberFormat value={props.struk.product_nomination} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
<tr>
    <td>ADMIN BANK</td>
    <td className="text-right"><NumberFormat value={props.struk.data.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
<tr>
    <td>BIAYA LOKET</td>
    <td className="text-right"><NumberFormat value={props.struk.data.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
</>
);