import { map } from 'jquery';
import React from 'react';
import NumberFormat from 'react-number-format';

const NamaBulan = ({data}) => {
    data =  parseInt(data);
    let bulan = ['JANUARI','FEBRUARI','MARET','APRIL','MEI','JUNI','JULI','AGUSTUS','SEPTEMBER','OKTOBER','NOVEMBER','DESEMBER',]
    return bulan[data-1];
}

export const PlnPostpaidInq = (props) => (
<>
<tr className="table-borderless">
    <td>ID PELANGGAN</td>
    <td className="text-right">{props.struk.subscriber_id}</td>
</tr>
<tr>
    <td>NAMA</td>
    <td className="text-right">{props.struk.subscriber_name}</td>
</tr>

<tr>
    <td>TARIF/DAYA</td>
    <td className="text-right">{props.struk.subscriber_segmentation}/{props.struk.power}</td>
</tr>

<tr>
    <td>BULAN/TAHUN</td>
    <td className="text-right">{props.struk.blth_summary}</td>
</tr>

<tr>
    <td>RP TAG</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.amount)-parseInt(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>ADMIN BANK</td>
    <td className="text-right"><NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>BIAYA LOKET</td>
    <td className="text-right"><NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
<tr>
    <td>TOTAL TAGIHAN</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.amount)+parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>


{/* chek apakah tagihan ada lebih dari 1 */}
{ (parseInt(props.struk.bill_status)> 1) ? (
    <tr>
        <td colspan="2">
            <div className=''>
                <div className='my-2 text-center'>Rincian Tagihan</div>
                <div className=''>
                    <table className='table'>
                        {props.struk.bills.map((dt)=>{
                            return (<tr>
                                <td><NamaBulan data={dt.bill_period.slice(4)}/>-{dt.bill_period.slice(0,4)}</td>
                                <td class='text-right'><NumberFormat value={parseInt(dt.penalty_fee)+parseInt(dt.total_electricity_bill)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
                            </tr>);
                        })}
                    </table>
                </div>
            </div>
        </td>
    </tr>
):(
    <></>
)}
</>
);