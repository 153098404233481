import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../router/Router";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";

function PanelMobile(props) {
  let history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AuthContext);
  const logo_name = localStorage.getItem("logo");
  const [datatrx, setDatatrx] = useState([]);
  const [data, setData] = useState([]);
  const [isAuth, setAuth] = useState(0);
  const token = localStorage.getItem("token");
  const getData = async () => {
    await setLoading(true);
    // console.warn("state", state);
    await state;
    Axios.get(
      `${process.env.REACT_APP_URL}/loket/transactions/history`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).then(async (response) => {
      await setDatatrx(response.data.data.history.transactions);
      await setData(response.data.data.history.total);
    }).catch(async function (err) {
      if (err.response.data.status == 401) {
        await setAuth(401);
      }
    })
    await setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <section className="section p-0px-t mb-4" style={{ backgroundColor: "#2196F3" }}>
        {state.role != "1" ? (
          <div className="container mb-2">
            <div className="row">
              <div className="col-12">
                <div className="card shadow-sm border border-radius-15">
                  <div className="card-body">
                    <div className="row no-gutters">
                      <div className="col-12 text-center container-image align-items-center">
                        <img className="rounded image-logo" width="150px" alt="" title="" src={logo_name == "null" ? "/img/loketpelangi.png" : `${process.env.REACT_APP_URL}/images/${logo_name}`} />

                        <div class="middle">
                          <p class="text"><NavLink to="/users/changelogo" href="#" className="btn btn-sm btn-primary">
                            Ganti Logo
                          </NavLink></p>
                        </div>
                      </div>
                      <div className="col-12">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item"><i className="fa fa-user text-primary mr-2"></i> {state.fullname}</li>
                          <li class="list-group-item"><i className="fa fa-wallet text-info mr-2"></i> <NumberFormat
                            value={state.balance != "" ? parseInt(state.balance) : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp"}
                            renderText={(value) => <>{value}</>}
                          /><span className="badge badge-secondary float-right small mt-1">Saldo</span></li>
                          <li class="list-group-item"><i className="fa fa-shopping-cart text-success mr-2"></i> {data.transaction} Transaksi</li>
                          <li class="list-group-item"><i className="fa fa-money-bill-wave text-danger mr-2"></i> <NumberFormat
                            value={parseInt(data.amount)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp"}
                            renderText={(value) => <>{value}</>}
                          /><span className="badge badge-secondary float-right small mt-1">Total Bayar</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (<></>)}
        <div className="list-group">
          {state.role != "1" ? (
            <>
              <NavLink to="/users/transaksi/closing/list" style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }} href="#" className="list-group-item list-group-item-action"><i className="fa fa-clock mr-2 text-dark"></i> Riwayat Closing Transaksi</NavLink>
              <NavLink to="/users/transaksi/importidpel" href="#" className="list-group-item list-group-item-action"><i className="fa fa-upload mr-2 text-dark"></i> Impor ID Pelanggan</NavLink>
              <NavLink to="/users/idpel/favorite" href="#" className="list-group-item list-group-item-action"><i className="fa fa-star mr-2 text-dark"></i> ID Pelanggan Favorit</NavLink>
              <NavLink to="/users/transaksi/manualadvice" href="#" className="list-group-item list-group-item-action"><i className="fa fa-sync mr-2 text-dark"></i> Cek Status Manual</NavLink>
              <NavLink to="/users/produk/listharga" href="#" className="list-group-item list-group-item-action"><i className="fa fa-money-bill-alt mr-2 text-dark"></i> Daftar Harga Produk</NavLink>
              <NavLink to="/users/setting/adminpulsa" href="#" className="list-group-item list-group-item-action"><i className="fa fa-signal mr-2 text-dark"></i> Setting Admin Pulsa</NavLink>
              <NavLink to="/users/setting/biayaloket" href="#" className="list-group-item list-group-item-action"><i className="fa fa-store mr-2 text-dark"></i> Setting Admin Loket</NavLink>
              <NavLink to="/users/setting/akunpengguna" href="#" className="list-group-item list-group-item-action"><i className="fa fa-users mr-2 text-dark"></i> Setting Akun Petugas</NavLink>
              <NavLink to="/users/changepassword" href="#" className="list-group-item list-group-item-action"><i className="fa fa-key mr-2 text-dark"></i> Ganti Password</NavLink>
            </>
          ) : (
            <>
            
              <a href="#" className="list-group-item list-group-item-action bg-white text-dark font-weight-bold border-bottom"> Transaksi</a>
              <NavLink to="/users/riwayat/topupsaldo" href="#" className="list-group-item list-group-item-action"><i className="fa fa-money-bill mr-2 text-dark ml-3"></i> Request Topup Saldo</NavLink>
              <NavLink to="/users/transaksi/manualadvice" href="#" className="list-group-item list-group-item-action"><i className="fa fa-sync mr-2 text-dark ml-3"></i> Cek Status Manual</NavLink>
              <NavLink to="/users/transaksi/updatestatustrx" href="#" className="list-group-item list-group-item-action"><i className="fa fa-edit mr-2 text-dark ml-3"></i> Edit Status Transaksi</NavLink>
              <NavLink to="/users/transaksi/transaksirevisi" href="#" className="list-group-item list-group-item-action"><i className="fa fa-edit mr-2 text-dark ml-3"></i> Transaksi Revisi</NavLink>

              <a href="#" className="list-group-item list-group-item-action bg-white text-dark font-weight-bold border-bottom border-top-0 border-right-0"> Produk</a>
              <NavLink to="/users/produk/listharga" href="#" className="list-group-item list-group-item-action"><i className="fa fa-money-bill-alt mr-2 text-dark ml-3"></i> Daftar Harga</NavLink>

              <a href="#" className="list-group-item list-group-item-action bg-white text-dark font-weight-bold border-bottom border-top-0 border-right-0"> Riwayat</a>
              <NavLink to="/users/transaksi/closing/list" href="#" className="list-group-item list-group-item-action"><i className="fa fa-clock mr-2 text-dark ml-3"></i> Closing Transaksi</NavLink>
              <NavLink to="/users/riwayat/pembayaran" href="#" className="list-group-item list-group-item-action"><i className="fa fa-search mr-2 text-dark ml-3"></i> Status Pembayaran</NavLink>

              <a href="#" className="list-group-item list-group-item-action bg-white text-dark font-weight-bold border-bottom border-top-0 border-right-0"> Pengaturan</a>
              <NavLink to="/users/setting/verifikasi" href="#" className="list-group-item list-group-item-action"><i className="fa fa-users mr-2 text-dark ml-3"></i> Data Registrasi</NavLink>
              <NavLink to="/users/setting/denomprepaid" href="#" className="list-group-item list-group-item-action"><i className="fa fa-bolt mr-3 text-dark ml-3"></i> Denom PLN Prepaid</NavLink>
              <NavLink to="/produk/setting" href="#" className="list-group-item list-group-item-action"><i className="fa fa-list-alt mr-2 text-dark ml-3"></i> Daftar Produk</NavLink>
              <NavLink to="/users/setting/akunpengguna" href="#" className="list-group-item list-group-item-action"><i className="fa fa-users mr-2 text-dark ml-3"></i> Akun Petugas Loket</NavLink>
              <NavLink to="/adpertanyaanumum" href="#" className="list-group-item list-group-item-action"><i className="fa fa-comments mr-2 text-dark ml-3"></i> Pusat Bantuan</NavLink>
              <NavLink to="/adsyaratketentuan" href="#" className="list-group-item list-group-item-action"><i className="fa fa-check-circle mr-2 text-dark ml-3"></i> Syarat & Ketentuan</NavLink>
              <NavLink to="/adhubungikami" href="#" className="list-group-item list-group-item-action"><i className="fa fa-phone mr-2 text-dark ml-3"></i> Kontak Kami</NavLink>
              <NavLink to="/users/setting/payment-methods" href="#" className="list-group-item list-group-item-action"><i className="fa fa-credit-card mr-2 text-dark ml-3"></i> Metode Pembayaran</NavLink>
              <NavLink to="/users/changepin" href="#" className="list-group-item list-group-item-action"><i className="fa fa-lock mr-2 text-dark ml-3"></i> Ganti PIN</NavLink>
              <NavLink to="/users/changepassword" href="#" className="list-group-item list-group-item-action"><i className="fa fa-key mr-2 text-dark ml-3"></i> Ganti Password</NavLink>
            </>
            )}
          
          <li href="#" className="list-group-item list-group-item-action border-0">
            <a
              href="#"
              className="btn btn-outline-danger border border-danger col-12"
              onClick={() =>
                Swal.fire({
                  title: "Yakin keluar  ?",
                  text: "",
                  icon: "info",
                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: "Keluar",
                  cancelButtonText: "Batal",
                }).then((result) => {
                  if (result.value) {
                    dispatch({
                      type: "LOGOUT",
                    });
                  } else if (
                    result.dismiss === Swal.DismissReason.cancel
                  ) {
                    //cencle
                  }
                })
              }
            ><i className="fa fa-power-off text-danger"></i> Keluar</a>
          </li>
          {state.role != "1" ? (
            <li className="list-group-item list-group-item-action border-0 pb-4">
              <sub className="text-secondary" style={{ lineHeight: "5px" }}><sup>*</sup> Klik gambar untuk edit logo</sub><br />
              <sub className="text-secondary" style={{ lineHeight: "5px" }}><sup>*</sup> Jml transaksi dan total bayar akan 0 jika sudah di closing</sub>
            </li>
          ) : (
            <>
              <li className="list-group-item list-group-item-action border-0 pb-5"></li>
              <li className="list-group-item list-group-item-action border-0 pb-5"></li>
            </>)}
        </div>

      </section>
    </>
  );
}

export default PanelMobile;
