import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["jpg", "png", "pdf", "jpeg"];
const createHistory = require("history").createBrowserHistory;
let history = createHistory();
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>
  }
}
class RequestSaldoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      name: "",
      idpel: null,
      masa_berlaku: "",
      displayMasaBerlaku: "none",
      messageError: "",
      btnDisabled: "disabled",
      dangerDisplay: "none",
      textButton: "Beli",
      message: "",
      isAuth: 0,
      token: localStorage.getItem("token"),
      inquiry: false,
      nominal: null,
      saldoType: localStorage.getItem("type"),
      uploaded: false,
      list_token: [
        {
          "id": 1,
          "product_id": 80,
          "denom": 20000,
          "description": "Rp. 20.000",
          "active": 1
        },
        {
          "id": 2,
          "product_id": 80,
          "denom": 50000,
          "description": "Rp. 50.000",
          "active": 1
        },
        {
          "id": 3,
          "product_id": 80,
          "denom": 100000,
          "description": "Rp. 100.000",
          "active": 1
        },
        {
          "id": 4,
          "product_id": 80,
          "denom": 200000,
          "description": "Rp. 200.000",
          "active": 1
        },
        {
          "id": 5,
          "product_id": 80,
          "denom": 500000,
          "description": "Rp. 500.000",
          "active": 1
        },
        {
          "id": 6,
          "product_id": 80,
          "denom": 1000000,
          "description": "Rp. 1.000.000",
          "active": 1
        },
        {
          "id": 7,
          "product_id": 80,
          "denom": 1500000,
          "description": "Rp. 1.500.000",
          "active": 1
        },
        {
          "id": 8,
          "product_id": 80,
          "denom": 2000000,
          "description": "Rp. 2.000.000",
          "active": 1
        },
      ],
      tombol: true,
      dataListRec: [],
      rekening: "",
      options:[],
      unique_code: "",
      nominalUnique : null,
      dataToStore : "",
      clone: false,
      noRek: "",
      file: null,
      id: null,
      automatic: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    })
    this.fetchListRec();
  }
  componentDidUpdate() {
    if (this.state.clone) {
      Swal.fire({
        position: 'top-end',
        // icon: 'success',
        html: "No Rekening <b>" + this.state.noRek + "</b> Disalin",
        showConfirmButton: false,
        timer: 1200
      })
    }
  }
  handleChange = (e) => {
    // console.log(e)
    this.setState({
      [e.name]: e.value,
    });
  };
  handleChangeBukti = async (file) => {
    await this.setState({ file: file })
  };
  handleSelectChange = (rekening) => {
    this.setState({ rekening });
  };
  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  setNominal = (value) => {
    this.setState({ nominal: value });
  };
  cloneNoRek(noRek) {
    this.setState({ clone: true, noRek: noRek })
    navigator.clipboard.writeText(noRek)
  }
  fetchListRec = async () => {
    await fetch(`${process.env.REACT_APP_URL}/rekening`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        this.setState({ loading: false, dataListRec: result.data });
        const optionsByCategory = {};
    
        const jsonData = this.state.dataListRec;

        jsonData.forEach((option) => {
          if (!optionsByCategory[option.senderType]) {
            optionsByCategory[option.senderType] = [];
          }
          optionsByCategory[option.senderType].push({
            value: option,
            label: option.bankName,
            name: "rekening",
          });
        });

        const options = Object.keys(optionsByCategory).map((senderType) => ({
          label: senderType,
          options: optionsByCategory[senderType],
          
        }));

        this.setState({ options });
      });
    });
  };

  fetchUniqueCode = async () => {
    const formData = new FormData();
    await formData.append('nominal', this.state.nominal);

    await axios
    .post(
      `${process.env.REACT_APP_URL}/uniqe_code`, 
      formData,
      {
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then( async(response) => {
      await this.setState({ unique_code: response.data.data.code_unique });
      await console.log("Type : "+this.state.unique_code);
    });
  };
  
  sendRequest = async () => {
    await this.setState({ nominalUnique: parseInt(this.state.nominal)+parseInt(this.state.unique_code) });
    await axios
      .post(
        `${process.env.REACT_APP_URL}/saldo`,
        // formData,
        {
          nominal: this.state.nominalUnique,
          payment_method : this.state.rekening.value.payment_method+" - "+this.state.rekening.value.bankName,
          type_sender: this.state.rekening.value.senderType,
          unique_code: this.state.unique_code,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        this.setState({ uploaded: true });
        if (json.data.status == 200) {
          this.setState({ id: json.data.data[0].id , uploaded: true })
          if(this.state.rekening.value.senderType == "otomatis"){
            this.setState({ automatic: true })
          }else{
            this.setState({ automatic: false })
          }
        } else {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      }).catch((err) => {
        if (err.response.data.status == 402) {
          Swal.fire({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        } else if (err.response.data.status == 401) {
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      });
  }
  sendRequestBukti = async () => {
    const formData = new FormData()
    await formData.append('images_tf', this.state.file)
    await formData.append('type' , 'DEPOSIT')
    axios
      .put(
        `${process.env.REACT_APP_URL}/saldo/update/`+this.state.id,
        formData,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then( async(json) => {
        if (json.data.status == 200) {
          await Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
          history.push("/users/riwayat/topupsaldo");
          let pathUrl = window.location.href;
          window.location.href = pathUrl;
        } else {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      }).catch((err) => {
        if (err.response.data.status == 402) {
          Swal.fire({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        } else if (err.response.data.status == 401) {
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      });
  }
  upload = async () => {
    await Swal.fire({
      title: "",
      html: "Upload Bukti Transfer ?",
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Upload",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.sendRequestBukti()
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
    })
  }
  prosesRequest = async () => {
    if (this.state.nominal == null || this.state.nominal == "") {
      Swal.fire({
        title: "Error",
        html: "Silahkan pilih nominal terlebih dahulu",
        icon: "error",
      });
    }else if (this.state.nominal < 10000) {
      Swal.fire({
        title: "",
        html: "Minimal Topup Rp 10.000",
        icon: "info",
      });
    }else if (this.state.rekening == null || this.state.rekening == ""){
      Swal.fire({
        title: "Error",
        html: "Silahkan pilih metode topup terlebih dahulu",
        icon: "error",
      });
    }else{
      Swal.fire({
        title: "",
        html: "Nominal Request Saldo :  <b>Rp. " + this.rupiahFormat(parseFloat(this.state.nominal)) + "</b> <br/> Nama Bank : <b>"+this.state.rekening.value.bankName+"</b> Metode Topup : <b>" + this.state.rekening.value.payment_method + "</b>",
        icon: "info",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Kirim Request",
      }).then(async(result) => {
        if (result.value) {
          await this.fetchUniqueCode();
          const {nominal, rekening, unique_code} = this.state;
          const dataToStore = JSON.stringify({nominal, rekening, unique_code });
          this.setState({ dataToStore: dataToStore });
          console.log(dataToStore);
          this.sendRequest()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
  
        }
      });
    }

  };
  render() {

    const { options, rekening } = this.state;

    return (
      <div className="bg-admin pb-5">
        <CheckSession isAuth={this.state.isAuth} />
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-money-bill-wave text-primary h1 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">REQUEST TOPUP SALDO</span> <br />
                          Request topup saldo akan diproses secepatnya
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="card-body">
                    {this.state.uploaded ? (
                      <>
                        <div className="alert alert-success alert-dismissible">
                          <p className="small">Request saldo berhasil dikirim, Jika dalam kurun waktu maksimal 15 menit kedepan saldo Anda belum bertambah, segera hubungi <i><b>customer service</b></i> kami.<br />
                            Untuk melihat riwayat dan status request saldo, Anda bisa masuk ke halaman panel pengguna</p>
                        </div>
                        {/* start pay */}
                        <div className="row">
                          {this.state.automatic ? (
                            <>
                              <div className="col-md-12">
                                <div className="card mt-2" style={{ border: "2px solid #dedede" }}>
                                  <div className="card-body bg-light">
                                    <p className="font-weight-bold text-center text-dark">Nominal Transfer</p>
                                    <p className="text-primary font-weight-bold text-center h3"> Rp. {this.rupiahFormat(this.state.nominalUnique)}</p>
                                    <p className="font-weight-bold text-center text-dark">Nomor Rekening</p>

                                    <div className="row justify-content-center">
                                      <div className="col-md-10 table-responsive">
                                        <div className="hidden-onphone">
                                          <table className="table table-borderless" width="100%">
                                            <tbody>
                                              <tr>
                                                {this.state.rekening.label === "BCA" ? (
                                                  <>
                                                    <td className="text-right" width="33%"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Bank_Central_Asia.svg/2560px-Bank_Central_Asia.svg.png" width="50%" /></td>
                                                  </>
                                                ): this.state.rekening.label === "BNI" ?(
                                                  <>
                                                    <td className="text-right" width="33%"><img src="https://rekreartive.com/wp-content/uploads/2019/04/Logo-BNI-Bank-Negara-Indonesia-46-Vector-.png" width="50%" /></td>
                                                  </>
                                                ): this.state.rekening.label === "BRI" ?(
                                                  <>
                                                    <td className="text-right" width="33%"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/BANK_BRI_logo.svg/2560px-BANK_BRI_logo.svg.png" width="50%" /></td>
                                                  </>
                                                ):(
                                                  <>
                                                    <td className="text-right" width="33%"><img src="https://savegame.id/wp-content/uploads/2020/02/Cara-Transfer-Virtual-Account-Mandiri-Paling-Mudah.png" width="50%" /></td>
                                                  </>
                                                )}
                                                <td className="text-center h6 align-middle">{this.state.rekening.value.accountNumber}</td>
                                                <td className="text-left align-middle"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary ml-3" onClick={() => this.cloneNoRek(this.state.rekening.value.accountNumber)}>Salin <i className="fa fa-clone text-secondary"></i></a></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="text-primary text-center font-weight-bold">( PT. PELANGI INDODATA )</p>
                                    <hr />
                                    <small><i>*) Transfer sesuai nominal yang tertera diatas ke salah satu nomor rekening yang tersedia</i></small>
                                  </div>
                                </div>

                              </div>
                            </>
                          ):(
                            <>
                              <div className="col-md-7">
                                <div className="card mt-2" style={{ border: "2px solid #dedede" }}>
                                  <div className="card-body bg-light">
                                    <p className="font-weight-bold text-center text-dark">Nominal Transfer</p>
                                    <p className="text-primary font-weight-bold text-center h3"> Rp. {this.rupiahFormat(this.state.nominalUnique)}</p>
                                    <p className="font-weight-bold text-center text-dark">Nomor Rekening</p>

                                    <div className="row justify-content-center">
                                      <div className="col-md-10 table-responsive">
                                        <div className="hidden-onphone">
                                          <table className="table table-borderless" width="100%">
                                            <tbody>
                                              <tr>
                                                {this.state.rekening.label === "BCA" ? (
                                                  <>
                                                    <td className="text-center" width="25%"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Bank_Central_Asia.svg/2560px-Bank_Central_Asia.svg.png" width="100%" /></td>
                                                  </>
                                                ): this.state.rekening.label === "BNI" ?(
                                                  <>
                                                    <td className="text-center" width="25%"><img src="https://rekreartive.com/wp-content/uploads/2019/04/Logo-BNI-Bank-Negara-Indonesia-46-Vector-.png" width="100%" /></td>
                                                  </>
                                                ): this.state.rekening.label === "BRI" ?(
                                                  <>
                                                    <td className="text-center" width="25%"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/BANK_BRI_logo.svg/2560px-BANK_BRI_logo.svg.png" width="100%" /></td>
                                                  </>
                                                ):(
                                                  <>
                                                    <td className="text-center" width="25%"><img src="https://savegame.id/wp-content/uploads/2020/02/Cara-Transfer-Virtual-Account-Mandiri-Paling-Mudah.png" width="100%" /></td>
                                                  </>
                                                )}
                                                <td className="text-left h6 align-middle">{this.state.rekening.value.accountNumber}</td>
                                                <td className="text-center"><a href="#" className="btn btn-sm btn-flat btn-outline-secondary ml-3" onClick={() => this.cloneNoRek(this.state.rekening.value.accountNumber)}>Salin <i className="fa fa-clone text-secondary"></i></a></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="text-primary text-center font-weight-bold">( PT. PELANGI INDODATA )</p>
                                    <hr />
                                    <small><i>*) Transfer sesuai nominal yang tertera diatas ke salah satu nomor rekening yang tersedia</i></small>
                                  </div>
                                </div>

                              </div>
                              <div className="col-md-5 mt-2 table-responsive">
                                <div className="card pb-5 bg-light" style={{ border: "2px solid #dedede" }}>
                                  <div className="card-body text-center">
                                    <div className="mt-2"></div>
                                    <FileUploader name="file" handleChange={this.handleChangeBukti} types={fileTypes} classes="col-md-12 py-5" onDrop={this.upload} onSelect={this.upload} maxSize="3" onSizeError="Ukuran file maksimal 3 MB" />
                                    <p>{this.state.file ?
                                      (
                                        <div>
                                          File name: {this.state.file.name}<br />
                                          {/* <button className="btn btn-primary">Upload File</button> */}
                                        </div>
                                      ) : (
                                        <div>
                                          Upload Bukti Transfer
                                        </div>
                                      )}</p>
                                    <div className="pb-5"></div>
                                    <div className="pb-5"></div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          
                        </div>
                        {/* end pay */}
                      </>
                    ) : (
                    <>
                      {this.state.list_token ? (
                        <div className="row">
                          <div className="col-12">
                            <ul className="nav nav-fill nav-tabs row p-2 border-0">
                              {this.state.list_token.map((value, index) => {
                                return (
                                  <li
                                    className="nav-item col-6 col-md-3 col-sm-6 mr-0 p-2"
                                    key={index}
                                    onClick={
                                      value.active === 1
                                        ? (e) => {
                                            this.setNominal(value.denom);
                                          }
                                        : ""
                                    }
                                  >
                                    <a
                                      href="#"
                                      data-toggle="tab"
                                      className={
                                        value.active === 0
                                          ? "nav-link btn disabled"
                                          : "nav-link btn"
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#F9F9F9",
                                        borderColor: "#dedede",
                                        padding: "15px",
                                      }}
                                    >
                                      <span>
                                        Rp
                                        {this.rupiahFormat(
                                          parseFloat(value.denom)
                                        )}
                                      </span>
                                      {value.active === 0 ? (
                                        <p
                                          className="small text-danger"
                                          style={{ lineHeight: "10px" }}
                                        >
                                          Produk sedang kosong
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="w-100 h-100  d-flex justify-content-center">

                        </div>
                      )}
                      <div className="row mt-4">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="input-group">
                              <CurrencyInput
                                id="nominal"
                                name="nominal"
                                placeholder="Ketik Request Nominal Topup"
                                defaultValue={""}
                                value={this.state.nominal}
                                className="w-100 form-control input-xl font-weight-bold"
                                decimalsLimit={2}
                                onValueChange={async (value) => { await this.setNominal(value); }}
                              />
                            </div>
                            <p className="text-danger">{this.state.messageError}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="input-group ">
                              <Select
                                id="rekening"
                                name="rekening"
                                options={options}
                                value={rekening}
                                onChange={this.handleSelectChange}
                                placeholder={<div>Pilih Metode Topup</div>}
                                className=" font-weight-bold col-md-12"
                                styles={{
                                  // Atur gaya untuk label
                                  groupHeading: (provided) => ({
                                    ...provided,
                                    fontSize: '18px', // Ganti dengan ukuran font yang Anda inginkan
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    fontSize: '18px', // Ganti dengan ukuran font yang Anda inginkan
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: '12px', // Ganti dengan ukuran font yang Anda inginkan
                                  }),
                                }}
                              />
                            </div>
                            <p className="text-danger">{this.state.messageError}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <LaddaButton
                            loading={this.state.loading}
                            onClick={() => {
                              this.prosesRequest();
                            }}
                            data-color="#008aff"
                            data-size={l}
                            className="m-btn m-btn-theme m-btn-radius w-100"
                            data-style={EXPAND_RIGHT}
                          >
                            Request Saldo
                          </LaddaButton>
                        </div>
                      </div>
                    </>
                    )}
                    
                    <div
                      className="card border border-primary bg-primary-300 mt-4"
                      style={{ backgroundColor: "#dbeefd" }}>
                      <div className="card-body">
                        <b className="text-primary">Keterangan</b><br />
                        <p className="small text-dark">
                          1. Request topup saldo akan proses secepatnya (maksimal 30 menit).<br />
                          2. Jika dalam kurun waktu 30 menit saldo belum masuk, harap segera menghungi customer service.<br />
                          3. Lakukan request topup sebelum kehabisan saldo agar tetap bisa melakukan transaksi.<br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </div>

    );
  }
}

export default RequestSaldoForm;
