import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';

const apiUrl = process.env.REACT_APP_URL;
const pulsaInit = [
  {
    nominal_display: 10000,
    price: 10000,
    admin_fee: 1500,
    masa_berlaku: "Masa berlaku 14 hari",
  },
  {
    nominal_display: 15000,
    price: 15000,
    admin_fee: 1500,
    masa_berlaku: "Masa berlaku 20 hari",
  },
  {
    nominal_display: 25000,
    price: 25000,
    admin_fee: 1500,
    masa_berlaku: "Masa berlaku 30 hari",
  },
  {
    nominal_display: 30000,
    price: 30000,
    admin_fee: 1500,
    masa_berlaku: "Masa berlaku 30 hari",
  },
  {
    nominal_display: 40000,
    price: 40000,
    admin_fee: 1500,
    masa_berlaku: "Masa berlaku 30 hari",
  },
  {
    nominal_display: 50000,
    price: 50000,
    admin_fee: 1500,
    masa_berlaku: "Masa berlaku 45 hari",
  },
  {
    nominal_display: 75000,
    price: 75000,
    admin_fee: 1500,
    masa_berlaku: "Masa berlaku 45 hari",
  },
  {
    nominal_display: 100000,
    price: 100000,
    admin_fee: 1500,
    masa_berlaku: "Masa berlaku 60 hari",
  },
];
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>
  }
}
class pulsa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_id: 0,
      nominal_display: 0,
      loading: true,
      name: "",
      idpel: props.match.params.idpel,
      masa_berlaku: "",
      displayMasaBerlaku: "none",
      messageError: "",
      btnDisabled: "disabled",
      dangerDisplay: "none",
      pulsaData: [],
      product_nomination: "",
      periode_payment: "",
      trx_id: "",
      email: "app@gmail.com",
      logoOperator: "",
      inquiry: false,
      prod_category: null,
      trx_type: "2100",
      textButton: "Beli",
      inqres: [],
      message: "",
      amount: 0,
      admin_fee: 0,
      loket_fee: 0,
      handling_fee: 0,
      isAuth: 0,
      token: localStorage.getItem("token"),
      idpel_multi: props.match.params.idpel,
      list_idpel: JSON.parse(localStorage.getItem("list_impor_idpel")),
      last_idpel: localStorage.getItem("list_impor_idpel") ? JSON.parse(localStorage.getItem("list_impor_idpel")).slice(-1) : '',
    };
  }
  componentDidMount() {
    let pathUrl = window.location.pathname;
    let urlImpor = pathUrl.split('/');
    // console.log("path", urlImpor)
    if (urlImpor[1] == 'pulsa') {
      if (urlImpor[1] != "users" && urlImpor[2] != "transaksi" && urlImpor[3] != "importidpel" && urlImpor[4] != "cektagihan") {
        if (this.state.list_idpel) {
          localStorage.removeItem("list_impor_idpel");
        }
      }
    }
    this.setState({
      loading: false
    })

    if (this.state.list_idpel && urlImpor[1] != 'pulsa') {
      let cekIdpel = this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
      // console.warn("cek", cekIdpel)
      if (cekIdpel.length > 0) {
        this.validateNoTelp(this.state.idpel);
      } else {
        Swal.fire({
          title: "Maaf",
          text: "ID pelanggan tidak ada di daftar yang diimpor",
          icon: "info",
          confirmButtonText: "oke",
        });
      }
    }
  }
  setInfo(event) {
    let disabled = "disabled";
    if (this.state.idpel == null) {
      this.setState({
        messageError: "Masukkan nomor telepon terlebih dahulu",
      })
    } else {
      if (this.state.idpel.length > 10) {
        disabled = "";
      }
      this.setState({
        nominal_display: event.price,
        name: event.name,
        product_id: event.prod_id,
        amount: event.price,
        admin_fee: event.admin_fee_pulsa,
        loket_fee: event.loket_fee_pulsa,
        handling_fee: event.handling_fee,
        masa_berlaku: "Masa berlaku 30 hari",
        displayMasaBerlaku: "none",
        btnDisabled: disabled,
        textButton: "Beli",
      });
    }
  }
  getPulsa = (opr) => {
    let apiUrlOp = "";
    let logoOp = "";
    let prod_cat = 0;
    if (
      opr === "12" ||
      opr === "13" ||
      opr === "21" ||
      opr === "22" ||
      opr === "52" ||
      opr === "53" ||
      opr === "23" ||
      opr === "51"
    ) {
      // Operator telkomsel
      prod_cat = 59;
      apiUrlOp = apiUrl + "/products?product_category_id=" + prod_cat;
      logoOp = "telkomsel.png";
    } else if (
      opr === "14" ||
      opr === "15" ||
      opr === "16" ||
      opr === "55" ||
      opr === "56" ||
      opr === "57" ||
      opr === "58"
    ) {
      // Operator indosat
      prod_cat = 58;
      apiUrlOp = apiUrl + "/products?product_category_id=" + prod_cat;
      logoOp = "indosat.jpg";
    } else if (
      opr === "17" ||
      opr === "18" ||
      opr === "19" ||
      opr === "59" ||
      opr === "77" ||
      opr === "78"
    ) {
      // Operator XL
      prod_cat = 50;
      apiUrlOp = apiUrl + "/products?product_category_id=" + prod_cat;
      logoOp = "xl.png";
    } else if (
      opr === "30" ||
      opr === "31" ||
      opr === "32" ||
      opr === "33" ||
      opr === "34" ||
      opr === "35" ||
      opr === "36" ||
      opr === "37" ||
      opr === "38" ||
      opr === "39"
    ) {
      // Operator axis
      prod_cat = 55;
      apiUrlOp = apiUrl + "/products?product_category_id=" + prod_cat;
      logoOp = "axis.png";
    } else if (
      opr === "95" ||
      opr === "96" ||
      opr === "97" ||
      opr === "98" ||
      opr === "99"
    ) {
      // Operator three
      prod_cat = 60;
      apiUrlOp = apiUrl + "/products?product_category_id=" + prod_cat;
      logoOp = "three.png";
    } else if (
      opr === "81" ||
      opr === "82" ||
      opr === "83" ||
      opr === "84" ||
      opr === "85" ||
      opr === "86" ||
      opr === "87" ||
      opr === "88" ||
      opr === "89"
    ) {
      // Operator smartfren
      prod_cat = 61;
      apiUrlOp = apiUrl + "/products?product_category_id=" + prod_cat;
      logoOp = "smartfren.jpg";
    }
    if (apiUrlOp === "") {
      this.setState({
        pulsaData: [],
        dangerDisplay: "block",
        logoOperator: "",
      });
    } else {
      // let token = process.env.REACT_APP_TOKEN;
      // console.log(this.state.token)
      axios
        .get(apiUrlOp, {
          headers: {
            Authorization: "Barier " + this.state.token,
          },
        })
        .then((res) => {
          // console.log(res.data.data);
          this.setState({
            pulsaData: res.data.data,
            dangerDisplay: "none",
            logoOperator: logoOp,
            prod_category: prod_cat,
          });
        }).catch((err) => {
          // console.log(err.response.data)
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
          this.setState({ isAuth: 401 });
        });
    }
  };
  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
  validateNoTelp = (e) => {
    let noTelp = "";
    if (this.state.idpel_multi) {
      noTelp = this.state.idpel_multi;
    } else {
      noTelp = e.target.value;
    }
    this.setState({ idpel: noTelp });
    if (noTelp.length === 0) {
      this.setState({
        messageError: "Masukkan nomor telepon terlebih dahulu",
        btnDisabled: "disabled",
        displayMasaBerlaku: "none",
        nominal_display: 0,
        pulsaData: [],
        prod_category: null
      });
    } else {
      let opr = noTelp.substring(2, 4);
      if (!noTelp.match(/^[0-9]+$/)) {
        this.setState({
          pulsaData: pulsaInit,
          messageError: "Nomor telepon hanya boleh berisi angka(08..)",
          btnDisabled: "disabled",
          displayMasaBerlaku: "none",
          nominal_display: 0,
          logoOperator: "",
        });
      } else {
        if (noTelp.length >= 4) {
          this.getPulsa(opr);
          this.setState({
            idpel: noTelp,
          });
        } else {
          this.setState({
            pulsaData: pulsaInit,
            dangerDisplay: "none",
            displayMasaBerlaku: "none",
            nominal_display: 0,
            logoOperator: "",
          });
        }
        if (noTelp.length < 10) {
          this.setState({
            messageError: "Nomor terlalu pendek, minimal 11 angka",
            btnDisabled: "disabled",
            displayMasaBerlaku: "none",
            nominal_display: 0,
          });
        } else {
          this.setState({ messageError: "" });
        }
      }
    }
  };
  renderLogoOp() {
    if (this.state.logoOperator != "") {
      if (this.state.idpel_multi) {
        return <img src={"../../../../../img/logo_operator/" + this.state.logoOperator} />;
      } else {
        return <img src={"img/logo_operator/" + this.state.logoOperator} />;
      }

    }
  }
  checkout = async () => {
    await this.setState({ loading: true })
    await fetch(`${process.env.REACT_APP_URL}/loket/transactions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((response) => {
      response.json().then(async (result) => {
        if (result.status == "200") {
          if (this.state.trx_type == "2100" && result.data.rc == "0000") {
            await localStorage.setItem(
              "private_inquiry",
              JSON.stringify({
                data: result.data,
                product_id: this.state.product_id,
                admin_fee: this.state.admin_fee,
                loket_fee: this.state.loket_fee,
              })
            );
            await this.setState({
              inquiry: true,
              inqres: result.data,
            });
          } else {
            await this.setState({
              message: result.data.desc,
              textButton: "Beli",
              btnDisabled: "",
            });
            await Swal.fire({
              title: "Maaf",
              text: result.data.desc || "Silahkan dicoba beberapa saat lagi",
              icon: "info",
              confirmButtonText: "oke",
            });
          }
        } else if (result.status == "401") {
          await Swal.fire({
            title: "Maaf",
            text: result.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "info",
            confirmButtonText: "Tutup",
          });
          await this.setState({ isAuth: 401 });
        } else {
          await Swal.fire({
            title: "Maaf",
            text: result.message || "Silahkan dicoba beberapa saat lagi",
            icon: "info",
            confirmButtonText: "oke",
          });
          await this.setState({
            message: result.info,
            textButton: "Beli",
            btnDisabled: "",
          });

          // if (this.state.list_idpel) {
          //   let indexOfIdpel = await this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
          //   if (this.state.last_idpel[0].idpel == this.state.idpel) {
          //     await this.props.history.push("/cart");
          //     let pathUrl = await window.location.href;
          //     window.location.href = await pathUrl;
          //   } else {
          //     let newProduk = await this.state.list_idpel[indexOfIdpel[0].key + 1];

          //     let nextProduk = await newProduk.produk;
          //     let nextIdpel = await newProduk.idpel;
          //     let pathProduk = "";
          //     if (nextProduk.toString().toUpperCase().includes("PREPAID")) {
          //       pathProduk = 'plnprepaid/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("POSTPAID")) {
          //       pathProduk = 'plnpostpaid/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("BPJS")) {
          //       pathProduk = 'bpjs/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("SPEEDY") || nextProduk.toString().toUpperCase().includes("TELEPON")) {
          //       pathProduk = 'telkom/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("PDAM")) {
          //       pathProduk = 'pdam/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("PULSA")) {
          //       pathProduk = 'pulsa/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("DATA")) {
          //       pathProduk = 'data/' + nextIdpel
          //     }

          //     await this.props.history.push(this.props.history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
          //     let pathUrl = await window.location.href;
          //     window.location.href = await pathUrl;
          //   }
          // }
        }
      });
      // this.setState({ loading: false });
    }).catch(function (err) {
      this.setState({
        message: "",
        textButton: "Beli",
        btnDisabled: "",
      });
      if (err.response) {
        Swal.fire({
          title: "Maaf",
          text: err.response.data.message || "Maaf, Session Anda sudah habis. Silahkan login kembali",
          icon: "error",
          confirmButtonText: "Oke",
        });
      }
      this.setState({ isAuth: 401 });
    }.bind(this))
    await this.setState({ loading: false })
  }
  render() {
    const enter = {
      fontSize: "14px",
      display: "block",
    };
    return (
      <div>
        <CheckSession isAuth={this.state.isAuth} />
        {this.state.inquiry ? (
          <>
            <Redirect to="/checkoutv1" />
          </>
        ) : (
          <></>
        )}
        <h5 className="text-dark h6">Beli Pulsa</h5>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="form-group">
              <h6 className="text-dark">Nomor HP</h6>
              <div className="input-group">
                <input
                  type="number"
                  value={this.state.idpel}
                  className="form-control"
                  disabled={this.state.idpel_multi ? true : false}
                  style={{ borderRight: "0" }}
                  className="form-control"
                  placeholder="Ketikkan nomor HP"
                  onChange={this.validateNoTelp}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    style={{
                      backgroundColor: "white",
                      border: "white 1px black",
                    }}
                  >
                    {this.renderLogoOp()}
                  </span>
                </div>
              </div>
              <p className="text-danger">{this.state.messageError}</p>
            </div>
          </div>
        </div>
        {this.state.pulsaData.length != 0 ? (
          <>
            <h6 className="text-dark">Pilih Nominal Pulsa</h6>
            <div
              className="alert alert-danger"
              style={{ display: this.state.dangerDisplay }}
            >
              Uppss.. maaf operator tidak dikenali
            </div>

            <div className="row" ref={(el) => { this.el = el; }}>
              {this.state.pulsaData.map((value, index) => {
                return (
                  <div
                    className="col-6 col-md-3 col-sm-6 col-xs-12 mr-0 p-2"
                    key={index}
                    onClick={value.kosong === 1 || value.gangguan === 1 ? '' : (e) => { this.setInfo(value); }}
                  >
                    <div
                      className="border rounded"
                      role="group"
                      style={{ backgroundColor: "#F9F9F9" }}
                    >
                      <button
                        type="button"
                        className={value.kosong === 1 || value.gangguan === 1 ? "btn text-center w-100 disabled" : "btn text-center w-100"}
                      >
                        {" "}
                        <b style={enter}>
                          {this.rupiahFormat(value.nominal_display)}
                        </b>{" "}
                        <label style={{ fontSize: "16px" }}>
                          {" "}
                          Rp {this.rupiahFormat(value.price + 0)}
                        </label>
                        {
                          value.kosong === 1 || value.gangguan === 1 ? (
                            <p className="small text-danger" style={{ lineHeight: "15px" }}>Produk sedang kosong/gangguan</p>
                          ) : (<></>)
                        }
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="card border border-primary bg-primary-300 mt-3"
              style={{
                backgroundColor: "#dbeefd",
                display: this.state.displayMasaBerlaku,
              }}
            >
              <div className="card-body">
                <p className="mb-0">
                  <b className="text-primary">Keterangan</b>
                  <br />
                  <span>{this.state.masa_berlaku}</span>
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            {this.state.pulsaData.length == 0 &&
              this.state.prod_category !=
              null ? (
              <>
                <div
                  className="card border border-primary bg-primary-300 mt-1"
                  style={{ backgroundColor: "#dbeefd" }}
                >
                  <div className="card-body">
                    <p className="small">
                      <b className="text-primary">Informasi</b>
                      <br />
                      Produk sedang kosong
                    </p>
                  </div>
                </div>
              </>
            ) : (<></>)}
          </>
        )}
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 col-xs-12 hidden-onphone ml-auto">
                <LaddaButton
                  loading={this.state.loading}
                  disabled={!(this.state.idpel != null && this.state.idpel.length > 10)}
                  onClick={() => {
                    this.checkout();
                  }}
                  data-color="#008aff"
                  data-size={l}
                  className="m-btn m-btn-theme m-btn-radius w-100"
                  data-style={EXPAND_RIGHT}
                >
                  Beli Pulsa {this.state.nominal_display != 0 ? "Rp" + this.rupiahFormat(this.state.nominal_display) : ""}
                </LaddaButton>
              </div>
            </div>
          </div>
        </div>
        <div className="show-onphone">
          <div className="row">
            <div className="col-md-12">
              <LaddaButton
                loading={this.state.loading}
                disabled={!(this.state.idpel != null && this.state.idpel.length > 10)}
                onClick={() => {
                  this.checkout();
                }}
                data-color="#008aff"
                data-size={l}
                className="m-btn m-btn-theme m-btn-radius w-100 mb-5"
                data-style={EXPAND_RIGHT}
              >
                Beli Pulsa {this.state.nominal_display != 0 ? "Rp" + this.rupiahFormat(this.state.nominal_display) : ""}
              </LaddaButton>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default pulsa;
