import React, { PureComponent } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading';
import LaddaButton from "react-ladda";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Select from "react-select";
import phone from '../PusatBantuan/phone.svg';
import envelope from '../PusatBantuan/envelope.svg';
import whatsapp from '../PusatBantuan/whatsapp.svg';
import telegram from '../PusatBantuan/telegram.svg';
import map from '../PusatBantuan/map-marked.svg';

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);


class TransaksiRevisi extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      dataDenom: [],
      DataProductID: [],
      product_id: "",
      loading: false,
      login: false,
      token: localStorage.getItem("token"),
      store: null,
      setIsOpen: false,
      denom: "",
      description: "",
      active: 1,
      valOptProdID: "",
      toogleBtn: true,
      typeModal: "",
      transaction_id: "",
      trx_id: "",
      status: "",
      response_code: "",
      default_amount: "",
      default_admin: "",
      message: "",
      dataStatus: [],
      startDate: "",
      endDate: "",
      filIdpel: "",
    };
  }
  setActiveDenom = (
    id = null,
    content = null,
    icon = null,
    type = null,
    active = null
  ) => {
    // console.warn("active", active);
    this.setState({ id: id, content: content, icon: icon, type:type });
    if (active == 0) {
      this.setState({ active: 1 });
    } else {
      this.setState({ active: 0 });
    }
    // this.updateDenom();

    // console.warn("state", this.state);
  };
  showModal = (type, data) => {
    if (type == "Edit") {
      this.setState({
        transaction_id: data.transaction_id,
        trx_id: data.trx_id,
        status: data.status,
        response_code: data.response_code,
        default_amount: data.default_amount,
        default_admin: data.default_admin,
        message: data.message,
      });
      // console.warn("data",data)
    } else {
      this.setState({
        id: 0,
        content: "",
        icon: "",
        type: "",
      });
    }
    this.setState({ setIsOpen: true, typeModal: type });
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  reset = async () => {
    this.setState({ startDate: "", endDate: "", filIdpel: "" });
    this.fetchDenom();
  };

  componentDidMount = async () => {
    await this.setState({ loading: true });
    await this.fetchDenom();
    await this.fetchStatus();
  }
  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  fetchDenom = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/transactions/manual/list`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ loading: false, dataDenom: result.data });
      });
    });
  };
  fetchStatus = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/status`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ loading: false, dataStatus: result.data });
        // console.log(this.state.dataStatus);
      });
    });
  };
  saveDenom = (typemodal) => {
    if (this.state.content == "" || this.state.icon == "" || this.state.type == "") {
      Swal.fire({
        title: "Maaf",
        text: "Lengkapi dahulu input yang kosong atau belum dipilih",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    } else {
      if (typemodal == "Tambah") {

        this.createDenom();
      } else {
        this.updateDenom();
      }
    }
  };
  createDenom = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_URL}/kontak/create`,
        {
          content: this.state.content,
          icon: this.state.icon,
          type: this.state.type,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil ditambahkan",
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          Swal.fire({
            title: "Sukses",
            text: "Data tidak dapat ditambahkan",
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false });
        this.fetchDenom();
      });
    // console.warn("data",this.state);
  };

  filterData = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${process.env.REACT_APP_URL}/transactions/manual/list?start_date=`+this.state.startDate+`&end_date=`+this.state.endDate+`&id_pelanggan=`+this.state.filIdpel,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({ loading: false, dataDenom: response.data.data });
      });
  };

  updateDenom = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_URL}/transactions/manual/update`,
        {
          transaction_id: this.state.transaction_id,
          trx_id: this.state.trx_id,
          status: this.state.status,
          response_code: this.state.response_code,
          default_amount: this.state.default_amount,
          default_admin: this.state.default_admin,
          message: this.state.message,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil diubah",
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          Swal.fire({
            title: "Sukses",
            text: "Data gagal diubah",
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false, toogleBtn: true });
        this.fetchDenom();
      });
  };

  confirmDelete = (iddenom) => {
    Swal.fire({
      title: "Hapus Data ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      reverseButtons:true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        this.deleteDenom(iddenom);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  deleteDenom = (iddenom) => {
    const { dataDenom } = this.state;

    axios
      .delete(`${process.env.REACT_APP_URL}/kontak/delete/` + iddenom, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then((json) => {
        if (json.data.status == 200) {
          this.setState({
            dataDenom: dataDenom.filter(
              (dataDenom) => dataDenom.id !== iddenom
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil dihapus",
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          this.setState({
            dataDenom: dataDenom.filter(
              (dataDenom) => dataDenom.id !== iddenom
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: "Data gagal dihapus",
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      });
  };
  render() {
    const dataStatus = this.state.dataStatus;
    const listOption = dataStatus.map((status)=>
    <option value={status.code}>{status.name}</option>
    );
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>ID Transaksi</div>
        ),
        accessor: "trx_id",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
  
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Produk</div>
        ),
        accessor: "product_name",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
  
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>ID Pelanggan</div>
        ),
        accessor: "idpel",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
  
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Type Payment</div>
        ),
        accessor: "payment_type",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
  
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Type Trx</div>
        ),
        accessor: "trx_type",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
  
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Response Code</div>
        ),
        accessor: "response_code",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
  
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Username</div>
        ),
        accessor: "username",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
  
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
        ),
        accessor: "id",
        maxWidth: 150,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => this.showModal("Edit", row.original)}
            >
              Update
            </button>
          </div>
        ),
      },
    ];

    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2 mb-3">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                              Filter data yang akan dicari
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Awal</label>
                          <input type="date" name="startDate" id="startDate" className="form-control" value={this.state.startDate} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Akhir</label>
                          <input type="date" name="endDate" id="endDate" className="form-control" value={this.state.endDate} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">ID Pelanggan</label>
                          <input type="text" name="filIdpel" id="filIdpel" className="form-control" value={this.state.filIdpel} onChange={this.handleChange} />
                        </div>
                      </div>
                    </div>
                  {/* </div>
                  <div className="card-footer"> */}
                    <div className="row mt-2">
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.filterData();
                          }}
                        >Terapkan Filter</LaddaButton>
                      </div>
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-danger m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.reset();
                          }}
                        >Reset Filter</LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow">
                  <div
                    className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-sync text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">TRANSAKSI REVISI</span> <br />
                            Cek dan update revisi status transaksi
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body pb-5">

                    {this.state.isLoading ? (
                      <Loading type="spinningBubbles" color="#91091e" />
                    ) : (
                      <div>
                        <ReactTable
                          data={this.state.dataDenom}
                          defaultPageSize={5}
                          columns={columns}
                          className="mt-3 table-striped w-100"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()}>
          <Modal.Header>
            <Modal.Title>Transaction Revisi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>
                Transaction ID <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="transaction_id"
                id="transaction_id"
                className="form-control"
                required="required"
                value={this.state.transaction_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>
                Trx ID <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="trx_id"
                id="trx_id"
                className="form-control"
                required="required"
                value={this.state.trx_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>
                Status <sup className="text-danger">*</sup>
              </label>
              <select 
              name="status"
              id="status"
              className="form-control"
              value={this.state.status} onChange={this.handleChange}>
                <option value="" selected disabled>-- Pilih Status --</option>
                {listOption}
              </select>
            </div>
            <div className="form-group">
              <label>
                Response Code <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="response_code"
                id="response_code"
                className="form-control"
                required="required"
                value={this.state.response_code}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>
                Default Amount <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="default_amount"
                id="default_amount"
                className="form-control"
                required="required"
                value={this.state.default_amount}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>
                Default Admin <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="default_admin"
                id="default_admin"
                className="form-control"
                required="required"
                value={this.state.default_admin}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>
                Message <sup className="text-danger">*</sup>
              </label>
              <textarea
                rows="5"
                type="text"
                name="message"
                id="message"
                className="form-control"
                required=""
                value={this.state.message}
                onChange={this.handleChange}
              />
            </div>
            <input
              type="hidden"
              name="active"
              value={this.state.active}
              onChange={this.handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Batal
            </button>
            <LaddaButton
              loading={this.state.loading}
              onClick={() => {
                this.saveDenom(this.state.typeModal);
              }}
              className="btn btn-primary"
            >
              Simpan
            </LaddaButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default TransaksiRevisi;