import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { AuthContext, CartContext } from "../../router/Router";
import Avatar from "react-avatar";
import Swal from "sweetalert2";
import axios from "axios";
import { FaSignOutAlt } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda'
const createHistory = require("history").createBrowserHistory;

function Navbar({ children }) {
  let history = createHistory();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const [dataCart, setDataCart] = useState([]);
  const token = localStorage.getItem("token");
  const { value, setValue } = useContext(CartContext)
  const [isAuth, setAuth] = useState(0);
  const logo_name = localStorage.getItem("logo");
  const [isOpen, setIsOpen] = useState(false)
  const [idpelFav, setIdpelFav] = useState([])
  const [favToMulti, setFavToMulti] = useState([]);

  const fetchCart = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/cart/list`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDataCart([...res.data.data]);
        // console.warn("res cart", res.data.data);
        setValue(dataCart.length);
      }).catch((err) => {
        setAuth(err.response.data.status)
      })
  };
  const fetchIdpelFav = async () => {
    setLoading(true)
    await fetch(`${process.env.REACT_APP_URL}/favorite/list`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then(async (result) => {
        await setIdpelFav(result.data)
        // await console.warn("set favorite", result.data);
        await setLoading(false);
      });
    });
  };
  const removeFavorite = async (id, idpel) => {
    await axios.delete(
      `${process.env.REACT_APP_URL}/favorite/delete/` + id,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    ).then(async (json) => {
      if (json.data.status == 200) {
        await Swal.fire({
          position: 'top-end',
          // icon: 'success',
          html: '<i class="fa fa-check-circle text-success"></i> Idpel ' + idpel + ' berhasil dihapus dari favorite',
          showConfirmButton: false,
          timer: 1300
        })
        await fetchIdpelFav();
      } else {
        Swal.fire({
          title: "Sukses",
          text: json.data.message,
          icon: "error",
          confirmButtonText: "Tutup",
        });
      }
    });

  }
  const showModal = async (type) => {
    await fetchIdpelFav();
    if (type == "show") {
      await localStorage.removeItem("list_impor_idpel");
      await setIsOpen(true);
    } else {
      await localStorage.removeItem("list_impor_idpel");
      await setIsOpen(false);
    }
  }
  useEffect(() => {

    fetchCart();
    fetchIdpelFav();
  }, [dataCart.length]);

  const setListIdpel = async (e) => {
    let splitName = e.target.name.split('-');
    let idpel = splitName[0];
    let produk = splitName[1];
    let checked = e.target.checked;
    let list_idpel = localStorage.getItem("list_impor_idpel") ? JSON.parse(localStorage.getItem("list_impor_idpel")) : [];
    let count = list_idpel.length;
    if (checked) {
      let newList = [];
      newList = {
        idpel: idpel.trim(),
        key: count,
        produk: produk.trim()
      }
      list_idpel.push(newList)
      localStorage.setItem("list_impor_idpel", JSON.stringify(list_idpel));

    } else {
      let cekIdpel = await list_idpel.filter(p => p.idpel == idpel.trim() && p.produk == produk.trim());
      // await console.log("hasil cek" + JSON.stringify(cekIdpel))
      if (cekIdpel) {
        await list_idpel.splice(cekIdpel[0].key, 1)
        // await console.log("after splice " + JSON.stringify(list_idpel))
        if (list_idpel.length == 0) {
          list_idpel = []
        } else {
          for (let i = 0; i < list_idpel.length; i++) {
            if (list_idpel[i].idpel != cekIdpel[0].idpel && list_idpel[i].produk != cekIdpel[0].produk) {
              list_idpel[i] = await {
                idpel: list_idpel[i].idpel,
                key: i,
                produk: list_idpel[i].produk
              }
            } else {
              continue;
            }
          }
        }
        // await console.log("after for " + JSON.stringify(list_idpel))
        await localStorage.setItem("list_impor_idpel", JSON.stringify(list_idpel));
      }
    }
    setFavToMulti(list_idpel);
  }
  const nextProcess = async () => {
    await setLoading(true)
    await fetchCart();
    if(dataCart.length > 0){
      await deleteItemCart();
    }
    let produkke1 = favToMulti[0].produk;
    let idpelke1 = favToMulti[0].idpel;
    let pathProduk = "";
    if (produkke1.toString().toUpperCase().includes("PREPAID")) {
      pathProduk = 'plnprepaid/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("POSTPAID")) {
      pathProduk = 'plnpostpaid/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("BPJS")) {
      pathProduk = 'bpjs/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("TELEPON")) {
      pathProduk = 'telepon/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("SPEEDY")) {
      pathProduk = 'speedy/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("PDAM")) {
      pathProduk = 'pdam/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("PULSA")) {
      pathProduk = 'pulsa/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("DATA")) {
      pathProduk = 'data/' + idpelke1
    }

    history.push(history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
    let pathUrl = window.location.href;
    window.location.href = pathUrl;
    await setLoading(false)
  }
  const deleteItemCart = async () => {
    let url = `${process.env.REACT_APP_URL}/cart/all/delete`;
    await axios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
      });
  };
  if (isAuth === 401) {
    dispatch({
      type: "LOGOUT",
    });
    // return <Redirect to="/login" />;
  }

  return (
    <div>
      <header className="header-nav header-dark header-bg hidden-onphone" style={{ minHeight: "78px" }}>
        <div className="fixed-header-bar">
          <div className="navbar navbar-main navbar-expand-lg shadow">
            <div className="container">
              <NavLink className="navbar-brand" to="/pln">
                {/* {tagline} */}
                <img
                  className="logo-dark"
                  alt=""
                  width="80px"
                  height="65px"
                  title=""
                  src={logo_name == "null" ? "/img/loketpelangi.png" : `${process.env.REACT_APP_URL}/images/${logo_name}`}
                />
                <img
                  className="logo-light"
                  alt=""
                  width="80px"
                  height="65px"
                  title=""
                  src={logo_name == "null" ? "/img/loketpelangi.png" : `${process.env.REACT_APP_URL}/images/${logo_name}`}
                />
              </NavLink>
              {/* <span className="h5 font-weight-bold">
                  {tagline}
                </span> */}
              <div className="show-onphone ml-auto">
                <NavLink to="/cart" className="btn btn-default ml-1"><i className="font-large-1 icon-line-height fa fa-shopping-cart text-secondary"></i>
                  <span className="badge badge-pill badge-glow badge-danger badge-up">{value}</span></NavLink>
                <a
                  href="#"
                  className="btn btn-outline-primary"
                  onClick={() =>
                    Swal.fire({
                      title: "Yakin keluar  ?",
                      text: "",
                      icon: "info",
                      showCancelButton: true,
                      reverseButtons: true,
                      confirmButtonText: "Keluar",
                      cancelButtonText: "Batal",
                    }).then((result) => {
                      if (result.value) {
                        dispatch({
                          type: "LOGOUT",
                        });
                      } else if (
                        result.dismiss === Swal.DismissReason.cancel
                      ) {
                        //cencle
                      }
                    })
                  }
                >
                  <FaSignOutAlt size="1em" className="text-primary" />
                  {" "}<small>Keluar</small>
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-main-collapse"
                aria-controls="navbar-main-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse navbar-collapse-overlay" id="navbar-main-collapse">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <NavLink to="/cart" className="nav-link btn btn-default ml-1"><i className="font-large-1 icon-line-height fa fa-shopping-cart text-secondary"></i>
                      <span className="badge badge-pill badge-glow badge-danger badge-up">{value}</span></NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/users/transaksi/importidpel" className="nav-link btn btn-default ml-1"> <i className="font-large-1 icon-line-height fa fa-upload text-secondary"></i></NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/users/idpel/favorite" className="nav-link btn btn-default ml-1"> <i className="font-large-1 icon-line-height fa fa-star text-secondary"></i></NavLink>
                  </li>
                  <li className="nav-item px-dropdown">
                    <a className="nav-link" href="#"><span className="mr-2 text-muted">{state.user}</span>
                      <Avatar
                        name={state.user}
                        size={32}
                        fgColor="#b9cbfb"
                        color="#ebf4fe"
                        round="50px"
                        textSizeRatio="2"
                      />
                    </a>
                    <ul className="px-dropdown-menu mm-dorp-in">
                      <li>
                        <NavLink to="/users/dashboard" href="#" className="text-dark">
                          <i className="fa fa-user-secret text-primary"></i> Panel Pengguna
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/users/changepassword" href="#" className="text-dark">
                          <i className="fa fa-key text-primary"></i> Ganti Password
                        </NavLink>
                      </li>
                      {
                        state.role != "1" ? (
                          <>
                            <li>
                              <NavLink to="/users/changelogo" href="#" className="text-dark">
                                <i className="fa fa-image text-primary"></i> Ganti Logo
                              </NavLink>
                            </li>
                          </>
                        ) : (<></>)}
                      <li>
                        <a
                          href="#"
                          className="text-danger"
                          onClick={() =>
                            Swal.fire({
                              title: "Yakin keluar  ?",
                              text: "",
                              icon: "info",
                              showCancelButton: true,
                              reverseButtons: true,
                              confirmButtonText: "Keluar",
                              cancelButtonText: "Batal",
                            }).then((result) => {
                              if (result.value) {
                                dispatch({
                                  type: "LOGOUT",
                                });
                              } else if (
                                result.dismiss === Swal.DismissReason.cancel
                              ) {
                                //cencle
                              }
                            })
                          }
                        >
                          <i className="fa fa-power-off text-danger"></i> Keluar
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="box-shadow shadow-lg">
        <nav className="navbar navbar-dark navbar-expand d-md-none d-lg-none d-xl-none fixed-bottom show-onphone bg-light border-top">
          <ul className="navbar-nav nav-justified w-100">
            <li className="nav-item">
              <NavLink to="/pln" className="nav-link">
                <i className="fa fa-home text-secondary p-0px-t"><br /><span className="label-nav">Beranda</span></i>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/users/riwayat/pembayaran" className="nav-link"><i className="fa fa-search text-secondary p-0px-t"><br /><span className="label-nav">Cari</span></i></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/cart" className="nav-link border border-radius-15 shadow-lg" style={{ backgroundColor: "#2196F3" }}><i className="font-large-1 icon-line-height fa fa-shopping-cart text-white"></i>
                <span className="badge badge-pill badge-glow badge-danger badge-up">{value}</span> <br /></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/users/dashboard" className="nav-link"><i className="fa fa-money-bill-wave text-secondary p-0px-t"><br /><span className="label-nav">Transaksi</span></i></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/users/panel" className="nav-link"><i className="fa fa-user text-secondary p-0px-t"><br /><span className="label-nav">Panel</span></i></NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <Modal show={isOpen} onHide={() => showModal("hide")} scrollable={true} size="lg">
        <Modal.Header>
          <div className="row col-md-12">
            <div className="col-md-6">
              <span className="h5"><i className="fa fa-star text-warning"></i> ID Pelanggan Favorit</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-borderless">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>PRODUK</th>
                <th>IDPEL</th>
                <th className="text-center">OPSI</th>
              </tr>
            </thead>
            <tbody>
              {idpelFav.length > 0 ? (
                <>
                  {idpelFav.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">
                          <i className="fa fa-star text-warning ml-2" title="Hapus favorite" onClick={() => removeFavorite(value.id, value.idpel)}></i>
                        </td>
                        <td>{value.name.toUpperCase()}</td>
                        <td>{value.idpel}</td>
                        <td className="text-center">
                          <input type="checkbox" name={value.idpel + "-" + value.name.toUpperCase()} className="form-check-input" onChange={(e) => setListIdpel(e)} />
                        </td>
                      </tr>
                    )
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">Belum ada daftar favorit yang dibuat</td>
                </tr>
              )}
            </tbody>
          </table>
          <p>
            <small>* Klik tanda bintang untuk menghapus dari daftar id pelanggan favorit</small><br />
            <small>* Ceklis produk yang dipilih untuk melanjutkan transaksi</small>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <LaddaButton
            onClick={() => showModal("hide")}
            className="btn btn-outline-secondary"
          >
            Keluar
          </LaddaButton>{" "}
          <LaddaButton
            loading={loading}
            disabled={!(favToMulti.length > 0)}
            onClick={() => nextProcess()}
            data-color="#008aff"
            data-size={l}
            className="btn btn-primary"
            data-style={EXPAND_RIGHT}
          >
            Lanjut Transaksi
          </LaddaButton>
        </Modal.Footer>
      </Modal>
      <div className="mains">{children}</div>
    </div>
  );
}

export default Navbar;
