import React, { PureComponent } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading';
import LaddaButton from "react-ladda";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Select from "react-select";
import * as XLSX from 'xlsx';
import { Form } from 'antd-v3';

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);


class Index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        id: 0,
        dataDenom: [],
        DataProductID: [],
        product_id: "",
        loading: false,
        login: false,
        token: localStorage.getItem("token"),
        store: null,
        setIsOpen: false,
        denom: "",
        description: "",
        active: 1,
        valOptProdID: "",
        toogleBtn: true,
        typeModal: "",
        pertanyaan: "",
        jawaban: "",
        prod_id: "",
        code: "",
        vcode: "",
        name: "",
        description: "",
        product_category_id: "",
        nominal: "",
        status: "",
        disabled: false,
        f_prod_id: "",
        f_name: "",
        f_status: "",
        dataCatProd: [],
        setIsOpenMulti: false,
        selectedFile: null,
        dataMulti: null,
        prodCatMulti: "",
        jsonDataArray: [],
        tableColumns: [],
        fixData: [],
        showButton: false,
    };
  }
  setActiveDenom = (
    id = null,
    pertanyaan = null,
    jawaban = null,
    active = null
  ) => {
    // console.warn("active", active);
    this.setState({ id: id, pertanyaan: pertanyaan, jawaban: jawaban });
    if (active == 0) {
      this.setState({ active: 1 });
    } else {
      this.setState({ active: 0 });
    }
    // this.updateDenom();

    // console.warn("state", this.state);
  };
  showModal = (type, data) => {
    if (type == "Edit") {
        this.setState({
            prod_id: data.prod_id,
            code: data.code,
            vcode: data.vcode,
            name: data.name,
            description: data.description,
            product_category_id: data.product_category_id,
            nominal: data.nominal,
            disabled: false,
        });
        if(data.active == 1){
            this.setState({ status: "true"})
        }else{
            this.setState({ status: "false"})
        }
      // console.warn("data",data)
    } else if(type == "View"){
        this.setState({
            prod_id: data.prod_id,
            code: data.code,
            vcode: data.vcode,
            name: data.name,
            description: data.description,
            product_category_id: data.product_category_id,
            nominal: data.nominal,
            disabled: true,
        });
        if(data.active == 1){
            this.setState({ status: "true"})
        }else{
            this.setState({ status: "false"})
        }
    } else {
      this.setState({
        prod_id: "",
        code: "",
        vcode: "",
        name: "",
        description: "",
        product_category_id: "",
        nominal: "",
        status: true,
        disabled: false,
      });
    }
    this.setState({ setIsOpen: true, typeModal: type });
    
    console.log(data)
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  // start proses upload multiple data
  showModalMulti = () => {
    this.setState({
      setIsOpenMulti: false,
      selectedFile: null,
      dataMulti: null,
      prodCatMulti: "",
      jsonDataArray: [],
      tableColumns: [],
      fixData: [],
      disabled: false,
      showButton: false,
    });
    this.setState({ setIsOpenMulti: true });
  };

  hideModalMulti = () => {
    this.setState({ setIsOpenMulti: false });
  };

  handleShowButton = () => {
    this.setState({ showButton: true });
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({ selectedFile: file });
  };

  handleFileUpload = (event) => {
    event.preventDefault();
    const { selectedFile } = this.state;
    if (this.state.prodCatMulti==""){
      Swal.fire({
        title: "Error",
        text: "Pilih Product Category Dahulu",
        icon: "error",
        confirmButtonText: "Tutup",
      });
      return;
    }
    if (!selectedFile) {
      Swal.fire({
        title: "Error",
        text: "Silakan pilih file Excel terlebih dahulu.",
        icon: "error",
        confirmButtonText: "Tutup",
      });
      return;
    }
    

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData.length === 0) {
        console.log('No data found in the Excel file');
        return;
      }

      const headers = jsonData[0];
      const jsonDataArray = [];

      for (let i = 1; i < jsonData.length; i++) {
        const row = jsonData[i];
        const obj = {};

        for (let j = 0; j < headers.length; j++) {
          const header = headers[j];
          obj[header] = row[j];
          obj['product_category_id'] = this.state.prodCatMulti;
        }
        
        jsonDataArray.push(obj);
      }

      let fixData = {
        "data": jsonDataArray
      };

      // console.log(fixData);
      this.setState({ jsonDataArray, fixData });
      this.handleShowButton();
      
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Apa yang akan Anda upload data.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
        .post(
          `${process.env.REACT_APP_URL}/products/multiimproduct`,
          {
            data: this.state.jsonDataArray
          },
          {
            headers: {
              Authorization: "Bearer " + this.state.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((json) => {
          if (json.data.status == 200) {
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil ditambahkan",
              icon: "success",
              confirmButtonText: "Tutup",
            });
          } else {
            Swal.fire({
              title: "Error",
              icon: "error",
              confirmButtonText: "Tutup",
            });
          }
          this.setState({ loading: false, setIsOpenMulti: false });
          this.fetchDenom();
        })
        .catch((err)=>{
          if(err.code === 500){
            Swal.fire({
              title: "GAGAL",
              html: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tutup",
            });
          }else{
            Swal.fire({
              title: "GAGAL",
              html: err.response.data.message.note+"<br>-"+err.response.data.message.data,
              icon: "error",
              confirmButtonText: "Tutup",
            });
          }
          
          this.setState({ loading: false });
        })
      } else {

      }
    });
    
  };

  fileInputRef = React.createRef();

  resetMulti =  (event) => {
    event.preventDefault();
    this.setState({
      selectedFile: null,
      dataMulti: null,
      prodCatMulti: "",
      jsonDataArray: [],
      tableColumns: [],
      fixData: [],
      showButton: false,
    });
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = null;
    }
  };

  handleGenerateExcel = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Apa yang akan Anda generate template.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      if (result.isConfirmed) {
        const data = [
          ['product_id','product_code','product_name','nominal'],
        ];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    
        XLSX.writeFile(workbook, 'List_Produk.xlsx');
      } else {

      }
    });
    
  };
  // end proses multiple data

  componentDidMount = async () => {
    await this.setState({ loading: true });
    await this.fetchDenom();
    await this.fetchCatProd();
  }
  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  fetchDenom = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/products`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ loading: false, dataDenom: result.data });
      });
    });
  };
  fetchCatProd = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/pCategories`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ loading: false, dataCatProd: result.data });
      });
    });
  };
  filterData = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${process.env.REACT_APP_URL}/products?prod_id=`+this.state.f_prod_id+`&name=`+this.state.f_name+`&status=`+this.state.f_status,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        },
        
      )
      .then((response) => {
        this.setState({ loading: false, dataDenom: response.data.data });
      });
  };
  reset = async () => {
    this.setState({ f_prod_id: "", f_name: "", f_status: "" });
    this.fetchDenom();
  };
  saveDenom = (typemodal) => {
    if (this.state.prod_id == "" || this.state.code == ""|| this.state.vcode == ""|| this.state.name == ""|| this.state.description == ""|| this.state.product_category_id == ""|| this.state.nominal == ""|| this.state.status == "") {
      Swal.fire({
        title: "Maaf",
        text: "Lengkapi dahulu input yang kosong atau belum dipilih",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    } else {
      if (typemodal == "Tambah") {

        this.createDenom();
      } else {
        this.updateDenom();
      }
    }
  };
  createDenom = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_URL}/products/save`,
        {
            prod_id: this.state.prod_id,
            code: this.state.code,
            vcode: this.state.vcode,
            name: this.state.name,
            description: this.state.description,
            product_category_id: this.state.product_category_id,
            nominal: this.state.nominal,
            status: this.state.status,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil ditambahkan",
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          Swal.fire({
            title: "Sukses",
            text: json.data.message.msg,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false });
        this.fetchDenom();
      })
      .catch((err)=>{
        Swal.fire({
            title: "GAGAL",
            text: JSON.stringify(err.response.data.message[0].msg),
            icon: "error",
            confirmButtonText: "Tutup",
        });
        this.setState({ loading: false });
      })
    // console.warn("data",this.state);
  };

  updateDenom = () => {
    // this.setState({ loading: true });
    Swal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Apa yang akan Anda update data.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
        .put(
          `${process.env.REACT_APP_URL}/products/update/` + this.state.prod_id,
          {
              prod_id: this.state.prod_id,
              code: this.state.code,
              vcode: this.state.vcode,
              name: this.state.name,
              description: this.state.description,
              product_category_id: this.state.product_category_id,
              nominal: this.state.nominal,
              status: this.state.status,
          },
          {
            headers: {
              Authorization: "Bearer " + this.state.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((json) => {
          if (json.data.status == 200) {
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil diubah",
              icon: "success",
              confirmButtonText: "Tutup",
            });
          } else {
            Swal.fire({
              title: "Sukses",
              text: "Data gagal diubah",
              icon: "error",
              confirmButtonText: "Tutup",
            });
          }
          // console.warn("response", json);
          this.setState({ loading: false, setIsOpen: false, toogleBtn: true });
          this.fetchDenom();
        });
      } else {

      }
    });
  };

  confirmDelete = (iddenom) => {
    Swal.fire({
      title: "Hapus Data ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      reverseButtons:true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        this.deleteDenom(iddenom);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  deleteDenom = (iddenom) => {
    const { dataDenom } = this.state;

    axios
      .delete(`${process.env.REACT_APP_URL}/products/delete/` + iddenom, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then((json) => {
        if (json.data.status == 200) {
          this.setState({
            dataDenom: dataDenom.filter(
              (dataDenom) => dataDenom.id !== iddenom
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil dihapus",
            icon: "success",
            confirmButtonText: "Tutup",
          });
          this.fetchDenom();
        } else {
          this.setState({
            dataDenom: dataDenom.filter(
              (dataDenom) => dataDenom.id !== iddenom
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: "Data gagal dihapus",
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      })
      .catch((err)=>{
        Swal.fire({
            title: "GAGAL",
            text: JSON.stringify(err.response.data.message),
            icon: "error",
            confirmButtonText: "Tutup",
        });
        // this.setState({ loading: false });
      })
  };
  handleChangeStatus = (data) => {
    this.setState({ product_category_id: data.value });
    // console.log(this.state.status_f)
  };
  handleChangeStatusMulti = (data) => {
    this.setState({ prodCatMulti: data.value });
  }
  render() {
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRODUK ID</div>
        ),
        accessor: "prod_id",
        maxWidth: 85,
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRODUK CODE</div>
        ),
        accessor: "code",
        maxWidth: 120,
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>NAME</div>
        ),
        accessor: "name",
        maxWidth: 120,
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>DESCRIPTION</div>
        ),
        accessor: "description",
        maxWidth: "50%",
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },

      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>STATUS</div>
        ),
        accessor: "active",
        maxWidth: 150,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
            <div>
                {(row.original.active == 1) ? (
                <span
                className="badge badge-success"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}
              >
                Aktif
              </span>
              ):(
              <span
                className="badge badge-danger"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}
              >
                Nonaktif
              </span>
              )}
            </div>
        )
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>OPSI</div>
        ),
        accessor: "id",
        maxWidth: 175,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="btn btn-flat btn-sm btn-info"
              onClick={() => this.showModal("View", row.original)}
            >
              View
            </button>
            &nbsp;
            <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => this.showModal("Edit", row.original)}
            >
              Edit
            </button>
            &nbsp;
            <button
              onClick={() => this.confirmDelete(row.original.prod_id)}
              className="btn btn-flat btn-sm btn-danger"
            >
              Hapus
            </button>
          </div>
        ),
      },
    ]; 
    const tableColumns = [
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRODUK ID</div>
        ),
        accessor: "product_id",
        maxWidth: "20%",
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRODUK CODE</div>
        ),
        accessor: "product_code",
        maxWidth: "20%",
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRODUK NAME</div>
        ),
        accessor: "product_name",
        maxWidth: "20%",
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRODUK CATEGORY CODE</div>
        ),
        accessor: "product_category_id",
        maxWidth: "20%",
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>NOMINAL</div>
        ),
        accessor: "nominal",
        maxWidth: "20%",
        style: {
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
          },
      },
    ];
    const { showButton } = this.state;
    const optCatProd = this.state.dataCatProd.map((d) => ({
        value: d.product_category_id,
        label: d.product_category_id + " - " + d.name.toUpperCase(),
    }));
    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              <div className="card shadow mt-2 mb-3">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                              Filter data yang akan dicari
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Produk ID</label>
                          <input type="text" name="f_prod_id" id="f_prod_id" className="form-control" value={this.state.f_prod_id} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Produk Name</label>
                          <input type="text" name="f_name" id="f_name" className="form-control" value={this.state.f_name} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Status</label>
                          {/* <input type="text" name="f_status" id="f_status" className="form-control" value={this.state.f_status} onChange={this.handleChange} /> */}
                            <select 
                            name="f_status"
                            id="f_status"
                            className="form-control"
                            value={this.state.f_status} onChange={this.handleChange}>
                                <option value="">-- Pilih Status --</option>
                                <option value="true">AKTIF</option>
                                <option value="false">NON AKTIF</option>
                            </select>
                        </div>
                      </div>
                    </div>
                  {/* </div>
                  <div className="card-footer"> */}
                    <div className="row mt-2">
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.filterData();
                          }}
                        >Terapkan Filter</LaddaButton>
                      </div>
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-danger m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.reset();
                          }}
                        >Reset Filter</LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mt-2 mb-5">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-file text-primary h3 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold">SETTING PRODUK</span> <br />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    <h4>
                      <div className="row">
                        <div class="col-md-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.showModal("Tambah")}
                          >
                            <i className="fa fa-plus"></i> Produk
                          </button>
                        </div>
                        <div class="col-md-3">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.showModalMulti("Tambah")}
                          >
                            <i className="fa fa-plus"></i> Multiple Import Data
                          </button>
                        </div>
                      </div>
                    </h4>
                    {this.state.loading ? (
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="text-center"
                      />
                    ) : (
                      <ReactTable
                        data={this.state.dataDenom}
                        // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                        defaultPageSize={this.state.dataDenom.length > 10 ? 10 : 10}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()}>
          <Modal.Header>
            <Modal.Title>{this.state.typeModal} Produk</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="form-group">
              <label>
                PRODUK ID <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="prod_id"
                id="prod_id"
                className="form-control"
                required="required"
                value={this.state.prod_id}
                onChange={this.handleChange}
                disabled = {(this.state.disabled)? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>
                PRODUK CODE <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="code"
                id="code"
                className="form-control"
                required="required"
                value={this.state.code}
                onChange={this.handleChange}
                disabled = {(this.state.disabled)? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>
                PRODUK VCODE <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="vcode"
                id="vcode"
                className="form-control"
                required="required"
                value={this.state.vcode}
                onChange={this.handleChange}
                disabled = {(this.state.disabled)? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>
                PRODUK NAME <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                required="required"
                value={this.state.name}
                onChange={this.handleChange}
                disabled = {(this.state.disabled)? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>
                DESCRIPTION <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="description"
                id="description"
                className="form-control"
                required="required"
                value={this.state.description}
                onChange={this.handleChange}
                disabled = {(this.state.disabled)? "disabled" : ""}
              />
            </div>
            <div className="form-group">
                <label>
                    PRODUCT CATEGORY ID <sup className="text-danger">*</sup>
                </label>
                <Select
                    name="product_category_id"
                    options={optCatProd}
                    value={optCatProd.filter(
                        (obj) => obj.value === this.state.product_category_id
                    )}
                    onChange={(value) => this.handleChangeStatus(value)}
                    placeholder={<div>Pilih Kategori Produk</div>}
                    isDisabled = {(this.state.disabled)? "true" : ""}
                />
            </div>
            <div className="form-group">
              <label>
                NOMINAL <sup className="text-danger">*</sup>
              </label>
              <input
                type="number"
                name="nominal"
                id="nominal"
                className="form-control"
                required="required"
                value={this.state.nominal}
                onChange={this.handleChange}
                disabled = {(this.state.disabled)? "disabled" : ""}
              />
            </div>
            {(this.state.typeModal=="Edit"||this.state.typeModal=="View") ? (
              <>
                <div className="form-group">
                  <label>
                      STATUS <sup className="text-danger">*</sup>
                  </label>
                  <select 
                  name="status"
                  id="status"
                  className="form-control"
                  disabled = {(this.state.disabled)? "disabled" : ""}
                  value={this.state.status} onChange={this.handleChange}>
                      <option value="">-- Pilih Status --</option>
                      <option value="true">AKTIF</option>
                      <option value="false">NON AKTIF</option>
                  </select>
                </div>
              </>
            ):(<></>)}
            {/* <div className="form-group">
                <label>
                    STATUS <sup className="text-danger">*</sup>
                </label>
                <select 
                name="status"
                id="status"
                className="form-control"
                disabled = {(this.state.disabled)? "disabled" : ""}
                value={this.state.status} onChange={this.handleChange}>
                    <option value="">-- Pilih Status --</option>
                    <option value="true">AKTIF</option>
                    <option value="false">NON AKTIF</option>
                </select>
            </div> */}
            
            {/* <input
              type="hidden"
              name="active"
              value={this.state.active}
              onChange={this.handleChange}
            /> */}
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Batal
            </button>
            {(this.state.disabled)? (
                <>
                
                </>
            ):(<>
                <LaddaButton
                    loading={this.state.loading}
                    onClick={() => {
                        this.saveDenom(this.state.typeModal);
                    }}
                    className="btn btn-primary"
                    >
                    Simpan
                </LaddaButton>
            </>)}
            
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.setIsOpenMulti} onHide={() => this.hideModalMulti()} size="lg">
          <Form>
          <Modal.Header>
            <Modal.Title>Multiple Imports Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                          PRODUCT CATEGORY ID <sup className="text-danger">*</sup>
                      </label>
                      <Select
                          name="product_category_id"
                          options={optCatProd}
                          value={optCatProd.filter(
                              (obj) => obj.value === this.state.prodCatMulti
                          )}
                          onChange={(value) => this.handleChangeStatusMulti(value)}
                          placeholder={<div>Pilih Kategori Produk</div>}
                          isDisabled = {(this.state.disabled)? "true" : ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <input className="form-control-file" type="file" accept=".xlsx, .xls" onChange={this.handleFileChange} ref={this.fileInputRef} />
                  </div>
                  <div className="col-md-3">
                    <button
                    className="btn btn-info"
                    onClick={this.handleGenerateExcel}
                    >
                    Generate Template  
                    </button>
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-warning"
                      onClick={this.handleFileUpload}
                    >
                    Tampilkan  
                    </button>
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-danger"
                      onClick={this.resetMulti}
                    >
                    Reset  
                    </button>
                  </div>
                </div>
              </div>
              {showButton ? (
                <ReactTable data={this.state.jsonDataArray} columns={tableColumns} 
                defaultPageSize={this.state.dataDenom.length > 5 ? 5 : 5}
                />
              ) : (
                <div></div>
              )}
          </Modal.Body>
          <Modal.Footer>
            {showButton ? (
              <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.handleSubmit}
              >
              Upload  
              </button>
            ) : (
              <div></div>
            )}
            <button
                onClick={() => this.hideModalMulti()}
                className="btn btn-outline-secondary"
              >
                Batal
            </button>
          </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Index;