import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda'
import { EditableFormRow, EditableCell } from "./editable";
import { Table, Button, Popconfirm, Row, Col, Icon, Upload } from "antd-v3";
import { ExcelRenderer } from "react-excel-renderer";
const createHistory = require("history").createBrowserHistory;
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>
  }
}
class ImportIdpel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: [],
      rows: [],
      count: 0,
      loading: true,
      name: "",
      idpel: null,
      masa_berlaku: "",
      displayMasaBerlaku: "none",
      messageError: "",
      btnDisabled: "disabled",
      dangerDisplay: "none",
      textButton: "Beli",
      message: "",
      isAuth: 0,
      dataCart: [],
      nominal: parseFloat(localStorage.getItem("reqTopup")),
      file: null,
      token: localStorage.getItem("token"),
      inquiry: false,
      clone: false,
      noRek: "",
      errorMessage: null,
      uploaded: false,
      setIsOpen: false,
      toogleBtn: true,
      idpelFav: [
        {
          "produk": "BPJS KESEHATAN",
          "idpel": "0000002426690250",
        },
        {
          "produk": "TELKOM SPEEDY",
          "idpel": "0123420204800",
        },
        {
          "produk": "PLN PREPAID",
          "idpel": "100456789071",
        }
      ],
      columns: [
        {
          title: "PRODUK",
          dataIndex: "produk",
          editable: false
        },
        {
          title: "IDPEL",
          dataIndex: "idpel",
          editable: false
        },
        {
          title: "OPSI",
          dataIndex: "opsi",
          render: (text, record) =>
            this.state.rows.length >= 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record.key)}
              >
                <Icon
                  type="delete"
                  theme="filled"
                  style={{ color: "red", fontSize: "20px" }}
                />
              </Popconfirm>
            ) : null
        }
      ]
    };
  }
  componentDidMount() {
    this.setState({
      loading: false
    })
  }
  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  handleChange = async (file) => {
    await this.setState({ file: file })
    // console.warn("file",this.state.file)
  };

  sendRequest = async () => {
    const formData = new FormData()
    await formData.append('nominal', this.state.nominal)
    await formData.append('images_tf', this.state.file)
    // console.warn("file", this.state.file[0])
    axios
      .post(
        `${process.env.REACT_APP_URL}/saldo`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        this.setState({ uploaded: true });
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
          localStorage.removeItem("reqTopup")
        } else {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        // this.fetchUsers();
      }).catch((err) => {
        // console.warn("error", err.response.data.message);
        if (err.response.data.status == 402) {
          Swal.fire({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        } else if (err.response.data.status == 401) {
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      });
  }
  upload = async () => {
    await Swal.fire({
      title: "",
      html: "Upload Bukti Transfer ?",
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Upload",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.sendRequest()
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
    })
  }
  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  cloneNoRek(noRek) {
    this.setState({ clone: true, noRek: noRek })
    navigator.clipboard.writeText(noRek)
  }
  handleSave = row => {
    const newData = [...this.state.rows];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ rows: newData });
  };

  checkFile(file) {
    let errorMessage = "";
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === "application/vnd.ms-excel" ||
      file[0].type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      errorMessage = "You can only upload Excel file!";
    }
    console.log("file", file[0].type);
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorMessage = "File must be smaller than 2MB!";
    }
    console.log("errorMessage", errorMessage);
    return errorMessage;
  }
  showModal = () => {
    this.setState({ setIsOpen: true });
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };
  fileHandler = fileList => {
    console.log("fileList", fileList);
    let fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: "No file uploaded!"
      });
      return false;
    }
    // console.log("fileObj.type:", fileObj.type);
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      this.setState({
        errorMessage: "Unknown file format. Only Excel files are uploaded!"
      });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let newRows = [];
        let no = 0;
        resp.rows.slice(1).map((row, index) => {
          if (row && row !== "undefined") {
            if (row[0]) {
              if (row[0].toString().toUpperCase().includes("PREPAID") || row[0].toString().toUpperCase().includes("POSTPAID") || row[0].toString().toUpperCase().includes("BPJSK") || row[0].toString().toUpperCase().includes("SPEEDY") || row[0].toString().toUpperCase().includes("TELEPON") || row[0].toString().toUpperCase().includes("PDAM") || row[0].toString().toUpperCase().includes("PULSA") || row[0].toString().toUpperCase().includes("DATA")) {
                newRows.push({
                  key: no,
                  produk: row[0],
                  idpel: row[1],
                });
                no++
              }
            }
          }
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: "No data found in file!"
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {
    console.log("submitting: ", this.state.rows);
    //submit to API
    //if successful, banigate and clear the data
    //this.setState({ rows: [] })
  };

  handleDelete = key => {
    const rows = [...this.state.rows];
    this.setState({ rows: rows.filter(item => item.key !== key) });
  };
  handleAdd = (newDt) => {
    let detail = newDt.split("-");
    let newProduk = detail[0]
    let newIdpel = detail[1]
    let arrSearch = this.state.rows.filter(
      (rows) => rows.idpel == newIdpel && rows.produk == newProduk)

    console.log(arrSearch)

    if (newProduk.toString().toUpperCase() != "PULSA" && newProduk.toString().toUpperCase() != "DATA" && arrSearch.length > 0) {
      Swal.fire({
        position: 'top-end',
        // icon: 'success',
        html: '<i class="fa fa-info-circle text-info"></i> Idpel ' + newProduk + "(" + newIdpel + ') sudah ada didaftar',
        showConfirmButton: false,
        timer: 1200
      })
    } else {
      const { count, rows } = this.state;
      const newData = {
        key: this.state.rows.length + 1,
        produk: newProduk,
        idpel: newIdpel,
      };
      this.setState({
        rows: [newData, ...rows],
        count: this.state.rows.length + 1
      });
    }
  };
  process = async () => {
    let history = createHistory();
    await this.setState({ loading: true });
    await this.fetchCart();
    if (this.state.dataCart.length > 0) {
      await this.deleteItemCart();
    }
    await localStorage.setItem(
      "list_impor_idpel", JSON.stringify(this.state.rows),
    );
    let produkke1 = this.state.rows[0].produk;
    let idpelke1 = this.state.rows[0].idpel;
    let pathProduk = "";
    if (produkke1.toString().toUpperCase().includes("PREPAID")) {
      pathProduk = 'plnprepaid/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("POSTPAID")) {
      pathProduk = 'plnpostpaid/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("BPJS")) {
      pathProduk = 'bpjs/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("TELEPON")) {
      pathProduk = 'telepon/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("SPEEDY")) {
      pathProduk = 'speedy/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("PDAM")) {
      pathProduk = 'pdam/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("PULSA")) {
      pathProduk = 'pulsa/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("DATA")) {
      pathProduk = 'data/' + idpelke1
    }

    history.push(history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
    let pathUrl = window.location.href;
    window.location.href = pathUrl;
    await this.setState({ loading: false });
  }
  deleteItemCart = async () => {
    const url = `${process.env.REACT_APP_URL}/cart/all/delete`;
    await axios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {

      });
  };
  fetchCart = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/cart/list`, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ dataCart: res.data.data })
      })
  };
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });
    return (
      <div>
        <CheckSession isAuth={this.state.isAuth} />
        <section className="section g-bg-gray p-0px-t bg-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-file-excel text-primary h1 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">IMPOR ID PELANGGAN</span> <br />
                          Transaksi multi ID pelanggan
                        </td>
                      </tr>
                    </table>

                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5 mt-2 table-responsive">
                        <div className="card bg-light" style={{ border: "2px dashed #dedede" }}>
                          <div className="card-body text-center">
                            <div className="mt-2"></div>
                            <p className="mb-5">
                              <Upload
                                name="file"
                                beforeUpload={this.fileHandler}
                                onRemove={() => this.setState({ rows: [] })}
                                multiple={false}
                              >
                                <Button>
                                  <Icon type="upload" /> Pilih File Excel
                                </Button>
                              </Upload>
                            </p>
                            <small className="mt-5">Gunakan format isi file excel berikut ini <a href="/sample format multi idpel.xlsx" className="btn btn-secondary btn-sm">Unduh Contoh Format File</a></small>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-7">
                        <div className="card mt-2" style={{ border: "2px solid #dedede" }}>
                          <div className="card-header bg-light border-0">
                            <span className="font-weight-bold text-center text-dark">Preview Isi File</span>
                            {this.state.rows.length > 0 && (
                            <>
                              <span className="float-right">
                                <Button
                                  // onClick={this.handleAdd}
                                  onClick={this.showModal}
                                  size="default"
                                  type="info"
                                >
                                  Tambah Dari Favorit
                                </Button>
                              </span>
                            </>
                            )}
                          </div>
                          <div className="card-body bg-light">
                            <Table
                              components={components}
                              rowClassName={() => "editable-row"}
                              dataSource={this.state.rows}
                              columns={columns}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 ">
                      <div className="col-md-3 ml-auto">
                        <LaddaButton
                          loading={this.state.loading}
                          disabled={!(this.state.rows.length > 0)}
                          onClick={() => {
                            this.process();
                          }}
                          data-color="#008aff"
                          data-size={l}
                          className="m-btn m-btn-theme m-btn-radius w-100 mb-5"
                          data-style={EXPAND_RIGHT}
                        >
                          Lanjut
                        </LaddaButton>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()} scrollable={true} size="lg">
            <Modal.Header>
              {/* <Modal.Title> */}
              <div className="row col-md-12">
                <div className="col-md-6">
                  <span className="h5"><i className="fa fa-star text-warning"></i> ID Pelanggan Favorit</span>
                </div>
              </div>
              {/* </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>PRODUK</th>
                    <th>IDPEL</th>
                    <th>OPSI</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.idpelFav.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{value.produk}</td>
                        <td>{value.idpel}</td>
                        <td><button className="btn btn-sm btn-danger" onClick={() => this.handleAdd(value.produk + "-" + value.idpel)}><i className="fa fa-plus"></i></button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <LaddaButton
                onClick={() => this.hideModal()}
                className="btn btn-outline-secondary"
              >
                Keluar
              </LaddaButton>
            </Modal.Footer>
          </Modal>
          {/* </div> */}
        </section>
      </div>

    );
  }
}

export default ImportIdpel;
