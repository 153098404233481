import Axios from "axios";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext } from "../../router/Router";
import Swal from "sweetalert2";

function ChangePin(props) {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [showPin, setShowPin] = useState(false);
  const [chaptha, setChaptha] = useState(false);
  const recaptchaRef = React.createRef();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const { dispatch, state } = useContext(AuthContext);

  const verifikasiPin = async (pin, data, e) => {
    await fetch(`${process.env.REACT_APP_URL}/users/pin/${pin}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + state.token,
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      await response.json().then(async (result) => {
        if (result.status === 200) {
          if (result?.data?.status === true) {
            updatePIN(data, e);
          } else {
            Swal.fire({
              title: "Proses Gagal",
              text: "PIN Sebelumnya Yang Dimasukkan Tidak Sesuai",
              icon: "warning",
            });
          }
        } else {
          Swal.fire({
            title: "Proses Gagal",
            text: "PIN Sebelumnya Yang Dimasukkan Tidak Sesuai",
            icon: "warning",
          });
        }
      });
    });
  };

  const onSubmit = async (data, e) => {
    if (data.pin != data.confirm_pin) {
      setMessage({
        data: "PIN yang dimasukkan tidak sama dengan konfirmasi",
        type: "alert-danger",
      });
    } else {
      if (chaptha) {
        await Swal.fire({
          title: "Konfirmasi Perubahan PIN",
          text: "Masukkan PIN sebelumnya",
          input: "text",
          icon: "info",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Perbarui PIN",
          cancelButtonText: "Batal",
          inputPlaceholder: "Masukkan PIN Sebelumnya",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
            maxlength: 6,
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            await verifikasiPin(result.value, data, e);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
        });
      } else {
        setMessage({
          data: "Chek Rechaptha",
          type: "alert-warning",
        });
      }
    }
  };

  const updatePIN = (data, e) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + state.token,
        Accept: "application/json",
      },
    };
    Axios.put(
      `${process.env.REACT_APP_URL}/users/pin/update`,
      JSON.stringify(data),
      config
    ).then((res) => {
      if (res.data.status == 200) {
        setMessage({
          data: res.data.data,
          type: "alert-success",
        });
      } else {
        setMessage({
          data: res.data.message,
          type: "alert-danger",
        });
      }
      !res.data.status && e.target.reset();
      window.grecaptcha.reset();
    });
  };

  const onClickReset = async () => {
    await Swal.fire({
      title: "Konfirmasi Reset PIN",
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Reset PIN",
      cancelButtonText: "Batal",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await resetPIN();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const resetPIN = async() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + state.token,
        Accept: "application/json",
      },
    };
    Axios.get(
      `${process.env.REACT_APP_URL}/users/pin/reset`,
      // JSON.stringify(data),
      config
    ).then((res) => {
      if (res.data.status == 200) {
        setMessage({
          data: res.data.data,
          type: "alert-success",
        });
      } else {
        setMessage({
          data: res.data.message,
          type: "alert-danger",
        });
      }
    });
  };

  const onChange = (value) => {
    if (value) {
      setChaptha(true);
      setBtnDisabled(false);
    }
  };

  return (
    <div className="bg-admin">
      <section className="section p-0px-t bg-admin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow mt-2 mb-5">
                <div className="card-header bg-light d-flex justify-content-between">
                  <div className="col-md-10">
                    <table>
                      <tr>
                        <td rowspan="2">
                          <i className="fa fa-lock text-primary h3 mr-2"></i>
                        </td>
                        <td>
                          <span className="font-weight-bold text-primary">
                            GANTI PIN
                          </span>{" "}
                          <br />
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-2">
                    <button
                      // type="submit"
                      className="btn btn-block  btn-primary"
                      onClick={onClickReset}
                      // disabled={btnDisabled}
                    >
                      Reset PIN
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="text-center p-3">
                    <span className="small"></span>
                    {message && (
                      <div
                        className={`alert fade show d-flex ${message.type}`}
                        role="alert"
                      >
                        {message.data}
                        <span
                          aria-hidden="true"
                          className="ml-auto cursor-pointer"
                          onClick={() => setMessage(null)}
                        >
                          &times;
                        </span>
                      </div>
                    )}
                  </div>

                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <label htmlFor="inputForPassword" className="text-dark">
                        Masukkan PIN
                      </label>
                      <span className="text-danger">*</span>
                      <input
                        type={showPin ? "text" : "password"}
                        name="pin"
                        className="form-control"
                        placeholder="Masukkan PIN baru"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter new PIN ",
                          },
                          minLength: {
                            value: 6,
                            message: "Minimum 6 characters are allowed",
                          },
                          maxLength: {
                            value: 6,
                            message: "Maximum 6 characters are allowed",
                          },
                        })}
                      />
                      {errors.pin && (
                        <span className="small text-danger text-center">
                          {errors.pin.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputForPassword" className="text-dark">
                        Konfirmasi PIN Baru
                      </label>
                      <span className="text-danger">*</span>
                      <input
                        type={showPin ? "text" : "password"}
                        name="confirm_pin"
                        className="form-control"
                        placeholder="Konfirmasi PIN Baru"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter new PIN ",
                          },
                          minLength: {
                            value: 6,
                            message: "Minimum 6 characters are allowed",
                          },
                          maxLength: {
                            value: 62,
                            message: "Maximum 6 characters are allowed",
                          },
                        })}
                      />
                      {errors.confirm_pin && (
                        <span className="small text-danger text-center">
                          {errors.confirm_pin.message}
                        </span>
                      )}
                      <div class="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={() => setShowPin(!showPin)}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          {showPin ? "Sembunyikan" : "Tampilkan"} PIN
                        </label>
                      </div>
                    </div>

                    <div className="text-center w-100 text-center d-flex">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfHMCEaAAAAAKjWZtDNjzWaaClaj1G2KHyAD1iA"
                        onChange={onChange}
                        className="py-3 m-auto "
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-block  btn-primary"
                      disabled={btnDisabled}
                    >
                      Perbarui PIN
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChangePin;
