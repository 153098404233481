import React, { Component } from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import 'react-table/react-table.css';
import Select from 'react-select';
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import { Redirect } from 'react-router-dom'
import Swal from 'sweetalert2'

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="ThreeDots" color="#007bff" height="100" width="100" />
      </div>
    )
  );
};
const namaBulan = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>
  }
}
class telkom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      struk: true,
      loading: true,
      idpel: props.match.params.idpel,
      message: null,
      nominal: null,
      trx_type: "2100",
      product_id: null,
      users: [],
      btnInq: true,
      inqRes: [],
      payRes: [],
      bulanTahun: null,
      isAuth: 0,
      token: localStorage.getItem('token'),
      idpel_multi: props.match.params.idpel,
      list_idpel: JSON.parse(localStorage.getItem("list_impor_idpel")),
      last_idpel: localStorage.getItem("list_impor_idpel") ? JSON.parse(localStorage.getItem("list_impor_idpel")).slice(-1) : '',
    };

  }
  componentDidMount() {
    let pathUrl = window.location.pathname;
    let urlImpor = pathUrl.split('/');
    // console.log("path", urlImpor)
    if (urlImpor[1] == 'pdam') {
      if (urlImpor[1] != "users" && urlImpor[2] != "transaksi" && urlImpor[3] != "importidpel" && urlImpor[4] != "cektagihan") {
        if (this.state.list_idpel) {
          localStorage.removeItem("list_impor_idpel");
        }
      }
    }
    this.getproduct();
    this.setState({
      struk: false,
      loading: false
    })
    if (this.state.list_idpel && urlImpor[1] != 'pdam') {
      let cekIdpel = this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
      // console.warn("cek", cekIdpel)
      if (cekIdpel.length > 0) {
        this.handleChangeIdpel(this.state.idpel);
      } else {
        Swal.fire({
          title: "Maaf",
          text: "ID pelanggan tidak ada di daftar yang diimpor",
          icon: "info",
          confirmButtonText: "oke",
        });
      }
    }
  }

  getproduct() {
    fetch(`${process.env.REACT_APP_URL}/products?product_category_id=62`, {
      method: "GET",
      headers: {
        'Authorization': 'Barier ' + this.state.token,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      response.json().then((result) => {
        this.setState({ users: result.data })
      })
    }).catch(function (err) {
      // console.log(err.response.data)
      Swal.fire({
        title: "Maaf",
        text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
        icon: "error",
        confirmButtonText: "Oke",
      });
      this.setState({ isAuth: 401 });
    }.bind(this));
  }
  handleChangeIdpel(e) {
    let inIdpel = "";
    if (this.state.idpel_multi) {
      inIdpel = this.state.idpel_multi;
    } else {
      inIdpel = e.target.value;
    }
    if (this.state.idpel_multi) {
      inIdpel = this.state.idpel_multi;
    } else {
      inIdpel = e.target.value;
    }
    if (inIdpel.length >= 6 && inIdpel.length <= 12) {
      this.setState({ message: "" });
      this.setState({ idpel: inIdpel });
      // this.cekinquiry();
      console.log(this.state.idpel)
    } else {
      this.setState({ message: 'idpel tidak sesuai' });
    }
  }

  currencyFormat(num) {
    return parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1')
  }

  getBulan1(num) {
    return parseInt(num.toString().substr(4, 2))
  }
  getBulan2(num) {
    return parseInt(num.toString().substr(10, 2))
  }
  getTahun1(num) {
    return parseInt(num.toString().substr(0, 4))
  }
  getTahun2(num) {
    return parseInt(num.toString().substr(6, 4))
  }

  setNominal(event) {
    alert('keklik')
    console.log(event)
    // this.setState({nominal : e });
  }

  cekinquiry() {
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_URL}/loket/transactions`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Barier ' + this.state.token,
        'Accept': 'application/json'
      },
      body: JSON.stringify(this.state)
    }).then((response) => {
      response.json().then(async (result) => {
        // console.warn("result", result);
        if (result.status == "200") {
          if ((this.state.trx_type == '2100') && (result.data.rc == "00")) {
            localStorage.setItem('private_inquiry', JSON.stringify({
              data: result.data,
              produk: "PDAM",
              product_id: this.state.product_id,
              product_nomination: this.state.product_nomination
            }))
            this.setState({ inquiry: true, inqres: result.data });
          } else {
            this.setState({ message: result.data.desc });
            Swal.fire({
              title: 'Maaf',
              text: result.data.desc || "Silahkan dicoba beberapa saat lagi",
              icon: 'info',
              confirmButtonText: 'oke'
            })
          }
        } else if (result.status == "401") {
          Swal.fire({
            title: "Maaf",
            text: result.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "info",
            confirmButtonText: "Tutup",
          });
          this.setState({ isAuth: 401 });
        } else {
          this.setState({ message: result.info });
          // alert("IDPEL yang anda masukkan Salah, silahkan periksa kembali")
          Swal.fire({
            title: 'Maaf',
            text: result.message.desc || "Silahkan dicoba beberapa saat lagi",
            icon: 'info',
            confirmButtonText: 'oke'
          })

          // if (this.state.list_idpel) {
          //   let indexOfIdpel = await this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
          //   if (this.state.last_idpel[0].idpel == this.state.idpel) {
          //     await this.props.history.push("/cart");
          //     let pathUrl = await window.location.href;
          //     window.location.href = await pathUrl;
          //   } else {
          //     let newProduk = await this.state.list_idpel[indexOfIdpel[0].key + 1];

          //     let nextProduk = await newProduk.produk;
          //     let nextIdpel = await newProduk.idpel;
          //     let pathProduk = "";
          //     if (nextProduk.toString().toUpperCase().includes("PREPAID")) {
          //       pathProduk = 'plnprepaid/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("POSTPAID")) {
          //       pathProduk = 'plnpostpaid/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("BPJS")) {
          //       pathProduk = 'bpjs/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("SPEEDY") || nextProduk.toString().toUpperCase().includes("TELEPON")) {
          //       pathProduk = 'telkom/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("PDAM")) {
          //       pathProduk = 'pdam/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("PULSA")) {
          //       pathProduk = 'pulsa/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("DATA")) {
          //       pathProduk = 'data/' + nextIdpel
          //     }

          //     await this.props.history.push(this.props.history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
          //     let pathUrl = await window.location.href;
          //     window.location.href = await pathUrl;
          //   }
          // }
        }
      })
      this.setState({ loading: false })
    }).catch(function (err) {
      // console.log(err.response.data)
      Swal.fire({
        title: "Maaf",
        text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
        icon: "error",
        confirmButtonText: "Oke",
      });
      this.setState({ isAuth: 401 });
    }.bind(this));
  }
  payment() {
    let token = 'CUAN-REST $2a$10$a1hNujOfYRHT6bGacbBkEOXBk476HswO8cNgLSJ6e2EtLEfYavqZG';
    console.log(JSON.stringify(this.state));
    this.setState({
      trx_type: "2200",
    });
    fetch(`${process.env.REACT_APP_URL}/loket/transactions`, {
      method: "POST",
      // headers: { 'Content-Type': 'application/json' },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Barier ' + this.state.token,
        'Accept': 'application/json'
      },
      body: JSON.stringify(this.state)
    }).then((response) => {
      response.json().then((result) => {
        console.warn("result", result);
        if (result.status == "200") {
          if (result.data.rc == "00") {
            // alert(result.data.desc);
            this.setState({
              message: result.data.name + ' tagihan ' + this.currencyFormat(result.data.amount) + ' sukses dibayarkan ',
              btnInq: true
            });
          } else {
            this.setState({ message: result.data.desc });
          }
        } else {
          this.setState({ message: result.info });
        }
      })
      this.setState({ loading: false })
    })
  }
  handleChange = (value) => {
    this.setState({ value: value });
    console.log(this.state.value)
    let produk_id = value.value;
    console.log(produk_id);
    this.setState({ product_id: produk_id })
  };

  render() {
    const btnInq = this.state.btnInq;
    const struk = this.state.struk;
    const { users } = this.state;
    const produxs = this.state.users.map(d => ({
      "value": d.prod_id,
      "label": d.name.toUpperCase()
    }));
    return (
      <div>
        <CheckSession isAuth={this.state.isAuth} />
        {
          this.state.inquiry ?
            <><Redirect to='/checkoutv1' /></>
            :
            <></>
        }
        {struk ? (
          <div className="container">
            {/* <nav className="navbar navbar-light bg-light">
        <a href="/" className="navbar-brand text-center"><FaArrowLeft/></a>
        <a className="navbar-brand text-center">Cuan</a>
        <a className="text-center">Bantuan</a> 
        </nav> */}
            <div className="text-center mt-5 mb-5">
              <img src="https://upload.wikimedia.org/wikipedia/commons/3/3f/Commons-emblem-success.svg"></img>
              <h5>Transaction Success</h5>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <table className="table small">
                  <tbody>
                    <tr>
                      <td>IDPEL</td>
                      <td className="text-right">{this.state.payRes.id_pelanggan}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td className="text-right">{this.state.payRes.nama_pelanggan}</td>
                    </tr>

                    <tr>
                      <td>Bulan/Tahun</td>
                      <td className="text-right">{this.state.bulanTahun}</td>
                    </tr>
                    <tr>
                      <td>NO REF</td>
                      <td className="text-right">{this.state.payRes.no_reference}</td>
                    </tr>
                    <tr>
                      <td>JUMLAH TAGIHAN</td>
                      <td className="text-right">Rp. {this.state.payRes.jumlah_tagihan}</td>
                    </tr>

                    <tr>
                      <td>ADMIN BANK</td>
                      <td className="text-right">Rp. {this.state.payRes.jumlah_adm}</td>
                    </tr>

                    <tr>
                      <td>TOTAL BAYAR</td>
                      <td className="text-right">Rp. {this.state.payRes.jumlah_bayar}</td>
                    </tr>

                    <tr>
                      <td colSpan="2" className="text-center text-weight-bold"><b>TELKOM menyatakan struk ini sebagai bukti pembayaran yang sah</b></td>
                    </tr>

                    {/* <tr>
    <td colSpan="2" className="text-left"><b>Terimakasih </b><b>"Informasi Hubungi Call Center 123 Atau Hub TELKOM Terdekat"</b></td>
</tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <a href="/telkom" className="btn btn-primary w-100" >Ingin Transaksi Lagi?</a>
          </div>
        ) : (
          <div>
            <h5 className="text-dark h6">Bayar Tagihan Air PDAM</h5>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="form-group">
                  <h6 className="text-dark">ID Pelanggan </h6>
                  <input
                    type="number"
                    className="form-control"
                    disabled={this.state.list_idpel ? true : false}
                    value={this.state.idpel}
                    placeholder="ID Pelanggan"
                    onChange={(e) => {
                      this.handleChangeIdpel(e);
                    }}
                  />
                  {/* <b className="text-warning">{this.state.message}</b> */}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="form-group">
                  <h6 className="text-dark">Pilih Area/Kota</h6>
                  <Select
                    options={produxs}
                    value={this.state.value}
                    onChange={value => this.handleChange(value)}
                    placeholder={<div>Ketik Produk</div>}
                  />
                  {/* <b className="text-warning">{this.state.message}</b> */}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4 ml-auto hidden-onphone">
                <LaddaButton
                  loading={this.state.loading}
                  disabled={!this.state.idpel}
                  onClick={() => {
                    this.cekinquiry();
                  }}
                  data-color="#008aff"
                  data-size={l}
                  className="m-btn m-btn-theme m-btn-radius w-100"
                  data-style={EXPAND_RIGHT}
                >
                  Cek Tagihan
                </LaddaButton>
              </div>
            </div>
            <div className="show-onphone">
              <div className="row mt-2">
                <div className="col-md-12">
                  <LaddaButton
                    loading={this.state.loading}
                    disabled={!this.state.idpel}
                    onClick={() => {
                      this.cekinquiry();
                    }}
                    data-color="#008aff"
                    data-size={l}
                    className="m-btn m-btn-theme m-btn-radius w-100 mb-5"
                    data-style={EXPAND_RIGHT}
                  >
                    Cek Tagihan
                  </LaddaButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default telkom;