import React from "react";
import NumberFormat from "react-number-format";

export const DataInq = (props) => (
  <>
    <tr className="table-borderless">
      <td>PRODUK</td>
      <td className="text-right">{props.struk.data.desc}</td>
    </tr>
    <tr>
      <td>NOMOR HP</td>
      <td className="text-right">{props.struk.data.msisdn}</td>
    </tr>

    <tr>
      <td>HARGA</td>
      <td className="text-right">
        <NumberFormat
          value={props.struk.data.price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"Rp. "}
          renderText={(value) => <div>{value}</div>}
        />
      </td>
    </tr>
    <tr>
      <td>BIAYA ADMIN</td>
      <td className="text-right">
        <NumberFormat
          value={props.struk.admin_fee}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"Rp. "}
          renderText={(value) => <div>{value}</div>}
        />
      </td>
    </tr>
    <tr>
      <td>BIAYA LOKET</td>
      <td className="text-right">
        <NumberFormat
          value={props.struk.data.loket_fee}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"Rp. "}
          renderText={(value) => <div>{value}</div>}
        />
      </td>
    </tr>
  </>
);
