import React, { Component, useContext } from "react";
import { Redirect } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import Swal from "sweetalert2";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Modal from "react-modal";
import ReactLoading from "react-loading";
// import { AuthContext } from "./../../router/Router";

// const { dispatch, state } = useContext(AuthContext);

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Loader type="ThreeDots" color="#007bff" height="100" width="100" />
      </div>
    )
  );
};

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);

const StrukInq = (props) => (
  <div>
    <div className="col-md-12">
      <hr></hr>
      <div className="row">
        <div className="col-md-10 text-right">
          <label></label>
          <h6></h6>
        </div>
        <div className="col-md-2">
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            style={{ height: "100%" }}>
            Bayar Sekarang
          </button>
        </div>
      </div>
    </div>
  </div>
);

class pln extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToken: false,
      idpel: null,
      idpelStatus: false,
      payment: false,
      inquiry: false,
      message: null,
      product_nomination: null,
      loading: true,
      trx_type: "2100",
      produk: "",
      list_product: [],
      list_token: [],
      product_id: 80,
      token: localStorage.getItem("token"),
      inqres: [],
    };
  }

  GetProduct() {
    fetch(`${process.env.REACT_APP_URL}/products/42`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.log(result.data[0].code)
        this.setState({ list_product: result.data });

        if (result.data) {
          if (
            result.data[0].code !== null ||
            result.data[0].code !== "undefined"
          ) {
            this.setState({
              produk: result.data[0].code,
              product_id: result.data[0].prod_id,
            });
          }
        }
      });
    });
  }

  GetToken() {
    fetch(`${process.env.REACT_APP_URL}/denom/80`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        this.setState({ list_token: result.data });
      });
    });
  }

  componentDidMount() {
    this.GetProduct();
    this.setState({
      showToken: false,
      loading: false,
      payment: false,
    });
    // console.log(window.location.pathname);
  }
  setTypeProduct(event) {
    this.setState({
      loading: true,
      inquiry: false,
      trx_type: "2100",
    });
    if (event.target.value == "TOKENPLN") {
      this.GetToken();
      this.setState({
        showToken: true,
        loading: false,
        product_id: 80,
        message: null,
      });
    } else {
      this.setState({
        showToken: false,
        loading: false,
        product_id: 100,
        message: null,
      });
    }
    // console.log(this.state.product_id)
  }

  handleChangeIdpel(e) {
    let inIdpel = e.target.value;
    if (inIdpel == "" || inIdpel == null) {
      this.setState({ message: null });
    }
    this.setState({ inquiry: false, trx_type: "2100", idpelStatus: false });
    if (inIdpel.length >= 9 && inIdpel.length <= 12) {
      this.setState({ message: null });
      this.setState({ idpel: inIdpel, idpelStatus: true });
      // console.log(this.state.idpel)
    } else {
      this.setState({
        message: "Inputan untuk produk PLN minimal panjangnya adalah 11 digit ",
        inquiry: false,
        trx_type: "2100",
        idpelStatus: false,
      });
    }
  }

  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  SaveDataToLocalStorage(data) {
    // dispatch({
    //   type: "",
    //   payload: data
    // })
    var existingEntries = JSON.parse(localStorage.getItem("allcart"));
    if (existingEntries == null) existingEntries = [];
    localStorage.setItem("cart", JSON.stringify(data));
    existingEntries.push(data);
    localStorage.setItem("allcart", JSON.stringify(existingEntries));
  }

  HitData() {
    if (
      this.state.product_nomination == null &&
      this.state.product_id == "80"
    ) {
      Swal.fire({
        title: "Maaf :)",
        text: "Anda belum memilih nominal pembelian",
        icon: "info",
        confirmButtonText: "oke",
      });
    } else {
      this.setState({ loading: true });
      console.log(JSON.stringify(this.state));
      fetch(`${process.env.REACT_APP_URL}/loket/transactions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + this.state.token,
          Accept: "application/json",
        },
        body: JSON.stringify(this.state),
      }).then((response) => {
        response.json().then((result) => {
          console.warn("result", result);
          //inq berhasil
          if (result.status == "200") {
            if (this.state.trx_type == "2100" && result.data.rc == "0000") {
              this.SaveDataToLocalStorage(result.data);
              console.log(this.state.inqres.amount);
              //  localStorage.push(options.data);
              localStorage.setItem(
                "private_inquiry",
                JSON.stringify({
                  data: result.data,
                  produk: this.state.product_id,
                  product_nomination: this.state.product_nomination,
                })
              );
              //   this.setState({inquiry:false,inqres:result.data});
            } else {
              this.setState({ message: result.data.desc });
              Swal.fire({
                title: "Maaf :(",
                text: result.data.desc,
                icon: "info",
                confirmButtonText: "Tutup",
              });
            }
          } else {
            // this.setState({ message: result.info });
            this.setState({ message: result.message.desc });
            Swal.fire({
              title: "Maaf :(",
              text: result.message.desc,
              icon: "info",
              confirmButtonText: "Tutup",
            });
          }
        });
        this.setState({ loading: false });
      });
    }
  }

  handleClick() {
    this.setState({ buttonState: "loading" });
    setTimeout(() => {
      this.setState({ buttonState: "success" });
    }, 3000);
  }

  setNominal(nominal) {
    this.setState({ product_nomination: nominal });
    console.log(this.state.product_nomination);
  }

  render() {
    var token = [
      { nominal: "20000", harga: "22500" },
      { nominal: "50000", harga: "52500" },
      { nominal: "100000", harga: "102500" },
      { nominal: "150000", harga: "152500" },
      { nominal: "200000", harga: "202500" },
      { nominal: "300000", harga: "302500" },
      { nominal: "400000", harga: "402500" },
      { nominal: "500000", harga: "502500" },
      { nominal: "1000000", harga: "1002500" },
    ];
    return (
      <div>
        {!this.state.produk ? (
          <div className="w-100 h-100  d-flex justify-content-center">
            <Loading type="bubbles" color="#008aff" className="m-auto" />
          </div>
        ) : (
          <div>
            <div>
              {/* {
              this.state.inquiry?
              <><Redirect to='/checkoutv1' /></>
              :
              <></>
            } */}

              {/* judul  */}
              <div className="">
                <label className="font-weight-bold text-dark">
                  Beli Token/Bayar Tagihan Listrik
                </label>
              </div>

              {/* pilihan produk */}

              <div className="row mt-4">
                <div
                  className="col-md-12"
                  onChange={this.setTypeProduct.bind(this)}>
                  {this.state.list_product.map(function (value, i) {
                    return (
                      <label className="font-weight-bold d-inline mr-3">
                        <input
                          key={i}
                          type="radio"
                          value={value.code}
                          id={value.prod_id}
                          name="bpjsType"
                          className="mr-2"
                          defaultChecked={i === 0 ? "check" : ""}
                        />
                        {value.nama_display}
                      </label>
                    );
                  })}
                </div>
              </div>

              <div className="">
                {this.state.showToken ? (
                  <div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        {this.state.message != null ? (
                          <div className="alert alert-warning" role="alert">
                            <label className="text-warning small">
                              {this.state.message}
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-9">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            defaultValue=""
                            placeholder="No. Meter/ID Planggan"
                            onKeyUp={(e) => {
                              this.handleChangeIdpel(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <LaddaButton
                          loading={this.state.loading}
                          disabled={!this.state.idpelStatus}
                          onClick={() => {
                            this.HitData();
                          }}
                          data-color="#008aff"
                          data-size={l}
                          className="m-btn m-btn-theme m-btn-radius w-100 shadow"
                          data-style={EXPAND_RIGHT}>
                          Beli Token {this.state.product_nomination}
                        </LaddaButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        {this.state.message != null ? (
                          <div className="alert alert-warning" role="alert">
                            <label className="text-warning small">
                              {this.state.message}
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-9">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            defaultValue=""
                            placeholder="No. Meter/ID Planggan"
                            onKeyUp={(e) => {
                              this.handleChangeIdpel(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <LaddaButton
                          loading={this.state.loading}
                          disabled={!this.state.idpelStatus}
                          onClick={() => {
                            this.HitData();
                          }}
                          data-color="#008aff"
                          data-size={l}
                          className="m-btn m-btn-theme m-btn-radius w-100"
                          data-style={EXPAND_RIGHT}>
                          Cek Tagihan
                        </LaddaButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {this.state.showToken ? (
                <div>
                  <h6 className="text-black my-3">Pilih Nominal Token</h6>
                  {this.state.list_token ? (
                    <div className="row">
                      <div className="tab-style-6 p-0 m-0">
                        <div className="row">
                          <div className="col-lg-12">
                            <ul className="nav nav-fill nav-tabs row m-2">
                              {this.state.list_token.map((value, index) => {
                                return (
                                  <li
                                    className="nav-item col-md-4 col-6 m-auto p-1"
                                    key={index}
                                    onClick={(e) => {
                                      this.setNominal(value.denom);
                                    }}>
                                    <a
                                      href="#"
                                      data-toggle="tab"
                                      className="nav-link"
                                      style={{ width: "100%" }}>
                                      <span>
                                        Rp.{" "}
                                        {this.rupiahFormat(
                                          parseFloat(value.denom)
                                        )}
                                      </span>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-100 h-100  d-flex justify-content-center">
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="m-auto"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              <div
                className="card border border-primary bg-primary-300 mt-1"
                style={{ backgroundColor: "#dbeefd" }}>
                <div className="card-body">
                  <p className="small">
                    <b className="text-primary">Keterangan</b>
                    <br />
                    <br />
                    1. Produk Listrik PLN tidak tersedia pada jam cut
                    off/maintenance (23.00 - 01.00).
                    <br />
                    2. Jatuh tempo pembayaran tagihan listrik adalah tanggal 20
                    di setiap bulannya.
                    <br />
                    3. Proses verifikasi pembayaran maksimum 2x24 jam hari
                    kerja.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default pln;
