import Axios from "axios";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext } from "../../router/Router";

function ChangePassword(props) {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [chaptha, setChaptha] = useState(false);
  const recaptchaRef = React.createRef();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const { dispatch, state } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (data, e) => {
    if (chaptha) {
      setMessage({
        data: "Login is in progress...",
        type: "alert-warning",
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + state.token,
          Accept: "application/json",
        },
      };
      Axios.post(
        `${process.env.REACT_APP_URL}/users/change/password`,
        JSON.stringify(data),
        config
      ).then((res) => {
        console.log(res.data);
        if (res.data.status == 200) {
          setMessage({
            data: res.data.data,
            type: "alert-success",
          });

          setTimeout(function () {
            dispatch({
              type: "LOGOUT",
              payload: res.data.data,
            });
          }, 3000);
        } else {
          setMessage({
            data: res.data.message,
            type: "alert-danger",
          });
        }
        !res.data.status && e.target.reset();
        window.grecaptcha.reset();
      });
    } else {
      setMessage({
        data: "Chek Rechaptha",
        type: "alert-warning",
      });
    }
  };

  const onChange = (value) => {
    if (value) {
      setChaptha(true);
      setBtnDisabled(false);
    }
  };

  return (
    <div className="bg-admin">
      <section className="section p-0px-t bg-admin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow mt-2 mb-5">
                <div className="card-header bg-light d-flex justify-content-between">
                  <div>
                    <table>
                      <tr>
                        <td rowspan="2">
                          <i className="fa fa-key text-primary h3 mr-2"></i>
                        </td>
                        <td>
                          <span className="font-weight-bold text-primary">
                            GANTI PASSWORD
                          </span>{" "}
                          <br />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="card-body">
                  <div className="text-center p-3">
                    <span className="small"></span>
                    {message && (
                      <div
                        className={`alert fade show d-flex ${message.type}`}
                        role="alert"
                      >
                        {message.data}
                        <span
                          aria-hidden="true"
                          className="ml-auto cursor-pointer"
                          onClick={() => setMessage(null)}
                        >
                          &times;
                        </span>
                      </div>
                    )}
                  </div>

                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <label htmlFor="inputForPassword" className="text-dark">
                        Password Lama
                      </label>
                      <span className="text-danger">*</span>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="old_password"
                        className="form-control"
                        id="inputForOldPassword"
                        placeholder="Password Lama"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter old password",
                          },
                          minLength: {
                            value: 8,
                            message: "Minimum 8 characters are allowed",
                          },
                          maxLength: {
                            value: 62,
                            message: "Maximum 62 characters are allowed",
                          },
                        })}
                      />
                      {errors.old_password && (
                        <span className="small text-danger text-center">
                          {errors.old_password.message}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputForPassword" className="text-dark">
                        Password Baru
                      </label>
                      <span className="text-danger">*</span>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="new_password"
                        className="form-control"
                        id="inputForPasswordNew"
                        placeholder="Password Baru"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter new password ",
                          },
                          minLength: {
                            value: 8,
                            message: "Minimum 8 characters are allowed",
                          },
                          maxLength: {
                            value: 62,
                            message: "Maximum 62 characters are allowed",
                          },
                        })}
                      />
                      {errors.new_password && (
                        <span className="small text-danger text-center">
                          {errors.new_password.message}
                        </span>
                      )}
                      <div class="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={() => setShowPassword(!showPassword)}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          {showPassword ? "Sembunyikan" : "Tampilkan"} password
                        </label>
                      </div>
                    </div>

                    <div className="text-center w-100 text-center d-flex">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfHMCEaAAAAAKjWZtDNjzWaaClaj1G2KHyAD1iA"
                        onChange={onChange}
                        className="py-3 m-auto "
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-block  btn-primary"
                      disabled={btnDisabled}
                    >
                      Simpan Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChangePassword;
