import { faAdjust } from "@fortawesome/free-solid-svg-icons";
import { auto } from "@popperjs/core";
import React from "react";
import { FaHospitalAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";

function Transactions(props) {
  return (
    <div
      style={{
        maxHeight: "100%",
        minHeight: "-webkit-fill-available",
      }}>
      <div class="row align-items-start">
        <div class="col-md-1">
          <div
            className="col-md-12 my-auto"
            style={{ height: "6rem", width: "8rem", overflowY: "auto" }}>
            <img
              className="mt-4 img-fluid p-2"
              alt=""
              title=""
              src="/img/logo_new.png"
            />
          </div>
          <div
            className="row shadow"
            style={{
              overflowY: "auto",
            }}>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">PLN</b>
              </NavLink>
            </div>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">BPJS</b>
              </NavLink>
            </div>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">BPJS</b>
              </NavLink>
            </div>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">BPJS</b>
              </NavLink>
            </div>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">BPJS</b>
              </NavLink>
            </div>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">BPJS</b>
              </NavLink>
            </div>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">BPJS</b>
              </NavLink>
            </div>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">BPJS</b>
              </NavLink>
            </div>
            <div className="col-md-12">
              <NavLink
                className="card p10 ml-2 my-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                to="/bpjs"
                activeClassName="bg-primary white-color theme-hover-bg">
                <div className="only-icon-30 d-inline-block w-100">
                  <FaHospitalAlt />
                </div>
                <b className="m-0px small mt-2">BPJS</b>
              </NavLink>
            </div>
          </div>
        </div>
        <div
          class="col-md-9"
          className=" h-100 text-center"
          style={{ minWidth: "70%" }}>
          <div className="w-100">
            <h1>Auto</h1>
          </div>
        </div>
        <div class="col-md-2 col-auto">
          <div
            className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white "
            style={{ width: "380px" }}>
            <a
              href="/"
              className="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
              <svg className="bi me-2" width={30} height={24}>
                <use xlinkHref="#bootstrap" />
              </svg>
              <span className="fs-5 fw-semibold">List group</span>
            </a>
            <div className="list-group list-group-flush border-bottom overflow-auto">
              <a
                href="#"
                className="list-group-item list-group-item-action active py-3 lh-tight"
                aria-current="true">
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <strong className="mb-1">List group item heading</strong>
                  <small>Wed</small>
                </div>
                <div className="col-10 mb-1 small">
                  Some placeholder content in a paragraph below the heading and
                  date.
                </div>
              </a>
              <a
                href="#"
                className="list-group-item list-group-item-action py-3 lh-tight">
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <strong className="mb-1">List group item heading</strong>
                  <small className="text-muted">Tues</small>
                </div>
                <div className="col-10 mb-1 small">
                  Some placeholder content in a paragraph below the heading and
                  date.
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
