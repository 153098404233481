import React, { PureComponent } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import ReactLoading from 'react-loading';
import LaddaButton from "react-ladda";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Select from "react-select";
import Moment from "react-moment";

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);
// const CheckSession = ({ isAuth }) => {
//   if (isAuth === 401) {
//     return <Redirect to="/login" />;
//   } else {
//     return <></>
//   }
// }
const logo_name = localStorage.getItem("logo");

class Users extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      dataUser: [],
      dataRoles: [],
      fullname: "",
      email: "",
      username: "",
      password: "",
      phone: "",
      type_saldo: "",
      role_id: 1,
      status: 1,
      logo: "",
      logo_url: "",
      selectedFiles: "",
      tagline: "",
      loading: false,
      login: false,
      token: localStorage.getItem("token"),
      user_ex: localStorage.getItem("user"),
      base_url: localStorage.getItem("logo") ? localStorage.getItem("logo").replace(/['"]+/g, '') : '',
      tagline: localStorage.getItem("tagline") ? localStorage.getItem("logo").replace(/['"]+/g, '') : '',
      store: null,
      setIsOpen: false,
      toogleBtn: true,
      typeModal: "",
      isAuth: 0,
      passwordShow: false,
      errValidate: [],
      hth_account_username: "",
      hth_account_password: "",
      hth_account_secretkey: "",
      loket_utama: "",
      fields: {},
      errors: {},
      formIsValid: true,
      company:"",
      first_name:"",
      last_name:"",
      tipe_identitas:"",
      nomor_identitas:"",
      alamat_lengkap:"",
      kode_pos:"",
      kelurahan:"",
      kecamatan:"",
      kabupaten:"",
      propinsi:"",
      name_f: "",
      username_f: "",
      email_f: "",
      no_hp_f: "",
      role_f: "",
      status_f: "",
      dataStatus: [{id:1,name:'Aktif'},{id:0,name:'Non Aktif'}],
      modelDetail:false,
      modelDetailRow:[],
    };
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["hth_account_username"]) {
      formIsValid = false;
      errors["hth_account_username"] = "Username Harus Diisi";
    }
    if (!fields["hth_account_password"]) {
      formIsValid = false;
      errors["hth_account_password"] = "Password Harus Diisi";
    }
    if (!fields["hth_account_secretkey"]) {
      formIsValid = false;
      errors["hth_account_secretkey"] = "Secret key Harus Diisi";
    }
    if (!fields["fullname"]) {
      formIsValid = false;
      errors["fullname"] = "Nama lengkap Harus Diisi";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Nama lengkap Harus Diisi";
    }
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Nama lengkap Harus Diisi";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password Harus Diisi";
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "No Handphone Harus Diisi";
    }
    if (!fields["tagline"]) {
      formIsValid = false;
      errors["tagline"] = "Tagline Harus Diisi";
    }

    if (typeof fields["fullname"] !== "undefined") {
      if (!fields["fullname"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["fullname"] = "Hanya Huruf Yang Diizinkan Untuk Nama Lengkap";
      }
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (!(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        fields["email"].indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        fields["email"].length - lastDotPos > 2
      )
      ) {
        formIsValid = false;
        errors["email"] = "Email tidak sesuai";
      }
    }

    if (!fields["company"]) {
      formIsValid = false;
      errors["company"] = "Nama lengkap Harus Diisi";
    }
    if (typeof fields["company"] !== "undefined") {
      if (!fields["company"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["company"] = "Hanya Huruf Yang Diizinkan Untuk Nama Lengkap";
      }
    }
    this.setState({ formIsValie: formIsValid, errors: errors });
    // return formIsValid;
  }
  showModal = (type, data) => {
    if (type == "Edit") {
      this.setState({
        id: data.id,
        fullname: data.name,
        email: data.email,
        username: data.username,
        hth_account_username: data.account_username,
        loket_utama: data.loket_utama,
        password: data.password,
        phone: data.phone,
        role_id: data.user_roles,
        status: data.status,
        logo: data.image_logo,
        logo_url: data.base_url,
        tagline: data.tagline,
        type_saldo: data.type_saldo,
        errValidate: [],
        company: data.company,
        first_name: data.first_name,
        last_name: data.last_name,
        tipe_identitas: data.tipe_identitas,
        nomor_identitas: data.nomor_identitas,
        alamat_lengkap: data.alamat_lengkap,
        kode_pos: data.kode_pos,
        kelurahan: data.kelurahan,
        kecamatan: data.kecamatan,
        kabupaten: data.kabupaten,
        propinsi: data.propinsi,
      });

      if (data.status == 1) {
        this.setState({ toogleBtn: true });
      } else {
        this.setState({ toogleBtn: false });
      }
      // console.warn("data",data)
    } else {
      this.setState({
        id: 0,
        fullname: "",
        hth_account_username: "",
        hth_account_password: "",
        hth_account_secretkey: "",
        email: "",
        username: "",
        password: "",
        phone: "",
        role_id: 1,
        status: 1,
        logo: null,
        logo_url: null,
        tagline: "",
        errValidate: [],
        toogleBtn: true,
        company:"",
        first_name:"",
        last_name:"",
        tipe_identitas:"",
        nomor_identitas:"",
        alamat_lengkap:"",
        kode_pos:"",
        kelurahan:"",
        kecamatan:"",
        kabupaten:"",
        propinsi:"",
      });
    }
    this.setState({ setIsOpen: true, typeModal: type });
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  showModalDetail = (data) => {
    // console.log(data);
    this.setState({ modelDetail: true,modelDetailRow:data});
  };

  hideModalDetail = () => {
    this.setState({ modelDetail: false});
  };

  componentDidMount = async () => {
    await this.setState({ loading: true });
    await this.fetchUsers();
    await this.fetchRoleId();
    await this.setState({ loading: false });
  }
  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    // console.log(this.state)
  };
  fetchUsers = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/users`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ dataUser: result.data });
      }).catch(function (err) {

        if (err.response.status == 500) {
          Swal.fire({
            title: "Maaf",
            text: "Maaf, terjadi kesalahan sistem [500]",
            icon: "error",
            confirmButtonText: "Oke",
          });
        } else {
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
          this.setState({ isAuth: 401 });
        }
      }.bind(this));
    });
  };
  fetchRoleId = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/roles`, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({ dataRoles: response.data.data });
      });
  };
  saveUser = async (typemodal) => {
    // await this.handleValidation();

    // if(this.state.formIsValid){
    if (typemodal == "Tambah") {
      await this.createUser();
    } else if(typemodal == "Email"){
      await this.updateUserEmail();
    } else {
      await this.updateUser();
    }
    // }
  };
  createUser = async () => {
    await this.setState({ loading: true });
    const formData = new FormData()
    await formData.append('hth_account_username', this.state.hth_account_username)
    await formData.append('hth_account_password', this.state.hth_account_password)
    await formData.append('hth_account_secretkey', this.state.hth_account_secretkey)
    await formData.append('fullname', this.state.fullname)
    await formData.append('email', this.state.email)
    await formData.append('username', this.state.username)
    await formData.append('type_saldo', this.state.type_saldo)

    if (this.state.password && this.state.password != "") {
      await formData.append('password', this.state.password)
      // await console.warn("password", formData.get('password'))
    }
    await formData.append('phone', this.state.phone)
    await formData.append('role_id', this.state.role_id)
    await formData.append('status', this.state.status)
    await formData.append('tagline', this.state.tagline)
    if (this.state.selectedFiles == "") {
      await formData.append('images', logo_name)
      // console.warn("image", formData.get('images'))
    } else {
      await formData.append('images', this.state.selectedFiles[0])
    }
    axios
      .post(
        `${process.env.REACT_APP_URL}/users`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          Swal.fire({
            title: "Maaf",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false });
        this.fetchUsers();
      }).catch((err) => {
        // console.warn("error", err.response.data.message);
        if (err.response.data.status == 402) {
          this.setState({ errValidate: err.response.data.message });
          this.setState({ loading: false });
        } else if (err.response.data.status == 401) {
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
          this.setState({ isAuth: 401 });
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
          this.setState({ loading: false, setIsOpen: false });
          this.fetchUsers();
        }
      });
    // console.warn("data",this.state);
  };
  selectFile = async (event) => {
    if (event.target.files[0].size > 2000000) {
      Swal.fire({
        title: "Error Ukuran File",
        text: "Ukuran maksimal file 2 MB",
        icon: "info",
        confirmButtonText: "oke",
      });
    } else {
      if (event.target.files[0].type != "image/png" && event.target.files[0].type != "image/jpg" && event.target.files[0].type != "image/gif" && event.target.files[0].type != "image/jpeg") {
        Swal.fire({
          title: "Error Tipe File",
          html: "Tipe file <b>" + event.target.files[0].type + "</b> tidak diizinkan. Gunakan file PNG,JPG,GIF,JPEG",
          icon: "info",
          confirmButtonText: "oke",
        });
      } else {
        await this.setState({
          selectedFiles: event.target.files,
        });
      }
    }
    // await console.warn("selected file", event.target.files[0].type);
  }
  updateUser = async () => {
    await this.setState({ loading: true });
    const formData = new FormData()
    await formData.append('hth_account_username', this.state.hth_account_username)
    await formData.append('hth_account_password', this.state.hth_account_password)
    await formData.append('hth_account_secretkey', this.state.hth_account_secretkey)
    await formData.append('fullname', this.state.fullname)
    await formData.append('email', this.state.email)
    await formData.append('username', this.state.username)
    await formData.append('type_saldo', this.state.type_saldo)

    if (this.state.password && this.state.password != "") {
      await formData.append('password', this.state.password)
      // await console.warn("password", formData.get('password'))
    }
    await formData.append('phone', this.state.phone)
    await formData.append('role_id', this.state.role_id)
    await formData.append('status', this.state.status)
    await formData.append('tagline', this.state.tagline)
    if (this.state.selectedFiles == "") {
      await formData.append('images', logo_name)
      // console.warn("image", formData.get('images'))
    } else {
      await formData.append('images', this.state.selectedFiles[0])
    }
    await formData.append('company', this.state.company)
    await formData.append('first_name', this.state.first_name)
    await formData.append('last_name', this.state.last_name)
    await formData.append('tipe_identitas', this.state.tipe_identitas)
    await formData.append('nomor_identitas', this.state.nomor_identitas)
    await formData.append('alamat_lengkap', this.state.alamat_lengkap)
    await formData.append('kode_pos', this.state.kode_pos)
    await formData.append('kelurahan', this.state.kelurahan)
    await formData.append('kecamatan', this.state.kecamatan)
    await formData.append('kabupaten', this.state.kabupaten)
    await formData.append('propinsi', this.state.propinsi)
    // await this.setState({ loading: false });
    await formData.append('send_email', false)

    axios
      .put(
        `${process.env.REACT_APP_URL}/users/` + this.state.id,
        formData,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          // console.warn("resp", json.data);
          if (this.state.user_ex == this.state.username) {
            // console.warn("user", json.data.data.base_url)
            localStorage.setItem(
              "logo",
              JSON.stringify(json.data.data.base_url)
            );
            localStorage.setItem(
              "tagline",
              JSON.stringify(this.state.tagline)
            );
          }
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Oke",
          }).then((result) => {
            if (result.isConfirmed && this.state.user_ex == this.state.username) {
              window.location.reload();
            }
          })
        } else {
          Swal.fire({
            title: "Maaf",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false, toogleBtn: true });
        this.fetchUsers();
      }).catch((err) => {
        // console.warn("error", err.response.data.message);
        if (err.response.data.status == 402) {
          console.log(err.response);
          Swal.fire({
            title: "Error",
            text: err.response.data.message[0].msg,
            icon: "error",
            confirmButtonText: "Tutup",
          });
          this.setState({ errValidate: err.response.data.message });
          this.setState({ loading: false });
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
          this.setState({ loading: false, setIsOpen: false });
          this.fetchUsers();
        }
      });
  };
  updateUserEmail = async () => {
    await this.setState({ loading: true });
    const formData = new FormData()
    await formData.append('hth_account_username', this.state.hth_account_username)
    await formData.append('hth_account_password', this.state.hth_account_password)
    await formData.append('hth_account_secretkey', this.state.hth_account_secretkey)
    await formData.append('fullname', this.state.fullname)
    await formData.append('email', this.state.email)
    await formData.append('username', this.state.username)
    await formData.append('type_saldo', this.state.type_saldo)

    if (this.state.password && this.state.password != "") {
      await formData.append('password', this.state.password)
      // await console.warn("password", formData.get('password'))
    }
    await formData.append('phone', this.state.phone)
    await formData.append('role_id', this.state.role_id)
    await formData.append('status', this.state.status)
    await formData.append('tagline', this.state.tagline)
    if (this.state.selectedFiles == "") {
      await formData.append('images', logo_name)
      // console.warn("image", formData.get('images'))
    } else {
      await formData.append('images', this.state.selectedFiles[0])
    }
    await formData.append('company', this.state.company)
    await formData.append('first_name', this.state.first_name)
    await formData.append('last_name', this.state.last_name)
    await formData.append('tipe_identitas', this.state.tipe_identitas)
    await formData.append('nomor_identitas', this.state.nomor_identitas)
    await formData.append('alamat_lengkap', this.state.alamat_lengkap)
    await formData.append('kode_pos', this.state.kode_pos)
    await formData.append('kelurahan', this.state.kelurahan)
    await formData.append('kecamatan', this.state.kecamatan)
    await formData.append('kabupaten', this.state.kabupaten)
    await formData.append('propinsi', this.state.propinsi)
    // await this.setState({ loading: false });
    await formData.append('send_email', true)

    axios
      .put(
        `${process.env.REACT_APP_URL}/users/` + this.state.id,
        formData,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          // console.warn("resp", json.data);
          if (this.state.user_ex == this.state.username) {
            // console.warn("user", json.data.data.base_url)
            localStorage.setItem(
              "logo",
              JSON.stringify(json.data.data.base_url)
            );
            localStorage.setItem(
              "tagline",
              JSON.stringify(this.state.tagline)
            );
          }
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Oke",
          }).then((result) => {
            if (result.isConfirmed && this.state.user_ex == this.state.username) {
              window.location.reload();
            }
          })
        } else {
          Swal.fire({
            title: "Maaf",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false, toogleBtn: true });
        this.fetchUsers();
      }).catch((err) => {
        // console.warn("error", err.response.data.message);
        if (err.response.data.status == 402) {
          this.setState({ errValidate: err.response.data.message });
          this.setState({ loading: false });
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
          this.setState({ loading: false, setIsOpen: false });
          this.fetchUsers();
        }
      });
  };
  confirmDelete = (iduser) => {
    Swal.fire({
      title: "Hapus Data ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then(async (result) => {
      if (result.value) {
        await this.deleteUser(iduser);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  deleteUser = async (iduser) => {
    const { dataUser } = this.state;
    await axios
      .delete(`${process.env.REACT_APP_URL}/users/` + iduser, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then((json) => {
        if (json.data.status == 200) {
          this.setState({
            dataUser: dataUser.filter(
              (dataUser) => dataUser.id !== iduser
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          this.setState({
            dataUser: dataUser.filter(
              (dataUser) => dataUser.id !== iduser
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      }).catch((err) => {
        Swal.fire({
          title: "Error",
          text: err.message,
          icon: "error",
          confirmButtonText: "Tutup",
        });
        this.setState({ loading: false, setIsOpen: false });
        this.fetchUsers();
      });
  };
  handleChangeRoleId = (data) => {
    this.setState({ role_id: data.value });
    // setRoleId({ label: data.label, value: data.value });
  };
  handleChangeTopup = (data) => {
    this.setState({ type_saldo: data.value });
    // setRoleId({ label: data.label, value: data.value });
  };
  togglePasswordVisiblity = () => {
    let showHide = this.state.passwordShow ? false : true
    this.setState({ passwordShow: showHide });
  };
  filterData = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${process.env.REACT_APP_URL}/users?name=`+this.state.name_f+`&email=`+this.state.email_f+`&username=`+this.state.username_f+`&phone=`+this.state.no_hp_f+`&user_roles=`+this.state.role_f+`&status=`+this.state.status_f,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({ loading: false, dataUser: response.data.data });
      });
  };
  reset = async () => {
    this.setState({ name_f: "", username_f: "", email_f: "", no_hp_f: "", role_f: "" , status_f: "" });
    this.fetchUsers();
  };
  handleChangeRole_f = (data) => {
    this.setState({ role_f: data.value });
    // console.log(this.state.role)
  };
  handleChangeStatus = (data) => {
    this.setState({ status_f: data.value });
    // console.log(this.state.status_f)
  };

  render() {
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Nama</div>
        ),
        accessor: "name",
        style: {
          textAlign: "left",
          flexDirection: "column",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Username</div>
        ),
        accessor: "username",
        style: {
          textAlign: "left",
          flexDirection: "column",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Email</div>
        ),
        accessor: "email",
        style: {
          textAlign: "left",
          flexDirection: "column",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>No Hp</div>
        ),
        accessor: "phone",
        style: {
          textAlign: "left",
          flexDirection: "column",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Role</div>
        ),
        accessor: "user_roles",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (data) => (
          <div>
            {data.original.user_roles == 1 ? (
              <span
                className="badge badge-success"
                style={{
                  fontSize: "12px",
                  letterSpacing: "1px",
                }}
              >
                ADMIN
              </span>
            ) : (
              <>
                {data.original.userid_cabang === null ? (
                  <span className="badge badge-primary" style={{ fontSize: "12px", letterSpacing: "1px", }}>
                    PUSAT
                  </span>
                ) : (
                  <span className="badge badge-warning" style={{ fontSize: "12px", letterSpacing: "1px", }}>
                    CABANG
                  </span>)}
              </>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Status</div>
        ),
        accessor: "status",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (data) => (
          <div>
            {data.original.status == 1 ? (
              <span
                className="badge badge-info"
                style={{
                  fontSize: "12px",
                  letterSpacing: "1px",
                }}
              >
                AKTIF
              </span>
            ) : (
              <span
                className="badge badge-secondary"
                style={{
                  fontSize: "12px",
                  letterSpacing: "1px",
                }}
              >
                NONAKTIF
              </span>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Created At</div>
        ),
        accessor: "created_at",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (data) => (
          <div>
            <Moment format="DD MMM YYYY - HH:mm:ss">{data.original.created_at}</Moment>
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
        ),
        accessor: "id",
        width: 100,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="badge badge-info border-0 mr-1"
              onClick={() => this.showModalDetail(row.original)}
            >
              DETAIL
            </button>
            <button
              className="badge badge-warning border-0 mr-1"
              onClick={() => this.showModal("Edit", row.original)}
            >
              EDIT
            </button>
            {/* &nbsp;
            <button
              onClick={() => this.confirmDelete(row.original.id)}
              className="btn btn-flat btn-sm btn-danger"
            >
              Hapus
            </button> */}
          </div>
        ),
      },
    ];
    const optRoleId = this.state.dataRoles.map((d) => ({
      value: d.id,
      label: d.id + " - " + d.name.toUpperCase(),
    }));
    const optStatus = this.state.dataStatus.map((d) => ({
      value: d.id,
      label: d.id + " - " + d.name.toUpperCase(),
    }));
    const optTopup = [{ value: "DEPOSIT", label: "DEPOSIT" },
    { value: "PLAFON", label: "PLAFON" },];
    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2 mb-3">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                              Filter data yang akan dicari
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Nama</label>
                          <input type="text" name="name_f" id="name_f" className="form-control" value={this.state.name_f} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Username</label>
                          <input type="text" name="username_f" id="username_f" className="form-control" value={this.state.username_f} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Email</label>
                          <input type="text" name="email_f" id="email_f" className="form-control" value={this.state.email_f} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">No HP</label>
                          <input type="number" name="no_hp_f" id="no_hp_f" className="form-control" value={this.state.no_hp_f} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Role</label>
                          {/* <input type="text" name="filIdpel" id="filIdpel" className="form-control" value={this.state.filIdpel} onChange={this.handleChange} /> */}
                          <Select
                            name="role_f"
                            options={optRoleId}
                            // value={isProdId}
                            value={optRoleId.filter(
                              (obj) => obj.value === this.state.role_f
                            )}
                            onChange={(value) => this.handleChangeRole_f(value)}
                            placeholder={<div>Pilih Role</div>}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Status</label>
                          {/* <input type="text" name="filIdpel" id="filIdpel" className="form-control" value={this.state.filIdpel} onChange={this.handleChange} /> */}
                          <Select
                            name="status_f"
                            options={optStatus}
                            // value={isProdId}
                            value={optStatus.filter(
                              (obj) => obj.value === this.state.status_f
                            )}
                            onChange={(value) => this.handleChangeStatus(value)}
                            placeholder={<div>Pilih Role</div>}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.filterData();
                          }}
                        >Terapkan Filter</LaddaButton>
                      </div>
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-danger m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.reset();
                          }}
                        >Reset Filter</LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-3 mt-2">
                  <div
                    className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-users text-primary h3 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">SETTING AKUN PENGGUNA</span> <br />
                            {/* Berikut data riwayat request topup saldo */}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    {this.state.loading ? (
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="text-center"
                      />
                    ) : (
                      <>
                        <h4>
                          <button
                            className="btn btn-primary"
                            onClick={() => this.showModal("Tambah")}
                          >
                            <i className="fa fa-plus"></i> Akun Pengguna
                          </button>
                        </h4>
                        <ReactTable
                          data={this.state.dataUser}
                          // pageSize={this.state.dataUser.length > 10 ? 10 : this.state.dataUser.length}
                          defaultPageSize={this.state.dataUser && this.state.dataUser.length > 10 ? 10 : 10}
                          columns={columns}
                          className="mt-3 table-striped w-100"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()} scrollable={true} size="lg">
          <Modal.Header>
            {/* <Modal.Title> */}
            <div className="row col-md-12">
              <div className="col-md-6">
                <span className="h5">{this.state.typeModal} Pengguna </span>
              </div>
              <div className="col-md-6 text-right">
                <span className="h5 text-secondary">{this.state.typeModal == "Edit" && this.state.hth_account_username == null ? ("Loket Pusat : " + this.state.loket_utama) : ("")}</span>
              </div>
            </div>
            {/* </Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <form id="uploadForm" encType="multipart/form-data">
              {/* {this.props.state.role == 1 && this.state.hth_account_username != null ? ( */}
              {this.props.state.role == 1 ? (
                <>
                  <fieldset className="h5 font-weight-bold text-dark">Akun H2H</fieldset>
                  <div className="form-group">
                    <label>
                      Username<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="hth_account_username"
                      className="form-control"
                      required="required"
                      value={this.state.hth_account_username}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["hth_account_username"]}</small>
                  </div>
                  <label>
                    Password<sup className="text-danger">*</sup>
                  </label>
                  <div className="input-group">
                    <input
                      type={this.state.passwordShow ? "text" : "password"}
                      name="hth_account_password"
                      className="form-control"
                      required="required"
                      value={this.state.hth_account_password}
                      onChange={this.handleChange}
                    />
                    <div className="input-group-prepend" style={{ cursor: "pointer" }}>
                      <span
                        className="input-group-text bg-light"
                        id="inputGroup-sizing-default"
                      >
                        <a href="#" onClick={this.togglePasswordVisiblity} >{this.state.passwordShow ? "Hide" : "Show"}</a>
                      </span>
                    </div>
                  </div>
                  <small style={{ color: "red" }}>{this.state.errors["hth_account_password"]}</small>
                  {this.state.typeModal == "Edit" ? (
                    <>
                      <span className="small text-danger">Kosongkan password akun H2H jika tidak diubah</span>
                    </>
                  ) : (<></>)}
                  <div className="form-group mt-3">
                    <label>
                      Secret Key<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="hth_account_secretkey"
                      className="form-control"
                      required="required"
                      value={this.state.hth_account_secretkey}
                      onChange={this.handleChange}
                    />

                    <small style={{ color: "red" }}>{this.state.errors["hth_account_secretkey"]}</small>
                    {this.state.typeModal == "Edit" ? (
                      <>
                        <span className="small text-danger">Kosongkan secret key jika tidak diubah</span>
                      </>
                    ) : (<></>)}
                  </div>
                  <hr />
                  <fieldset className="h5 font-weight-bold text-dark">Akun Loket</fieldset>
                </>
              ) : (<></>)}
              <div className="form-group">
                <label>
                  Nama Lengkap<sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="fullname"
                  className="form-control"
                  required="required"
                  value={this.state.fullname}
                  onChange={this.handleChange}
                />
                <small style={{ color: "red" }}>{this.state.errors["fullname"]}</small>
              </div>
              <div className="form-group">
                <label>
                  Email<sup className="text-danger">*</sup>
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  required="required"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <small style={{ color: "red" }}>{this.state.errors["email"]}</small>
              </div>
              <div className="form-group">
                <label>
                  Username<sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  required="required"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
                <small style={{ color: "red" }}>{this.state.errors["username"]}</small>
              </div>
              <label>
                Password<sup className="text-danger">*</sup>
              </label>
              <div className="input-group">
                <input
                  type={this.state.passwordShow ? "text" : "password"}
                  name="password"
                  className="form-control"
                  required="required"
                  value={this.state.password}
                  onChange={this.handleChange}
                />

                <div className="input-group-prepend" style={{ cursor: "pointer" }}>
                  <span
                    className="input-group-text bg-light"
                    id="inputGroup-sizing-default"
                  >
                    <a href="#" onClick={this.togglePasswordVisiblity} >{this.state.passwordShow ? "Hide" : "Show"}</a>
                  </span>
                </div>
              </div>
              <small style={{ color: "red" }}>{this.state.errors["password"]}</small>
              {this.state.typeModal == "Edit" ? (
                <>
                  <span className="small text-danger">Kosongkan password jika tidak diubah</span>
                </>
              ) : (<></>)}
              <div className="form-group mt-3">
                <label>
                  No Hp<sup className="text-danger">*</sup>
                </label>
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  required=""
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
                <small style={{ color: "red" }}>{this.state.errors["phone"]}</small>
              </div>
              {this.props.state.role == 1 && this.state.hth_account_username != null ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Role<sup className="text-danger">*</sup></label>
                        <Select
                          name="role_id"
                          options={optRoleId}
                          // value={isProdId}
                          value={optRoleId.filter(
                            (obj) => obj.value === this.state.role_id
                          )}
                          onChange={(value) => this.handleChangeRoleId(value)}
                          placeholder={<div>Pilih Role</div>}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Tipe Saldo<sup className="text-danger">*</sup></label>
                        <Select
                          name="type_saldo"
                          options={optTopup}
                          // value={isProdId}
                          value={optTopup.filter(
                            (obj) => obj.value === this.state.type_saldo
                          )}
                          onChange={(value) => this.handleChangeTopup(value)}
                          placeholder={<div>Pilih Tipe Saldo</div>}
                        />
                      </div>
                    </div>
                  </div>
                  <input type="hidden" name="role" value={this.state.role} onChange={this.handleChange} />
                  <div className="form-group mt-3">
                    <div className="text-center">
                      <label>Logo</label><br />
                      {
                        this.state.logo != null ? (
                          <>
                            <img className="img-thumbnail border-0 mb-2" src={`${process.env.REACT_APP_URL}/images/${this.state.logo}`} width="200px" />
                          </>
                        ) : (
                          <>
                            <span className="font-italic text-dark">(Logo belum diatur)</span>
                          </>
                        )
                      }
                      <input type="file" name="file" id="file" className="form-control" onChange={this.selectFile} />
                    </div>
                    {this.state.typeModal == "Edit" ? (
                      <>
                        <span className="small text-danger text-left">Kosongkan input gambar jika tidak diubah</span>
                      </>
                    ) : (<></>)}
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>
                          Tagline
                        </label>
                        <input
                          type="text"
                          name="tagline"
                          className="form-control"
                          required=""
                          value={this.state.tagline}
                          onChange={this.handleChange}
                        />
                        <small style={{ color: "red" }}>{this.state.errors["tagline"]}</small>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Status Akun<sup className="text-danger">*</sup></label>
                        <br />
                        <BootstrapSwitchButton
                          checked={this.state.toogleBtn}
                          onlabel="AKTIF"
                          offlabel="NONAKTIF"
                          onstyle="info"
                          offstyle="secondary"
                          width={105}
                          onChange={(checked: boolean) => {
                            this.setState({ toogleBtn: checked });
                            {
                              this.state.toogleBtn
                                ? this.setState({ status: 0 })
                                : this.setState({ status: 1 });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group">
                    <label>Status Akun<sup className="text-danger">*</sup></label>
                    <br />
                    <BootstrapSwitchButton
                      checked={this.state.toogleBtn}
                      onlabel="AKTIF"
                      offlabel="NONAKTIF"
                      onstyle="info"
                      offstyle="secondary"
                      width={105}
                      onChange={(checked: boolean) => {
                        this.setState({ toogleBtn: checked });
                        {
                          this.state.toogleBtn
                            ? this.setState({ status: 0 })
                            : this.setState({ status: 1 });
                        }
                      }}
                    />
                  </div>
                </>
              )}
              {this.state.typeModal == "Edit" ? (
                <>
                  <fieldset className="h5 font-weight-bold text-dark">Detail Akun</fieldset>
                  <div className="form-group">
                    <label>
                      Company<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="company"
                      className="form-control"
                      required="required"
                      value={this.state.company}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["company"]}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Fist Name<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      required="required"
                      value={this.state.first_name}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["first_name"]}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Last Name<sup className="text-danger"></sup>
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      required="required"
                      value={this.state.last_name}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["last_name"]}</small>
                  </div>
                  <div className='form-group'>
                    <label>
                      type Identitas<sup className="text-danger">*</sup>
                    </label>
                    <select 
                    name="tipe_identitas"
                    id="tipe_identitas"
                    className="form-control"
                    value={this.state.tipe_identitas} onChange={this.handleChange}>
                      <option value="" disabled>-- PILIH TYPE --</option>
                      <option value="KTP">KTP</option>
                      <option value="SIM">SIM</option>
                      <option value="PASPOR">Paspor</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>
                      Nomer Identitas<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="nomor_identitas"
                      className="form-control"
                      required="required"
                      value={this.state.nomor_identitas}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["nomor_identitas"]}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Alamat Lengkap<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="alamat_lengkap"
                      className="form-control"
                      required="required"
                      value={this.state.alamat_lengkap}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["alamat_lengkap"]}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Kode Pos<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="kode_pos"
                      className="form-control"
                      required="required"
                      value={this.state.kode_pos}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["kode_pos"]}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Kelurahan<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="kelurahan"
                      className="form-control"
                      required="required"
                      value={this.state.kelurahan}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["kelurahan"]}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Kecamatan<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="kecamatan"
                      className="form-control"
                      required="required"
                      value={this.state.kecamatan}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["kecamatan"]}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Kabupaten<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="kabupaten"
                      className="form-control"
                      required="required"
                      value={this.state.kabupaten}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["kabupaten"]}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Propinsi<sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="propinsi"
                      className="form-control"
                      required="required"
                      value={this.state.propinsi}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: "red" }}>{this.state.errors["propinsi"]}</small>
                  </div>
                  {/* <span className="small text-danger">Kosongkan password jika tidak diubah</span> */}
                </>
              ) : (<></>)}
              <div className="form-group">
                {this.state.errValidate != "" ? (
                  <>
                    {Array.isArray(this.state.errValidate) ? (
                      <div className="alert alert-danger">
                        <p className="font-weight-bold">Periksa Form Input Berikut</p>
                        {this.state.errValidate.map((item) =>
                          <li className="small">{item.param + " : " + item.msg}</li>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="alert alert-danger">
                          <p className="font-weight-bold">Gagal Membuat Akun</p>
                          <li className="small">{this.state.errValidate}</li>
                        </div>
                      </>)}
                  </>
                ) : (<></>)}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Batal
            </button>
            <LaddaButton
              loading={this.state.loading}
              onClick={() => {
                this.saveUser(this.state.typeModal);
              }}
              className="btn btn-primary"
            >
              Simpan
            </LaddaButton>
            { this.state.typeModal == "Edit" ? (
              <>
            <LaddaButton
              loading={this.state.loading}
              onClick={() => {
                this.updateUserEmail();
              }}
              className="btn btn-primary"
            >
              Simpan & Kirim Email
            </LaddaButton>
            </>
            ):(<></>)}
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.modelDetail} scrollable={true} size="lg">
          <Modal.Header>
            <div className="row col-md-12">
              <div className="col-md-6">
                <span className="h5">Detail Akun</span>
              </div>
              <div className="col-md-6 text-right">
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            {/* {this.state.modelDetailRow} */}
            <div className='table-responsive'>
               <table className='table'>
               <tr className='border-0'>
                  <td width={'35%'}>Nama Partner</td>
                  <td >: {this.state.modelDetailRow.company}</td>
                </tr>
                <tr>
                  <td>Nama Lengkap Pic</td>
                  <td>: {this.state.modelDetailRow.first_name} {this.state.modelDetailRow.last_name}</td>
                </tr>
                <tr>
                  <td>Identitas</td>
                  <td >: {this.state.modelDetailRow.tipe_identitas}</td>
                </tr>
                <tr>
                  <td>Nomor Identitas</td>
                  <td>: {this.state.modelDetailRow.nomor_identitas}</td>
                </tr>
                <tr>
                  <td>Alamat Lengkap</td>
                  <td >: {this.state.modelDetailRow.alamat_lengkap}</td>
                </tr>
                <tr>
                  <td>Kode Pos</td>
                  <td >: {this.state.modelDetailRow.kode_pos}</td>
                </tr>
                <tr>
                  <td>Kabupaten</td>
                  <td>: {this.state.modelDetailRow.kabupaten}</td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>: {this.state.modelDetailRow.kecamatan}</td>
                </tr>
                <tr>
                  <td>Kelurahan</td>
                  <td>: {this.state.modelDetailRow.kelurahan}</td>
                </tr>
                <tr>
                  <td>Kabupaten / Kota</td>
                  <td>: {this.state.modelDetailRow.kabupaten}</td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>: {this.state.modelDetailRow.propinsi}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td >: {this.state.modelDetailRow.email}</td>
                </tr>
                <tr>
                  <td>Nomor Telephone</td>
                  <td >: {this.state.modelDetailRow.phone}</td>
                </tr>
               </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModalDetail()}
              className="btn btn-outline-light border-0 text-dark"
            >
              Tutup
            </button>
            {/* <button
              onClick={() => this.hitGenerateMan()}
              className="btn btn-primary shadow"
            >
              Proses Manual
            </button>
            <button
              onClick={() => this.hitGenerate()}
              className="btn btn-primary shadow"
            >
              Proses Otomatis
            </button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Users;