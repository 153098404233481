import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading';
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import Swal from 'sweetalert2'
import Select from 'react-select';

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={'10%'} width={'10%'} />
);

class SetMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      loading: false,
      login: false,
      token: localStorage.getItem('token'),
      store: null,
      value: '62',
      size: 0,
      listquotes: [],
      quotes: null,
      quote_id_edit: null,
      isEdit: false,
      produkId: [],
      produkCat: [],
      prodId: null,
      prodCat: null,
      valId: null,
      ValCat: null,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({ quotes: event.target.value });
    console.warn(this.state.quotes);
  }

  handleChangePId = (value) => {
    this.setState({ valId: value });
    // console.log(this.state.valId)
    let produk_id = value.value;
    // console.log(produk_id);
    this.setState({ prodId: produk_id })
  };

  handleChangePCat = (value) => {
    this.setState({ ValCat: value });
    console.log('', this.state.ValCat)
    let produk_cat = value.value;
    console.log(produk_cat);
    this.setState({ prodCat: produk_cat })
    this.getproductId(produk_cat);
  };

  update() {
    Swal.fire({
      title: 'Simpan Perubahan?',
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Simpan",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(process.env.REACT_APP_URL + '/admin/setting/update/' + this.state.prodCat, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Barier " + this.state.token,
            Accept: "application/json",
          },
          body: JSON.stringify({
            admin_fee: this.state.quotes,
          })
        })
          .then((response) => {
            response.json().then(async (result) => {
              if (result.status == "200") {
                await this.getproduct();
                await this.setState({ isEdit: false });
                await Swal.fire({
                  title: 'Sukses Disimpan',
                  text: result.data.desc,
                  icon: 'success',
                  confirmButtonText: 'oke'
                });
              }
              else {
                // console.warn("response", result)
                if (result.status == 402) {
                  this.setState({ message: result.message });
                } else {
                  this.setState({ message: result.data.desc });
                }
                Swal.fire({
                  title: 'Maaf',
                  text: this.state.message,
                  icon: 'error',
                  confirmButtonText: 'oke'
                });
              }
            })
          });
      }
    })

  }

  edit(ori) {
    this.setState({
      quote_id_edit: ori.id,
      isEdit: true,
      quotes: ori.admin_fee_pulsa,
    });
    // console.warn("id",this.state.quote_id_edit);
    // console.warn("admin fee",this.state.quotes);
  }

  componentDidMount() {
    this.getproduct();
    // this.getproductId();
    this.getproductCat();
  }

  getproduct() {
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_URL + '/products?product_category_id=pulsa', {
      method: "GET",
      headers: {
        Authorization: "Barier " + this.state.token,
      }
    }).then((response) => {
      response.json().then((result) => {
        //   console.warn("result",result.data.length);
        this.setState({ loading: false, users: result.data, size: result.data.length })
        //   console.warn(this.state.users);
      })
    })
  }

  getproductId(produk_cat) {
    // this.setState({loading:true})
    fetch(process.env.REACT_APP_URL + '/products?product_category_id=' + produk_cat, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
      // body: JSON.stringify({
      //   prod_id: this.state.prodId,
      //   product_category_id: produk_cat,
      // })
    }).then((response) => {
      response.json().then((result) => {
        //   console.warn("result",result.data.length);
        this.setState({
          // loading:false,
          produkId: result.data
        })
        //   console.warn(this.state.users);
      })
    })
  }

  getproductCat() {
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_URL + '/products/categori/pulsa', {
      method: "get",
      headers: {
        'Authorization': "Bearer " + this.state.token
      }
    }).then((response) => {
      response.json().then((result) => {
        if (result.data) {
          this.setState({ loading: false, produkCat: result.data })
        }
      })
    })
  }
  getproductFil() {
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_URL + '/products?product_category_id=' + this.state.prodCat, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
      // body: JSON.stringify({
      //   prod_id: this.state.prodId,
      //   product_category_id: this.state.prodCat,
      // })
    }).then((response) => {
      response.json().then((result) => {
        //   console.warn("result",result.data.length);
        this.setState({ loading: false, users: result.data, size: result.data.length })
        //   console.warn(this.state.users);
      })
    })
  }

  reset() {
    this.getproduct();
    this.setState({ valId: null, ValCat: null, prodId: null, prodCat: null, produkId: [] });
  }

  render() {

    const { options, value, options_pulsa } = this.state;
    const columns = [
      {
        Header: '#',
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: 'center',
          textTransform: 'uppercase',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Produk</div>
        ),
        accessor: 'name',
        style: {
          textAlign: 'left',
          textTransform: 'uppercase',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Harga</div>
        ),
        accessor: 'price',
        style: {
          textAlign: 'right',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        },
        Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(props.value)
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Biaya Admin</div>
        ),
        accessor: 'admin_fee_pulsa',
        style: {
          textAlign: 'right',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        },
        Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(props.value)
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
        ),
        accessor: 'id',
        maxWidth: 100,
        style: {
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <LaddaButton
              loading={this.state.loading}
              className="btn btn-warning btn-radius"
              data-color="#008aff"
              // data-size={S}
              onClick={() => this.edit(row.original)}
            >Edit</LaddaButton>
          </div>
        )
      },
    ];
    const produxs = this.state.produkId.map(d => ({
      "value": d.prod_id,
      "label": d.prod_id + " - " + d.name
    }));
    const produxsCat = this.state.produkCat.map(d => ({
      "value": d.product_category_id,
      "label": d.product_category_id + " - " + d.name
    }));

    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2 mb-5">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-cogs text-primary h3 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold">SETTING ADMIN PULSA / DATA</span> <br />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-header">
                    <div className="row mt-3">
                      <div className="col-md-8 mt-3">
                        <Select
                          options={produxsCat}
                          value={this.state.ValCat}
                          onChange={value => this.handleChangePCat(value)}
                          placeholder={<div>Pilih Kategori Produk</div>}
                        />
                      </div>
                      {/* <div className="col-md-4">
                    <Select
                      options={produxs}
                      value={this.state.valId}
                      onChange={value => this.handleChangePId(value)}
                      placeholder={<div>Pilih Produk</div>}
                    />
                  </div> */}
                      <div className="col-md-2 col-6 mt-3">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-color="#008aff"
                          data-size={l}
                          onClick={() => this.getproductFil()}
                        >Filter</LaddaButton>
                      </div>
                      <div className="col-md-2 col-6 mt-3">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-danger m-btn-radius w-100"
                          data-color="#008aff"
                          data-size={l}
                          onClick={() => this.reset()}
                        >Reset</LaddaButton>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.state.isEdit ? (
                      <div className="row">
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-2">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={this.state.quotes}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="col-md-2">
                          <LaddaButton
                            loading={this.state.loading}
                            className="btn btn-success m-btn-radius w-100"
                            // data-color="#008aff"
                            data-size={l}
                            onClick={() => this.update()}
                          >Simpan</LaddaButton>
                        </div>
                        <div className="col-md-2">
                          <LaddaButton
                            loading={this.state.loading}
                            className="btn btn-warning m-btn-radius w-100"
                            // data-color="#008aff"
                            data-size={l}
                            onClick={() => this.setState({ isEdit: false })}
                          >Batal</LaddaButton>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row d-flex justify-content-center">
                      {
                        this.state.loading ?
                          <Loading type="bubbles" color="#008aff" className="text-center" />
                          :
                          <ReactTable
                            data={this.state.users}
                            defaultPageSize={5}
                            pageSizeOptions={[5, 10, 50, 100]}
                            columns={columns}
                            className="mt-3 table-striped w-100 react-bs-table"
                          />
                      }
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default SetMenu;