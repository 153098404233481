import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../router/Router";
import Axios from "axios";
import Swal from "sweetalert2";
import ReactTable from "react-table";
import { Redirect } from "react-router-dom";
import ReactLoading from "react-loading";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const createHistory = require("history").createBrowserHistory;
function DashboardAdmin(props) {
  const token = localStorage.getItem("token");
  const { dispatch, state } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [jmlLembar, setJmlLembar] = useState(0);
  const [jmlAmount, setJmlAmount] = useState(0);
  const [isAuth, setAuth] = useState(0);
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"3%"}
      width={"3%"}
      className="d-flex justify-content-center"
    />
  );
  const [datasetLembar, setDatasetLembar] = useState([])
  const [datasetAmount, setDatasetAmount] = useState([])
  const [labels, setLabels] = useState([]);
  let history = createHistory();
  const getData = async () => {
    await setLoading(true);
    // console.warn("state", state);
    await state;
    Axios.get(
      `${process.env.REACT_APP_URL}/dashboard/admin`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).then(async (response) => {
      await setData(response.data.data);
      let jmlTransaksi = await response.data.data.reduce((prev, current) => {
        return prev += current.lembar;
      }, 0);
      let jmlAmount = await response.data.data.reduce((prev, current) => {
        return prev += current.amount;
      }, 0);
      await setJmlLembar(jmlTransaksi)
      await setJmlAmount(jmlAmount)

      await response.data.data.map((item) => {
        labels.push(item.username);
        datasetLembar.push(item.lembar);
        datasetAmount.push(item.amount);
      })

      // await console.warn("labels", labels);
      // await console.warn("data", response.data.data);
      await setLoading(false);
    }).catch(async function (err) {
      // if (err.response.data.status == 401) {
        await setAuth(401);
      // }
    })
    
  };
  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: '',
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };
  const dataChart = {
    labels,
    datasets: [
      {
        label: 'Transaksi',
        data: datasetLembar,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
      },
      {
        label: 'Pembayaran',
        data: datasetAmount,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y1',
      },
    ],
  };
  const numberFormat = (num) => {
    let data = num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return `Rp. ` + data;
  };
  useEffect(() => {
    getData();
    
  }, []);

  if (isAuth === 401) {
    dispatch({
      type: "LOGOUT",
    });
  }
  const columns = [
    {
      Header: "#",
      id: "no",
      maxWidth: 50,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: () => (
        <div style={{textAlign:"left", color: "black"}}>Nama Loket</div>
      ),
      accessor: "name",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: () => (
        <div style={{textAlign:"left", color: "black"}}>ID Loket</div>
      ),
      accessor: "username",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{textAlign:"center", color: "black"}}>Jumlah Transaksi</div>
      ),
      accessor: "lembar",
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{textAlign:"center", color: "black"}}>Jumlah Pembayaran</div>
      ),
      accessor: "amount",
      style: {
        textAlign: "right",
        flexDirection: "column",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },
  ];
  return (

    <div className="bg-primary-admin">
      <section className="section p-0px-t">
        <div className="container">
          <div className="card shadow">
            <div className="card-header d-flex justify-content-between bg-light">
              <div>
                <table>
                  <tr>
                    <td rowspan="2"><i className="fa fa-chart-line text-primary h1 mr-2"></i></td>
                    <td>
                      <span className="font-weight-bold text-primary">GRAFIK TRANSAKSI LOKET</span> <br />
                      Berikut grafik transaksi loket hari ini
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <Line options={options} data={dataChart} />;
              </div>
            </div>
          </div>
          <div className="card mt-3 shadow mb-5">
            <div className="card-header bg-light">
              <table>
                <tr>
                  <td rowspan="2"><i className="fa fa-file-alt text-primary h1 mr-2"></i></td>
                  <td>
                    <span className="font-weight-bold text-primary">DATA TRANSAKSI LOKET</span> <br />
                    Berikut ringkasan data transaksi loket hari ini
                  </td>
                </tr>
              </table>
            </div>
            <div className="card-body text-dark table-responsive">
              {loading ? (
                <div className="w-100 text-center justify-content-center">
                  Loading...
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="alert alert-primary border border-primary">
                        <div className="card-body px-0 py-0 font-weight-bold">Total Jumlah Transaksi <span className="float-right">{jmlLembar}</span></div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="alert alert-primary border border-primary">
                        <div className="card-body px-0 py-0 font-weight-bold">Total Jumlah Pembayaran <span className="float-right">{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(jmlAmount)}</span></div>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <Loading type="spinningBubbles" color="#91091e" />
                  ) : (
                    <ReactTable
                      // defaultSorted={[{ id: "tanggal", desc: true }]}
                      data={data}
                      defaultPageSize={data.length >= 10 ? 10 : 5}
                      defaultPageSize={5}
                      columns={columns}
                      className="mt-3 table-striped w-100"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardAdmin;
