import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../../router/Router";
const logo_name = localStorage.getItem("logo");

function Done(props) {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [chaptha, setChaptha] = useState(false);
  const [registers, setRegisters] = useState(false);
  const recaptchaRef = React.createRef();

  const { dispatch, state } = useContext(AuthContext);

  // console.log(state.isAuthenticated);
  if (state.isAuthenticated) {
    return <Redirect to="/" />;
  }

  // get token from url
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("q");
  // console.log("token = > "+token);

  const onSubmit = () => {
    window.location.replace('/');
  };

  return (
    <div className="bg-primary-admin">
      <div className="container">
        <div className="row vh-100">
          <div className="col-md-5 m-auto">
            <div className="card shadow">
              <div className="card-body">
                <div className="text-center p-3">
                  <img
                    className="logo-light"
                    alt=""
                    width="160px"
                    height="130px"
                    title=""
                    src={logo_name == "null" ? "/img/loketpelangi.png" : `${process.env.REACT_APP_URL}/images/${logo_name}`}
                  />
                  <h2>Kata Sandi <br></br> Berhasil Diubah</h2>
                  <h5><span className="small">Anda baru saja melakukan perubahan kata sandi akun pengguna. Sebagai pengingat, mohon untuk melakukan perubahan kata sandi melalui aplikasi secara berkala untuk keamanan data akun pengguna.</span></h5>
                  {message && (
                    <div
                      className={`alert fade show d-flex ${message.type}`}
                      role="alert">
                      {message.data}
                      <span
                        aria-hidden="true"
                        className="ml-auto cursor-pointer"
                        onClick={() => setMessage(null)}>
                        &times;
                      </span>
                    </div>
                  )}
                </div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  autoComplete="off">
                  <div className="row">
                    <div className="col-md-9 col-11 m-auto">
                      {errors.username && (
                        <span className="small text-danger text-center">
                          {errors.username.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-10 col-sm-12 m-auto">
                    <button type="submit" className="btn btn-block  btn-primary">
                      Masuk Sekarang <i className="fa fa-chevron-circle-right ml-2"></i>
                    </button>
                  </div>

                  <div className="text-center mt-3">
                    <label
                      htmlFor="copiright"
                      className="small text-mute m-auto">
                      © PT. Pelangi Indodata 2020
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Done;
