import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../router/Router";
import Axios from "axios";
import { NavLink } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import NumberFormat from "react-number-format";

function Breadcrumb(props) {
  let history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const [segarkan, setSegarkan] = useState("");
  const title = props.title ? props.title : "";
  // const user_role = localStorage.getItem("user_role");

  // console.warn("props",state.balance);

  const path = window.location.pathname;
  const updateInfosaldo = () => {
    setSegarkan("Refreshing....");
    if (localStorage.getItem("token")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      };
      Axios.post(
        `${process.env.REACT_APP_URL}/login/verify`,
        null,
        config
      ).then((res) => {
        // console.log(res.data.status);
        if (res.data.status == 200) {
          setSegarkan("");
          dispatch({
            type: "REDATA",
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: "LOGOUT",
            payload: null,
          });
          history.push("/login");
        }
      });
    } else {
      dispatch({
        type: "LOGOUT",
        payload: null,
      });
      history.push("/login");
    }
  };

  return (
    <>
      <div style={props.page != "admin" ? { backgroundColor: "#F2F3FA" } : { backgroundColor: "#2196F3" }} className="hidden-onphone">
        <section className="mt-3">
          <div className="container">
            <div className="row">
              <div className={props.page != "admin" ? "col-md-12 mt-3 mb-2 text-primary" : "col-md-12 mt-3 mb-2 text-white"} >
                {state.role == "2" ? (
                  <>
                    <div className="hidden-onphone">
                      <i className="fa fa-user mr-2"></i>
                      Halo, <b>{state.user}</b>
                      <a
                        role="button"
                        onClick={updateInfosaldo}
                        className={props.page != "admin" ? "text-primary link float-right" : "text-white link float-right"}>
                        <i className="ml-2 fa fa-sync-alt" />
                        {segarkan}
                      </a>

                      <span className="float-right">
                        <NumberFormat
                          value={state.balance != "" ? parseInt(state.balance) : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp. "}
                          renderText={(value) => <b>{value}</b>}
                        />{" "}
                      </span>
                      <i className="fa fa-money-bill-wave mx-2 my-1 float-right" />
                    </div>

                  </>
                ) : (
                  <>
                  </>)}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="show-onphone">
        {path == "/pln" || path == "/bpjs" || path == "/telkom" || path == "/pdam" || path == "/pulsa" || path == "/data" ? (
          <>

            <div className="bg-phone">
              <div className="card-body border-0" style={{ backgroundColor: "#2196F3", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                <div className="row">
                  {/* <div className="col-3 text-right">
                <img className="rounded" width="65px" alt="" title="" src={logo_name == "null" ? "/img/loketpelangi.png" : `${process.env.REACT_APP_URL}/images/${logo_name}`} />
              </div> */}
                  <div className="col-12 text-center">
                    {/* <i className="fa fa-user text-white"></i> <span className="text-white"> {state.fullname}</span><br /> */}
                    {/* <i className="fa fa-wallet text-white"></i> */}
                    <sup className="text-white">Rp</sup>
                    <span className="text-white h6"><NumberFormat
                      value={state.balance != "" ? parseInt(state.balance) : 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      // prefix={"Rp"}
                      renderText={(value) => <b>{value}</b>}
                    /></span>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="row justify-content-center">
                      <NavLink to="/users/transaksi/requestsaldo" className="btn btn-outline-light border col-4"><i className="fa fa-plus"><br /><span className="label-nav">Isi Saldo</span></i></NavLink>
                      <NavLink to="/users/riwayat/topupsaldo" className="btn btn-outline-light border col-4 ml-2"><i className="fa fa-history "><br /><span className="label-nav">Riwayat</span></i></NavLink>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card-body m-auto" style={{ backgroundColor: "#2196F3" }}>
            <div className="row">
              <div className="col-12">

                {state.role == "1" && window.location.pathname == "/users/dashboard" && state.user!=null  ? (
                  <>
                    <span className="ml-2 text-white">DASHBOARD - <b>{state.user.toUpperCase()}</b></span>
                  </>) : (
                  <>
                    <a href="#" onClick={() => history.goBack()}>
                      <i className="fa fa-arrow-left mr-2 text-white fa-lg"></i>
                    </a><span className="ml-2 text-white">{title}</span>
                  </>)}

              </div>
            </div>
          </div>)}
      </div>
    </>
  );
}

export default Breadcrumb;
