
import React, { PureComponent } from 'react';
import { PlnPrepaid } from "../Element/PlnPrepaid";
import { PlnPostpaid } from "../Element/PlnPostpaid";
import { Bpjs } from "../Element/Bpjs";
import { PulsaData } from "../Element/PulsaData";
import { Telkom } from "../Element/Telkom";
import { PDAM } from "../Element/PDAM";

const ProdukData = ({
  data = null,
  product_type = null,
  adminBank = 0,
}) => {
  // console.warn("produk", kel_produk)
  if (data.product_type == "PLN" && data.product_name.toUpperCase().includes("POSTPAID")) {
    console.log(data)
    return <PlnPostpaid struk={data} type="print" />;
  } else if (data.product_type == "PLN" && data.product_name.toUpperCase().includes("PREPAID")) {
    return <PlnPrepaid struk={data} type="print" />;
  } else if (data.product_type == "BPJS") {
    return <Bpjs struk={data} type="print"/>;
  } else if (data.product_type == "TELKOM") {
    return <Telkom struk={data} type="print"/>;
  } else if (data.product_type == "PULSA" || data.product_type == "PAKET DATA") {
    return (
      <PulsaData
        struk={data}
        adminBank={adminBank}
        product_type={product_type}
        type="print"
      />
    );
  } else if (data.product_type == "PDAM") {
    return <PDAM struk={data} type="print"/>;
  } else {
    return (
      <div className="text-center w-100">
        Kesahalah parsing data
      </div>
    );
  }
};

class StrukComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      infoLoket: "",
      orderId: "",
      produkType: "",
      dateTime: "",
    };
  }
  componentDidMount() {
    // console.warn("no struk", this.props.noStruk)
    this.state.orderId = this.props.orderId.substr(9, 24);
    if (this.props.infoLoket != null) {
      this.setState({ infoLoket: this.props.infoLoket.toUpperCase() })
    }
  }


  render() {
    return (
      <div>
        <table width="53%" style={{ color: "black", align: "center", borderLeft: "1px solid black", borderRight: "1px solid black", marginLeft: "10px", tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td style={{ fontSize: "26px", fontWeight: "bold" }}><center style={{ marginTop: "20px" }}><p>{this.state.infoLoket}</p></center></td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px" }}><center style={{ marginTop: "20px" }}>STRUK PEMBELIAN</center></td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px" }}><center>Order ID : {this.state.orderId}</center></td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px" }}>
                <center>
                  Tanggal{' : '}
                  {this.props.strukData[0].product_type == "PDAM" ? (
                    <>
                      {this.props.strukData[0].waktu_lunas.substr(0, 4) + "-" + this.props.strukData[0].waktu_lunas.substr(4, 2) + "-" + this.props.strukData[0].waktu_lunas.substr(6, 2)}
                    </>
                  ) : (
                    <>
                      {this.props.strukData[0].datetime.substr(0, 4) + "-" + this.props.strukData[0].datetime.substr(4, 2) + "-" + this.props.strukData[0].datetime.substr(6, 2)}
                    </>
                  )
                  }
                </center>
              </td>
            </tr>
          </tbody>
        </table>
        {/* {this.props.noStruk == "all" ? (
          <> */}
        {this.props.strukData.map((struk, index) => (
          <table width="53%" style={{ color: "black", align: "center", borderLeft: "1px solid black", borderRight: "1px solid black", fontSize: "20px", lineHeight: "30px", marginLeft: "10px" }} key={index}>
            <tbody>
              <tr>
                <td colSpan="3" style={{ fontSize: "20px", fontWeight: "bold" }}>
                  <center style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <br /><p>::{struk.product_type}::<br />
                      {struk.product_type == "PDAM" ? (
                        <>
                          {struk.waktu_lunas.substr(8, 2) + ":" + struk.waktu_lunas.substr(10, 2) + ":" + struk.waktu_lunas.substr(12, 2) + " WIB"}
                        </>
                      ) : (
                        <>
                          {struk.datetime.substr(8, 2) + ":" + struk.datetime.substr(10, 2) + ":" + struk.datetime.substr(12, 2) + " WIB"}
                        </>
                      )
                      }
                    </p>
                  </center>
                </td>
              </tr>
              <ProdukData
                produk={struk.product_id}
                data={struk}
                product_type={struk.product_type}
                adminBank={struk.admin_charge}
                kel_produk={struk.product_name}
              />
            </tbody>
          </table>
        ))}
        {/* </>
        ) : (
          <>
            <table width="53%" style={{ color: "black", align: "center", borderLeft: "1px solid black", borderRight: "1px solid black", fontSize: "20px", lineHeight: "30px", marginLeft: "10px" }}>
              <tbody>
                <tr>
                  <td colSpan="3" style={{ fontSize: "20px", fontWeight: "bold" }}>
                    <center style={{ marginTop: "20px", marginBottom: "10px" }}>
                      {this.props.strukData[this.props.noStruk].product_type}<br />
                      {this.props.strukData[this.props.noStruk].product_type == "PDAM" ? (
                        <>
                          {this.props.strukData[this.props.noStruk].waktu_lunas.substr(0, 4) + "-" + this.props.strukData[this.props.noStruk].waktu_lunas.substr(4, 2) + "-" + this.props.strukData[this.props.noStruk].waktu_lunas.substr(6, 2) + " " + this.props.strukData[this.props.noStruk].waktu_lunas.substr(8, 2) + ":" + this.props.strukData[this.props.noStruk].waktu_lunas.substr(10, 2) + ":" + this.props.strukData[this.props.noStruk].waktu_lunas.substr(12, 2)}
                        </>
                      ) : (
                        <>
                          {this.props.strukData[this.props.noStruk].datetime.substr(0, 4) + "-" + this.props.strukData[this.props.noStruk].datetime.substr(4, 2) + "-" + this.props.strukData[this.props.noStruk].datetime.substr(6, 2) + " " + this.props.strukData[this.props.noStruk].datetime.substr(8, 2) + ":" + this.props.strukData[this.props.noStruk].datetime.substr(10, 2) + ":" + this.props.strukData[this.props.noStruk].datetime.substr(12, 2)}
                        </>
                      )
                      }
                    </center>
                  </td>
                </tr>
                <ProdukData
                  produk={this.props.strukData[this.props.noStruk].product_id}
                  data={this.props.strukData[this.props.noStruk]}
                  product_type={this.props.strukData[this.props.noStruk].product_type}
                  adminBank={this.props.strukData[this.props.noStruk].admin_charge}
                  kel_produk={this.props.strukData[this.props.noStruk].product_name}
                />
              </tbody>
            </table>
          </>
        )} */}

      </div>
    )
  }
}
export default StrukComponent;