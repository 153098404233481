import React, { Component } from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import 'react-table/react-table.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import { Redirect } from 'react-router-dom'
import Swal from 'sweetalert2'
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>
  }
}
class telkom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inquiry: false,
      struk: true,
      loading: true,
      idpel: props.match.params.idpel,
      message: null,
      nominal: null,
      trx_type: "2100",
      product_id: null,
      produk: "",
      list_prod: [],
      token: localStorage.getItem('token'),
      btnInq: true,
      inqRes: [],
      payRes: [],
      isAuth: 0,
      bulanTahun: null,
      defaultIndex: 0,
      produk_multi: props.match.params.produk,
      idpel_multi: props.match.params.idpel,
      list_idpel: JSON.parse(localStorage.getItem("list_impor_idpel")),
      last_idpel: localStorage.getItem("list_impor_idpel") ? JSON.parse(localStorage.getItem("list_impor_idpel")).slice(-1) : '',
    };
  }

  componentDidUpdate() {
    // this.scrollToBottom();
  }
  componentDidMount() {
    let pathUrl = window.location.pathname;
    let urlImpor = pathUrl.split('/');
    // console.log("path", urlImpor)
    if (urlImpor[1] == 'telkom') {
      if (urlImpor[1] != "users" && urlImpor[2] != "transaksi" && urlImpor[3] != "importidpel" && urlImpor[4] != "cektagihan") {
        if (this.state.list_idpel) {
          localStorage.removeItem("list_impor_idpel");
        }
      }
    }
    this.getproduct();
    this.setState({
      struk: false,
      loading: false
    })

    if (this.state.list_idpel && urlImpor[1] != 'telkom') {
      let cekIdpel = this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
      // console.warn("cek", cekIdpel)
      if (cekIdpel.length > 0) {
        this.handleChangeIdpel(this.state.idpel);
      } else {
        Swal.fire({
          title: "Maaf",
          text: "ID pelanggan tidak ada di daftar yang diimpor",
          icon: "info",
          confirmButtonText: "oke",
        });
      }
    }
  }
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }
  ucwords(str) {
    const arrOfWords = str.split(" ");
    const arrOfWordsCased = [];
    for (let i = 0; i < arrOfWords.length; i++) {
      const word = arrOfWords[i];
      arrOfWordsCased.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    }
    return arrOfWordsCased.join(" ");
  }
  getproduct() {
    fetch(`${process.env.REACT_APP_URL}/products?product_category_id=43`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Barier ' + this.state.token,
        'Accept': 'application/json'
      }
    }).then((response) => {
      response.json().then(async (result) => {
        this.setState({ list_prod: result.data })
        if (this.state.idpel_multi && this.state.produk_multi) {
          if (this.state.produk_multi == "telepon") {
            await this.setState({
              defaultIndex: 0,
              produk: result.data[0].name,
              product_id: result.data[0].prod_id,
              produk_multi:"TELEPON RUMAH"
            })
          } else {
            await this.setState({
              defaultIndex: 0,
              produk: result.data[1].name,
              product_id: result.data[1].prod_id,
              produk_multi:"TELKOM SPEEDY"
            });
          }
          // console.warn("default index", this.state.defaultIndex)
        } else {
          if (result.data[0].code !== null) {
            this.setState({
              defaultIndex: 0,
              produk: result.data[0].name,
              product_id: result.data[0].prod_id,
            });
          }
        }
      })
    }).catch(function (err) {
      // console.log(err.response.data)
      Swal.fire({
        title: "Maaf",
        text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
        icon: "error",
        confirmButtonText: "Oke",
      });
      this.setState({ isAuth: 401 });
    }.bind(this));
  }
  setTypeProduct(index) {
    this.setState({
      produk: this.state.list_prod[index].name,
      product_id: this.state.list_prod[index].prod_id,
    });
  }
  handleChangeIdpel(e) {
    let inIdpel = "";
    if (this.state.idpel_multi) {
      inIdpel = this.state.idpel_multi;
    } else {
      inIdpel = e.target.value;
    }
    if (inIdpel.length >= 9 && inIdpel.length <= 13) {
      this.setState({ message: "" });
      this.setState({ idpel: inIdpel });
      this.setState({ loading: false });
    } else {
      this.setState({ message: 'idpel tidak sesuai' });
    }
    // console.log(this.state.idpel)
  }

  currencyFormat(num) {
    return 'Rp ' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  getDate(num) {
    return parseInt(num.toString().substr(4, 2))
  }
  getTahun(num) {
    return parseInt(num.toString().substr(0, 4))
  }

  setNominal(event) {
    alert('keklik')
    console.log(event)
  }

  cekinquiry() {
    this.setState({ loading: true })
    let token = process.env.REACT_APP_TOKEN;
    // console.log(JSON.stringify(this.state))
    fetch(`${process.env.REACT_APP_URL}/loket/transactions`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Barier ' + this.state.token,
        'Accept': 'application/json'
      },
      body: JSON.stringify(this.state)
    }).then((response) => {
      response.json().then(async (result) => {
        // console.warn("result", result);
        if (result.status == "200") {
          if ((this.state.trx_type == '2100') && (result.data.rc == "0000")) {
            await localStorage.setItem('private_inquiry', JSON.stringify({
              data: result.data,
              produk: this.state.produk,
              product_id: this.state.product_id,
              product_nomination: this.state.product_nomination
            }))
            await this.setState({ inquiry: true, inqres: result.data });
            // console.log(this.state.inqres);
            // window.location.reload(false);
          } else {
            await this.setState({ message: result.data.desc });
            await Swal.fire({
              title: 'Maaf',
              text: result.data.desc || "Silahkan dicoba beberapa saat lagi",
              icon: 'error',
              confirmButtonText: 'oke'
            })
          }
        } else if (result.status == "401") {
          await Swal.fire({
            title: "Maaf",
            text: result.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "info",
            confirmButtonText: "Tutup",
          });
          await this.setState({ isAuth: 401 });
        } else {
          await this.setState({ message: result.message.desc });
          await Swal.fire({
            title: 'Maaf',
            text: result.message.desc || "Silahkan dicoba beberapa saat lagi",
            icon: 'info',
            confirmButtonText: 'oke'
          })

          // if (this.state.list_idpel) {
          //   let indexOfIdpel = await this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
          //   if (this.state.last_idpel[0].idpel == this.state.idpel) {
          //     await this.props.history.push("/cart");
          //     let pathUrl = await window.location.href;
          //     window.location.href = await pathUrl;
          //   } else {
          //     let newProduk = await this.state.list_idpel[indexOfIdpel[0].key + 1];

          //     let nextProduk = await newProduk.produk;
          //     let nextIdpel = await newProduk.idpel;
          //     let pathProduk = "";
          //     if (nextProduk.toString().toUpperCase().includes("PREPAID")) {
          //       pathProduk = 'plnprepaid/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("POSTPAID")) {
          //       pathProduk = 'plnpostpaid/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("BPJS")) {
          //       pathProduk = 'bpjs/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("SPEEDY") || nextProduk.toString().toUpperCase().includes("TELEPON")) {
          //       pathProduk = 'telkom/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("PDAM")) {
          //       pathProduk = 'pdam/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("PULSA")) {
          //       pathProduk = 'pulsa/' + nextIdpel
          //     }
          //     if (nextProduk.toString().toUpperCase().includes("DATA")) {
          //       pathProduk = 'data/' + nextIdpel
          //     }

          //     await this.props.history.push(this.props.history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
          //     let pathUrl = await window.location.href;
          //     window.location.href = await pathUrl;
          //   }
          // }

        }
      })
      this.setState({ loading: false })
    }).catch(function (err) {
      // console.log(err.response.data)
      Swal.fire({
        title: "Maaf",
        text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
        icon: "error",
        confirmButtonText: "Oke",
      });
      this.setState({ isAuth: 401 });
    }.bind(this));
  }
  payment() {
    this.setState({
      trx_type: "2200",
    });
    console.log(JSON.stringify(this.state));
    fetch(`${process.env.REACT_APP_URL}/transactions`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
    }).then((response) => {
      response.json().then((result) => {
        console.warn("result", result);
        if (result.status == "200") {
          if (result.data.rc == "0000" && result.data.request_type == "PAYMENT") {
            // alert(result.data.desc);
            this.setState({
              message: result.data.nama_pelanggan + ' tagihan ' + this.currencyFormat(result.data.jumlah_bayar) + ' sukses dibayarkan ',
              btnInq: true,
              trx_type: "2100",
              payRes: result.data,
              struk: true
            });
            // document.getElementById("input-form").reset();
            console.log("state res payment : " + JSON.stringify(this.state))
          } else {
            this.setState({ message: result.data.desc });
          }
        } else {
          this.setState({ message: result.info });
        }
      })
      this.setState({ loading: false })
    })
  }
  handleChange = (value) => {
    this.setState({ value: value });
    // console.log(this.state.value)
    let produk_id = value.value;
    // console.log(produk_id);
    this.setState({ product_id: produk_id })
  };

  render() {
    const btnInq = this.state.btnInq;
    const struk = this.state.struk;
    const { list_prod } = this.state;
    const produxs = this.state.list_prod.map(d => ({
      "value": d.prod_id,
      "label": d.name
    }));

    return (
      <div>
        <CheckSession isAuth={this.state.isAuth} />
        {
          this.state.inquiry ?
            <><Redirect to='/checkoutv1' /></>
            :
            <></>
        }
        <h5 className="text-dark h6">Bayar Tagihan Telkom {this.state.list_idpel ? ' (' + this.state.produk_multi + ') ' : ''}</h5>
        <Tabs defaultIndex={this.state.defaultIndex} onSelect={index => this.setTypeProduct(index)}>
          {!this.state.idpel_multi ? (
            <>
              <TabList>
                {this.state.list_prod.map(function (value, i) {
                  return (<Tab><span className="" style={{ fontSize: "14px" }}>{value.name.toString().toLowerCase().includes("speedy") ? "INDIHOME/SPEEDY" : value.name}</span></Tab>)
                })}
              </TabList>
              <TabPanel>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <h6 className="text-dark">ID Pelanggan </h6>
                      <form id="input-form">
                        <input
                          type="number"
                          className="form-control"
                          defaultValue=""
                          placeholder={"ID Pelanggan " + this.ucwords(this.state.produk.toLowerCase())}
                          onChange={(e) => {
                            this.handleChangeIdpel(e);
                          }}
                        />
                        {
                          this.state.message !== null ? (
                            <div>
                              <label className="text-danger small">{this.state.message}</label>
                            </div>) : (<></>)
                        }
                      </form>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <h6 className="text-dark">ID Pelanggan </h6>
                      <form id="input-form">
                        <input
                          type="number"
                          className="form-control"
                          defaultValue=""
                          placeholder={"ID Pelanggan " + this.ucwords(this.state.produk.toLowerCase())}
                          onChange={(e) => {
                            this.handleChangeIdpel(e);
                          }}
                        />
                        {
                          this.state.message !== null ? (
                            <div>
                              <label className="text-danger small">{this.state.message}</label>
                            </div>) : (<></>)
                        }
                      </form>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </>
          ) : (<></>)}

          {this.state.defaultIndex == 0 ? (
            <>
              <TabPanel>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <h6 className="text-dark">ID Pelanggan </h6>
                      <form id="input-form">
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.idpel}
                          disabled={this.state.list_idpel ? true : false}
                          placeholder={"ID Pelanggan " + this.ucwords(this.state.produk.toLowerCase())}
                          onChange={(e) => {
                            this.handleChangeIdpel(e);
                          }}
                        />
                        {
                          this.state.message !== null ? (
                            <div>
                              <label className="text-danger small">{this.state.message}</label>
                            </div>) : (<></>)
                        }
                      </form>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanel>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <h6 className="text-dark">ID Pelanggan </h6>
                      <form id="input-form">
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.idpel}
                          disabled={this.state.list_idpel ? true : false}
                          placeholder={"ID Pelanggan " + this.ucwords(this.state.produk.toLowerCase())}
                          onChange={(e) => {
                            this.handleChangeIdpel(e);
                          }}
                        />
                        {
                          this.state.message !== null ? (
                            <div>
                              <label className="text-danger small">{this.state.message}</label>
                            </div>) : (<></>)
                        }
                      </form>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </>)}
        </Tabs>
        <div className="row mt-3">
          <div className="col-md-4 ml-auto hidden-onphone">
            <LaddaButton
              loading={this.state.loading}
              disabled={!this.state.idpel}
              onClick={() => {
                this.cekinquiry();
              }}
              data-color="#008aff"
              data-size={l}
              className="m-btn m-btn-theme m-btn-radius w-100"
              data-style={EXPAND_RIGHT}
            >
              Cek Tagihan
            </LaddaButton>
          </div>
        </div>
        <div className="show-onphone">
          <div className="row">
            <div className="col-md-12">
              <LaddaButton
                loading={this.state.loading}
                disabled={!this.state.idpel}
                onClick={() => {
                  this.cekinquiry();
                }}
                data-color="#008aff"
                data-size={l}
                className="m-btn m-btn-theme m-btn-radius w-100"
                data-style={EXPAND_RIGHT}
              >
                Cek Tagihan
              </LaddaButton>
            </div>
          </div>
        </div>


      </div>
    )
  }
}

export default telkom;