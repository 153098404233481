import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../router/Router";

function LoginPage(props) {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [chaptha, setChaptha] = useState(false);
  const [registers, setRegisters] = useState(false);
  const recaptchaRef = React.createRef();

  const { dispatch, state } = useContext(AuthContext);

  // console.log(state.isAuthenticated);
  if (state.isAuthenticated) {
    return <Redirect to="/" />;
  }

  const onSubmit = (data, e) => {
    if (chaptha) {
      setMessage({
        data: "Login is in progress...",
        type: "alert-warning",
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + process.env.REACT_APP_TOKEN,
          Accept: "application/json",
        },
      };
      Axios.post(
        `${process.env.REACT_APP_URL}/login`,
        JSON.stringify(data),
        config
      )
        .then((res) => {
          console.log(res.data);
          if (res.status == 200) {
            if (res.data.data.user_roles === 2) {
              setMessage({
                data: "Terima kasih sudah menggunakan website loket pelangi.\n Saat ini website sedang dalam pemeliharaan. Silahkan gunakan aplikasi android untuk bertransaksi",
                type: "alert-info",
              });
            } else {
              setMessage({
                data: res.data.message,
                type: "alert-success",
              });

              dispatch({
                type: "LOGIN",
                payload: res.data.data,
              });
            }
          } else {
            setMessage({
              data: res.message,
              type: "alert-danger",
            });
          }
          !res.data.status && e.target.reset();
          window.grecaptcha.reset();
        })
        .catch((err) => {
          if (err.response && err.response.data.status == 500) {
            setMessage({
              data: "Maaf, website belum dapat diakses. Sedang dilakukan maintenance. Coba beberapa saat lagi",
              type: "alert-danger",
            });
          } else {
            setMessage({
              data: "login Gagal periksa username / password",
              type: "alert-danger",
            });
          }
          // !res.data.status && e.target.reset();
          window.grecaptcha.reset();
        });
    } else {
      setMessage({
        data: "Chek Rechaptha",
        type: "alert-warning",
      });
    }
  };

  const onChange = (value) => {
    if (value) {
      setChaptha(true);
    }

    // console.log(chaptha);
  };

  return (
    <div className="bg-primary-admin">
      <div className="container">
        <div className="row vh-100">
          <div className="col-md-5 m-auto">
            <div className="card shadow">
              <div className="card-body">
                <div className="text-center">
                  <h4>Loket Pelangi</h4>
                  <p className="px-2">Masuk menggunakan akun loket</p>
                  {message && (
                    <div className={`alert mt-2 ${message.type}`} role="alert">
                      {message.data}
                      <span
                        aria-hidden="true"
                        className="ml-auto cursor-pointer"
                        onClick={() => setMessage(null)}
                      >
                        &times;
                      </span>
                    </div>
                  )}
                </div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  autoComplete="off"
                >
                  <div class="input-group col-12 m-auto">
                    <div class="input-group-prepend">
                      <div class="input-group-text" id="btnGroupAddon2">
                        <i className="fa fa-user text-secondary"></i>
                      </div>
                    </div>
                    <input
                      id="inputForName"
                      name="username"
                      type="text"
                      className="form-control"
                      aria-describedby="Nama Pengguna"
                      placeholder="Nama Pengguna"
                      ref={register({
                        required: {
                          value: true,
                          message: "Please enter username",
                        },
                        // pattern: {
                        //   value: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
                        //   message: "Please a valid username without space ",
                        // },
                        minLength: {
                          value: 5,
                          message: "Minimum 5 characters are allowed",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maximum 50 characters are allowed",
                        },
                      })}
                    />
                  </div>
                  <div className="row">
                    <div className="col-11 m-auto">
                      {errors.username && (
                        <span className="text-danger text-center">
                          {errors.username.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <div class="input-group col-12 m-auto">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="btnGroupAddon2">
                          <i className="fa fa-lock text-secondary"></i>
                        </div>
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        id="inputForPassword"
                        placeholder="Kata Sandi"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter password",
                          },
                          maxLength: {
                            value: 62,
                            message: "Maximum 62 characters are allowed",
                          },
                        })}
                      />
                    </div>
                    <div className="row">
                      <div className="col-11 m-auto">
                        {errors.password && (
                          <span className="text-center text-danger">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={() => setShowPassword(!showPassword)}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          {showPassword ? "Sembunyikan" : "Tampilkan"} kata sandi
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 mb-4 text-center d-flex">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LfHMCEaAAAAAKjWZtDNjzWaaClaj1G2KHyAD1iA"
                      onChange={onChange}
                      className="m-auto"
                    />
                  </div>
                  <div className="col-12 m-auto">
                    <button
                      type="submit"
                      className="btn btn-block  btn-primary"
                    >
                      Masuk <i className="fa fa-chevron-circle-right ml-2"></i>
                    </button>
                  </div>

                  <div className="text-center mt-3">
                    <label
                      htmlFor="copiright"
                      className="small text-mute m-auto"
                    >
                      © PT. Pelangi Indodata 2020
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
