import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { CartContext } from "../../router/Router";
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda'

const createHistory = require("history").createBrowserHistory;

function IdpelFavorit({ children }) {
  let history = createHistory();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const { value, setValue } = useContext(CartContext)
  const [isAuth, setAuth] = useState(0);
  const [idpelFav, setIdpelFav] = useState([])
  const [favToMulti, setFavToMulti] = useState([]);

  const fetchIdpelFav = async () => {
    setLoading(true)
    await fetch(`${process.env.REACT_APP_URL}/favorite/list`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then(async (result) => {
        await setIdpelFav(result.data)
        // await console.warn("set favorite", result.data);
        await setLoading(false);
      });
    });
  };
  const removeFavorite = async (id, idpel) => {
    await axios.delete(
      `${process.env.REACT_APP_URL}/favorite/delete/` + id,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    ).then(async (json) => {
      if (json.data.status == 200) {
        await Swal.fire({
          position: 'top-end',
          // icon: 'success',
          html: '<i class="fa fa-check-circle text-success"></i> Idpel ' + idpel + ' berhasil dihapus dari favorite',
          showConfirmButton: false,
          timer: 1300
        })
        await fetchIdpelFav();
      } else {
        Swal.fire({
          title: "Sukses",
          text: json.data.message,
          icon: "error",
          confirmButtonText: "Tutup",
        });
      }
    });

  }

  useEffect(() => {
    localStorage.removeItem("list_impor_idpel");
    fetchIdpelFav();
  }, []);

  const setListIdpel = async (e) => {
    let splitName = e.target.name.split('-');
    let idpel = splitName[0];
    let produk = splitName[1];
    let checked = e.target.checked;
    let list_idpel = localStorage.getItem("list_impor_idpel") ? JSON.parse(localStorage.getItem("list_impor_idpel")) : [];
    let count = list_idpel.length;
    if (checked) {
      let newList = [];
      newList = {
        idpel: idpel.trim(),
        key: count,
        produk: produk.trim()
      }
      list_idpel.push(newList)
      localStorage.setItem("list_impor_idpel", JSON.stringify(list_idpel));

    } else {
      let cekIdpel = await list_idpel.filter(p => p.idpel == idpel.trim() && p.produk == produk.trim());
      // await console.log("hasil cek" + JSON.stringify(cekIdpel))
      if (cekIdpel) {
        await list_idpel.splice(cekIdpel[0].key, 1)
        // await console.log("after splice " + JSON.stringify(list_idpel))
        if (list_idpel.length == 0) {
          list_idpel = []
        } else {
          for (let i = 0; i < list_idpel.length; i++) {
            if (list_idpel[i].idpel != cekIdpel[0].idpel && list_idpel[i].produk != cekIdpel[0].produk) {
              list_idpel[i] = await {
                idpel: list_idpel[i].idpel,
                key: i,
                produk: list_idpel[i].produk
              }
            } else {
              continue;
            }
          }
        }
        // await console.log("after for " + JSON.stringify(list_idpel))
        await localStorage.setItem("list_impor_idpel", JSON.stringify(list_idpel));
      }
    }
    setFavToMulti(list_idpel);
  }
  const nextProcess = async () => {
    await setLoading(true)
    // await fetchCart();
    if (value.length > 0) {
      await deleteItemCart();
    }
    let produkke1 = favToMulti[0].produk;
    let idpelke1 = favToMulti[0].idpel;
    let pathProduk = "";
    if (produkke1.toString().toUpperCase().includes("PREPAID")) {
      pathProduk = 'plnprepaid/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("POSTPAID")) {
      pathProduk = 'plnpostpaid/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("BPJS")) {
      pathProduk = 'bpjs/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("TELEPON")) {
      pathProduk = 'telepon/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("SPEEDY")) {
      pathProduk = 'speedy/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("PDAM")) {
      pathProduk = 'pdam/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("PULSA")) {
      pathProduk = 'pulsa/' + idpelke1
    }
    if (produkke1.toString().toUpperCase().includes("DATA")) {
      pathProduk = 'data/' + idpelke1
    }

    history.push(history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
    let pathUrl = window.location.href;
    window.location.href = pathUrl;
    await setLoading(false)
  }
  const deleteItemCart = async () => {
    let url = `${process.env.REACT_APP_URL}/cart/all/delete`;
    await axios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
      });
  };

  return (
    <div>
      <section className="section g-bg-gray p-0px-t bg-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow mt-2">
                <div className="card-header bg-light d-flex justify-content-between">
                  <table>
                    <tr>
                      <td rowspan="2"><i className="fa fa-star text-primary h1 mr-2"></i></td>
                      <td>
                        <span className="font-weight-bold text-primary">ID Pelanggan Favorite</span> <br />
                        Daftar ID pelanggan favorite
                      </td>
                    </tr>
                  </table>

                </div>
                <div className="card-body table-responsive">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th className="text-center" width="5%">#</th>
                        <th>PRODUK</th>
                        <th>IDPEL</th>
                        <th className="text-center">OPSI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {idpelFav.length > 0 ? (
                        <>
                          {idpelFav.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-center">
                                  <i className="fa fa-star text-warning ml-2" title="Hapus favorite" onClick={() => removeFavorite(value.id, value.idpel)}></i>
                                </td>
                                <td>{value.name.toUpperCase()}</td>
                                <td>{value.idpel}</td>
                                <td className="text-center">
                                  <input type="checkbox" name={value.idpel + "-" + value.name.toUpperCase()} className="form-check-input" onChange={(e) => setListIdpel(e)} />
                                </td>
                              </tr>
                            )
                          })}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">Belum ada daftar favorit yang dibuat</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {idpelFav.length > 0 ? (
                    <p>
                      <small>* Klik tanda bintang untuk menghapus dari daftar id pelanggan favorit</small><br />
                      <small>* Ceklis produk yang dipilih untuk melanjutkan transaksi</small>
                    </p>
                  ) : (<></>)}
                
                {idpelFav.length > 0 ? (
                  <div className="row">
                    <div className="col-md-3 col-xs-12 ml-auto">
                      <LaddaButton
                        loading={loading}
                        disabled={!(favToMulti.length > 0)}
                        onClick={() => nextProcess()}
                        data-color="#008aff"
                        data-size={l}
                        className="btn btn-primary btn-block"
                        data-style={EXPAND_RIGHT}
                      >
                        Lanjut Transaksi
                      </LaddaButton>
                    </div>
                  </div>
                ) : (<></>)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
      <div className="mains">{children}</div>
    </div>
  );
}

export default IdpelFavorit;
