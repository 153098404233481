import React from 'react';
import NumberFormat from 'react-number-format';

const Parsetanggal = ({ periode }) => {
    if (periode == '01') {
        return 'JANUARI';
    }else if (periode == '02') {
        return 'FEBRUARY';
    }else if (periode == '03') {
        return 'MARET';
    }else if (periode == '04') {
        return 'APRIL';
    }else if (periode == '05') {
        return 'MEI';
    }else if (periode == '06') {
        return 'JUNI';
    }else if (periode == '07') {
        return 'JULI';
    }else if (periode == '08') {
        return 'AGUSTUS';
    }else if (periode == '09') {
        return 'SEPTEMBER';
    }else if (periode == '10') {
        return 'OKTOBER';
    }else if (periode == '11') {
        return 'NOVEMBER';
    }else if (periode == '12') {
        return 'DESEMBER';
    }
}
var moment = require('moment');
export const BpjsInq = (props) => (
<>
<tr className="table-borderless">
    <td>NOMOR VA</td>
    <td className="text-right">{props.struk.no_va}</td>
</tr>
<tr>
    <td>NAMA</td>
    <td className="text-right">{props.struk.name}</td>
</tr>

<tr>
    <td>PERIODE PEMBAYARAN</td>
<td className="text-right">{props.struk.periode} Bulan</td>
</tr>

<tr>
    <td>SISA SEBELUMNYA</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.sisa)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>BIAYA IURAN</td>
    <td className="text-right"><NumberFormat value={props.struk.premi} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>ADMIN BANK</td>
    <td className="text-right"><NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
<tr>
    <td>BIAYA LOKET</td>
    <td className="text-right"><NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

{/* <tr>
    <td>RP BAYAR</td>
    <td className="text-right"><NumberFormat value={props.struk.premi} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr> */}
</>
);