import React from 'react';
import NumberFormat from 'react-number-format';

const NamaBulan = ({data}) => {
    data =  parseInt(data);
    let bulan = ['JANUARI','FEBRUARI','MARET','APRIL','MEI','JUNI','JULI','AGUSTUS','SEPTEMBER','OKTOBER','NOVEMBER','DESEMBER',]
    return bulan[data-1];
}


export const PDAMInq = (props) => (
<>
<tr className="table-borderless">
    <td>ID PELANGGAN</td>
    <td className="text-right">{props.struk.idpel}</td>
</tr>
<tr>
    <td>NAMA</td>
    <td className="text-right">{props.struk.name}</td>
</tr>

<tr>
    <td>PERIODE</td>
    <td className="text-right">{props.struk.blth.substr(4,2)}/{props.struk.blth.substr(0,4)} - {props.struk.blth.substr(10,2)}/{props.struk.blth.substr(6,4)}</td>
</tr>

<tr>
    <td>RP TAG</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.rp_tag)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>ADMIN BANK</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
<tr>
    <td>BIAYA LOKET</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

{/* <tr>
    <td>TOTAL BAYAR</td>
    <td className="text-right text-danger"><NumberFormat value={parseInt(props.struk.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr> */}

{/* chek apakah tagihan ada lebih dari 1 */}
{ (parseInt(props.struk.bill_count)> 1) ? (
    <tr>
        <td colspan="2">
            <div className=''>
                <div className='my-2 text-center'>Rincian Tagihan</div>
                <div className=''>
                    <table className='table'>
                        {props.struk.bills.map((dt)=>{
                            return (<tr>
                                <td><NamaBulan data={dt.bill_date[0].slice(4)}/>-{dt.bill_date[0].slice(0,4)}</td>
                                <td class='text-right'><NumberFormat value={parseInt(dt.bill_amount[0])} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
                            </tr>);
                        })}
                    </table>
                </div>
            </div>
        </td>
    </tr>
):(
    <></>
)}
</>
);