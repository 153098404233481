import React from 'react';
import NumberFormat from 'react-number-format';
import Moment from "react-moment";

const NamaBulan = ({data}) => {
    data =  parseInt(data);
    let bulan = ['JANUARI','FEBRUARI','MARET','APRIL','MEI','JUNI','JULI','AGUSTUS','SEPTEMBER','OKTOBER','NOVEMBER','DESEMBER',]
    return bulan[data-1];
}


export const PDAM = (props) => (
    <>
        {
            props.type == "print" ? (
                <>
                    <tr>
                        <td style={{borderRight: "1px solid black"}}>
                            {"ID Pelanggan  : " + props.struk.idpel}<br />
                            {"Nama          : " + props.struk.name}<br />
                            {"BL/TH         : " + props.struk.blth}<br />
                            {"No Ref        : " + props.struk.switching_ref}<br />
                            {"Waktu Lunas   : " + props.struk.waktu_lunas.substr(0, 4) + "-" + props.struk.waktu_lunas.substr(4, 2) + "-" + props.struk.waktu_lunas.substr(6, 2) + " " + props.struk.waktu_lunas.substr(8, 2) + ":" + props.struk.waktu_lunas.substr(10, 2) + ":" + props.struk.waktu_lunas.substr(12, 2)}<br />
                            {"Rp Tag        : "}<NumberFormat value={props.struk.rp_tag} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Bank      : "}<NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Loket     : "}<NumberFormat value={props.struk.loket_fee} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Rp Bayar      : "}<NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            <br/>{"Rincian Tagihan  : "}<br />
                            {parseInt(props.struk.bill_count)>1 ? (
                                <>
                                {props.struk.bills.map((dt)=>{
                                 return(
                                    <>
                                    <NamaBulan data={dt.bill_date[0].slice(4)}/>-{dt.bill_date[0].slice(0,4)} : 
                                    <NumberFormat value={parseInt(dt.bill_amount[0])} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <>{value}</>} /><br/>
                                    </>
                                 )})  
                                }
                                </>
                            ):(<></>)}
                        </td>
                    </tr>
                </>
            ) : (
                <>
                    <tr>
                        <td>ID Pelanggan</td>
                        <td width="3%">:</td>
                        <td>{props.struk.idpel}</td>
                    </tr>
                    <tr>
                        <td>Nama</td>
                        <td> : </td>
                        <td>{props.struk.name}</td>
                    </tr>
                    <tr>
                        <td>BL/TH</td>
                        <td> : </td>
                        {/* <td>{props.struk.blth.substr(4,2)}/{props.struk.blth.substr(0,4)} - {props.struk.blth.substr(10,2)}/{props.struk.blth.substr(6,4)}</td> */}
                        <td>{props.struk.blth}</td>
                    </tr>
                    <tr>
                        <td>No Ref</td>
                        <td> : </td>
                        <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>{props.struk.switching_ref}</td>
                    </tr>
                    <tr>
                        <td>Waktu Lunas</td>
                        <td> : </td>
                        <td><Moment format="DD MMM YYYY HH:mm:ss">{props.struk.waktu_lunas.substr(0, 4) + "-" + props.struk.waktu_lunas.substr(4, 2) + "-" + props.struk.waktu_lunas.substr(6, 2) + " " + props.struk.waktu_lunas.substr(8, 2) + ":" + props.struk.waktu_lunas.substr(10, 2) + ":" + props.struk.waktu_lunas.substr(12, 2)}</Moment></td>
                    </tr>
                    <tr>
                        <td>Rp Tag</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.rp_tag)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Bank</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Loket</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Rp Bayar</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>

                    
                    <tr style={{alignItems:'center',textAlign:'center'}}>
                        <td colSpan={3} align="center">Rincian Tagihan</td>
                    </tr>

                    {/* chek apakah tagihan ada lebih dari 1 */}
                    { (parseInt(props.struk.bill_count)> 0) ? (
                        <>
                            {props.struk.bills.map((dt)=>{
                                return (<tr>
                                    <td><NamaBulan data={dt.bill_date[0].slice(4)}/><br/>{dt.bill_date[0].slice(0,4)}</td>
                                    <td> : </td>
                                    <td class='text-left'><NumberFormat value={parseInt(dt.bill_amount[0])} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
                                </tr>);
                            })}
                        </>
                    ):(
                        <></>
                    )}
                </>
            )}
    </>
);