import React from "react";
// import Style from "./Style";

export function FAQ({message,index,toggleFAQ}) {
  return (
    <div 
      className={"message " + (message.open ? 'open' : '')}
      key={index}
      onClick={()=>toggleFAQ(index)}
    >
      <div className="message-pertanyaan">
        {message.pertanyaan}
      </div>
      <div className="message-jawaban">
        {message.jawaban}
      </div>
    </div>
  )
}
export default FAQ;