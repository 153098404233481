import React, { useEffect } from 'react';
import Router from './router/Router';
// import './components/common/style.css';

function App() {

  const logo_url = localStorage.getItem("logo");
  useEffect(() => {
    document.title = localStorage.getItem("tagline") && localStorage.getItem("tagline") != "null" ? localStorage.getItem("tagline").replace(/['"]+/g, '') : 'Loket Pelangi';
    let link = document.querySelector('link[rel="shortcut icon"]') ||
      document.querySelector('link[rel="icon"]');

    if (!link) {
      link = document.createElement('link');
      link.id = 'favicon';
      link.rel = 'shortcut icon';
      document.head.appendChild(link);
    }

    link.href = logo_url && logo_url == "null" ? "/img/loketpelangi.png" : "http://" + logo_url
  }, [])
  return (
    <div>
      <Router />
    </div>
  );
}
export default App;
