import React, { useContext, useEffect, useState } from "react";
import { AuthContext, CartContext } from "../../router/Router";
import axios from "axios";
import NumberFormat from "react-number-format";
import ReactLoading from "react-loading";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Swal from "sweetalert2";
import { Redirect } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import KeyboardEventHandler from "react-keyboard-event-handler";
import CurrencyInput from "react-currency-input-field";

const apiUrl = process.env.REACT_APP_URL;
const createHistory = require("history").createBrowserHistory;
function Cart() {
  let history = createHistory();
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"4%"}
      width={"4%"}
      className="d-flex justify-content-center"
    />
  );
  const { dispatch } = useContext(AuthContext);
  const [dataCart, setDataCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const { state } = useContext(AuthContext);
  const [isAuth, setAuth] = useState(0);
  const token = localStorage.getItem("token");
  const { value, setValue } = useContext(CartContext)
  const [bayar, setBayar] = useState("0");
  const [kembalian, setKembalian] = useState("0");
  const [totalBayar, setTotalBayar] = useState(0);
  const [show, setShow] = useState(false);
  const [metode_bayar, setMetode_bayar] = useState("");
  const [dataPay, setDataPay] = useState([{ "card_id": "" }]);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    await setShow(true);
    await setMetode_bayar("");
  }
  const fetchCart = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/cart/list`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDataCart([...res.data.data]);
        let total = res.data.data.reduce((totalBayar, cart) => totalBayar = totalBayar + parseInt(cart.info_total_bayar), 0)
        setTotalBayar(total);
        // console.warn("res cart", res.data.data);
      }).catch((err) => {
        // console.log(err.response.data)
        Swal.fire({
          title: "Maaf",
          text: err.response.data.message || "Silahkan dicoba beberapa saat lagi",
          icon: "error",
          confirmButtonText: "Oke",
        });
        setAuth(err.response.data.status)
      })
  };

  useEffect(() => {
    // setIsLoading(true);
    fetchCart();
    // setIsLoading(false);
  }, []);

  const hit_multipay = async () => {
    Swal.fire({
      title: 'Lanjut Pembayaran ?',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Lanjut",
      cancelButtonText: "Nanti Dulu",
      showLoaderOnConfirm: true,
      preConfirm: async (response) => {
        let url = `${process.env.REACT_APP_URL}/loket/transactions/payment`;
        for (var i = 0; i < dataCart.length; i++) {
          dataPay[i] = await { card_id: dataCart[i].id };
        }
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Barier " + token,
            Accept: "application/json",
          },
          body: JSON.stringify({
            data: dataPay,
            type_payment: metode_bayar,
            uang_bayar: bayar,
            uang_sisa: kembalian
          }),
        }).then(response => {
          return response.json();
        }).catch((error) => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        // console.warn("result", result)
        if (result.value.status == "200") {
          await Swal.fire({
            title: "Pembayaran Berhasil",
            icon: 'success',
            confirmButtonText: 'oke'
          });
          await history.push("/invoice/" + result.value.data[0].order_id);
          let pathUrl = await window.location.href;
          window.location.href = await pathUrl;
        } else {
          Swal.fire({
            title: "Maaf",
            text: result.value.message.data.trx.desc,
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
      }
    })
  }
  const hit_multipay2 = async () => {
    await setIsLoading(true);
    let url = `${process.env.REACT_APP_URL}/loket/transactions/payment`;
    for (var i = 0; i < dataCart.length; i++) {
      dataPay[i] = { card_id: dataCart[i].id };
    }
    let data_payment = { data: dataPay };
    // console.warn('data pay', data_payment);
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + token,
        Accept: "application/json",
      },
      body: JSON.stringify({
        data: dataPay,
        type_payment: metode_bayar,
        uang_bayar: bayar,
        uang_sisa: kembalian
      }),
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        // console.warn("resp pay", result.data[0].order_id)
        if (result.status == "200") {
          history.push("/invoice/" + result.data[0].order_id);
          deleteItemCart(0, "all");
          Swal.fire({
            title: "Success",
            text: result.message,
            icon: "success",
            confirmButtonText: "Oke",
          }).then((result) => {
            if (result.value) {
              let pathUrl = window.location.href;
              window.location.href = pathUrl;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              //cencle
            }
          });
        } else {
          Swal.fire({
            title: "Maaf",
            text: result.message[0].msg || result.message || "Silahkan dicoba beberapa saat lagi",
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
      });
    }).catch((err) => {
      // console.log(err.response.data)
      Swal.fire({
        title: "Maaf",
        text: err.response.data.message || "Silahkan dicoba beberapa saat lagi",
        icon: "error",
        confirmButtonText: "Oke",
      });
      setAuth(err.response.data.status)
    })
    await setIsLoading(false);
  };
  const confirmDelete = async () => {
    await setIsLoading(true);
    Swal.fire({
      title: "Kosongkan Daftar Tagihan ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Lanjut",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        deleteItemCart(0, "all")
        localStorage.removeItem("list_impor_idpel");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
    await setIsLoading(false);
  };
  const deleteItemCart = async (id_cart, type) => {
    await setIsLoading(true);
    let url = "";
    if (type == "all") {
      url = `${process.env.REACT_APP_URL}/cart/all/delete`;
    } else {
      url = `${process.env.REACT_APP_URL}/cart/delete/` + id_cart;
    }
    await axios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log("res", res);
        // if (res.data.status == 200) {
        setValue(value - 1);
        fetchCart();
        // }
        // console.warn("res cart", res.data.data);
      });
    await setIsLoading(false);
  };
  const Kembalian = async (value) => {
    await setBayar(value);
    await setKembalian(value - totalBayar);
    if (parseInt(value) >= parseInt(totalBayar)) {
      await setBtnDisabled(false)
    } else {
      await setBtnDisabled(true)
    }
  };

  var metode_pembayaran = [
    { name: "Bayar Tunai", prename: "tunai" },
    { name: "Mesin EDC", prename: "edc" },
    { name: "Transfer Bank", prename: "transfer_bank" },
  ];
  if (isAuth === 401) {
    dispatch({
      type: "LOGOUT",
    });
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        xaria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title>
            Pembayaran Multiproduk
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="card border-0">
                <div className="card-body text-center">
                  <NumberFormat
                    value={totalBayar}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                    renderText={(value) => <h1>{value}</h1>}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row mt-2">
                <div className="tab-style-6 p-0 m-0 w-100">
                  <div className="row w-100">
                    <div className="col-md-12 col-12">
                      <ul className="nav nav-fill nav-tabs row m-2 w-100">
                        {metode_pembayaran.map((value, index) => {
                          return (
                            <li
                              className="nav-item col-md-4 col-6 m-auto p-1"
                              key={index}
                              onClick={async (e) => {
                                await setMetode_bayar(value.prename);
                                if (value.prename.toLowerCase() != "tunai") {
                                  await setBtnDisabled(false);
                                } else {
                                  await setBtnDisabled(true);
                                }
                              }}
                            >
                              <a
                                href="#"
                                data-toggle="tab"
                                className="nav-link"
                                style={{ width: "100%" }}
                              >
                                <span>{value.name}</span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {metode_bayar == "tunai" ? (
              <div className="col-md-12">
                <div className="row mt-5">
                  <div className="col-md-6">
                    <div className="card border-0">
                      <div className="mx-1">
                        <label htmlFor="" className="font-weight-bold text-dark">
                          Input Jumlah Uang (Rp)
                        </label>
                        <CurrencyInput
                          id="kembalian"
                          name="kembalian"
                          placeholder="Input Jumlah Uang"
                          // defaultValue={props.value}
                          className="w-100 form-control input-xl font-weight-bold"
                          decimalsLimit={2}
                          onValueChange={async (value) => { await Kembalian(value); }}
                        />
                        {
                          parseInt(kembalian) < 0 ? (
                            <><span className="small text-danger">Nominal uang kurang dari total pembayaran</span></>
                          ) : (<></>)
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="card border-0">
                      <div className="mx-1">
                        <label htmlFor="" className="font-weight-bold text-dark">
                          Kembalian
                        </label>
                        <br />
                        <NumberFormat
                          value={kembalian}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp"}
                          renderText={(value) => <h1>{value}</h1>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div className="col-md-12 p-2 text-right mt-5">
              <div className="row">
                <div className="col-md-12">
                  <LaddaButton
                    loading={isLoading}
                    disabled={btnDisabled}
                    onClick={() => {
                      hit_multipay()
                    }}
                    data-color="#008aff"
                    data-size={l}
                    className="m-btn m-btn-theme m-btn-radius w-100"
                    data-style={EXPAND_RIGHT}
                  >
                    Bayar Sekarang
                  </LaddaButton>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <KeyboardEventHandler handleKeys={["ctrl+b"]} onKeyEvent={handleShow} />
      <section className="section g-bg-gray p-0px-t bg-section">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <div className="card mt-2">
                <div className="card-header">
                  <h5 className="card-title m-auto">Daftar Tagihan</h5>
                </div>
                <div className="card-body table-responsive">
                  <div className="hidden-onphone">
                    <table className="table" width="100%">
                      <thead>
                        <tr>
                          <th>Produk</th>
                          <th>Idpel</th>
                          <th>Tagihan</th>
                          <th>Biaya Admin</th>
                          <th>Biaya Loket</th>
                          <th>Jumlah Bayar</th>
                          <th>Opsi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <Loading type="spinningBubbles" color="#91091e" />
                        ) : (
                          <>
                            {dataCart.length == 0 ? (
                              <tr>
                                <th colSpan="7" className="text-center">Anda belum memiliki daftar tagihan untuk dibayar. Silahkan masuk kehalaman pembayaran <br /><a href="/pln" className="btn btn-sm btn-success mt-2">Halaman Pembayaran</a></th>
                              </tr>
                            ) : (
                              <>
                                {dataCart.map((cart, index) => (
                                  <tr key={index}>
                                    <td>{cart.name.toUpperCase()}</td>
                                    <td>{cart.idpel}</td>
                                    <td>
                                      <NumberFormat
                                        value={cart.info_rp_tag}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rp"}
                                        renderText={(value) => value}
                                      />
                                    </td>
                                    <td>
                                      <NumberFormat
                                        value={cart.info_admin}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rp"}
                                        renderText={(value) => value}
                                      />
                                    </td>
                                    <td>
                                      <NumberFormat
                                        value={cart.loket_fee}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rp"}
                                        renderText={(value) => value}
                                      />
                                    </td>
                                    <td>
                                      <NumberFormat
                                        value={cart.info_total_bayar}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rp"}
                                        renderText={(value) => value}
                                      />
                                    </td>
                                    <td>
                                      <button className="btn btn-sm btn-danger" onClick={() => deleteItemCart(cart.id, "single")}><i className="fa fa-trash"></i></button>
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <th colSpan="5" className="text-right">TOTAL BAYAR</th>
                                  <td className="font-weight-bold">
                                    <NumberFormat
                                      value={totalBayar}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"Rp"}
                                      renderText={(value) => value}
                                    /></td>
                                </tr>
                              </>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="show-onphone">
                    <table className="table table-borderless">
                      <tbody>
                        {isLoading ? (
                          <Loading type="spinningBubbles" color="#91091e" />
                        ) : (
                          <>
                            {dataCart.length == 0 ? (
                              <tr>
                                <th colSpan="2" className="text-center">Anda belum memiliki daftar tagihan untuk dibayar. Silahkan masuk kehalaman pembayaran <br /><a href="/pln" className="btn btn-sm btn-success mt-2">Halaman Pembayaran</a></th>
                              </tr>
                            ) : (
                              <>
                                {dataCart.map((cart, index) => (
                                  <>
                                    <tr key={index}>
                                      <th colSpan="2">
                                        <div className="row">
                                          <div className="col-9">
                                            {cart.name.toUpperCase()}
                                          </div>
                                          <div className="col-3 float-right">
                                            <button className="btn btn-sm btn-outline-danger btn-block col-12" onClick={() => deleteItemCart(cart.id, "single")}><i className="fa fa-trash"></i></button>
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr style={{lineHeight:"15px"}}>
                                      <td>Idpel</td>
                                      <td className="text-right">{cart.idpel}</td>
                                    </tr>
                                    <tr style={{lineHeight:"15px"}}>
                                      <td>Tagihan</td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={cart.info_rp_tag}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"Rp"}
                                          renderText={(value) => value} />
                                      </td>
                                    </tr>
                                    <tr style={{lineHeight:"15px"}}>
                                      <td>Biaya Admin</td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={cart.info_admin}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"Rp"}
                                          renderText={(value) => value} />
                                      </td>
                                    </tr>
                                    <tr style={{lineHeight:"15px"}}>
                                      <td>Biaya Loket</td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={cart.loket_fee}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"Rp"}
                                          renderText={(value) => value} />
                                      </td>
                                    </tr>
                                    <tr style={{lineHeight:"15px"}}>
                                      <td>Jumlah Bayar</td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={cart.info_total_bayar}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"Rp"}
                                          renderText={(value) => value} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2"><hr /></td>
                                    </tr>
                                  </>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {dataCart.length != 0 ? (
                    <>
                      <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-3 hidden-onphone">
                          <button className="btn btn-outline-primary btn-block" onClick={() => confirmDelete()}>Kosongkan Tagihan</button>
                        </div>
                        <div className="col-md-3 hidden-onphone">
                          <button className="btn btn-primary btn-block" onClick={handleShow}>Lanjut Pembayaran</button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-11 show-onphone mb-5 m-auto">
                          <button className="btn btn-outline-primary btn-block" onClick={() => confirmDelete()}>Kosongkan Tagihan</button>
                        </div>
                      </div>
                    </>
                  ) : (<></>)}
                </div>
              </div>
              {dataCart.length != 0 ? (
                <div className="show-onphone">
                  <div className="card mt-2 mb-5">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-8">
                          <span className="">Total Bayar</span><br />
                          <span className="h6">
                            <NumberFormat
                              value={totalBayar}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp"}
                              renderText={(value) => value}
                            />
                          </span>
                        </div>
                        <div className="col-4 m-auto">
                          <button className="btn btn-primary btn-block" onClick={handleShow}>Bayar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (<></>)}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Cart;
