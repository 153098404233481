import React, { PureComponent } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import ReactLoading from 'react-loading';
import LaddaButton from "react-ladda";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { FaSearch } from "react-icons/fa";


const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);


class BiayaLoket extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      dataProduk: [],
      biaya_loket: 0,
      loading: false,
      login: false,
      token: localStorage.getItem("token"),
      store: null,
      setIsOpen: false,
      toogleBtn: true,
      searchInput: "",
      filteredData: [],
      errValidate: []
    };
  }

  showModal = async (data) => {
    await this.setState({
      id: data.product_category_id,
      biaya_loket: data.loket_fee,
      errValidate: []
    });
    await this.setState({ setIsOpen: true });
    // await console.warn("id produk",data)
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  componentDidMount = async () => {
    await this.setState({ loading: true });
    await this.fetchProduk();
    await this.setState({ loading: false });
  }

  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  fetchProduk = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/loket/setting`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      await response.json().then(async (result) => {
        await this.setState({ filteredData: result.data, dataProduk: result.data });
      });
    });
  };
  filterData = async (keyword) => {
    await this.setState({ searchInput: keyword });
    if (this.state.searchInput !== "") {
      let data = await this.state.dataProduk.filter((value) => {
        if (value.product_type == null) {
          value.product_type = "";
        }
        if (value.product_category_id == null) {
          value.product_category_id = "";
        }
        if (value.loket_fee == null) {
          value.loket_fee = "";
        }
        return (
          value.product_type.toString().toLowerCase().includes(this.state.searchInput.toString().toLowerCase()) ||
          value.product_category_id.toString().toLowerCase().includes(this.state.searchInput.toString().toLowerCase()) ||
          value.loket_fee.toString().toLowerCase().includes(this.state.searchInput.toString().toLowerCase())
        );
      });
      await this.setState({ filteredData: data });
      // console.warn("data", data);
    } else {
      await this.setState({ filteredData: this.state.dataProduk });
    }
  }
  updateBiayaLoket = async () => {
    let biaya_loket = await parseInt(this.state.biaya_loket);

    if (biaya_loket < 0 || biaya_loket > 10000) {
      Swal.fire({
        title: "Info",
        text: "Biaya loket 1000 s/d 10.000",
        icon: "info",
        confirmButtonText: "Tutup",
      });
    } else {
      await this.setState({ loading: true });
      await axios
        .put(
          `${process.env.REACT_APP_URL}/loket/setting/update/` + this.state.id,
          {
            loket_fee: this.state.biaya_loket,
          },
          {
            headers: {
              Authorization: "Bearer " + this.state.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (json) => {
          if (json.data.status == 200) {
            await Swal.fire({
              title: "Sukses",
              text: json.data.message,
              icon: "success",
              confirmButtonText: "Tutup",
            });
          } else {
            await Swal.fire({
              title: "Sukses",
              text: json.data.message,
              icon: "error",
              confirmButtonText: "Tutup",
            });
          }
          // console.warn("response", json);
          await this.fetchProduk();
          await this.filterData(this.state.searchInput);
          await this.setState({ loading: false, setIsOpen: false, toogleBtn: true });
        }).catch(async (err) => {
          // console.warn("error", err.response.data.message);
          if (err.response.data.status == 402) {
            await this.setState({ errValidate: err.response.data.message });
            await this.setState({ loading: false });
          } else {
            await Swal.fire({
              title: "Error",
              text: err.message,
              icon: "error",
              confirmButtonText: "Tutup",
            });
            await this.setState({ loading: false, setIsOpen: false });
          }
        });
    }
  };
  render() {
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>ID Kategori</div>
        ),
        accessor: 'product_category_id',
        style: {
          textAlign: 'center',
          textTransform: 'uppercase',
          flexDirection: 'column',
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Produk</div>
        ),
        accessor: 'product_type',
        style: {
          textAlign: 'left',
          flexDirection: 'column',
          justifyContent: 'center'
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Biaya Loket</div>
        ),
        accessor: "loket_fee",
        style: {
          textAlign: "right",
          flexDirection: "column",
        },
        Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(props.value)
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
        ),
        accessor: "id",
        width: 100,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => this.showModal(row.original)}
            >
              EDIT
            </button>
            {/* &nbsp;
            <button
              onClick={() => this.confirmDelete(row.original.id)}
              className="btn btn-flat btn-sm btn-danger"
            >
              Hapus
            </button> */}
          </div>
        ),
      },
    ];
    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2 mb-5">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-store text-primary h3 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold">SETTING BIAYA LAYANAN LOKET</span> <br />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    {this.state.loading ? (
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="text-center"
                      />
                    ) : (
                      <>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span
                              class="input-group-text bg-light"
                              id="inputGroup-sizing-default"
                            >
                              <FaSearch />
                            </span>
                          </div>
                          <input
                            type="text"
                            name="keyword"
                            value={this.state.searchInput}
                            onChange={(e) => this.filterData(e.target.value)}
                            className="form-control"
                            aria-describedby="inputGroup-sizing-default"
                            placeholder="masukkan kata kunci"
                          />
                        </div>
                        <ReactTable
                          data={this.state.filteredData}
                          defaultPageSize={this.state.filteredData.length > 10 ? 10 : this.state.filteredData.length}
                          columns={columns}
                          className="mt-3 table-striped w-100"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()} scrollable={true} >
          <Modal.Header>
            <Modal.Title>Edit Biaya Loket</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>
                Biaya Loket (Rp) <sup className="text-danger">*</sup>
              </label>
              <input
                type="number"
                name="biaya_loket"
                id="biaya_loket"
                className="form-control"
                placeholder="Min 1000 maks 10000"
                required="required"
                value={this.state.biaya_loket}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              {this.state.errValidate.length != 0 ? (
                <div className="alert alert-danger">
                  <p className="font-weight-bold">Periksa Form Input Berikut</p>
                  {this.state.errValidate.map((item) =>
                    <li className="small">{item.param + " : " + item.msg}</li>
                  )}
                </div>
              ) : (<></>)}
            </div>
            <input
              type="hidden"
              name="role"
              value={this.state.role}
              onChange={this.handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Batal
            </button>
            <LaddaButton
              loading={this.state.loading}
              onClick={() => {
                this.updateBiayaLoket();
              }}
              className="btn btn-primary"
            >
              Simpan
            </LaddaButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default BiayaLoket;