import React from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';

const Parsetanggal = ({ periode }) => {
    if (periode == '01') {
        return 'JANUARI';
    } else if (periode == '02') {
        return 'FEBRUARY';
    } else if (periode == '03') {
        return 'MARET';
    } else if (periode == '04') {
        return 'APRIL';
    } else if (periode == '05') {
        return 'MEI';
    } else if (periode == '06') {
        return 'JUNI';
    } else if (periode == '07') {
        return 'JULI';
    } else if (periode == '08') {
        return 'AGUSTUS';
    } else if (periode == '09') {
        return 'SEPTEMBER';
    } else if (periode == '10') {
        return 'OKTOBER';
    } else if (periode == '11') {
        return 'NOVEMBER';
    } else if (periode == '12') {
        return 'DESEMBER';
    }
}
var moment = require('moment');

export const Bpjs = (props) => (
    <>
        {
            props.type == "print" ? (
                <>
                    <tr>
                        <td style={{borderRight: "1px solid black"}}>
                            {"Nomor VA      : " + props.struk.no_va}<br />
                            {"Nama          : " + props.struk.name}<br />
                            {"Periode       : "}{props.struk.periode} Bulan<br />
                            {"No Ref        : " + props.struk.sw_reff}<br />
                            {"Sisa          : "}<NumberFormat value={parseInt(props.struk.sisa)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Waktu Lunas   : "}<Moment format="DD MMM YYYY HH:mm:ss">{props.struk.datetime.substr(0, 4) + "-" + props.struk.datetime.substr(4, 2) + "-" + props.struk.datetime.substr(6, 2) + " " + props.struk.datetime.substr(8, 2) + ":" + props.struk.datetime.substr(10, 2) + ":" + props.struk.datetime.substr(12, 2)}</Moment><br />
                            {"Biaya Iuran   : "}<NumberFormat value={parseFloat(props.struk.premi)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Bank      : "}<NumberFormat value={parseFloat(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Adm Loket     : "}<NumberFormat value={parseFloat(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                            {"Rp Bayar      : "}<NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <>{value}</>} /><br />
                        </td>
                    </tr>
                </>
            ) : (
                <>
                    <tr>
                        <td>Nomor VA</td>
                        <td width="3%"> : </td>
                        <td className="text-left" style={{ maxWidth: "100px" }}><div style={{ wordWrap: "break-word" }}>{props.struk.no_va}</div></td>
                    </tr>
                    <tr>
                        <td>Nama</td>
                        <td> : </td>
                        <td>{props.struk.name}</td>
                    </tr>
                    <tr>
                        <td>Periode</td>
                        <td> : </td>
                        <td>{props.struk.periode} Bulan</td>
                    </tr>
                    <tr>
                        <td>No Ref</td>
                        <td> : </td>
                        <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>{props.struk.sw_reff}</td>
                    </tr>
                    <tr>
                        <td>Sisa</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.sisa)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Waktu Lunas</td>
                        <td> : </td>
                        <td><Moment format="DD MMM YYYY HH:mm:ss">{props.struk.datetime.substr(0, 4) + "-" + props.struk.datetime.substr(4, 2) + "-" + props.struk.datetime.substr(6, 2) + " " + props.struk.datetime.substr(8, 2) + ":" + props.struk.datetime.substr(10, 2) + ":" + props.struk.datetime.substr(12, 2)}</Moment></td>
                    </tr>
                    <tr>
                        <td>Biaya Iuran</td>
                        <td> : </td>
                        <td><NumberFormat value={parseFloat(props.struk.premi)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Bank</td>
                        <td> : </td>
                        <td><NumberFormat value={parseFloat(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Adm Loket</td>
                        <td> : </td>
                        <td><NumberFormat value={parseFloat(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                    <tr>
                        <td>Rp Bayar</td>
                        <td> : </td>
                        <td><NumberFormat value={parseInt(props.struk.amount) + parseInt(props.struk.loket_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} renderText={value => <div>{value}</div>} /></td>
                    </tr>
                </>
            )}
    </>
);