import React, { PureComponent } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReactLoading from "react-loading";
import LaddaButton from "react-ladda";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";

const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
};

const copyToClipboard = (content) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(content);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: `Text ${content} Disalin`,
      showConfirmButton: false,
      timer: 1500,
    });
  } else {
    unsecuredCopyToClipboard(content);
  }
};

const Loading = ({ type, color }) => (
  <ReactLoading
    type={type}
    color={color}
    className="text-center"
    height={"10%"}
    width={"10%"}
  />
);
const optStatus = [
  { value: "", label: "SEMUA" },
  { value: "APPROVE", label: "DISETUJUI" },
  { value: "REJECT", label: "DITOLAK" },
  { value: "WAITING", label: "MENUNGGU" },
  { value: "EXPIRED", label: "KADALUARSA" },
];

class RiwayatTopupSaldo extends PureComponent {
  constructor(props) {
    super(props);
    // this.showNotification = this.showNotification.bind(this);
    this.state = {
      id: 0,
      dataRiwayat: [],
      loading: false,
      btnReset: false,
      token: localStorage.getItem("token"),
      searchInput: "",
      created_at: "",
      filteredData: [],
      errValidate: [],
      img_bukti: "",
      startDate: "",
      endDate: "",
      status: "",
      reqApprove: 0,
      reqReject: 0,
      setIsOpen: false,
    };
    if (!("Notification" in window)) {
      Swal.fire({
        title: "Informasi",
        text: "Browser tidak mendukung notifikasi desktop",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    } else {
      Notification.requestPermission();
    }
    this.fetchRiwayatTopup();
    this.updateTotalTrxAmount();
  }

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };
  updateTotalTrxAmount = async () => {
    let totalApprove = await this.state.filteredData.reduce((prev, current) => {
      if (current.status == "APPROVE") prev += current.nominal;
      return prev;
    }, 0);
    let totalReject = await this.state.filteredData.reduce((prev, current) => {
      if (current.status == "REJECT") prev += current.nominal;
      return prev;
    }, 0);

    await this.setState({ reqApprove: totalApprove });
    await this.setState({ reqReject: totalReject });
  };
  showNotification(total_confirm = 0) {
    var options = {
      body: `Ada ${total_confirm} request topup saldo menunggu konfirmasi dari admin`,
      icon: "/img/loketpelangi.png",
      dir: "ltr",
    };

    new Notification("Request Topup Saldo", options);
  }
  componentDidMount = () => {
    setInterval(async () => {
      await this.fetchRiwayatTopup();
      await this.updateTotalTrxAmount();
    }, 120000);
  };
  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  fetchRiwayatTopup = async () => {
    // await
    await fetch(`${process.env.REACT_APP_URL}/saldo/list`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      await response.json().then(async (result) => {
        await this.setState({
          filteredData: result.data,
          dataRiwayat: result.data,
        });
        let data = await result.data.filter((value) => {
          return (
            value.status.toString().includes("waiting") &&
            value.type_sender.toString().includes("manual") &&
            value.img_bukti_transfer != null
          );
        });
        if (data.length > 0) {
          this.showNotification(data.length);
        }
      });
    });
  };
  filterData = async () => {
    await this.setState({ loading: true });
    if (this.state.startDate == "" || this.state.endDate == "") {
      Swal.fire({
        title: "Maaf",
        text: "Lengkapi filter tanggal",
        icon: "info",
        confirmButtonText: "oke",
      });
      // console.warn('status', this.state.status)
    } else {
      let url =
        process.env.REACT_APP_URL +
        "/saldo/list?start_date=" +
        this.state.startDate +
        "&end_date=" +
        this.state.endDate;

      if (this.state.status != "") {
        url += "&status_saldo=" + this.state.status;
      }

      await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Barier " + this.state.token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then(async (response) => {
        await response.json().then(async (result) => {
          await this.setState({
            filteredData: result.data,
            dataRiwayat: result.data,
          });
        });
      });
    }
    await this.setState({ loading: false });
  };
  resetTable = async () => {
    await this.setState({ btnReset: true });
    await this.fetchRiwayatTopup();
    await this.setState({ btnReset: false });
  };
  search = async (keyword) => {
    await this.setState({ searchInput: keyword });
    if (this.state.searchInput !== "") {
      let data = await this.state.dataRiwayat.filter((value) => {
        if (value.user_id == null) {
          value.user_id = "";
        }
        if (value.name == null) {
          value.name = "";
        }
        if (value.type == null) {
          value.type = "";
        }
        if (value.nominal == null) {
          value.nominal = "";
        }
        if (value.status == null) {
          value.status = "";
        }
        return (
          value.user_id
            .toString()
            .toLowerCase()
            .includes(this.state.searchInput.toString().toLowerCase()) ||
          value.name
            .toString()
            .toLowerCase()
            .includes(this.state.searchInput.toString().toLowerCase()) ||
          value.type
            .toString()
            .toLowerCase()
            .includes(this.state.searchInput.toString().toLowerCase()) ||
          value.nominal
            .toString()
            .toLowerCase()
            .includes(this.state.searchInput.toString().toLowerCase()) ||
          value.status
            .toString()
            .toLowerCase()
            .includes(this.state.searchInput.toString().toLowerCase())
        );
      });
      await this.setState({ filteredData: data });
      // console.warn("data", data);
    } else {
      await this.setState({ filteredData: this.state.dataRiwayat });
    }
  };
  showModal = async (data) => {
    await this.setState({
      setIsOpen: true,
      img_bukti: data.base_url,
      created_at: data.created_at,
    });
    // await console.warn("img", data.img_bukti_transfer)
  };
  prosesRequest = async (paramStatus, paramId) => {
    await this.setState({ loading: true });

    let url = `${process.env.REACT_APP_URL}/saldo/update/status/${paramId}`;

    if (paramStatus == "Approve Manual") {
      url = `${process.env.REACT_APP_URL}/saldo/update/${paramId}/manual`;
      paramStatus = "APPROVE";
    }
    axios
      .put(
        url,
        {
          status: paramStatus,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (json) => {
        if (json.data.status == 200) {
          let textConfirm = "";
          if (paramStatus == "APPROVE") {
            textConfirm = "Request Saldo Disetujui";
          } else {
            textConfirm = "Request Saldo Ditolak";
          }
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: textConfirm,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            title: "Maaf",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
        await this.fetchRiwayatTopup();
        await this.updateTotalTrxAmount();
        await this.setState({ loading: false });
      })
      .catch((err) => {
        if (err.response.data.status == 402) {
          this.setState({ loading: false });
        } else {
          let error = err.message;
          if (err.response) {
            error = err?.response?.data?.message;
          }

          Swal.fire({
            title: "Error",
            text: error,
            icon: "error",
            confirmButtonText: "Tutup",
          });
          this.setState({ loading: false });
          this.fetchRiwayatTopup();
          this.updateTotalTrxAmount();
        }
      });
  };
  handleChangeStatus = (data) => {
    this.setState({ status: data.value });
  };
  verifikasiPin = async (pin, type, id) => {
    await fetch(`${process.env.REACT_APP_URL}/users/pin/${pin}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      await response.json().then(async (result) => {
        if (result.status === 200) {
          if (result?.data?.status === true) {
            this.prosesRequest(type, id);
          } else {
            Swal.fire({
              title: "Proses Gagal",
              text: "PIN Yang Dimasukkan Tidak Sesuai",
              icon: "warning",
            });
          }
        } else {
          Swal.fire({
            title: "Proses Gagal",
            text: "PIN Yang Dimasukkan Tidak Sesuai",
            icon: "warning",
          });
        }
      });
    });
  };
  confirmProcess = async (type, id) => {
    let textConfirm = "";
    if (type == "APPROVE") {
      textConfirm = "Setujui request saldo dan topup saldo akun HTH ?";
    } else if (type == "REJECT") {
      textConfirm = "Tolak Request Saldo?";
    } else {
      textConfirm =
        "Approve manual (Hanya mengubah status topup menjadi disetujui tanpa melakukan topup saldo ke akun HTH)";
    }

    await Swal.fire({
      title: "Konfirmasi",
      text: textConfirm,
      input: "password",
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Proses",
      cancelButtonText: "Batal",
      // inputLabel: 'Masukkan PIN',
      inputPlaceholder: "Masukkan PIN",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
        maxlength: 6,
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.verifikasiPin(result.value, type, id);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };
  render() {
    const tableAdmin = [
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>
            Tanggal Request
          </div>
        ),
        accessor: "created_at",
        minWidth: 150,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            <Moment format="DD MMM YYYY - HH:mm:ss">
              {row.original.created_at}
            </Moment>
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Loket</div>
        ),
        accessor: "name",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.name == row.original.loket_utama ? (
              <>{row.original.name}</>
            ) : (
              <>{row.original.name + " ( " + row.original.loket_utama + " )"}</>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Username</div>
        ),
        accessor: "account_username",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div className="d-flex">
            {row.original.account_username}
            <a
              role={"button"}
              onClick={() => {
                copyToClipboard(row.original.account_username);
              }}
            >
              <i className="far fa-copy text-secondary ml-2"></i>
            </a>
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Nominal</div>
        ),
        accessor: "nominal",
        maxWidth: 200,
        style: {
          textAlign: "right",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (props) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(props.value),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Payment</div>
        ),
        accessor: "payment_method",
        minWidth: 200,
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Status</div>
        ),
        accessor: "status",
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.status == "WAITING" ? (
              <>
                {row.original.type_sender == "manual" ? (
                  <>
                    {row.original.img_bukti_transfer === null ? (
                      <>
                        <span className="badge badge-warning">
                          MENUNGGU BUKTI TRANSFER
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="badge badge-warning">
                          MENUNGGU KONFIRMASI
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <span className="badge badge-warning">PROSES</span>
                  </>
                )}
              </>
            ) : (
              <>
                {row.original.status == "REJECT" ||
                row.original.status == "REJECTED" ? (
                  <>
                    <span className="badge badge-danger">DITOLAK</span>
                  </>
                ) : (
                  <>
                    {row.original.status == "EXPIRED" ||
                    row.original.status == "EXPIRE" ? (
                      <>
                        <span className="badge badge-secondary">
                          KADALUARSA
                        </span>
                      </>
                    ) : (
                      <>
                        {row.original.status == "APPROVED" ||
                        row.original.status == "APPROVE" ? (
                          <>
                            <span className="badge badge-success">
                              DISETUJUI
                            </span>
                          </>
                        ) : (
                          <>N/A</>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>
            Bukti
            <br />
            Transfer
          </div>
        ),
        accessor: "img_bukti_transfer",
        width: 80,
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.type == "PLAFON" ||
            row.original.type_sender == "otomatis" ? (
              <>
                <span className="text-center">N/A</span>
              </>
            ) : (
              <>
                {row.original.img_bukti_transfer != null ? (
                  <>
                    <a href="#" onClick={() => this.showModal(row.original)}>
                      <i className="far fa-window-restore"></i>
                    </a>
                  </>
                ) : (
                  <>
                    <span className="text-center">N/A</span>
                  </>
                )}
              </>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
        ),
        accessor: "id",
        width: 150,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            {row.original.status == "WAITING" ? (
              <>
                {row.original.type_sender === "otomatis" ? (
                  <>
                    <a
                      href="#"
                      className="fa fa-check-circle text-success fa-lg mr-2"
                      onClick={() =>
                        this.confirmProcess("APPROVE", row.original.id)
                      }
                    ></a>
                    <a
                      href="#"
                      className="fa fa-times-circle text-danger fa-lg"
                      onClick={() =>
                        this.confirmProcess("REJECT", row.original.id)
                      }
                    ></a>
                  </>
                ) : (
                  <>
                    {row.original.img_bukti_transfer != null ? (
                      <>
                        <a
                          href="#"
                          className="fa fa-check-circle text-success fa-lg mr-2"
                          onClick={() =>
                            this.confirmProcess("APPROVE", row.original.id)
                          }
                        ></a>
                        <a
                          href="#"
                          className="fa fa-times-circle text-danger fa-lg"
                          onClick={() =>
                            this.confirmProcess("REJECT", row.original.id)
                          }
                        ></a>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {row.original.status == "EXPIRE" ||
                row.original.status == "EXPIRED" ? (
                  <>
                    <a
                      href="#"
                      className="fa fa-check-circle text-success fa-lg mr-2"
                      onClick={() =>
                        this.confirmProcess("APPROVE", row.original.id)
                      }
                    ></a>
                    <a
                      href="#"
                      className="fa fa-times-circle text-danger fa-lg"
                      onClick={() =>
                        this.confirmProcess("REJECT", row.original.id)
                      }
                    ></a>{" "}
                    <a
                      href="#"
                      className="badge badge-warning badge-lg"
                      onClick={() =>
                        this.confirmProcess("Approve Manual", row.original.id)
                      }
                    >
                      Approve Manual
                    </a>
                  </>
                ) : (
                  <>-</>
                )}
              </>
            )}
          </div>
        ),
      },
    ];
    const tablePetugas = [
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>
            Tanggal Request
          </div>
        ),
        accessor: "created_at",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            <Moment format="DD MMM YYYY - HH:mm:ss">
              {row.original.created_at}
            </Moment>
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Loket</div>
        ),
        accessor: "name",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.name == row.original.loket_utama ? (
              <>{row.original.name}</>
            ) : (
              <>{row.original.name + " ( " + row.original.loket_utama + " )"}</>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Jenis Saldo</div>
        ),
        accessor: "type",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Nominal</div>
        ),
        accessor: "nominal",
        maxWidth: 200,
        style: {
          textAlign: "right",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (props) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(props.value),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>
            Bukti Transfer
          </div>
        ),
        accessor: "img_bukti_transfer",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.type == "PLAFON" ? (
              <>
                <span className="text-center">N/A</span>
              </>
            ) : (
              <>
                <a href="#" onClick={() => this.showModal(row.original)}>
                  <i className="far fa-window-restore"></i>
                </a>
              </>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Status</div>
        ),
        accessor: "status",
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.status == "WAITING" ? (
              <>
                <span className="badge badge-warning">MENUNGGU</span>
              </>
            ) : (
              <>
                {row.original.status == "REJECT" ? (
                  <>
                    <span className="badge badge-danger">DITOLAK</span>
                  </>
                ) : (
                  <>
                    <span className="badge badge-success">DISETUJUI</span>
                  </>
                )}
              </>
            )}
          </div>
        ),
      },
    ];
    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mb-3 mt-2">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2">
                            <i className="fa fa-filter text-primary h1 mr-2"></i>
                          </td>
                          <td>
                            <span className="font-weight-bold text-primary">
                              FILTER DATA
                            </span>{" "}
                            <br />
                            Filter data yang akan dicari
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Awal</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ startDate: e.target.value })
                            }
                            value={this.state.startDate}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Akhir</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ endDate: e.target.value })
                            }
                            value={this.state.endDate}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Status</label>
                          <Select
                            name="status"
                            options={optStatus}
                            // value={isProdId}
                            value={optStatus.filter(
                              (obj) => obj.value === this.state.status
                            )}
                            onChange={(value) => this.handleChangeStatus(value)}
                            placeholder={<div>Pilih Status Topup</div>}
                          />
                        </div>
                      </div>
                    </div>
                    {/* </div>
                  <div className="card-footer"> */}
                    <div className="row mt-2">
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-size="s"
                          onClick={() => this.filterData()}
                        >
                          Terapkan Filter
                        </LaddaButton>
                      </div>
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.btnReset}
                          className="btn btn-danger m-btn-radius w-100"
                          data-size="s"
                          onClick={() => this.resetTable()}
                        >
                          Reset Filter
                        </LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2">
                            <i className="fa fa-history text-primary h1 mr-2"></i>
                          </td>
                          <td>
                            <span className="font-weight-bold text-primary">
                              RIWAYAT TOPUP SALDO
                            </span>{" "}
                            <br />
                            Data riwayat request topup saldo
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    {this.state.loading ? (
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="text-center"
                      />
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="alert alert-success border border-success">
                              <div className="card-body px-0 py-0 font-weight-bold">
                                Total Request Disetujui{" "}
                                <span className="float-right">
                                  {" "}
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(this.state.reqApprove)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="alert alert-danger border border-danger">
                              <div className="card-body px-0 py-0 font-weight-bold">
                                Total Request Ditolak{" "}
                                <span className="float-right">
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(this.state.reqReject)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text bg-light"
                              id="inputGroup-sizing-default"
                            >
                              <FaSearch />
                            </span>
                          </div>
                          <input
                            type="text"
                            name="keyword"
                            value={this.state.searchInput}
                            onChange={(e) => this.search(e.target.value)}
                            className="form-control"
                            aria-describedby="inputGroup-sizing-default"
                            placeholder="Ketik kata kunci untuk mencari data ditabel"
                          />
                        </div>
                        <ReactTable
                          data={this.state.filteredData.reverse()}
                          defaultPageSize={
                            this.state.filteredData.length > 10 ? 10 : 10
                          }
                          columns={
                            this.props.state.role == 1
                              ? tableAdmin
                              : tablePetugas
                          }
                          className="mt-3 table-striped w-100"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={this.state.setIsOpen}
          onHide={() => this.hideModal()}
          scrollable={true}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              Bukti Transfer{" "}
              <Moment format="DD MMM YYYY - HH:mm:ss">
                {this.state.created_at}
              </Moment>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              className="img-thumbnail border-0"
              src={this.state.img_bukti}
            />
            {/* {this.state.img_bukti} */}
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default RiwayatTopupSaldo;
