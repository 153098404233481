import React, { PureComponent } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading';
import LaddaButton from "react-ladda";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Select from "react-select";
import * as XLSX from 'xlsx';
import { Form } from 'antd-v3';

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);


class Index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        id: 0,
        dataDenom: [],
        DataProductID: [],
        product_id: "",
        loading: false,
        login: false,
        token: localStorage.getItem("token"),
        store: null,
        setIsOpen: false,
        denom: "",
        description: "",
        active: 1,
        valOptProdID: "",
        toogleBtn: true,
        typeModal: "",
        pertanyaan: "",
        jawaban: "",
        prod_id: "",
        code: "",
        vcode: "",
        name: "",
        description: "",
        product_category_id: "",
        nominal: "",
        status: "",
        disabled: false,
        f_prod_id: "",
        f_name: "",
        f_status: "",
        dataCatProd: [],
        setIsOpenMulti: false,
        selectedFile: null,
        dataMulti: null,
        prodCatMulti: "",
        jsonDataArray: [],
        tableColumns: [],
        fixData: [],
        showButton: false,
        price:null,
        price_id:null,
        dataProduk:[],
        setIsOpenTemplate: false,
        dataProdType: [],
        prodType: "",
        product_type: "",
        prodCatId: "",
        dataCatProd2: [],
        dataGenerate:[],
        showButtonGen: false,
    };
  }
  setActiveDenom = (
    id = null,
    pertanyaan = null,
    jawaban = null,
    active = null
  ) => {
    // console.warn("active", active);
    this.setState({ id: id, pertanyaan: pertanyaan, jawaban: jawaban });
    if (active == 0) {
      this.setState({ active: 1 });
    } else {
      this.setState({ active: 0 });
    }
    // this.updateDenom();

    // console.warn("state", this.state);
  };
  showModal = (type, data) => {
    if (type == "Edit") {
        this.setState({
            prod_id: data.product_id,
            price: data.price,
            description: data.product_name,
            price_id: data.price_id,
            disabled: false,
        });
        if(data.active == 1){
            this.setState({ status: "true"})
        }else{
            this.setState({ status: "false"})
        }
      // console.warn("data",data)
    } else if(type == "View"){
        this.setState({
            prod_id: data.product_id,
            price: data.price,
            description: data.product_name,
            disabled: true,
        });
        if(data.active == 1){
            this.setState({ status: "true"})
        }else{
            this.setState({ status: "false"})
        }
    } else {
      this.setState({
        prod_id: "",
        price: null,
        price_id: null,
        description: "",
        disabled: false,
      });
    }
    this.setState({ setIsOpen: true, typeModal: type });
    
    // console.log(data)
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  // start proses upload multiple data
  showModalMulti = () => {
    this.setState({
      setIsOpenMulti: false,
      selectedFile: null,
      dataMulti: null,
      prodCatMulti: "",
      jsonDataArray: [],
      tableColumns: [],
      fixData: [],
      disabled: false,
      showButton: false,
    });
    this.setState({ setIsOpenMulti: true });
  };

  hideModalMulti = () => {
    this.setState({ setIsOpenMulti: false });
  };

  showModalTemplate = () => {
    this.setState({
      setIsOpenTemplate: false,
      prodType: "",
      product_type: "",
      prodCatId: "",
      dataCatProd2:[],
      dataGenerate:[],
      showButtonGen: false,
    });
    this.setState({ setIsOpenTemplate: true });
    this.hideModalMulti();
  };

  hideModalTemplate = () => {
    this.setState({ setIsOpenTemplate: false });
  };

  handleShowButton = () => {
    this.setState({ showButton: true });
  };

  handleShowButtonGen = () => {
    this.setState({ showButtonGen: true });
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({ selectedFile: file });
  };

  handleFileUpload = (event) => {
    event.preventDefault();
    const { selectedFile } = this.state;
    if (!selectedFile) {
      Swal.fire({
        title: "Error",
        text: "Silakan pilih file Excel terlebih dahulu.",
        icon: "error",
        confirmButtonText: "Tutup",
      });
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData.length === 0) {
        console.log('No data found in the Excel file');
        return;
      }

      const headers = jsonData[0];
      const jsonDataArray = [];

      for (let i = 1; i < jsonData.length; i++) {
        const row = jsonData[i];
        const obj = {};

        for (let j = 0; j < headers.length; j++) {
          const header = headers[j];
          obj[header] = row[j];
        //   obj['product_category_code'] = this.state.prodCatMulti;
        }
        
        jsonDataArray.push(obj);
      }

      let fixData = {
        "data": jsonDataArray
      };

      // console.log(fixData);
      this.setState({ jsonDataArray, fixData });
      this.handleShowButton();
      
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Apa yang akan Anda upload data.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
        .post(
          `${process.env.REACT_APP_URL}/pprice/multiimprice`,
          {
            data: this.state.jsonDataArray
          },
          {
            headers: {
              Authorization: "Bearer " + this.state.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((json) => {
          if (json.data.status == 200) {
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil ditambahkan",
              icon: "success",
              confirmButtonText: "Tutup",
            });
          } else {
            Swal.fire({
              title: "Error",
              icon: "error",
              confirmButtonText: "Tutup",
            });
          }
          this.setState({ loading: false, setIsOpenMulti: false });
          this.fetchDenom();
        })
        .catch((err)=>{
          Swal.fire({
            title: "GAGAL",
            html: err.response.data.message.note+"<br>-"+err.response.data.message.data,
            icon: "error",
            confirmButtonText: "Tutup",
          });
          this.setState({ loading: false });
        })
      } else {

      }
    });
    
  };

  fileInputRef = React.createRef();

  resetMulti =  (event) => {
    event.preventDefault();
    this.setState({
      selectedFile: null,
      dataMulti: null,
      prodCatMulti: "",
      jsonDataArray: [],
      tableColumns: [],
      fixData: [],
      showButton: false,
    });
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = null;
    }
  };
  resetGenerate =  (event) => {
    event.preventDefault();
    this.setState({
      prodType: "",
      product_type: "",
      prodCatId: "",
      dataCatProd2: [],
      dataGenerate:[],
      showButtonGen: false,
    });
  };
  // end proses multiple data

  componentDidMount = async () => {
    await this.setState({ loading: true });
    await this.fetchDenom();
    // await this.fetchCatProd();
    await this.fetchDataProduk();
    await this.fetchProdType();
  };
  handleChange = (e) => {
    // console.log( e);
    this.setState({
      [e.name]: e.value,
    });
  };
  fetchDenom = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/pprice?product_category_id`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ loading: false, dataDenom: result.data });
      });
    });
  };
  fetchDataProduk = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/products`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ loading: false, dataProduk: result.data });
      });
    });
  };
  fetchCatProd = async () => {
    if(this.state.product_type==""){
      this.setState({ loading: false, dataCatProd: [] });
    }else{
      await fetch(`${process.env.REACT_APP_URL}/pCategories/list?status=true&product_type=`+this.state.product_type, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then((result) => {
          // console.warn("result", result);
          this.setState({ loading: false, dataCatProd: result.data });
        });
      });
    }
  };
  fetchCatProd2 = async () => {
    if(this.state.prodType==""){
      this.setState({ loading: false, dataCatProd2: [] });
    }else{
      await fetch(`${process.env.REACT_APP_URL}/pCategories/list?status=true&product_type=`+this.state.prodType, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then((result) => {
          // console.warn("result", result);
          this.setState({ loading: false, dataCatProd2: result.data });
        });
      });
    }
  };
  fetchProdType = async () => {
    await fetch(`${process.env.REACT_APP_URL}/list/producttype`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ loading: false, dataProdType: result.data });
      });
    });
  };
  fetchDataGen = async () => {
    if(this.state.prodType==""){
      Swal.fire({
        title: "Maaf",
        text: "Pilih produk type terlebih dahulu",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    }else{
      await fetch(`${process.env.REACT_APP_URL}/pprice?product_category_id=`+this.state.prodCatId+`&product_type=`+this.state.prodType+`&imports=true`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then((result) => {
          // console.warn("result", result);
          this.setState({ loading: false, dataGenerate: result.data });
          this.handleShowButtonGen();
        });
      });
    }
  };

  filterData = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${process.env.REACT_APP_URL}/pprice?product_category_id=`+this.state.product_category_id+'&product_type='+this.state.product_type,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        },
        
      )
      .then((response) => {
        this.setState({ loading: false, dataDenom: response.data.data });
      });
  };
  reset = async () => {
    this.setState({ product_category_id: ""});
    this.setState({ product_type: ""});
    this.setState({ dataCatProd: [] });
    this.fetchDenom();
  };
  saveDenom = (typemodal) => {
    if (this.state.prod_id == "" || this.state.price == null) {
      Swal.fire({
        title: "Maaf",
        text: "Lengkapi dahulu input yang kosong atau belum dipilih",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    } else {
      if (typemodal == "Tambah") {

        this.createDenom();
      } else {
        this.updateDenom();
      }
    }
  };
  createDenom = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_URL}/pprice/create`,
        {
            product_id: this.state.prod_id,
            product_price: this.state.price,
            // description: this.state.description,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil ditambahkan",
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          Swal.fire({
            title: "Sukses",
            text: json.data.message.msg,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false });
        this.fetchDenom();
      })
      .catch((err)=>{
        if(err.response.status === 400){
            Swal.fire({
                title: "GAGAL",
                text: JSON.stringify(err.response.data.message),
                icon: "error",
                confirmButtonText: "Tutup",
            });
        }else{
            Swal.fire({
                title: "GAGAL",
                text: JSON.stringify(err.response.data.message[0].msg),
                icon: "error",
                confirmButtonText: "Tutup",
            });
        }
        
        this.setState({ loading: false });
      })
    // console.warn("data",this.state);
  };

  updateDenom = () => {
    // this.setState({ loading: true });
    Swal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Apa yang akan Anda update data.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
        .put(
          `${process.env.REACT_APP_URL}/pprice/update/` + this.state.price_id,
          {
              product_id: this.state.prod_id,
              product_price: this.state.price,
              // description: this.state.description,
          },
          {
            headers: {
              Authorization: "Bearer " + this.state.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((json) => {
          if (json.data.status == 200) {
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil diubah",
              icon: "success",
              confirmButtonText: "Tutup",
            });
          } else {
            Swal.fire({
              title: "Sukses",
              text: "Data gagal diubah",
              icon: "error",
              confirmButtonText: "Tutup",
            });
          }
          // console.warn("response", json);
          this.setState({ loading: false, setIsOpen: false, toogleBtn: true });
          this.fetchDenom();
        })
        .catch((err)=>{
          Swal.fire({
              title: "GAGAL",
              text: JSON.stringify(err.response.data.message),
              icon: "error",
              confirmButtonText: "Tutup",
          });
          this.setState({ loading: false });
        })
      } else {

      }
    });
  };

  confirmDelete = (iddenom) => {
    Swal.fire({
      title: "Hapus Data ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      reverseButtons:true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        this.deleteDenom(iddenom);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  deleteDenom = (iddenom) => {
    const { dataDenom } = this.state;

    axios
      .delete(`${process.env.REACT_APP_URL}/pprice/delete/` + iddenom, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then((json) => {
        if (json.data.status == 200) {
          this.setState({
            dataDenom: dataDenom.filter(
              (dataDenom) => dataDenom.id !== iddenom
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil dihapus",
            icon: "success",
            confirmButtonText: "Tutup",
          });
          this.fetchDenom();
        } else {
          this.setState({
            dataDenom: dataDenom.filter(
              (dataDenom) => dataDenom.id !== iddenom
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: "Data gagal dihapus",
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      })
      .catch((err)=>{
        Swal.fire({
            title: "GAGAL",
            text: JSON.stringify(err.response.data.message),
            icon: "error",
            confirmButtonText: "Tutup",
        });
        // this.setState({ loading: false });
      })
  };
  handleChangeProdType = async (data) => {
    await this.setState({ product_type: data.value });
    await this.fetchCatProd();
    // console.log(this.state.status_f)
  };
  handleChangeProdType2 = async (data) => {
    await this.setState({ prodType: data.value });
    await this.fetchCatProd2();
    // console.log(this.state.status_f)
  };
  handleChangeStatus = (data) => {
    this.setState({ product_category_id: data.value });
    // console.log(this.state.status_f)
  };
  handleChangeStatusMulti = (data) => {
    this.setState({ prodCatMulti: data.value });
  }
  handleChangeProdId = (data) => {
    this.setState({ prod_id: data.value });
  }
  handleGenerateExcel = (event) => {
    event.preventDefault();

    Swal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Apa yang akan Anda generate template.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      if (result.isConfirmed) {
        const data = this.state.dataGenerate;

        const modifiedData = data.map(({ price_id, product_id, product_name, product_type, price, product_category_id, product_category_name }) => ({ price_id, product_id, product_name, product_type, product_price: price, product_category_id, product_category_name }));
        const filteredData = modifiedData.map(({ product_id, product_name, product_price}) => ({ product_id, product_name, product_price}));

        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        if(this.state.prodCatId!==''){
          XLSX.writeFile(workbook, 'Produk_Price_'+filteredData[0].product_name+'.xlsx');
        }else{
          XLSX.writeFile(workbook, 'Produk_Price_'+this.state.prodType+'.xlsx');
        }
        
        this.hideModalTemplate();
      } else {

      }
    });

    
  };
  render() {
    const columns = [
        {
            Header: "#",
            maxWidth: 50,
            Cell: (row) => {
            return <div>{row.index + 1}</div>;
            },
            style: {
                textAlign: "center",
                textTransform: "uppercase",
                flexDirection: "column",
                justifyContent: "center",
            },
        },
        {
            Header: () => (
            <div style={{ textAlign: "center", color: "black" }}>PRODUK NAME</div>
            ),
            accessor: "product_name",
            maxWidth: "20%",
            headerStyle: {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
            },
            style: {
                textAlign: "center",
                flexDirection: "column",
                justifyContent: "center",
            },
        },
        {
            Header: () => (
            <div style={{ textAlign: "center", color: "black" }}>PRODUK CATEGORY NAME</div>
            ),
            accessor: "product_category_name",
            maxWidth: "20%",
            style: {
                textAlign: "center",
                flexDirection: "column",
                justifyContent: "center",
            },
        },
        {
            Header: () => (
            <div style={{ textAlign: "center", color: "black" }}>PRICE</div>
            ),
            accessor: "price",
            maxWidth: 180,
            style: {
                textAlign: "right",
                flexDirection: "column",
                justifyContent: "center",
            },
        },
        {
            Header: () => (
            <div style={{ textAlign: "center", color: "black" }}>OPSI</div>
            ),
            accessor: "id",
            maxWidth: 180,
            style: {
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="btn btn-flat btn-sm btn-info"
              onClick={() => this.showModal("View", row.original)}
            >
              View
            </button>
            &nbsp;
            <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => this.showModal("Edit", row.original)}
            >
              Edit
            </button>
            &nbsp;
            <button
              onClick={() => this.confirmDelete(row.original.price_id)}
              className="btn btn-flat btn-sm btn-danger"
            >
              Hapus
            </button>
          </div>
        ),
      },
    ];
    const columnsGenerate = [
      {
          Header: "#",
          maxWidth: 50,
          Cell: (row) => {
          return <div>{row.index + 1}</div>;
          },
          style: {
              textAlign: "center",
              textTransform: "uppercase",
              flexDirection: "column",
              justifyContent: "center",
          },
      },
      {
          Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRODUK NAME</div>
          ),
          accessor: "product_name",
          maxWidth: "20%",
          headerStyle: {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
          },
          style: {
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
          },
      },
      {
          Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRODUK CATEGORY NAME</div>
          ),
          accessor: "product_category_name",
          maxWidth: "20%",
          style: {
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
          },
      },
      {
          Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>PRICE</div>
          ),
          accessor: "price",
          maxWidth: 180,
          style: {
              textAlign: "right",
              flexDirection: "column",
              justifyContent: "center",
          },
      },
    ];
    const tableColumns = [
        {
          Header: () => (
            <div style={{ textAlign: "center", color: "black" }}>PRODUK ID</div>
          ),
          accessor: "product_id",
          maxWidth: "20%",
          style: {
              textAlign: "left",
              flexDirection: "column",
              justifyContent: "center",
            },
        },
        {
          Header: () => (
            <div style={{ textAlign: "center", color: "black" }}>PRODUK NAME</div>
          ),
          accessor: "product_name",
          maxWidth: "20%",
          style: {
              textAlign: "left",
              flexDirection: "column",
              justifyContent: "center",
            },
        },
        {
          Header: () => (
            <div style={{ textAlign: "center", color: "black" }}>PRODUK PRICE</div>
          ),
          accessor: "product_price",
          maxWidth: "20%",
          style: {
              textAlign: "left",
              flexDirection: "column",
              justifyContent: "center",
            },
        },
    ];
    const { showButton } = this.state;
    const { showButtonGen } = this.state;
    const optCatProd = this.state.dataCatProd.map((d) => ({
        value: d.product_category_id,
        label: d.product_category_id + " - " + d.name.toUpperCase(),
    }));
    const optCatProd2 = this.state.dataCatProd2.map((d) => ({
      value: d.product_category_id,
      label: d.product_category_id + " - " + d.name.toUpperCase(),
      name: "prodCatId",
    }));
    const optProdId = this.state.dataProduk.map((d) => ({
        value: d.prod_id,
        label: d.prod_id + " - " +d.name.toUpperCase(),
    }));
    const optProdType = this.state.dataProdType.map((d) => ({
      value: d.code,
      label: d.name.toUpperCase(),
      name: "product_type",
    }));
    const optProdType2 = this.state.dataProdType.map((d) => ({
      value: d.code,
      label: d.name.toUpperCase(),
      name: "prodType",
    }));
    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              <div className="card shadow mt-2 mb-3">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                              Filter data yang akan dicari
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                          <div className="form-group">
                              <label>
                                  PRODUCT TYPE
                              </label>
                              <Select
                                  name="product_type"
                                  options={optProdType}
                                  value={optProdType.filter(
                                      (obj) => obj.value === this.state.product_type
                                  )}
                                  onChange={(value) => this.handleChangeProdType(value)}
                                  placeholder={<div>Pilih Produk Type</div>}
                                  isDisabled = {(this.state.disabled)? "true" : ""}
                              />
                          </div>
                      </div>
                      <div className="col-md-4">
                          <div className="form-group">
                              <label>
                                  PRODUCT CATEGORY ID
                              </label>
                              <Select
                                  name="product_category_id"
                                  options={optCatProd}
                                  value={optCatProd.filter(
                                      (obj) => obj.value === this.state.product_category_id
                                  )}
                                  onChange={(value) => this.handleChangeStatus(value)}
                                  placeholder={<div>Pilih Kategori Produk</div>}
                                  isDisabled = {(this.state.disabled)? "true" : ""}
                              />
                          </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.filterData();
                          }}
                        >Terapkan Filter</LaddaButton>
                      </div>
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-danger m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.reset();
                          }}
                        >Reset Filter</LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mt-2 mb-5">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-file text-primary h3 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold">SETTING PRODUK PRICE</span> <br />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    <h4>
                      <div className="row">
                        <div class="col-md-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.showModal("Tambah")}
                          >
                            <i className="fa fa-plus"></i> Produk Price
                          </button>
                        </div>
                        <div class="col-md-3">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.showModalMulti("Tambah")}
                          >
                            <i className="fa fa-plus"></i> Multiple Import Data
                          </button>
                        </div>
                      </div>
                    </h4>
                    {this.state.loading ? (
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="text-center"
                      />
                    ) : (
                      <ReactTable
                        data={this.state.dataDenom}
                        // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                        defaultPageSize={this.state.dataDenom.length > 10 ? 10 : 10}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()}>
          <Modal.Header>
            <Modal.Title>{this.state.typeModal} Produk Price</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="form-group">
                <label>
                    PRODUK ID <sup className="text-danger">*</sup>
                </label>
                <Select
                    name="prod_id"
                    options={optProdId}
                    value={optProdId.filter(
                        (obj) => obj.value === this.state.prod_id
                    )}
                    onChange={(value) => this.handleChangeProdId(value)}
                    placeholder={<div>Pilih Produk ID</div>}
                    isDisabled = {(this.state.disabled)? "true" : ""}
                />
            </div>
            <div className="form-group">
                <label>
                    PRICE <sup className="text-danger">*</sup>
                </label>
                <input
                    type="number"
                    name="price"
                    id="price"
                    className="form-control"
                    required="required"
                    value={this.state.price}
                    onChange={this.handleChange}
                    disabled = {(this.state.disabled)? "disabled" : ""}
                />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Batal
            </button>
            {(this.state.disabled)? (
                <>
                
                </>
            ):(<>
                <LaddaButton
                    loading={this.state.loading}
                    onClick={() => {
                        this.saveDenom(this.state.typeModal);
                    }}
                    className="btn btn-primary"
                    >
                    Simpan
                </LaddaButton>
            </>)}
            
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.setIsOpenMulti} onHide={() => this.hideModalMulti()} size="lg">
            <Form>
                <Modal.Header>
                    <Modal.Title>Multiple Imports Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-5">
                                <input className="form-control-file" type="file" accept=".xlsx, .xls" onChange={this.handleFileChange} ref={this.fileInputRef} />
                            </div>
                            <div className="col-md-3">
                                <button
                                className="btn btn-info"
                                onClick={this.showModalTemplate}
                                >
                                Generate Template  
                                </button>
                            </div>
                            
                            <div className="col-md-2">
                                <button
                                className="btn btn-warning"
                                onClick={this.handleFileUpload}
                                >
                                Tampilkan  
                                </button>
                            </div>
                            <div className="col-md-1">
                                <button
                                className="btn btn-danger"
                                onClick={this.resetMulti}
                                >
                                Reset  
                                </button>
                            </div>
                        </div>
                    </div>
                    {showButton ? (
                        <ReactTable data={this.state.jsonDataArray} columns={tableColumns} 
                        defaultPageSize={this.state.dataDenom.length > 5 ? 5 : 5}
                        />
                    ) : (
                        <div></div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {showButton ? (
                        <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={this.handleSubmit}
                        >
                        Upload  
                        </button>
                    ) : (
                        <div></div>
                    )}
                    <button
                        onClick={() => this.hideModalMulti()}
                        className="btn btn-outline-secondary"
                    >
                        Batal
                    </button>
                    
                </Modal.Footer>
            </Form>
        </Modal>
        <Modal show={this.state.setIsOpenTemplate} onHide={() => this.hideModalTemplate()} size="lg">
            <Form>
                <Modal.Header>
                    <Modal.Title>Generate Template Imports Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4">
                                {/* <input className="form-control-file" type="file" accept=".xlsx, .xls" onChange={this.handleFileChange} ref={this.fileInputRef} /> */}
                                <Select
                                    name="prodType"
                                    options={optProdType2}
                                    value={optProdType2.filter(
                                        (obj) => obj.value === this.state.prodType
                                    )}
                                    onChange={(value) => this.handleChangeProdType2(value)}
                                    placeholder={<div>Pilih Produk Type</div>}
                                    isDisabled = {(this.state.disabled)? "true" : ""}
                                />
                            </div>
                            <div className="col-md-4">
                                <Select
                                  name="product_category_id"
                                  options={optCatProd2}
                                  value={optCatProd2.filter(
                                      (obj) => obj.value === this.state.prodCatId
                                  )}
                                  onChange={(value) => this.handleChange(value)}
                                  placeholder={<div>Pilih Kategori Produk</div>}
                                  isDisabled = {(this.state.disabled)? "true" : ""}
                                />
                            </div>
                            
                            <div className="col-md-2">
                                <button
                                className="btn btn-warning"
                                onClick={this.fetchDataGen}
                                >
                                Tampilkan
                                </button>
                            </div>
                            <div className="col-md-1">
                                <button
                                className="btn btn-danger"
                                onClick={this.resetGenerate}
                                >
                                Reset  
                                </button>
                            </div>
                        </div>
                    </div>
                    {showButtonGen ? (
                        <ReactTable data={this.state.dataGenerate} columns={columnsGenerate} 
                        defaultPageSize={this.state.dataGenerate.length > 5 ? 5 : 5}
                        />
                    ) : (
                        <div></div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {showButtonGen ? (
                        <button
                          className="btn btn-info"
                          onClick={this.handleGenerateExcel}
                        >
                          Generate Template  
                        </button>
                    ) : (
                        <div></div>
                    )}
                    <button
                        onClick={() => this.hideModalTemplate()}
                        className="btn btn-outline-secondary"
                    >
                        Batal
                    </button>
                    
                </Modal.Footer>
            </Form>
        </Modal>
      </div>
    );
  }
}

export default Index;