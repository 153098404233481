import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import Pln from "../products/pln";
import Pulsa from "../products/pulsa";
import Bpjs from "../products/bpjs";
import Telkom from "../products/telkom";
import Pdam from "../products/pdam";
import Data from "../products/data";
import LaddaButton, { m, EXPAND_RIGHT } from "react-ladda";

const createHistory = require("history").createBrowserHistory;
const history = createHistory();
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>
  }
}

class MultiInquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      isAuth: 0,
      loading: true,
      pathUrl: window.location.pathname,
      idpel: '',
      list_idpel: JSON.parse(localStorage.getItem("list_impor_idpel")),
      last_idpel: localStorage.getItem("list_impor_idpel") ? JSON.parse(localStorage.getItem("list_impor_idpel")).slice(-1) : '',
    }
  }

  componentDidMount() {
    // console.warn("pathname", this.state.pathUrl.split('/').slice(-1))
    this.setState({
      loading: false,
      idpel: this.state.pathUrl.split('/').slice(-1)
    })
  }
  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  skipProd = async (type) => {
    let indexOfIdpel = await this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
    if (type == "next") {
      this.state.list_idpel[indexOfIdpel[0].key] = await {
        'idpel': this.state.list_idpel[indexOfIdpel[0].key].idpel,
        'key': this.state.list_idpel[indexOfIdpel[0].key].key,
        'produk': this.state.list_idpel[indexOfIdpel[0].key].produk,
        'status': 'gagal/skip'
      }
      await localStorage.setItem('list_impor_idpel', JSON.stringify(this.state.list_idpel))
      if (this.state.list_idpel) {
        if (this.state.last_idpel[0].idpel == this.state.idpel) {
          await history.push("/cart");
          let pathUrl = await window.location.href;
          window.location.href = await pathUrl;
        } else {
          if (indexOfIdpel.length == 0) {
            await history.push("/cart");
            let pathUrl = await window.location.href;
            window.location.href = await pathUrl;
          } else {
            let newProduk = await this.state.list_idpel[indexOfIdpel[0].key + 1];
            let nextProduk = await newProduk.produk;
            let nextIdpel = await newProduk.idpel;
            let pathProduk = "";
            if (nextProduk.toString().toUpperCase().includes("PREPAID")) {
              pathProduk = 'plnprepaid/' + nextIdpel
            }
            if (nextProduk.toString().toUpperCase().includes("POSTPAID")) {
              pathProduk = 'plnpostpaid/' + nextIdpel
            }
            if (nextProduk.toString().toUpperCase().includes("BPJS")) {
              pathProduk = 'bpjs/' + nextIdpel
            }
            if (nextProduk.toString().toUpperCase().includes("TELEPON")) {
              pathProduk = 'telepon/' + nextIdpel
            }
            if (nextProduk.toString().toUpperCase().includes("SPEEDY")) {
              pathProduk = 'speedy/' + nextIdpel
            }
            if (nextProduk.toString().toUpperCase().includes("PDAM")) {
              pathProduk = 'pdam/' + nextIdpel
            }
            if (nextProduk.toString().toUpperCase().includes("PULSA")) {
              pathProduk = 'pulsa/' + nextIdpel
            }
            if (nextProduk.toString().toUpperCase().includes("DATA")) {
              pathProduk = 'data/' + nextIdpel
            }

            await history.push(history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
            let pathUrl = await window.location.href;
            window.location.href = await pathUrl;
          }
        }
      }
    } else {
      let newProduk = await this.state.list_idpel[indexOfIdpel[0].key - 1];
      let nextProduk = await newProduk.produk;
      let nextIdpel = await newProduk.idpel;
      let pathProduk = "";
      if (nextProduk.toString().toUpperCase().includes("PREPAID")) {
        pathProduk = 'plnprepaid/' + nextIdpel
      }
      if (nextProduk.toString().toUpperCase().includes("POSTPAID")) {
        pathProduk = 'plnpostpaid/' + nextIdpel
      }
      if (nextProduk.toString().toUpperCase().includes("BPJS")) {
        pathProduk = 'bpjs/' + nextIdpel
      }
      if (nextProduk.toString().toUpperCase().includes("SPEEDY") || nextProduk.toString().toUpperCase().includes("TELEPON")) {
        pathProduk = 'telkom/' + nextIdpel
      }
      if (nextProduk.toString().toUpperCase().includes("PDAM")) {
        pathProduk = 'pdam/' + nextIdpel
      }
      if (nextProduk.toString().toUpperCase().includes("PULSA")) {
        pathProduk = 'pulsa/' + nextIdpel
      }
      if (nextProduk.toString().toUpperCase().includes("DATA")) {
        pathProduk = 'data/' + nextIdpel
      }

      await history.push(history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
      let pathUrl = await window.location.href;
      window.location.href = await pathUrl;
    }
  }
  render() {
    return (
      <div>
        <CheckSession isAuth={this.state.isAuth} />
        <section className="section g-bg-gray p-0px-t bg-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <table>
                      <tbody>
                        <tr>
                          <td rowSpan="2"><i className="fa fa-file-excel text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">CEK TAGIHAN </span> <br />
                            Cek tagihan idpel yang di impor
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 table-responsive">
                        <div className="card" style={{ border: "2px dashed #dedede" }}>
                          <div className="card-body">
                            <ul className="list-group list-group-flush">
                              {this.state.list_idpel.map((value, index) => {
                                return (
                                  <li key={index} className="list-group-item">
                                    {value.status ? (
                                      <i className={value.status == "sukses" ? "fa fa-check-circle text-success mr-2" : "fa fa-times-circle text-danger mr-2"}></i>
                                    ) : (<></>)}
                                    <span className="font-weight-bold">{value.produk.toUpperCase()}</span> <span className="float-right">{value.idpel}</span></li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="card" style={{ border: "2px solid #dedede" }}>
                          <div className="card-body">
                            {window.location.pathname.includes('prepaid') || window.location.pathname.includes('postpaid') ? (
                              <Route path="/users/transaksi/importidpel/cektagihan/:produk/:idpel" component={Pln} />
                            ) : (
                              <>
                                {window.location.pathname.includes('telepon') || window.location.pathname.includes('speedy') ? (
                                  <Route path="/users/transaksi/importidpel/cektagihan/:produk/:idpel" component={Telkom} />
                                ) : (
                                  <>
                                    <Route path="/users/transaksi/importidpel/cektagihan/pulsa/:idpel" component={Pulsa} />
                                    <Route path="/users/transaksi/importidpel/cektagihan/bpjs/:idpel" component={Bpjs} />
                                    <Route path="/users/transaksi/importidpel/cektagihan/pdam/:idpel" component={Pdam} />
                                    <Route path="/users/transaksi/importidpel/cektagihan/data/:idpel" component={Data} />
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="card-footer">
                            <div className="row justify-content-center">
                              <LaddaButton
                                onClick={() => this.skipProd("prev")}
                                data-size={m}
                                disabled={this.state.list_idpel[0].idpel == this.state.idpel}
                                className="btn btn-outline-secondary"
                                data-style={EXPAND_RIGHT}
                              >
                                <i className="fa fa-arrow-left mr-2"></i> Sebelumnya
                              </LaddaButton>
                              <LaddaButton
                                onClick={() => this.skipProd("next")}
                                data-size={m}
                                // disabled={this.state.list_idpel[0].idpel != this.state.idpel}
                                className="btn btn-outline-primary ml-2"
                                data-style={EXPAND_RIGHT}
                              >
                                Lewati Produk <i className="fa fa-arrow-right ml-2"></i>
                              </LaddaButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mt-2 ">
                      <div className="col-md-3 ml-auto">
                        <LaddaButton
                          loading={this.state.loading}
                          disabled={!(this.state.rows.length > 0)}
                          onClick={() => {
                            this.process();
                          }}
                          data-color="#008aff"
                          data-size={l}
                          className="m-btn m-btn-theme m-btn-radius w-100 mb-5"
                          data-style={EXPAND_RIGHT}
                        >
                          Lanjut Bayar
                        </LaddaButton>
                      </div>
                    </div> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    );
  }
}

export default MultiInquiry;
