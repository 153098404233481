import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../router/Router";
import Axios from "axios";
import ReactTable from "react-table";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import { useReactToPrint } from "react-to-print";
import Pdf from "react-to-pdf";
import { each } from "jquery";
import Moment from "react-moment";
import moment from "moment-timezone";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";

const token = localStorage.getItem("token");
function ClosingDetail(props) {
  const { dispatch, state } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [start_time, setStart_time] = useState("08:00");
  const [end_time, setEnd_time] = useState("16:00");
  const [date, setDatex] = useState("");
  const [data, setData] = useState([]);
  const [infoClosing, setInfoClosing] = useState([]);
  const [datatrx, setDatatrx] = useState([]);
  const [isAuth, setAuth] = useState(0);
  let identifier = useParams();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const logo = localStorage.getItem("logo");
  const tagline = localStorage.getItem("tagline");
  const options = {
    orientation: "portrait",
    unit: "px",
    format: [3508, 2480],
  };

  const ref = React.createRef();

  var curr = new Date();
  curr.setDate(curr.getDate() + 0);
  var dateval = curr.toISOString().substr(0, 10);

  const getData = async () => {
    setLoading(true);
    await state;

    await Axios.get(
      `${process.env.REACT_APP_URL}/closing/proses/${identifier.Id}`,
      {
        headers: {
          Authorization: "Bearer " + state.token,
        },
      }
    ).then(async (response) => {
      await setDatatrx(response.data.data.transactions);
      await setData(response.data.data.total);
      await setInfoClosing(response.data.data.information_plg);
    }).catch(async function (err) {
      if (err.response.data.status == 401) {
        await setAuth(401);
      }
    });
    await setLoading(false);
  };
  const numberFormat = (num) => {
    let data = num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return `Rp` + data;
  };

  useEffect(() => {
    getData();
  }, [setData, setDatatrx]);

  if (isAuth === 401) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="bg-primary-admin">
      <div className="container">
        <div className="card shadow mb-5">
          <div className="hidden-onphone">
            <div className="card-header bg-light d-flex justify-content-between">
              <table>
                <tbody>
                  <tr>
                    <td rowspan="2"><i className="fa fa-file-alt text-primary h1 mr-2"></i></td>
                    <td>
                      <span className="font-weight-bold text-primary">DETAIL CLOSING TRANSAKSI</span> <br />
                      Daftar transaksi yang diclosing
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <Pdf
                  options={options}
                  x={0.5}
                  y={0.5}
                  scale={3}
                  targetRef={componentRef}
                  filename={`ClosingCuan_${dateval}.pdf`}
                >
                  {({ toPdf }) => (
                    <button className="btn btn-danger mr-2" onClick={toPdf}>
                      Unduh PDF
                    </button>
                  )}
                </Pdf>
                <button className="btn btn-outline-danger" onClick={handlePrint}>
                  Print
                </button>
              </div>
            </div>
          </div>
          <div className="card-header bg-light justify-content-between show-onphone">
            <div className="row">
              <div className="col-6">
                <Pdf
                  options={options}
                  x={0.5}
                  y={0.5}
                  scale={3}
                  targetRef={componentRef}
                  filename={`ClosingCuan_${dateval}.pdf`}
                >
                  {({ toPdf }) => (
                    <button className="btn btn-danger mr-2 btn-block" onClick={toPdf}>
                      Unduh PDF
                    </button>
                  )}
                </Pdf>
              </div>
              <div className="col-6">
                <button className="btn btn-outline-danger btn-block" onClick={handlePrint}>
                  Print
                </button>
              </div>
            </div>
          </div>
          <div ref={componentRef}>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-12">
                  <table className="table small table-borderless">
                    <tbody>
                      <tr>
                        <td width="50% px-2">
                          {
                            logo != "null" ? (
                              <>
                                <img className="img-thumbnail border-0" src={`${process.env.REACT_APP_URL}/images/${logo}`} width="100px" height="100px" />
                              </>
                            ) : (
                              <>
                                <img className="img-thumbnail border-0" src="/img/loketpelangi.png" width="100px" height="100px" />
                              </>
                            )
                          }
                        </td>
                        <td className="text-right">
                          <p className="mt-4" style={{fontSize:"12px"}}>
                            <b>Closing Datetime </b> <br />
                            <Moment
                              format="DD MMM YYYY HH:mm:ss"
                              tz="Asia/Jakarta"
                            >
                              {moment(infoClosing.date_end).add(0, "hours")}
                            </Moment>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                </div>
              </div>
            </div>
            <div className="card-body ">
              <div className="col-md-12">
                <div className="my-2">
                  <div className="table-responsive">
                    <b>Detail Loket</b>
                    <table className="table table-striped table-bordered small text-center">
                      <thead>
                        <tr>
                          <th>Nama Loket</th>
                          <th>Akun</th>
                          <th>Saldo</th>
                          <th>Total Transaksi</th>
                          <th>Total Bayar</th>
                        </tr>
                      </thead>
                      {data.transaction ? (
                        <tbody>
                          <tr>
                            <td>{state.fullname}</td>
                            <td>{state.mitra}</td>
                            <td>
                              <NumberFormat
                                value={parseInt(state.balance)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp"}
                                renderText={(value) => <div>{value}</div>}
                              />
                            </td>
                            <td>{data.transaction}</td>
                            <td>
                              <NumberFormat
                                value={parseInt(data.amount)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp"}
                                renderText={(value) => <div>{value}</div>}
                              />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5" className="text-center">
                              Belum Ada Closing
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card border-0">
                  <div className="">
                    <b htmlFor="">Detail Transaksi</b>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered small text-center">
                      <thead>
                        <tr>
                          <th>Tanggal Transaksi</th>
                          <th>ID Transaksi</th>
                          <th>Produk</th>
                          <th>Idpel</th>
                          <th>Status</th>
                          <th>Total Bayar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datatrx.map((value, index) => {
                          return (
                            <tr key={value.trx_id} index={index}>
                              <td>
                                <Moment
                                  format="DD MMM YYYY HH:mm"
                                  tz="Asia/Jakarta"
                                >
                                  {moment(value.transaction_date).add(0, "hours")}
                                </Moment>
                              </td>
                              <td>{value.trx_id}</td>
                              <td>{value.product_name}</td>
                              <td>{value.customer_number}</td>
                              <td>
                                <b>{value.status}</b>
                              </td>
                              <td className="text-right">
                                <NumberFormat
                                  value={parseInt(value.amount)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp"}
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" className="bg-light text-right">
                            TOTAL
                          </th>
                          {data.amount ? (
                            <td className="text-right bg-light">
                              {numberFormat(parseFloat(data.amount))}
                            </td>
                          ) : (
                            <td className="bg-light">Rp0</td>
                          )}
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-5">
                <hr />
                <small>End of report {dateval}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClosingDetail;
