import React, { Component } from "react";
import LaddaButton, { EXPAND_RIGHT, l } from "react-ladda";
import ReactLoading from "react-loading";
import "react-loadingmask/dist/react-loadingmask.css";
import { Redirect } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);
const CheckSession = ({ isAuth }) => {
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  } else {
    return <></>;
  }
};
class pln extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToken: false,
      idpel: props.match.params.idpel,
      idpelStatus: false,
      payment: false,
      inquiry: false,
      produk: "",
      message: null,
      product_nomination: null,
      loading: true,
      trx_type: "2100",
      produk: "",
      list_product: [],
      list_token: [],
      product_id: 0,
      token: localStorage.getItem("token"),
      inqres: [],
      isAuth: 0,
      defaultIndex: 0,
      produk_multi: props.match.params.produk,
      idpel_multi: props.match.params.idpel,
      list_idpel: JSON.parse(localStorage.getItem("list_impor_idpel")),
      last_idpel: localStorage.getItem("list_impor_idpel")
        ? JSON.parse(localStorage.getItem("list_impor_idpel")).slice(-1)
        : "",
    };
  }

  GetProduct = async () => {
    await fetch(
      `${process.env.REACT_APP_URL}/products?product_category_id=42`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + this.state.token,
          Accept: "application/json",
        },
      }
    )
      .then(async (response) => {
        response.json().then(async (result) => {
          // if (result.data && result.data.length != 0) {
          //   result.data.reverse();
          // }
          if (result.status == 200) {
            await this.setState({ list_product: result.data });

            if (this.state.idpel_multi && this.state.produk_multi) {
              if (this.state.produk_multi == "plnprepaid") {
                await this.setState({
                  defaultIndex: 0,
                  showToken: true,
                  loading: false,
                  product_id: result.data[0].prod_id,
                  message: null,
                  produk: result.data[0].code,
                });
              } else {
                await this.setState({
                  defaultIndex: 1,
                  showToken: false,
                  loading: false,
                  product_id: result.data[1].prod_id,
                  message: null,
                  produk: result.data[1].code,
                });
              }
            } else {
              await this.setState({
                defaultIndex: 0,
                showToken: true,
                loading: false,
                product_id: result?.data[0]?.prod_id,
                message: null,
                produk: result?.data[0]?.code,
              });
            }
          } else {
            // Swal.fire({
            //   title: "Maaf",
            //   text: "Maaf, gagal mengambil data produk",
            //   icon: "info",
            //   confirmButtonText: "oke",
            // });
            await this.setState({ loading: false });
          }
        });
      })
      .catch(
        function (err) {
          Swal.fire({
            title: "Maaf",
            text:
              err.response.data.message ||
              "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
          this.setState({ isAuth: 401 });
        }.bind(this)
      );
  };

  GetToken() {
    fetch(`${process.env.REACT_APP_URL}/prepaid/denom/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        this.setState({
          list_token: result.data.sort((a, b) => a.denom - b.denom),
        });
      });
    });
  }

  componentDidMount() {
    let pathUrl = window.location.pathname;
    let urlImpor = pathUrl.split("/");
    if (urlImpor[1] == "pln") {
      if (
        urlImpor[1] != "users" &&
        urlImpor[2] != "transaksi" &&
        urlImpor[3] != "importidpel" &&
        urlImpor[4] != "cektagihan"
      ) {
        if (this.state.list_idpel) {
          localStorage.removeItem("list_impor_idpel");
        }
      }
    }
    this.GetProduct();
    this.GetToken();
    if (this.state.list_idpel && urlImpor[1] != "pln") {
      let cekIdpel = this.state.list_idpel.filter(
        (p) => p.idpel == this.state.idpel
      );
      if (cekIdpel.length > 0) {
        this.handleChangeIdpel(this.state.idpel);
      } else {
        Swal.fire({
          title: "Maaf",
          text: "ID pelanggan tidak ada di daftar yang diimpor",
          icon: "info",
          confirmButtonText: "oke",
        });
      }
    }
  }

  setTypeProduct(index) {
    this.setState({
      loading: true,
      inquiry: false,
      trx_type: "2100",
      idpel: "",
    });
    if (!this.state.list_idpel) {
      if (this.state.list_product[index] == "TOKENPLN") {
        this.GetToken();
        this.setState({
          showToken: true,
          loading: false,
          product_id: this.state.list_product[index].prod_id,
          message: null,
          produk: this.state.list_product[index].code,
        });
      } else {
        this.setState({
          showToken: false,
          loading: false,
          product_id: this.state.list_product[index].prod_id,
          message: null,
          produk: this.state.list_product[index].code,
        });
      }
    }
  }

  handleChangeIdpel(e) {
    try {
      let inIdpel = "";
      if (this.state.idpel_multi) {
        inIdpel = this.state.idpel_multi;
      } else {
        inIdpel = e.target.value;
      }
      if (inIdpel == "" || inIdpel == null) {
        this.setState({ message: null });
      }
      this.setState({ inquiry: false, trx_type: "2100", idpelStatus: false });
      if (inIdpel.length >= 9 && inIdpel.length <= 12) {
        this.setState({ idpel: inIdpel, idpelStatus: true, message: null });
      } else {
        this.setState({
          message:
            "Inputan untuk produk PLN minimal panjangnya adalah 11 digit ",
          inquiry: false,
          trx_type: "2100",
          idpelStatus: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  HitData() {
    if (
      this.state.product_nomination == null &&
      this.state.produk == "TOKENPLN"
    ) {
      Swal.fire({
        title: "Maaf",
        text: "Anda belum memilih nominal pembelian",
        icon: "info",
        confirmButtonText: "oke",
      });
    } else {
      this.setState({ loading: true });
      fetch(`${process.env.REACT_APP_URL}/loket/transactions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + this.state.token,
          Accept: "application/json",
        },
        body: JSON.stringify(this.state),
      })
        .then((response) => {
          response.json().then(async (result) => {
            //inq berhasil
            if (result.status == "200") {
              if (this.state.trx_type == "2100" && result.data.rc == "0000") {
                localStorage.setItem(
                  "private_inquiry",
                  JSON.stringify({
                    data: result.data,
                    produk: this.state.produk,
                    product_id: this.state.product_id,
                    product_nomination: this.state.product_nomination,
                    idpel: result.data.subscriber_id,
                  })
                );
                this.setState({ inquiry: true, inqres: result.data });
              } else {
                this.setState({ message: result.data.desc });
                Swal.fire({
                  title: "Maaf",
                  text:
                    result.data.desc || "Silahkan dicoba beberapa saat lagi",
                  icon: "info",
                  confirmButtonText: "Tutup",
                });
              }
            } else if (result.status == "401") {
              Swal.fire({
                title: "Maaf",
                text:
                  result.message ||
                  "Maaf, session Anda telah habis, silahkan login kembali",
                icon: "info",
                confirmButtonText: "Tutup",
              });
              this.setState({ isAuth: 401 });
            } else {
              // this.setState({ message: result.info });
              await this.setState({ message: result.message.desc });
              await Swal.fire({
                title: "Maaf",
                text:
                  result.message.desc || "Silahkan dicoba beberapa saat lagi",
                icon: "info",
                confirmButtonText: "Tutup",
              });
              // if (this.state.list_idpel) {
              //   let indexOfIdpel = await this.state.list_idpel.filter(p => p.idpel == this.state.idpel);
              //   if (this.state.last_idpel[0].idpel == this.state.idpel) {
              //     await this.props.history.push("/cart");
              //     let pathUrl = await window.location.href;
              //     window.location.href = await pathUrl;
              //   } else {
              //     let newProduk = await this.state.list_idpel[indexOfIdpel[0].key + 1];

              //     let nextProduk = await newProduk.produk;
              //     let nextIdpel = await newProduk.idpel;
              //     let pathProduk = "";
              //     if (nextProduk.toString().toUpperCase().includes("PREPAID")) {
              //       pathProduk = 'plnprepaid/' + nextIdpel
              //     }
              //     if (nextProduk.toString().toUpperCase().includes("POSTPAID")) {
              //       pathProduk = 'plnpostpaid/' + nextIdpel
              //     }
              //     if (nextProduk.toString().toUpperCase().includes("BPJS")) {
              //       pathProduk = 'bpjs/' + nextIdpel
              //     }
              //     if (nextProduk.toString().toUpperCase().includes("SPEEDY") || nextProduk.toString().toUpperCase().includes("TELEPON")) {
              //       pathProduk = 'telkom/' + nextIdpel
              //     }
              //     if (nextProduk.toString().toUpperCase().includes("PDAM")) {
              //       pathProduk = 'pdam/' + nextIdpel
              //     }
              //     if (nextProduk.toString().toUpperCase().includes("PULSA")) {
              //       pathProduk = 'pulsa/' + nextIdpel
              //     }
              //     if (nextProduk.toString().toUpperCase().includes("DATA")) {
              //       pathProduk = 'data/' + nextIdpel
              //     }

              //     await this.props.history.push(this.props.history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
              //     let pathUrl = await window.location.href;
              //     window.location.href = await pathUrl;
              //   }
              // }
            }
          });
          this.setState({ loading: false });
        })
        .catch(
          function (err) {
            Swal.fire({
              title: "Maaf",
              text: err.response
                ? err.response.data.message
                : "Maaf, session Anda telah habis, silahkan login kembali",
              icon: "error",
              confirmButtonText: "Oke",
            });
            this.setState({ isAuth: 401 });
          }.bind(this)
        );
    }
  }

  handleClick() {
    this.setState({ buttonState: "loading" });
    setTimeout(() => {
      this.setState({ buttonState: "success" });
    }, 3000);
  }

  setNominal(nominal) {
    this.setState({ product_nomination: nominal });
  }
  render() {
    return (
      <div id="pln">
        <CheckSession isAuth={this.state.isAuth} />

        {!this.state.list_product ? (
          <div className="alert alert-warning">
            <p>Gagal mengambil data produk. Sedang dalam perbaikan</p>
          </div>
        ) : (
          <div>
            <div>
              {this.state.inquiry ? (
                <>
                  <Redirect to="/checkoutv1" />
                </>
              ) : (
                <></>
              )}

              <div className="">
                <h5 className="text-dark h6">
                  Beli Token/Bayar Tagihan Listrik
                </h5>
              </div>
              <Tabs
                defaultIndex={this.state.defaultIndex}
                onSelect={(index) => this.setTypeProduct(index)}
              >
                {!this.state.idpel_multi ? (
                  <>
                    <TabList>
                      <Tab>
                        <span className="" style={{ fontSize: "14px" }}>
                          TOKEN LISTRIK
                        </span>
                      </Tab>
                      <Tab>
                        <span className="" style={{ fontSize: "14px" }}>
                          TAGIHAN LISTRIK
                        </span>
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <div className="row mt-4">
                        <div className="col-md-9">
                          <div className="form-group">
                            <h6 className="text-dark">ID Pelanggan </h6>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="No. Meter/ ID Pelanggan"
                              onKeyUp={(e) => {
                                this.handleChangeIdpel(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 hidden-onphone">
                          <LaddaButton
                            loading={this.state.loading}
                            disabled={!this.state.idpelStatus}
                            onClick={() => {
                              this.HitData();
                            }}
                            data-color="#008aff"
                            data-size={l}
                            className="m-btn m-btn-theme m-btn-radius w-100 shadow"
                            style={{ marginTop: "25px" }}
                            data-style={EXPAND_RIGHT}
                          >
                            Beli Token {this.state.product_nomination}
                          </LaddaButton>
                        </div>
                      </div>
                      <h6 className="text-dark mt-3">Pilih Nominal Token</h6>
                      {this.state.list_token ? (
                        <div className="row">
                          <div className="col-12">
                            <ul className="nav nav-fill nav-tabs row p-2 border-0">
                              {this.state.list_token.map((value, index) => {
                                return (
                                  <li
                                    className="nav-item col-6 col-md-3 col-sm-6 mr-0 p-2"
                                    key={index}
                                    onClick={
                                      value.active === 1
                                        ? (e) => {
                                            this.setNominal(value.denom);
                                          }
                                        : ""
                                    }
                                  >
                                    <a
                                      href="#"
                                      data-toggle="tab"
                                      className={
                                        value.active === 0
                                          ? "nav-link btn disabled"
                                          : "nav-link btn"
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#F9F9F9",
                                        borderColor: "#dedede",
                                        padding: "15px",
                                      }}
                                    >
                                      <span>
                                        Rp
                                        {this.rupiahFormat(
                                          parseFloat(value.denom)
                                        )}
                                      </span>
                                      {value.active === 0 ? (
                                        <p
                                          className="small text-danger"
                                          style={{ lineHeight: "10px" }}
                                        >
                                          Produk sedang kosong
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="w-100 h-100  d-flex justify-content-center">
                          <Loading
                            type="bubbles"
                            color="#008aff"
                            className="m-auto"
                          />
                        </div>
                      )}
                      <div className="show-onphone">
                        <div className="row">
                          <div className="col-md-12">
                            <LaddaButton
                              loading={this.state.loading}
                              disabled={!this.state.idpelStatus}
                              onClick={() => {
                                this.HitData();
                              }}
                              data-color="#008aff"
                              data-size={l}
                              className="m-btn m-btn-theme m-btn-radius w-100 btn-block shadow"
                              data-style={EXPAND_RIGHT}
                            >
                              Beli Token {this.state.product_nomination}
                            </LaddaButton>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row mt-4">
                        <div className="col-md-9">
                          <h6 className="text-dark">ID Pelanggan </h6>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              disabled={this.state.idpel_multi ? true : false}
                              placeholder="No. Meter/ ID Pelanggan"
                              onKeyUp={(e) => {
                                this.handleChangeIdpel(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 hidden-onphone">
                          <LaddaButton
                            loading={this.state.loading}
                            disabled={!this.state.idpelStatus}
                            onClick={() => {
                              this.HitData();
                            }}
                            data-color="#008aff"
                            data-size={l}
                            style={{ marginTop: "25px" }}
                            className="m-btn m-btn-theme m-btn-radius w-100"
                            data-style={EXPAND_RIGHT}
                          >
                            Cek Tagihan
                          </LaddaButton>
                        </div>
                      </div>
                      <div className="show-onphone">
                        <div className="row">
                          <div className="col-md-12">
                            <LaddaButton
                              loading={this.state.loading}
                              disabled={!this.state.idpelStatus}
                              onClick={() => {
                                this.HitData();
                              }}
                              data-color="#008aff"
                              data-size={l}
                              className="m-btn m-btn-theme m-btn-radius w-100 btn-block shadow"
                              data-style={EXPAND_RIGHT}
                            >
                              Cek Tagihan
                            </LaddaButton>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}
                {this.state.defaultIndex == 0 ? (
                  <>
                    <TabPanel>
                      <div className="row mt-4">
                        <div className="col-md-7">
                          <div className="form-group">
                            <h6 className="text-dark">ID Pelanggan </h6>
                            <input
                              type="number"
                              className="form-control"
                              value={this.state.idpel}
                              disabled={this.state.list_idpel ? true : false}
                              placeholder="No. Meter/ ID Pelanggan"
                              onKeyUp={(e) => {
                                this.handleChangeIdpel(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-5 hidden-onphone">
                          <LaddaButton
                            loading={this.state.loading}
                            disabled={!this.state.idpelStatus}
                            onClick={() => {
                              this.HitData();
                            }}
                            data-color="#008aff"
                            data-size={l}
                            className="m-btn m-btn-theme m-btn-radius w-100 shadow"
                            style={{ marginTop: "25px" }}
                            data-style={EXPAND_RIGHT}
                          >
                            Beli Token {this.state.product_nomination}
                          </LaddaButton>
                        </div>
                      </div>
                      <h6 className="text-dark mt-3">Pilih Nominal Token</h6>
                      {this.state.list_token ? (
                        <div className="row">
                          <div className="col-12">
                            <ul className="nav nav-fill nav-tabs row p-2 border-0">
                              {this.state.list_token.map((value, index) => {
                                return (
                                  <li
                                    className="nav-item col-6 col-md-3 col-sm-6 mr-0 p-2"
                                    key={index}
                                    onClick={
                                      value.active === 1
                                        ? (e) => {
                                            this.setNominal(value.denom);
                                          }
                                        : ""
                                    }
                                  >
                                    <a
                                      href="#"
                                      data-toggle="tab"
                                      className={
                                        value.active === 0
                                          ? "nav-link btn disabled"
                                          : "nav-link btn"
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#F9F9F9",
                                        borderColor: "#dedede",
                                        padding: "15px",
                                      }}
                                    >
                                      <span>
                                        Rp
                                        {this.rupiahFormat(
                                          parseFloat(value.denom)
                                        )}
                                      </span>
                                      {value.active === 0 ? (
                                        <p
                                          className="small text-danger"
                                          style={{ lineHeight: "10px" }}
                                        >
                                          Produk sedang kosong
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="w-100 h-100  d-flex justify-content-center">
                          <Loading
                            type="bubbles"
                            color="#008aff"
                            className="m-auto"
                          />
                        </div>
                      )}
                      <div className="show-onphone">
                        <div className="row">
                          <div className="col-md-12">
                            <LaddaButton
                              loading={this.state.loading}
                              disabled={!this.state.idpelStatus}
                              onClick={() => {
                                this.HitData();
                              }}
                              data-color="#008aff"
                              data-size={l}
                              className="m-btn m-btn-theme m-btn-radius w-100 btn-block shadow"
                              data-style={EXPAND_RIGHT}
                            >
                              Beli Token {this.state.product_nomination}
                            </LaddaButton>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </>
                ) : (
                  <>
                    <TabPanel>
                      <div className="row mt-4">
                        <div className="col-md-8">
                          <h6 className="text-dark">ID Pelanggan </h6>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              value={this.state.idpel}
                              disabled={this.state.idpel_multi ? true : false}
                              placeholder="No. Meter/ ID Pelanggan"
                              onKeyUp={(e) => {
                                this.handleChangeIdpel(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 hidden-onphone">
                          <LaddaButton
                            loading={this.state.loading}
                            disabled={!this.state.idpelStatus}
                            onClick={() => {
                              this.HitData();
                            }}
                            data-color="#008aff"
                            data-size={l}
                            style={{ marginTop: "25px" }}
                            className="m-btn m-btn-theme m-btn-radius w-100"
                            data-style={EXPAND_RIGHT}
                          >
                            Cek Tagihan
                          </LaddaButton>
                        </div>
                      </div>
                      <div className="show-onphone">
                        <div className="row">
                          <div className="col-md-12">
                            <LaddaButton
                              loading={this.state.loading}
                              disabled={!this.state.idpelStatus}
                              onClick={() => {
                                this.HitData();
                              }}
                              data-color="#008aff"
                              data-size={l}
                              className="m-btn m-btn-theme m-btn-radius w-100 btn-block shadow"
                              data-style={EXPAND_RIGHT}
                            >
                              Cek Tagihan
                            </LaddaButton>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </>
                )}
              </Tabs>
              <div
                className="card border border-primary bg-primary-300 mt-3 mb-3"
                style={{ backgroundColor: "#dbeefd" }}
              >
                <div className="card-body">
                  <p className="small">
                    <b className="text-primary">Keterangan</b>
                    <br />
                    <br />
                    1. Produk Listrik PLN tidak tersedia pada jam cut
                    off/maintenance (23.00 - 01.00).
                    <br />
                    2. Jatuh tempo pembayaran tagihan listrik adalah tanggal 20
                    di setiap bulannya.
                    <br />
                    3. Proses verifikasi pembayaran maksimum 2x24 jam hari
                    kerja.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default pln;
