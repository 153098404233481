import React from "react";
// import Style from "./Style";

export function Kontak({message,index}) {
  return (
    <div 
      className={"message " + (message.open ? 'open' : '')}
      key={index}
      
    >
      <div className={"message-"+message.icon}>
        {message.content}
      </div>
    </div>
  )
}
export default Kontak;