import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../router/Router";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Axios from "axios";
import Moment from "react-moment";
import ReactTable from "react-table";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";

function ClosingList(props) {
  const { dispatch, state } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [datatrx, setDatatrx] = useState([]);
  const createHistory = require("history").createBrowserHistory;
  const token = localStorage.getItem("token");
  let history = createHistory();
  const getData = async () => {
    await setLoading(true);
    await state;
    // console.log(state.token);
    // console.log(state);
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_URL}/closing/history`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      // await console.log(response);
      if (response.status === "401") {
        // dis;
        alert("session expired");
      }
      // await console.warn("total", response.data.data.History.total);
      await setDatatrx(response.data.data);
      // await setData(response.data.data.History.total);
    } catch (e) {
      // console.log(e);
    }
    await setLoading(false);
  };
  const Filter = async () => {
    await setLoading(true);
    if (startDate == null || endDate == null) {
      Swal.fire({
        title: 'Maaf',
        text: "Lengkapi filter tanggal",
        icon: 'info',
        confirmButtonText: 'oke'
      });
    } else {
      fetch(process.env.REACT_APP_URL + '/closing/history?start_date=' + startDate + '&end_date=' + endDate, {
        method: "GET",
        headers: {
          'Authorization': "Barier " + token,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }).then(async (response) => {
        await response.json().then(async (result) => {
          if (result.status == 200) {
            console.warn("result", result.status);
            await setDatatrx(result.data);
            // await setData(result.data.data.History.total);
          } else {
            Swal.fire({
              title: 'Maaf',
              text: result.message,
              icon: 'error',
              confirmButtonText: 'oke'
            });
          }
        })
      })
    }
    await setLoading(false);
  };

  useEffect(() => {
    getData();
  },[]);

  async function Reset() {
    await setLoading(true);
    await getData();
    await setLoading(false);
  };
  const columns = [
    {
      Header: () => (
        <div style={{ textAlign: "left", color: "black" }}>Account</div>
      ),
      accessor: "pelangi_account",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Saldo Akhir</div>
      ),
      accessor: "pelangi_balance",
      style: {
        textAlign: "right",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Total Transaksi</div>
      ),
      accessor: "transaction_total",
      style: {
        textAlign: "center",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Amount</div>
      ),
      accessor: "transaction_amount",
      style: {
        textAlign: "right",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Mulai Transaksi</div>
      ),
      accessor: "datetime_login",
      style: {
        textAlign: "center",
        flexDirection: "column",
      },
      Cell: (row) => (
        <div>
          <Moment format="DD MMM YYYY - HH:mm">{row.original.datetime_login}</Moment>
        </div>
      ),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Selesai Transaksi</div>
      ),
      accessor: "datetime_logout",
      style: {
        textAlign: "center",
        flexDirection: "column",
      },
      Cell: (row) => (
        <div>
          <Moment format="DD MMM YYYY - HH:mm">{row.original.datetime_logout}</Moment>
        </div>
      ),
    },

    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
      ),
      maxWidth: "100",
      accessor: "approve_status",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <NavLink
            className="btn-xs btn-info"
            to={`/users/transaksi/closing/detail/${row.original.id}`}
          >
            Detail
          </NavLink>
        </div>
      )
    },
  ];
  return (
    <div className="bg-admin">
      <section className="section p-0px-t bg-admin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="card shadow mb-3 mt-2">
                <div className="card-header bg-light d-flex justify-content-between">
                  <div>
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                          Filter data yang akan dicari
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-dark">Tanggal Awal</label>
                        <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} value={startDate} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-dark">Tanggal Akhir</label>
                        <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} value={endDate} />
                      </div>
                    </div>
                  </div>
                {/* </div>
                <div className="card-footer"> */}
                  <div className="row mt-2">
                    <div className="col-md-2 col-6">
                      <LaddaButton
                        loading={loading}
                        className="btn btn-primary m-btn-radius w-100"
                        data-size='s'
                        onClick={() => Filter()}
                      >Terapkan Filter</LaddaButton>
                    </div>
                    <div className="col-md-2 col-6">
                      <LaddaButton
                        loading={loading}
                        className="btn btn-danger m-btn-radius w-100"
                        data-size='s'
                        onClick={Reset}
                      >Reset Filter</LaddaButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow mt-2">
                <div className="card-header bg-light d-flex justify-content-between">
                  <div>
                    <table>
                      <tr>
                        <td rowspan="2"><i className="fa fa-file-alt text-primary h1 mr-2"></i></td>
                        <td>
                          <span className="font-weight-bold text-primary">DATA CLOSING</span> <br />
                          Daftar data closing yang pernah dibuat
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="card-body pb-5">

                  {loading ? (
                    <div className="w-100 text-center justify-content-center">
                      Loading...
                    </div>
                  ) : (
                    <div>
                      <ReactTable
                        // defaultSorted={[{ id: "tanggal", desc: true }]}
                        data={datatrx}
                        //   defaultPageSize={isDataSaldo.length >= 10 ? 10 : 5}
                        defaultPageSize={5}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ClosingList;
