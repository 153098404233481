import React from "react";
import { useHistory, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaBolt, FaWater, FaPhone, FaWifi, FaHospitalAlt, FaMobileAlt, FaSignal } from "react-icons/fa";
//conponent produk
import Pln from "../products/pln";
import Pulsa from "../products/pulsa";
import Bpjs from "../products/bpjs";
import Telkom from "../products/telkom";
import Pdam from "../products/pdam";
import Data from "../products/data";

import Info from "./info";

import "react-toastify/dist/ReactToastify.css";

function MenuProduk(props) {
  let history = useHistory();
  const list_idpel = JSON.parse(localStorage.getItem("list_impor_idpel"));

  // console.log(list_idpel)
  return (
    <div>
      <div className="bg-phone">
        {/* <SideBatch/> */}
        <section className="section p-0px-t bg-phone">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-12">
                    <div className="card border-radius-20 mt-2">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-3 col-lg-2 col-sm-4 col-4 mt-2 mb-1" >
                            <NavLink
                              className="card p10 pt-3 text-center bg-white box-shadow border-radius-15 text-decoration-none border-0 shadow-sm"
                              to="/pln"
                              activeClassName="bg-light">
                              <div className="only-icon-30 d-inline-block w-100">
                                <FaBolt color="#3f51b5" />
                              </div>
                              <b className="m-0px small mt-2 text-dark">PLN</b>
                            </NavLink>
                          </div>
                          <div className="col-md-3 col-lg-2 col-sm-4 col-4 mt-2 mb-1">
                            <NavLink
                              className="card p10 pt-3 text-center bg-white box-shadow border-radius-15 border-0 text-decoration-none shadow-sm"
                              to="/bpjs"
                              activeClassName="bg-light">
                              <div className="only-icon-30 d-inline-block w-100">
                                <FaHospitalAlt className="text-success" />
                              </div>
                              <b className="m-0px small mt-2 text-dark">BPJS</b>
                            </NavLink>
                          </div>
                          <div className="col-md-3 col-lg-2 col-sm-4 col-4 mt-2 mb-1">
                            <NavLink
                              className="card p10 pt-3 text-center bg-white box-shadow border-radius-15 text-decoration-none border-0 shadow-sm"
                              to="/telkom"
                              activeClassName="bg-light">
                              <div className="only-icon-30 d-inline-block w-100">
                                <FaPhone className="text-danger" />
                              </div>
                              <b className="m-0px small mt-2 text-dark">TELKOM</b>
                            </NavLink>
                          </div>
                          <div className="col-md-3 col-lg-2 col-sm-4 col-4 mt-2 mb-1">
                            <NavLink
                              className="card p10 pt-3 text-center bg-white box-shadow border-radius-15 text-decoration-none border-0 shadow-sm"
                              to="/pdam"
                              activeClassName="bg-light">
                              <div className="only-icon-30 d-inline-block w-100 ">
                                <FaWater color="#03a9f4" />
                              </div>
                              <b className="m-0px small mt-2 text-dark">PDAM</b>
                            </NavLink>
                          </div>
                          <div className="col-md-3 col-lg-2 col-sm-4 col-4 mt-2 mb-1">
                            <NavLink
                              className="card p10 pt-3 text-center bg-white box-shadow border-radius-15 text-decoration-none border-0 shadow-sm"
                              to="/pulsa"
                              activeClassName="bg-light">
                              <div className="only-icon-30 d-inline-block w-100">
                                <FaSignal color="#ff9800" />
                              </div>
                              <b className="m-0px small mt-2 text-dark">PULSA</b>
                            </NavLink>
                          </div>
                          <div className="col-md-3 col-lg-2 col-sm-4 col-4 mt-2 mb-1">
                            <NavLink
                              className="card p10 pt-3 text-center bg-white box-shadow border-radius-15 text-decoration-none border-0 shadow-sm"
                              to="/data"
                              activeClassName="bg-light">
                              <div className="only-icon-30 d-inline-block w-100">
                                <FaWifi color="#009688" />
                              </div>
                              <b className="m-0px small mt-2 text-dark">DATA</b>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                {history.location.pathname == "/" ? (
                  <div>
                    <Info />
                  </div>
                ) : (
                  <div className="card mt-2">
                    <div className="card-body">
                      <Route path="/pln" component={Pln} />
                      <Route path="/pulsa" component={Pulsa} />
                      <Route path="/bpjs" component={Bpjs} />
                      <Route path="/telkom" component={Telkom} />
                      <Route path="/pdam" component={Pdam} />
                      <Route path="/data" component={Data} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MenuProduk;
