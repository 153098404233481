import React, { PureComponent } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import ReactLoading from 'react-loading';
import LaddaButton from "react-ladda";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Select from "react-select";
import Moment from "react-moment";

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);
const logo_name = localStorage.getItem("logo");

class Users extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      dataUser: [],
      dataRoles: [],
      fullname: "",
      email: "",
      username: "",
      password: "",
      phone: "",
      type_saldo: "",
      role_id: 1,
      status: 1,
      logo: "",
      logo_url: "",
      selectedFiles: "",
      tagline: "",
      loading: false,
      login: false,
      token: localStorage.getItem("token"),
      user_ex: localStorage.getItem("user"),
      base_url: localStorage.getItem("logo") ? localStorage.getItem("logo").replace(/['"]+/g, '') : '',
      tagline: localStorage.getItem("tagline") ? localStorage.getItem("logo").replace(/['"]+/g, '') : '',
      store: null,
      setIsOpen: false,
      toogleBtn: true,
      typeModal: "",
      isAuth: 0,
      passwordShow: false,
      errValidate: [],
      hth_account_username: "",
      hth_account_password: "",
      hth_account_secretkey: "",
      loket_utama: "",
      modelDetail:false,
      modelDetailRow:[],
      fields: {},
      errors: {},
      formIsValid: true,
      f_name: "",
      f_email: "",
      f_no_hp: "",
      modelSukses: false,
    };
  }

  hitReject(id){
    Swal.fire({
      title: 'Yakin Reject ?',
      text: "Anda tidak akan dapat mengembalikan ini!",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons:true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Ya, Reject !'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_URL}/register/delete/`+id,
          {
            headers: {
              Authorization: "Bearer " + this.state.token,
              "Content-Type": "application/json",
            },
          }
        ).then((response)=>{
          this.fetchUsers();
          this.hideModalDetail();
          Swal.fire('Success!',response.data.data,'success');
        }).catch((response)=>{
          this.fetchUsers();
          this.hideModalDetail();
          Swal.fire('Warning!',response.data.data,'warning');
        })
      }
    })
  }

  hitGenerate(){
    Swal.fire({
      title: 'Apakah anda yakin ?',
      text: "Data akan di proses otomatis menjadi akun pelangi !",
      icon: 'question',
      showCancelButton: true,
      reverseButtons:true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Ya, proses !',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        //build param
        let payload= {
          "id":this.state.modelDetailRow.id,
          "company":this.state.modelDetailRow.company,
          "first_name": this.state.modelDetailRow.first_name,
          "last_name": this.state.modelDetailRow.last_name,
          "email":this.state.modelDetailRow.email,
          "phone": this.state.modelDetailRow.phone,
          "address": this.state.modelDetailRow.alamat_lengkap,
          "identitas": this.state.modelDetailRow.tipe_identitas,
          "nomor_identitas": this.state.modelDetailRow.nomor_identitas,
          "kode_pos": this.state.modelDetailRow.kode_pos,
          "kecamatan": this.state.modelDetailRow.kecamatan,
          "kelurahan": this.state.modelDetailRow.kelurahan,
          "kab_kota": this.state.modelDetailRow.kabupaten,
          "propinsi":this.state.modelDetailRow.propinsi,
          "action":true,
        }

        //send param
        axios.post(`${process.env.REACT_APP_URL}/generate_hth`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + this.state.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if(response.status==200){
            Swal.fire('Success!',response.data.data,'success');
            this.fetchUsers();
            this.hideModalDetail();
            // this.showModalSukses();
          }else{
            Swal.fire('Warning!',response.data.data,'warning');
          }
        }).catch((err)=>{
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
          // console.log(err);
        })
      }
    })
  };

  hitGenerateMan(){
    Swal.fire({
      title: 'Apakah anda yakin ?',
      text: "Data akan di proses Manual menjadi akun pelangi !",
      icon: 'question',
      showCancelButton: true,
      reverseButtons:true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Ya, proses !',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        //build param
        let payload= {
          "id":this.state.modelDetailRow.id,
          "company":this.state.modelDetailRow.company,
          "first_name": this.state.modelDetailRow.first_name,
          "last_name": this.state.modelDetailRow.last_name,
          "email":this.state.modelDetailRow.email,
          "phone": this.state.modelDetailRow.phone,
          "address": this.state.modelDetailRow.alamat_lengkap,
          "identitas": this.state.modelDetailRow.tipe_identitas,
          "nomor_identitas": this.state.modelDetailRow.nomor_identitas,
          "kode_pos": this.state.modelDetailRow.kode_pos,
          "kecamatan": this.state.modelDetailRow.kecamatan,
          "kelurahan": this.state.modelDetailRow.kelurahan,
          "kab_kota": this.state.modelDetailRow.kabupaten,
          "propinsi":this.state.modelDetailRow.propinsi,
          "action":false,
        }

        //send param
        axios.post(`${process.env.REACT_APP_URL}/generate_hth`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + this.state.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if(response.status==200){
            console.log(response.data.data.url);
            Swal.fire('Success!','Akun pengguna loket berhasil dibuat.<br>Silahkan buat akun pelangi untuk keperluan transaksi.','success').then((result) => {window.open(response.data.data.url, "_blank")});
            this.fetchUsers();
            this.hideModalDetail();
            // this.showModalSukses();
            
          }else{
            Swal.fire('Warning!',response.data.data,'warning');
          }
        }).catch((err)=>{
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
          // console.log(err);
        })
      }
    })
  };

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["hth_account_username"]) {
      formIsValid = false;
      errors["hth_account_username"] = "Username Harus Diisi";
    }
    if (!fields["hth_account_password"]) {
      formIsValid = false;
      errors["hth_account_password"] = "Password Harus Diisi";
    }
    if (!fields["hth_account_secretkey"]) {
      formIsValid = false;
      errors["hth_account_secretkey"] = "Secret key Harus Diisi";
    }
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Nama lengkap Harus Diisi";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password Harus Diisi";
    }

    this.setState({ formIsValie: formIsValid, errors: errors });
    
    return formIsValid;
  }
  showModal = (type, data) => {
    if (type == "Edit") {
      this.setState({
        id: data.id,
        fullname: data.name,
        email: data.email,
        username: data.username,
        hth_account_username: data.account_username,
        loket_utama: data.loket_utama,
        password: data.password,
        phone: data.phone,
        role_id: data.user_roles,
        status: data.status,
        logo: data.image_logo,
        logo_url: data.base_url,
        tagline: data.tagline,
        type_saldo: data.type_saldo,
        errValidate: []
      });

      if (data.status == 1) {
        this.setState({ toogleBtn: true });
      } else {
        this.setState({ toogleBtn: false });
      }
      // console.warn("data",data)
    } else {
      this.setState({
        id: 0,
        fullname: "",
        hth_account_username: "",
        hth_account_password: "",
        hth_account_secretkey: "",
        email: "",
        username: "",
        password: "",
        phone: "",
        role_id: 1,
        status: 1,
        logo: null,
        logo_url: null,
        tagline: "",
        errValidate: [],
        toogleBtn: true
      });
    }
    this.setState({ setIsOpen: true, typeModal: type });
  };

  showModalDetail = (data) => {
    // console.log(data);
    this.setState({ modelDetail: true,modelDetailRow:data});
  };

  showModalSukses = (data) => {
    // console.log(data);
    this.setState({ modelSukses: true,});
  };

  ApproveAction = (data) => {
    alert(data)
  };

  hideModal = () => {
    this.setState({ setIsOpen: false,formIsValie: true,errors: {} });
  };

  hideModalDetail = () => {
    this.setState({ modelDetail: false});
  };

  hideModalSukses = () => {
    this.setState({ modelSukses: false});
    window.open("http://202.152.61.101:7117", "_blank")
  };

  componentDidMount = async () => {
    await this.setState({ loading: true });
    await this.fetchUsers();
    await this.fetchRoleId();
    await this.setState({ loading: false });
  }
  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };
  filterData = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${process.env.REACT_APP_URL}/register/list?name=`+this.state.f_name+`&email=`+this.state.f_email+`&phone=`+this.state.f_no_hp,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({ loading: false, dataUser: response.data.data });
      });
  };
  reset = async () => {
    this.setState({ f_name: "", f_email: "", f_no_hp: "" });
    this.fetchUsers();
  };
  fetchUsers = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/register/list`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ dataUser: result.data });
      }).catch(function (err) {

        if (err.response.status == 500) {
          Swal.fire({
            title: "Maaf",
            text: "Maaf, terjadi kesalahan sistem [500]",
            icon: "error",
            confirmButtonText: "Oke",
          });
        } else {
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
          this.setState({ isAuth: 401 });
        }
      }.bind(this));
    });
  };
  fetchRoleId = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/roles`, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({ dataRoles: response.data.data });
      });
  };
  saveUser = async (typemodal) => {
      await this.createUser();
  };
  createUser = async () => {
    let validation = await this.handleValidation()

    if (!validation) {
      return
    }

    //console.log(`/register/list/`+this.state.id)
    await this.setState({ loading: true });

    let payload = {
      hth_account_username :this.state.hth_account_username,
      hth_account_password :this.state.hth_account_password,
      hth_account_secretkey :this.state.hth_account_secretkey,
      username :this.state.username,
      password :this.state.password,
    }


    axios
      .put(
        `${process.env.REACT_APP_URL}/register/aktivasi/`+this.state.id,
        payload,
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          Swal.fire({
            title: "Maaf",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false });
        this.fetchUsers();
      }).catch((err) => {
        // console.warn("error", err.response.data.message);
        if (err.response.data.status == 402) {
          this.setState({ errValidate: err.response.data.message });
          this.setState({ loading: false });
        } else if (err.response.data.status == 401) {
          Swal.fire({
            title: "Maaf",
            text: err.response.data.message || "Maaf, session Anda telah habis, silahkan login kembali",
            icon: "error",
            confirmButtonText: "Oke",
          });
          this.setState({ isAuth: 401 });
        } else {
          Swal.fire({
            title: "Error",
            text: err.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
          this.setState({ loading: false, setIsOpen: false });
          this.fetchUsers();
        }
      });
    // console.warn("data",this.state);
  };
  handleChangeRoleId = (data) => {
    this.setState({ role_id: data.value });
    // setRoleId({ label: data.label, value: data.value });
  };
  handleChangeTopup = (data) => {
    this.setState({ type_saldo: data.value });
    // setRoleId({ label: data.label, value: data.value });
  };
  togglePasswordVisiblity = () => {
    let showHide = this.state.passwordShow ? false : true
    this.setState({ passwordShow: showHide });
  };
  render() {
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Nama Pemilik</div>
        ),
        accessor: "full_name",
        style: {
          textAlign: "left",
          flexDirection: "column",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Nama Loket</div>
        ),
        accessor: "company",
        style: {
          textAlign: "left",
          flexDirection: "column",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Email</div>
        ),
        accessor: "email",
        style: {
          textAlign: "left",
          flexDirection: "column",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>No Hp</div>
        ),
        accessor: "phone",
        style: {
          textAlign: "left",
          flexDirection: "column",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Loket</div>
        ),
        accessor: "userid_cabang",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (data) => (
          <div>
          {data.original.userid_cabang == null ? (
            <span className="badge badge-primary" style={{ fontSize: "12px", letterSpacing: "1px", }}>
              PUSAT
            </span>
          ) : (
            <>
            <span className="badge badge-warning" style={{ fontSize: "12px", letterSpacing: "1px", }}>
              CABANG
            </span>
            </>
          )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Status</div>
        ),
        accessor: "userid_cabang",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (data) => (
          <div>
            {data.original.status_user == 'Belum Disetujui' ? (
              <span
                className="badge badge-secondary"
                style={{
                  fontSize: "12px",
                  letterSpacing: "1px",
                }}
              >
                Belum Disetujui
              </span>
            ) : (
              <span
                className="badge badge-info"
                style={{
                  fontSize: "12px",
                  letterSpacing: "1px",
                }}
              >
                Disetujui
              </span>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Created At</div>
        ),
        accessor: "created_at",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (data) => (
          <div>
            <Moment format="DD MMM YYYY - HH:mm:ss">{data.original.created_at}</Moment>
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
        ),
        accessor: "id",
        width: 120,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="badge badge-danger border-0 mr-1"
              onClick={() => this.hitReject(row.original.id)}
            >
              REJECT
            </button>
            <button
              className="badge badge-success border-0 mr-1"
              onClick={() => this.showModalDetail(row.original)}
            >
              PROSES
            </button>
          </div>
        ),
      },
    ];
    const optRoleId = this.state.dataRoles.map((d) => ({
      value: d.id,
      label: d.id + " - " + d.name.toUpperCase(),
    }));
    const optTopup = [{ value: "DEPOSIT", label: "DEPOSIT" },
    { value: "PLAFON", label: "PLAFON" },];
    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              <div className="card shadow mt-2 mb-3">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                              Filter data yang akan dicari
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Nama</label>
                          <input type="text" name="f_name" id="f_name" className="form-control" value={this.state.f_name} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Email</label>
                          <input type="text" name="f_email" id="f_email" className="form-control" value={this.state.f_email} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">No HP</label>
                          <input type="number" name="f_no_hp" id="f_no_hp" className="form-control" value={this.state.f_no_hp} onChange={this.handleChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.filterData();
                          }}
                        >Terapkan Filter</LaddaButton>
                      </div>
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={this.state.loading}
                          className="btn btn-danger m-btn-radius w-100"
                          data-size='s'
                          onClick={() => {
                            this.reset();
                          }}
                        >Reset Filter</LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-3 mt-2">
                  <div
                    className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-users text-primary h3 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary text-capitalize">VERIFIKASI AKUN PENGGUNA</span> <br />
                            {/* Berikut data riwayat request topup saldo */}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    {this.state.loading ? (
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="text-center"
                      />
                    ) : (
                      <>
                        <h4>
                          {/* <button
                            className="btn btn-primary"
                            onClick={() => this.showModal("Tambah")}
                          >
                            <i className="fa fa-plus"></i> Akun Pengguna
                          </button> */}
                        </h4>
                        <ReactTable
                          data={this.state.dataUser}
                          // pageSize={this.state.dataUser.length > 10 ? 10 : this.state.dataUser.length}
                          defaultPageSize={this.state.dataUser && this.state.dataUser.length > 10 ? 10 : 10}
                          columns={columns}
                          className="mt-3 table-striped w-100"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <Modal show={this.state.modelDetail} scrollable={true} size="lg">
          <Modal.Header>
            <div className="row col-md-12">
              <div className="col-md-6">
                <span className="h5">Detail Akun</span>
              </div>
              <div className="col-md-6 text-right">
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            {/* {this.state.modelDetailRow} */}
            <div className='table-responsive'>
               <table className='table'>
               <tr className='border-0'>
                  <td width={'35%'}>Nama Partner</td>
                  <td >: {this.state.modelDetailRow.company}</td>
                </tr>
                <tr>
                  <td>Nama Lengkap Pic</td>
                  <td>: {this.state.modelDetailRow.full_name}</td>
                </tr>
                <tr>
                  <td>Identitas</td>
                  <td >: {this.state.modelDetailRow.tipe_identitas}</td>
                </tr>
                <tr>
                  <td>Nomor Identitas</td>
                  <td>: {this.state.modelDetailRow.nomor_identitas}</td>
                </tr>
                <tr>
                  <td>Alamat Lengkap</td>
                  <td >: {this.state.modelDetailRow.alamat_lengkap}</td>
                </tr>
                <tr>
                  <td>Kode Pos</td>
                  <td >: {this.state.modelDetailRow.kode_pos}</td>
                </tr>
                <tr>
                  <td>Kabupaten</td>
                  <td>: {this.state.modelDetailRow.kabupaten}</td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>: {this.state.modelDetailRow.kecamatan}</td>
                </tr>
                <tr>
                  <td>Kelurahan</td>
                  <td>: {this.state.modelDetailRow.kelurahan}</td>
                </tr>
                <tr>
                  <td>Kabupaten / Kota</td>
                  <td>: {this.state.modelDetailRow.kabupaten}</td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>: {this.state.modelDetailRow.propinsi}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td >: {this.state.modelDetailRow.email}</td>
                </tr>
                <tr>
                  <td>Nomor Telephone</td>
                  <td >: {this.state.modelDetailRow.phone}</td>
                </tr>
               </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModalDetail()}
              className="btn btn-outline-light border-0 text-dark"
            >
              Tutup
            </button>
            <button
              onClick={() => this.hitGenerateMan()}
              className="btn btn-primary shadow"
            >
              Proses Manual
            </button>
            <button
              onClick={() => this.hitGenerate()}
              className="btn btn-primary shadow"
            >
              Proses Otomatis
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()} scrollable={true} size="lg">
          <Modal.Header>
            {/* <Modal.Title> */}
            <div className="row col-md-12">
              <div className="col-md-6">
                <span className="h5">Verifikasi Pengguna </span>
              </div>
              <div className="col-md-6 text-right">
                {/* <span className="h5 text-secondary">{this.state.typeModal == "Edit" && this.state.hth_account_username == null ? ("Loket Pusat : " + this.state.loket_utama) : ("")}</span> */}
              </div>
            </div>
            {/* </Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <form id="uploadForm" encType="multipart/form-data">
            
            {/* hth input */}
            <fieldset className="h5 font-weight-bold text-dark">Akun H2H</fieldset>

            <div className="form-group">
              <label>
                Username<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="hth_account_username"
                className="form-control"
                required="required"
                value={this.state.hth_account_username}
                onChange={this.handleChange}
              />
              <small style={{ color: "red" }}>{this.state.errors["hth_account_username"]}</small>
            </div>

            <label>
              Password<sup className="text-danger">*</sup>
            </label>
            <div className="input-group">
              <input
                type={this.state.passwordShow ? "text" : "password"}
                name="hth_account_password"
                className="form-control"
                required="required"
                value={this.state.hth_account_password}
                onChange={this.handleChange}
              />
              <div className="input-group-prepend" style={{ cursor: "pointer" }}>
                <span
                  className="input-group-text bg-light"
                  id="inputGroup-sizing-default"
                >
                  <a href="#" onClick={this.togglePasswordVisiblity} >{this.state.passwordShow ? "Hide" : "Show"}</a>
                </span>
              </div>
            </div>
            <small style={{ color: "red" }}>{this.state.errors["hth_account_password"]}</small>
            
            <div className="form-group mt-3">
              <label>
                Secret Key<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="hth_account_secretkey"
                className="form-control"
                required="required"
                value={this.state.hth_account_secretkey}
                onChange={this.handleChange}
              />

              <small style={{ color: "red" }}>{this.state.errors["hth_account_secretkey"]}</small>
            </div>
            
            <fieldset className="h5 font-weight-bold text-dark">Akun Pengguna</fieldset>

              <div className="form-group">
                <label>
                  Username<sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  required="required"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
                <small style={{ color: "red" }}>{this.state.errors["username"]}</small>
              </div>
              <label>
                Password<sup className="text-danger">*</sup>
              </label>
              <div className="input-group">
                <input
                  type={this.state.passwordShow ? "text" : "password"}
                  name="password"
                  className="form-control"
                  required="required"
                  value={this.state.password}
                  onChange={this.handleChange}
                />

                <div className="input-group-prepend" style={{ cursor: "pointer" }}>
                  <span
                    className="input-group-text bg-light"
                    id="inputGroup-sizing-default"
                  >
                    <a href="#" onClick={this.togglePasswordVisiblity} >{this.state.passwordShow ? "Hide" : "Show"}</a>
                  </span>
                </div>
              </div>
              <small style={{ color: "red" }}>{this.state.errors["password"]}</small>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Batal
            </button>
            <LaddaButton
              loading={this.state.loading}
              onClick={() => {
                this.saveUser(this.state.typeModal);
              }}
              className="btn btn-primary"
            >
              Simpan
            </LaddaButton>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modelSukses} scrollable={true} size="s">
        <Modal.Header>
            <div className="row col-md-12">
              <div className="col-md-12 text-center">
                <span className="h5">SUKSES</span>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="row col-md-12">
              <div className="col-md-12 text-center">
                <span className="">Akun pengguna loket berhasil dibuat.<br></br></span>
                <span className="">Silahkan buat akun pelangi untuk keperluan transaksi.</span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModalSukses()}
              className="btn btn-primary shadow"
            >
              OK
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Users;