import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { AuthContext, CartContext } from "../../router/Router";
import Avatar from "react-avatar";
import Swal from "sweetalert2";

function Navbar_admin({ children }) {
  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const { value, setValue } = useContext(CartContext)
  const logo_name = localStorage.getItem("logo");
  const isBranch = localStorage.getItem("isBranch");

  return (
    <div>
      <header
        className="header-nav header-dark header-bg hidden-onphone"
        style={{ minHeight: "78px" }}
      >
        <div className="fixed-header-bar">
          <div className="navbar navbar-main navbar-expand-lg">
            <div className="container">
              <NavLink className="navbar-brand" to="/pln">
                {/* {tagline} */}
                <img
                  className="logo-dark"
                  alt=""
                  width="80px"
                  height="65px"
                  title=""
                  src={logo_name == "null" ? "/img/loketpelangi.png" : `${process.env.REACT_APP_URL}/images/${logo_name}`}
                />
                <img
                  className="logo-light"
                  alt=""
                  width="80px"
                  height="65px"
                  title=""
                  src={logo_name == "null" ? "/img/loketpelangi.png" : `${process.env.REACT_APP_URL}/images/${logo_name}`}
                />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-main-collapse"
                aria-controls="navbar-main-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse navbar-collapse-overlay"
                id="navbar-main-collapse"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <NavLink className="nav-link mt-1" to="/users/dashboard">
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item px-dropdown mt-1">
                    <a className="nav-link" href="#">
                      Transaksi
                    </a>
                    <ul className="px-dropdown-menu mm-dorp-in">
                      <li>
                        <NavLink to={state.role == "1" ? "/users/riwayat/topupsaldo" : "/users/transaksi/requestsaldo"} href="#">
                          Request Topup Saldo
                        </NavLink>
                      </li>
                      {state.role == "1" ? ( 
                        <li>
                          <NavLink to="/users/transaksi/manualtopup" href="#">
                            Manual Topup Saldo
                          </NavLink>
                        </li>
                      ):(<></>)}
                      
                      <li>
                        <NavLink to="/users/transaksi/manualadvice" href="#">
                          Cek Advice
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/users/transaksi/updatestatustrx" href="#">
                          Update Status Transaksi
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/users/transaksi/transaksirevisi" href="#">
                          Transaksi Revisi
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/users/transaksi/transaksiTransferDana" href="#">
                          Transfer Dana
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item  px-dropdown mt-1">
                    <a className="nav-link" href="#">
                      Produk
                    </a>
                    <ul className="px-dropdown-menu mm-dorp-in">
                      {state.role == "1" ? (
                        <>
                          <li>
                            <NavLink to="/category" href="#">
                              Daftar Category
                            </NavLink>
                          </li>
                        </>
                      ) : (<></>)}
                      <li>
                        <NavLink to="/users/produk/listharga">
                          Daftar Harga
                        </NavLink>
                      </li>
                      {state.role == "1" ? (
                        <>
                          <li>
                            <NavLink to="/produk/setting" href="#">
                              Daftar Produk
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/produk/price" href="#">
                              Daftar Produk Price
                            </NavLink>
                          </li>
                        </>
                      ) : (<></>)}
                    </ul>
                  </li>
                  <li className="nav-item  px-dropdown mt-1">
                    <a className="nav-link" href="#">
                      Riwayat
                    </a>
                    <ul className="px-dropdown-menu mm-dorp-in">
                      <li>
                        <NavLink to="/users/transaksi/closing/list">
                          Closing Transaksi
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/users/riwayat/pembayaran">
                          Status Pembayaran
                        </NavLink>
                      </li>
                      {state.role == "2" ? (
                        <>
                          <li>
                            <NavLink to="/users/riwayat/topupsaldo">
                              Request Topup Saldo
                            </NavLink>
                          </li>
                        </>
                      ) : (<></>)}
                    </ul>
                  </li>
                  <li className="nav-item  px-dropdown mt-1">
                    <a className="nav-link" href="#">
                      Pengaturan
                    </a>
                    <ul className="px-dropdown-menu mm-dorp-in">
                      {state.role == "2" ? (
                        <>
                          <li>
                            <NavLink to="/users/setting/adminpulsa" href="#">
                              Admin Pulsa / Data
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/users/setting/biayaloket" href="#">
                              Biaya Layanan Loket
                            </NavLink>
                          </li>
                        </>
                      ) : (<>
                        <li>
                          <NavLink to="/users/setting/denomprepaid" href="#">
                            Denom PLN Prepaid
                          </NavLink>
                        </li>
                        
                        <li>
                          <NavLink to="/adpertanyaanumum" href="#">
                            Pusat Bantuan
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/adsyaratketentuan" href="#">
                            Syarat Dan Ketentuan
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/adhubungikami" href="#">
                            Kontak Kami
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/users/setting/payment-methods" href="#">
                            Metode Pembayaran
                          </NavLink>
                        </li>
                      </>)}

                      {
                        isBranch == "false" ? (
                          <>
                            <li>
                              <NavLink to="/users/setting/akunpengguna" href="#">
                                Akun Petugas Loket
                              </NavLink>
                            </li>
                          </>
                        ) : (<></>)
                      }


                    </ul>
                  </li>

                  <li className="nav-item  px-dropdown">
                    <a className="nav-link" href="#"><span className="mr-2 text-muted">{state.user}</span>
                      <Avatar
                        name={state.user}
                        size={32}
                        fgColor="#b9cbfb"
                        color="#ebf4fe"
                        round="50px"
                        textSizeRatio="2"
                      />
                    </a>
                    <ul className="px-dropdown-menu mm-dorp-in">
                      {
                        state.role != "1" ? (
                          <>
                            <li>
                              <NavLink to="/pln" href="#" className="text-dark">
                                <i className="fa fa-credit-card text-primary"></i> Menu Transaksi
                              </NavLink>
                            </li>
                          </>
                        ) : (<></>)
                      }

                      {
                        state.role == "1" ? (
                        <>
                              <li>
                                <NavLink to="/users/setting/verifikasi" href="#">
                                <i className="fa fa-user text-primary"></i> Data Registrasi
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/users/changepin" href="#" className="text-dark">
                                  <i className="fa fa-lock text-primary"></i> Ganti PIN
                                </NavLink>
                              </li>
                        </>
                        ):(<></>)
                      }
                      <li>
                        <NavLink to="/users/changepassword" href="#" className="text-dark">
                          <i className="fa fa-key text-primary"></i> Ganti Password
                        </NavLink>
                      </li>
                      {
                        state.role != "1" ? (
                          <>
                            <li>
                              <NavLink to="/users/changelogo" href="#" className="text-dark">
                                <i className="fa fa-image text-primary"></i> Ganti Logo
                              </NavLink>
                            </li>
                          </>
                        ) : (<></>)
                      }
                      <li>
                        <a
                          role={'button'}
                          className="text-danger"
                          onClick={() =>
                            Swal.fire({
                              title: "Yakin keluar  ?",
                              text: "",
                              icon: "info",
                              showCancelButton: true,
                              reverseButtons: true,
                              confirmButtonText: "Keluar",
                              cancelButtonText: "Batal",
                            }).then((result) => {
                              if (result.value) {
                                setTimeout(() => {
                                  dispatch({
                                    type: "LOGOUT",
                                  });
                                }, 500);
                              } else if (
                                result.dismiss === Swal.DismissReason.cancel
                              ) {
                                //cencle
                              }
                            })
                          }
                        >
                          <i className="fa fa-power-off text-danger"></i> Keluar
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="box-shadow shadow-lg">
        <nav className="navbar navbar-dark navbar-expand d-md-none d-lg-none d-xl-none fixed-bottom show-onphone bg-light border-top">
          <ul className="navbar-nav nav-justified w-100">
            {state.role != "1" ? (
              <>
                <li className="nav-item">
                  <NavLink to="/pln" className="nav-link">
                    <i className="fa fa-home text-secondary p-0px-t"><br /><span className="label-nav">Beranda</span></i>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users/riwayat/pembayaran" className="nav-link"><i className="fa fa-search text-secondary p-0px-t"><br /><span className="label-nav">Cari</span></i></NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/cart" className="nav-link border border-radius-15 shadow-lg" style={{ backgroundColor: "#2196F3" }}><i className="font-large-1 icon-line-height fa fa-shopping-cart text-white"></i>
                    <span className="badge badge-pill badge-glow badge-danger badge-up">{value}</span> <br /></NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users/dashboard" className="nav-link"><i className="fa fa-money-bill-wave text-secondary p-0px-t"><br /><span className="label-nav">Transaksi</span></i></NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users/panel" className="nav-link"><i className="fa fa-user text-secondary p-0px-t"><br /><span className="label-nav">Panel</span></i></NavLink>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <NavLink to="/users/dashboard" className="nav-link">
                    <i className="fa fa-home text-secondary p-0px-t"><br /><span className="label-nav">Dashboard</span></i>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users/riwayat/pembayaran" className="nav-link"><i className="fa fa-search text-secondary p-0px-t"><br /><span className="label-nav">Cari</span></i></NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users/riwayat/topupsaldo" className="nav-link"><i className="fa fa-wallet text-secondary p-0px-t"><br /><span className="label-nav">Topup</span></i></NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users/transaksi/closing/list" className="nav-link"><i className="fa fa-file-alt text-secondary p-0px-t"><br /><span className="label-nav">Closing</span></i></NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users/panel" className="nav-link"><i className="fa fa-bars text-secondary p-0px-t"><br /><span className="label-nav">Menu</span></i></NavLink>
                </li>
              </>)}
          </ul>
        </nav>
      </div>
      <div className="mains">{children}</div>
    </div>
  );
}

export default Navbar_admin;