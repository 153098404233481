import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { FaCheckCircle, FaWhatsapp, FaTimesCircle } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import NumberFormat from "react-number-format";
import axios from "axios";
import ReactLoading from "react-loading";
import { AuthContext } from "../../router/Router";
import { PlnPrepaid } from "../Element/PlnPrepaid";
import { PlnPostpaid } from "../Element/PlnPostpaid";
import { Bpjs } from "../Element/Bpjs";
import { PulsaData } from "../Element/PulsaData";
import { Telkom } from "../Element/Telkom";
import { PDAM } from "../Element/PDAM";
import Moment from "react-moment";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import Collapsible from 'react-collapsible';
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import ReactToPrint from "react-to-print";
import StrukComponent from "../front/StrukComponent";

const ProdukData = ({
  data = null,
  product_type = null,
  adminBank = 0,
}) => {
  // console.warn("produk", kel_produk)
  if (data.product_type == "PLN" && data.product_name.toUpperCase().includes("POSTPAID")) {
    return <PlnPostpaid struk={data} />;
  } else if (data.product_type == "PLN" && data.product_name.toUpperCase().includes("PREPAID")) {
    return <PlnPrepaid struk={data} />;
  } else if (data.product_type == "BPJS") {
    return <Bpjs struk={data} />;
  } else if (data.product_type == "TELKOM") {
    return <Telkom struk={data} />;
  } else if (data.product_type == "PULSA" || data.product_type == "PAKET DATA") {
    return (
      <PulsaData
        struk={data}
        adminBank={adminBank}
        product_type={product_type}
      />
    );
  } else if (data.product_type == "PDAM") {
    return <PDAM struk={data} />;
  } else {
    return (
      <div className="text-center w-100">
        Kesahalah parsing data
      </div>
    );
  }
};
const Header = ({
  data = null,
  product_type = null,
  transaction = null,
}) => {
  if (data.rc == "0000" || data.rc == "00") {
    // console.warn("produk", data.product_type)
    if (data.product_type == "PLN" && data.product_name.toUpperCase().includes("POSTPAID")) {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()} <span className="float-right"><Moment format="DD MMM YYYY HH:mm:ss">{data.datetime.substr(0, 4) + "-" + data.datetime.substr(4, 2) + "-" + data.datetime.substr(6, 2) + " " + data.datetime.substr(8, 2) + ":" + data.datetime.substr(10, 2) + ":" + data.datetime.substr(12, 2)}</Moment></span></h6>
            </div>
          </div>
          <div className="card border border-primary">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Pembayaran</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={parseInt(data.amount) + parseInt(data.loket_fee)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (data.product_type == "PLN" && data.product_name.toUpperCase().includes("PREPAID")) {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()} <span className="float-right"><Moment format="DD MMM YYYY HH:mm:ss">{data.datetime.substr(0, 4) + "-" + data.datetime.substr(4, 2) + "-" + data.datetime.substr(6, 2) + " " + data.datetime.substr(8, 2) + ":" + data.datetime.substr(10, 2) + ":" + data.datetime.substr(12, 2)}</Moment></span></h6>
            </div>
          </div>
          <h6 className="mt-1">Token</h6>
          <div className="card border border-warning">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-12 col-12 text-center m-auto text-info">
                  <h4 className="text-dark hidden-onphone">
                    <NumberFormat
                      format="#### - #### - #### - #### - ####"
                      value={data.token}
                      displayType={"text"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h4>
                  <h4 className="text-dark h6 show-onphone">
                    <NumberFormat
                      format="#### - #### - #### - #### - ####"
                      value={data.token}
                      displayType={"text"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <h6 className="mt-3">Jumlah Kwh : {data.jml_kwh}</h6>
          <div className="card border">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Bayar</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={parseInt(data.amount) + parseInt(data.loket_fee)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (data.product_type == "BPJS") {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()} <span className="float-right"><Moment format="DD MMM YYYY HH:mm:ss">{data.datetime.substr(0, 4) + "-" + data.datetime.substr(4, 2) + "-" + data.datetime.substr(6, 2) + " " + data.datetime.substr(8, 2) + ":" + data.datetime.substr(10, 2) + ":" + data.datetime.substr(12, 2)}</Moment></span></h6>
            </div>
          </div>
          <div className="card border border-primary">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Pembayaran</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={parseInt(data.amount) + parseInt(data.loket_fee)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (data.product_type == "TELKOM") {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()} <span className="float-right"><Moment format="DD MMM YYYY HH:mm:ss">{data.datetime.substr(0, 4) + "-" + data.datetime.substr(4, 2) + "-" + data.datetime.substr(6, 2) + " " + data.datetime.substr(8, 2) + ":" + data.datetime.substr(10, 2) + ":" + data.datetime.substr(12, 2)}</Moment></span></h6>
            </div>
          </div>
          <div className="card border border-primary">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Pembayaran</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={parseInt(data.jumlah_bayar) + parseInt(data.loket_fee)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (data.product_type == "PDAM") {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()} <span className="float-right"><Moment format="DD MMM YYYY HH:mm:ss">{data.waktu_lunas.substr(0, 4) + "-" + data.waktu_lunas.substr(4, 2) + "-" + data.waktu_lunas.substr(6, 2) + " " + data.waktu_lunas.substr(8, 2) + ":" + data.waktu_lunas.substr(10, 2) + ":" + data.waktu_lunas.substr(12, 2)}</Moment></span></h6>
            </div>
          </div>
          <div className="card border border-primary">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Pembayaran</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={parseInt(data.amount) + parseInt(data.loket_fee)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (data.product_type == "PULSA" || data.product_type == "PAKET DATA") {
      if (data.code == "0000") {
        // console.log(product_type);
        return (
          <div>
            <div className="row">
              <div className="col-md-12 col-12">
                <h6>{product_type} <span className="float-right"><Moment format="DD MMM YYYY HH:mm:ss">{data.datetime.substr(0, 4) + "-" + data.datetime.substr(4, 2) + "-" + data.datetime.substr(6, 2) + " " + data.datetime.substr(8, 2) + ":" + data.datetime.substr(10, 2) + ":" + data.datetime.substr(12, 2)}</Moment></span></h6>
              </div>
            </div>

            <div className="card border border-primary">
              <div className="card-header">
                <div className="row mt-2">
                  <div className="col-md-6 col-6 text-left m-auto">
                    <h6>Total Pembayaran</h6>
                  </div>
                  <div className="col-md-6 col-6 text-right m-auto">
                    <h6>
                      <NumberFormat
                        value={parseInt(data.price) + parseInt(data.admin_fee) + parseInt(data.loket_fee)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <div>
          Kesalahan mengambil data transaksi
        </div>
      );
    }
  } else if (data.rc == "0001" || data.rc == "01") {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-12">
            <h6>{data.product_name} <span className="float-right"><Moment format="DD MMM YYYY HH:mm:ss">{data.datetime.substr(0, 4) + "-" + data.datetime.substr(4, 2) + "-" + data.datetime.substr(6, 2) + " " + data.datetime.substr(8, 2) + ":" + data.datetime.substr(10, 2) + ":" + data.datetime.substr(12, 2)}</Moment></span></h6>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-12">
            <h6>
              REFOUND {transaction.description} - {transaction.idpel}
            </h6>
          </div>
        </div>
        <div className="card border">
          <div className="card-header">
            <div className="row mt-2">
              <div className="col-md-6 col-6 text-left m-auto">
                <h6>Total Refound</h6>
              </div>
              <div className="col-md-6 col-6 text-right m-auto">
                <h6>
                  <NumberFormat
                    value={transaction.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp"}
                    renderText={(value) => <div>{value}</div>}
                  />
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function Invoice(props) {
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState([]);
  const [isAuth, setAuth] = useState(0);
  const [strukData, setStruk] = useState([]);
  const [favorite, setFavorite] = useState([]);
  const [displayStruk, setDisplayStruk] = useState({ display: "none", width: "60mm", fontSizeProduk: "12px" });
  const [transaction, setTransaction] = useState([]);
  let identifier = useParams();
  const [listIdpelInv, setIdpel] = useState([]);
  const { dispatch, state } = useContext(AuthContext);
  const [totalBayar, setTotalBayar] = useState(0);
  const token = localStorage.getItem("token");

  const getData = async () => {
    await setLoading(true);
    await state;
    var token = localStorage.getItem("token");
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_URL}/loket/transaction/reprint/${identifier.Id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      ).catch(async (err) => {
        // console.log(err.response.data)
        await Swal.fire({
          title: "Maaf",
          text: err.response.data.message || "Silahkan dicoba beberapa saat lagi",
          icon: "error",
          confirmButtonText: "Oke",
        });
        await setAuth(err.response.data.status)
      });
      // console.warn("resp struk", response.data.data);
      await setStruk(response.data.data);
      await strukData.map(function (struk, index) {
        if (struk.product_type == "PLN") {
          listIdpelInv[index] = struk.subscriber_id;
        }
        if (struk.product_type == "TELKOM") {
          listIdpelInv[index] = struk.id_pelanggan;
        }
        if (struk.product_type == "BPJS") {
          listIdpelInv[index] = struk.no_va;
        }
        if (struk.product_type == "PDAM") {
          listIdpelInv[index] = struk.idpel;
        }
        if (struk.product_type == "PULSA" || struk.product_type == "PAKET DATA") {
          listIdpelInv[index] = struk.msisdn;
        }
      })
      await console.log(listIdpelInv)
      if (response.data.data[0].payment.type == "tunai") {
        await setTotalBayar(parseInt(response.data.data[0].payment.uang_bayar) - parseInt(response.data.data[0].payment.uang_sisa));
      } else {
        let total = response.data.data.reduce((totalBayar, struk) => totalBayar = totalBayar + parseInt(struk.payment.uang_bayar), 0)
        await setTotalBayar(total);
      }
    } catch (e) {

    }
    await setLoading(false);
  };
  const multiProdukRef = useRef(null);

  useEffect(() => {
    getData();
    fetchIdpelFav();
  }, [identifier, setStruk]);
  const handlePrint = async (produk, no_struk = '') => {
    htmlToPdf(produk, identifier.Id, no_struk)
    const timeId = setTimeout(() => {
      setDisplayStruk({ display: "none", width: "60mm", fontSizeProduk: "12px" });
    }, 2000);
    return () => {
      clearTimeout(timeId);
    };
  }
  const htmlToPdf = async (produk = '', no_nota = '', no_struk = '') => {
    const doc = new jsPDF('p', 'mm', [60, 220])
    // var nota = 'Nota : '+no_nota+' / '+waktu_lunas;
    var nota = no_nota.substr(9, 24);
    if (produk == "all") {
      for (var i = 0; i < strukData.length; i++) {
        var produkName = '';
        if (strukData[i].product_type.toUpperCase() == "PULSA" || strukData[i].product_type.toUpperCase() == "PAKET DATA") {
          produkName = strukData[i].product_type.toUpperCase();
        } else {
          produkName = strukData[i].product_name.toUpperCase();
        }
        if (i == 0) {
          await autoTable(doc, {
            theme: 'plain', columnStyles: {
              0: {
                columnWidth: 'auto',
                cellPadding: 0
              }
            },
            styles: { halign: 'center', fontStyle: 'bold' },
            margin: { top: 35, left: 0, right: 0, bottom: 0 },
            body: [
              [produkName]
            ],
          });
        } else {
          await autoTable(doc, {
            theme: 'plain', columnStyles: {
              0: {
                columnWidth: 'auto',
                cellPadding: 0
              }
            },
            styles: { halign: 'center', fontStyle: 'bold' },
            // margin: { top: 0, left: 0, right: 0, bottom: 0 },
            body: [
              [produkName]
            ],
          });
        }
        if (strukData[i].product_type == "PDAM") {
          var date_time = strukData[i].waktu_lunas.substr(0, 4) + "-" + strukData[i].waktu_lunas.substr(4, 2) + "-" + strukData[i].waktu_lunas.substr(6, 2) + " " + strukData[i].waktu_lunas.substr(8, 2) + ":" + strukData[i].waktu_lunas.substr(10, 2) + ":" + strukData[i].waktu_lunas.substr(12, 2)
        } else {
          var date_time = strukData[i].datetime.substr(0, 4) + "-" + strukData[i].datetime.substr(4, 2) + "-" + strukData[i].datetime.substr(6, 2) + " " + strukData[i].datetime.substr(8, 2) + ":" + strukData[i].datetime.substr(10, 2) + ":" + strukData[i].datetime.substr(12, 2)
        }

        await autoTable(doc, {
          theme: 'plain', columnStyles: {
            0: {
              columnWidth: 'auto',
              cellPadding: -6
            }
          },
          styles: { halign: 'center', fontStyle: 'bold' },
          margin: { top: 0, left: 0, right: 0, bottom: 0 },
          body: [
            [date_time]
          ],
        });
        await autoTable(doc, {
          theme: 'plain', columnStyles: {
            2: {
              columnWidth: 'auto',
            }
          }, didDrawPage: function (data) {
            var pageHeight = doc.internal.pageSize.height;
            var pageWidth = doc.internal.pageSize.width;
            if (i == 0) {
              doc.setFontSize(14);
              doc.text(state.fullname.toUpperCase(), pageWidth / 2, pageHeight - 210, { align: 'center' });
              doc.setFontSize(9);
              doc.text("STRUK PEMBELIAN PRODUK", pageWidth / 2, pageHeight - 203, { align: 'center' });
              doc.setFontSize(10);
              doc.text("Order ID : " + nota, pageWidth / 2, pageHeight - 195, { align: 'center' });
            }

          }, pageBreak: 'auto', margin: { top: 5, left: 0, right: 0, bottom: 3 }, styles: { fontSize: 10, cellWidth: 'wrap' }, html: '#produkPdf' + i
        });
      }
    } else {
      var produkName = '';
      if (strukData[no_struk].product_type.toUpperCase() == "PULSA" || strukData[no_struk].product_type.toUpperCase() == "PAKET DATA") {
        produkName = strukData[no_struk].product_type.toUpperCase();
      } else {
        produkName = strukData[no_struk].product_name.toUpperCase();
      }
      await autoTable(doc, {
        theme: 'plain', columnStyles: {
          0: {
            columnWidth: 'auto',
            cellPadding: 0
          }
        },
        styles: { halign: 'center', fontStyle: 'bold' },
        margin: { top: 35, left: 0, right: 0, bottom: 0 },
        body: [
          [produkName]
        ],
      });
      if (strukData[no_struk].product_type == "PDAM") {
        var date_time = strukData[no_struk].waktu_lunas.substr(0, 4) + "-" + strukData[no_struk].waktu_lunas.substr(4, 2) + "-" + strukData[no_struk].waktu_lunas.substr(6, 2) + " " + strukData[no_struk].waktu_lunas.substr(8, 2) + ":" + strukData[no_struk].waktu_lunas.substr(10, 2) + ":" + strukData[no_struk].waktu_lunas.substr(12, 2)
      } else {
        var date_time = strukData[no_struk].datetime.substr(0, 4) + "-" + strukData[no_struk].datetime.substr(4, 2) + "-" + strukData[no_struk].datetime.substr(6, 2) + " " + strukData[no_struk].datetime.substr(8, 2) + ":" + strukData[no_struk].datetime.substr(10, 2) + ":" + strukData[no_struk].datetime.substr(12, 2)
      }
      await autoTable(doc, {
        theme: 'plain', columnStyles: {
          0: {
            columnWidth: 'auto',
            cellPadding: -6
          }
        },
        styles: { halign: 'center', fontStyle: 'bold' },
        margin: { top: 0, left: 0, right: 0, bottom: 0 },
        body: [
          [date_time]
        ],
      });
      await autoTable(doc, {
        theme: 'plain', columnStyles: {
          2: {
            columnWidth: 'auto',
          }
        }, didDrawPage: function (data) {
          var pageHeight = doc.internal.pageSize.height;
          var pageWidth = doc.internal.pageSize.width;
          doc.setFontSize(14);
          doc.text(state.fullname.toUpperCase(), pageWidth / 2, pageHeight - 210, { align: 'center' });
          doc.setFontSize(9);
          doc.text("STRUK PEMBELIAN PRODUK", pageWidth / 2, pageHeight - 203, { align: 'center' });
          doc.setFontSize(10);
          doc.text("Order ID : " + nota, pageWidth / 2, pageHeight - 195, { align: 'center' });
        }, pageBreak: 'auto', margin: { top: 0, left: 0, right: 0, bottom: 0 }, styles: { fontSize: 10, cellWidth: 'wrap' }, html: '#' + produk
      });
    }

    if (produk == "all") {
      await autoTable(doc, {
        theme: 'plain', columnStyles: {
          2: {
            columnWidth: 'auto',
          }
        }, pageBreak: 'auto', margin: { top: 0, left: 0, right: 0, bottom: 0 }, styles: { fontSize: 10, cellWidth: 'wrap', fontStyle: 'bold' }, html: '#tableTotal'
      });
    }
    await doc.save('struk_' + nota + '.pdf')
  }
  const addToFavorite = async (prod_id, index) => {
    let arrSearch = favorite.filter(
      (rows) => rows.idpel == listIdpelInv[index] && rows.product_id == prod_id)

    if (arrSearch.length == 0) {
      await axios.post(
        `${process.env.REACT_APP_URL}/favorite/create`,
        {
          product_id: prod_id,
          idpel: listIdpelInv[index],
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (json) => {
          if (json.data.status == 200) {
            await Swal.fire({
              position: 'top-end',
              // icon: 'success',
              html: '<i class="fa fa-check-circle text-success"></i> Idpel '+ listIdpelInv[index] +' berhasil ditambahkan kefavorite',
              showConfirmButton: false,
              timer: 1300
            })
            await fetchIdpelFav();
          } else {
            Swal.fire({
              title: "Sukses",
              text: json.data.message,
              icon: "error",
              confirmButtonText: "Tutup",
            });
          }
          // console.warn("response", json);
          // setLoading(false)
          
        });
    }
  };
  const removeFavorite = async (prod_id, index) => {
    let arrSearch = favorite.filter(
      (rows) => rows.idpel == listIdpelInv[index] && rows.product_id == prod_id)
    if (arrSearch.length > 0) {
      for (let i = 0; i < arrSearch.length; i++) {
        await axios.delete(
          `${process.env.REACT_APP_URL}/favorite/delete/`+arrSearch[i].id,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
          .then(async (json) => {
            if (json.data.status == 200) {
              await Swal.fire({
                position: 'top-end',
                // icon: 'success',
                html: '<i class="fa fa-check-circle text-success"></i> Idpel '+arrSearch[i].idpel+' berhasil dihapus dari favorite',
                showConfirmButton: false,
                timer: 1300
              })
              await fetchIdpelFav();
            } else {
              Swal.fire({
                title: "Sukses",
                text: json.data.message,
                icon: "error",
                confirmButtonText: "Tutup",
              });
            }
            // console.warn("response", json);
            // setLoading(false)
          });
      }
    }
  }
  const fetchIdpelFav = async () => {
    // setLoading(true)
    await fetch(`${process.env.REACT_APP_URL}/favorite/list`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then(async (result) => {
        await setFavorite(result.data)
        // await console.warn("set favorite", result.data);
        // await setLoading(false);
      });
    });
  };
  if (isAuth === 401) {
    dispatch({
      type: "LOGOUT",
    });
  }
  const FavoriteComp = ({ prod_id, index }) => {
    let arrSearch = favorite.filter(
      (rows) => rows.idpel == listIdpelInv[index] && rows.product_id == prod_id)
    // console.warn("arr search", arrSearch)
    if (arrSearch.length > 0) {
      return (
        <>
          <i className="fa fa-star text-warning ml-2" title="Hapus favorite" onClick={() => removeFavorite(prod_id, index)}></i>
        </>
      )
    } else {
      return (
        <>
          <i className="fa fa-star text-secondary ml-2" title="Tambah favorite" onClick={() => addToFavorite(prod_id, index)}></i>
        </>
      )
    }
  }
  return (
    <div>
      {loading ? (
        <div>
          <section
            className="section g-bg-gray p-0px-t bg-section"
            style={{ display: display }}>
            <div className="container" style={{ maxWidth: "600px" }}>
              <div className="row">
                <div className="col-sm-12 col-lg-12 col-12 m-15px-tb ">
                  <div className="card border-radius-2 text-center m-auto py-3">
                    <ReactLoading
                      type={"spin"}
                      color={"#007bff"}
                      height={"10%"}
                      width={"10%"}
                      className="mx-auto my-5"
                    />
                    <h6>Mengambil Data</h6>
                    <label htmlFor="loading">
                      Tunggu sebentar kami sedang mengambil data transaksi anda{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <>
          {strukData.length != 0 ? (
            <div>
              <section className="section g-bg-gray p-0px-t bg-section">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card mt-2">
                        <div className="row">
                          <div className="col-md-7 col-sm-12 border-right border-default">
                            {strukData.map((struk, index) => (
                              <>
                                <div className={strukData.length > 1 ? "card border-0" : "card border-0"} >
                                  <div className="card-header">
                                    {struk.rc == "0000" || struk.rc == "00" || struk.code == "0000" ? (
                                      <div>
                                        <FaCheckCircle className="mr-2 text-success" />
                                        <label>Transaksi Sukses</label>
                                        <span className="float-right h6 mt-2 text-danger">{struk.trx_id} <FavoriteComp prod_id={struk.product_id} index={index} /></span>
                                      </div>
                                    ) : (
                                      <div>
                                        {struk.rc == "0090" || struk.rc == "0001" || struk.rc == "01" ? (
                                          <div>
                                            <FaClock className="mr-2 text-warning" />
                                            <label>Transaksi Pending </label>
                                            <span className="float-right text-danger h6 mt-2">{struk.trx_id} <FavoriteComp prod_id={struk.product_id} index={index} /></span>
                                          </div>
                                        ) : (
                                          <div>
                                            <FaClock className="mr-2 text-warning" />
                                            <label>
                                              Transaksi Tidak di temukan
                                            </label>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="card-body table-responsive">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Header
                                          data={struk}
                                          product_type={struk.product_type}
                                          transaction={transaction}
                                          product_name={struk.product_name}
                                        />

                                        {struk.rc != "0000" ||
                                          struk.rc != "00" ||
                                          struk.code != "0000" ? (

                                          <>
                                            {struk.rc == "0001" || struk.rc == "01" ? (
                                              <>
                                                <div className="row mt-2">
                                                  <div className="col-md-12 col-12 mb-3 text-center">
                                                    <LaddaButton
                                                      loading={loading}
                                                      onClick={() => {
                                                        getData();
                                                      }}
                                                      data-color="#008aff"
                                                      data-size={l}
                                                      className="btn btn-primary btn-lg border w-100 font-weight-bold text-white shadow"
                                                      data-style={EXPAND_RIGHT}
                                                    >
                                                      Perbarui Status
                                                    </LaddaButton>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {struk.rc == "0090" ? (
                                                  <div className="mt-2">
                                                    <a
                                                      href={
                                                        "https://api.whatsapp.com/send?phone=6283870574102&text=*info transaksi pending* \n id_transaksi=" +
                                                        struk.trx_id +
                                                        "\n produk =" +
                                                        transaction.description +
                                                        "\n idpel =" +
                                                        transaction.idpel +
                                                        "\n pay time =" +
                                                        transaction.paid_at
                                                      }
                                                      className="w-100 btn btn-success"
                                                      target="_blank">
                                                      Proses Refound{" "}
                                                      <FaWhatsapp className="ml-2" />
                                                    </a>
                                                  </div>
                                                ) : (
                                                  <div></div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <div>
                                            {/*  kondisi normal  */}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-md-12">
                                        {struk.rc == "0000" || struk.rc == "00" ? (
                                          <>
                                            {/* <div className="row mb-2"> */}
                                            {/* <div className="col-md-6"> */}
                                            <button className="btn btn-secondary btn-block mt-2 mb-2" onClick={() => handlePrint("produkPdf" + index, index)}>
                                              Unduh Struk
                                            </button>
                                            {/* </div>
                                              <div className="col-md-6">
                                                <button className="btn btn-outline-danger btn-block mt-2 mb-2" onClick={() => addToFavorite(struk.trx_id)}>
                                                  Tambah Ke Favorite
                                                </button>
                                              </div> */}
                                            {/* </div> */}
                                            {/* <div style={{ display: "none" }}>
                                              <StrukComponent ref={(el) => (multiProdukRef = el)} infoLoket={state.fullname} strukData={strukData} noStruk="all" orderId={identifier.Id} />
                                            </div> */}
                                            <Collapsible trigger="Tampilkan Detail" triggerWhenOpen="Sembunyikan Detail" className="btn btn-primary btn-block" openedClassName="btn btn-secondary btn-block" triggerStyle={{ color: "#ffff", borderRadius: "5px", display: "block", cursor: "pointer" }}>
                                              <div className="bg-white mt-2">
                                                <table className="table table-borderless" id={"tableHeader" + index}>
                                                  <thead>
                                                    <tr>
                                                      <th className="text-center">STRUK PEMBELIAN PRODUK
                                                        {struk.product_type == "PULSA" || struk.product_type == "PAKET DATA" ? (
                                                          " " + struk.product_type.toUpperCase()
                                                        ) : (
                                                          " " + struk.product_name.toUpperCase()
                                                        )}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                </table>
                                                <table className="table small font-weight-bold" id={"produkPdf" + index}>
                                                  <tbody className="text-left">
                                                    <ProdukData
                                                      data={struk}
                                                      product_type={struk.product_type}
                                                      adminBank={struk.admin_charge}
                                                      kel_produk={struk.product_name}
                                                    />
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* </div> */}
                                            </Collapsible>
                                          </>
                                        ) : (
                                          <div>
                                            {struk.rc == "0090" ? (
                                              <div>
                                                <div className="acco-group">
                                                  <a className="acco-heading">
                                                    Rincian Transaksi
                                                  </a>
                                                  <div
                                                    className="acco-des"
                                                    style={{ display: "block" }}>
                                                    <table className="table small font-weight-bold">
                                                      <tbody>
                                                        <tr className="table-borderless">
                                                          <td>Deskripsi Transaksi</td>
                                                          <td className="text-right text-danger">
                                                            {struk.desc}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>ID transaksi</td>
                                                          <td className="text-right text-danger">
                                                            {struk.trx_id}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div>
                                                {struk.rc == null ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    <div>
                                                      <div className="acco-group">
                                                        <a className="acco-heading">
                                                          Rincian Transaksi
                                                        </a>
                                                        <div
                                                          className="acco-des"
                                                          style={{ display: "block" }}>
                                                          <table className="table small font-weight-bold">
                                                            <tbody>
                                                              <tr className="table-borderless">
                                                                <td>Deskripsi Transaksi</td>
                                                                <td className="text-right text-danger">
                                                                  {struk.desc}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td>ID transaksi</td>
                                                                <td className="text-right text-danger">
                                                                  {struk.trx_id}
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr style={{ border: "5px solid #F2F3FA" }} />
                              </>
                            ))}
                          </div>
                          <div className="col-md-5 col-sm-12">
                            <div className="card border-0 mb-5">
                              <div className="card-header">
                                <table className="table table-borderless" id="tableTotal">
                                  <tbody>
                                    <tr className="font-weight-bold">
                                      <td width="45%">TIPE BAYAR</td>
                                      <td width="5%">:</td>
                                      <td className="text-right">{strukData[0].payment.type.toUpperCase().replace('_', ' ')}</td>
                                    </tr>
                                    <tr className="font-weight-bold">
                                      <td>TERBAYAR</td>
                                      <td>:</td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={totalBayar}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"Rp"}
                                          renderText={(value) => <div>{value}</div>}
                                        />
                                      </td>
                                    </tr>
                                    <tr className="font-weight-bold">
                                      <td>KEMBALIAN</td>
                                      <td>:</td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={strukData[0].payment.uang_sisa}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"Rp"}
                                          renderText={(value) => <div>{value}</div>}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div className="row">
                                  <div className="col-md-6">
                                    <button className="btn btn-secondary btn-block mt-2" onClick={() => handlePrint("all")}>
                                      Struk Multiproduk
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <ReactToPrint
                                      trigger={() => <button className="btn btn-outline-dark btn-block mt-2"><i className="fa fa-print"></i> Print Struk</button>}
                                      content={() => multiProdukRef.current}
                                    />
                                    <div style={{ display: "none" }}>
                                      <StrukComponent ref={multiProdukRef} infoLoket={state.fullname} strukData={strukData} noStruk="all" orderId={identifier.Id} />
                                    </div>
                                  </div>
                                </div>
                                {state.role == "2" ? (
                                  <>
                                    <NavLink className="btn btn-success btn-block mt-2 mb-5" to="/pln">
                                      Lanjut Transaksi
                                    </NavLink>
                                  </>
                                ):(
                                  <>
                                    <NavLink className="btn btn-success btn-block mt-2 mb-5" to="/users/riwayat/pembayaran">
                                      Kembali
                                    </NavLink>
                                  </>
                                )}
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </section>

            </div>
          ) : (
            <div>
              <section
                className="section g-bg-gray p-0px-t bg-section"
                style={{ display: display }}>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-lg-12 col-12">
                      <div className="card border-radius-2 text-center m-auto py-3 align-items-center">
                        <FaTimesCircle className="text-danger" style={{ fontSize: "80px" }} />
                        <label htmlFor="loading" className="mt-4">
                          Tidak ada transaksi dengan order id : {identifier.Id}
                        </label>
                        <NavLink className="btn btn-success mt-2" to="/pln">
                          Transaksi Sekarang
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Invoice;