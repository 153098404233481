import React, { PureComponent } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading';
import LaddaButton from "react-ladda";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Select from "react-select";


const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} className="text-center" height={'10%'} width={'10%'} />
);


class DenomPrepaid extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      dataDenom: [],
      DataProductID: [],
      product_id: "",
      loading: false,
      login: false,
      token: localStorage.getItem("token"),
      store: null,
      setIsOpen: false,
      denom: "",
      description: "",
      active: 1,
      valOptProdID: "",
      toogleBtn: true,
      typeModal: "",
    };
  }
  setActiveDenom = (
    id = null,
    denom = null,
    description = null,
    active = null
  ) => {
    // console.warn("active", active);
    this.setState({ id: id, denom: denom, description: description });
    if (active == 0) {
      this.setState({ active: 1 });
    } else {
      this.setState({ active: 0 });
    }
    // this.updateDenom();

    // console.warn("state", this.state);
  };
  showModal = (type, data) => {
    if (type == "Edit") {
      this.setState({
        id: data.id,
        product_id: data.product_id,
        denom: data.denom,
        description: data.description,
        active: data.active,
      });
      if (data.active == 1) {
        this.setState({ toogleBtn: true });
      } else {
        this.setState({ toogleBtn: false });
      }
      // console.warn("data",data)
    } else {
      this.setState({
        id: 0,
        product_id: 0,
        denom: "",
        description: "",
        active: 1,
      });
    }
    this.setState({ setIsOpen: true, typeModal: type });
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  componentDidMount = async () => {
    await this.setState({ loading: true });
    await this.fetchDenom();
  }
  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  fetchDenom = async () => {
    // await 
    await fetch(`${process.env.REACT_APP_URL}/prepaid/denom/list`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        // console.warn("result", result);
        this.setState({ loading: false, dataDenom: result.data });
      });
    });
  };
  saveDenom = (typemodal) => {
    if (this.state.denom == "" || this.state.description == "") {
      Swal.fire({
        title: "Maaf",
        text: "Lengkapi dahulu input yang kosong atau belum dipilih",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    } else {
      if (typemodal == "Tambah") {

        this.createDenom();
      } else {
        this.updateDenom();
      }
    }
  };
  createDenom = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_URL}/prepaid/denom/create`,
        {
          product_id: "prepaid",
          denom: this.state.denom,
          description: this.state.description,
          active: this.state.active,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false });
        this.fetchDenom();
      });
    // console.warn("data",this.state);
  };

  updateDenom = () => {
    this.setState({ loading: true });
    axios
      .put(
        `${process.env.REACT_APP_URL}/prepaid/denom/update/` + this.state.id,
        {
          product_id: "prepaid",
          denom: this.state.denom,
          description: this.state.description,
          active: this.state.active,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
        // console.warn("response", json);
        this.setState({ loading: false, setIsOpen: false, toogleBtn: true });
        this.fetchDenom();
      });
  };

  confirmDelete = (iddenom) => {
    Swal.fire({
      title: "Hapus Data ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      reverseButtons:true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        this.deleteDenom(iddenom);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  deleteDenom = (iddenom) => {
    const { dataDenom } = this.state;

    axios
      .delete(`${process.env.REACT_APP_URL}/prepaid/denom/delete/` + iddenom, {
        headers: {
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then((json) => {
        if (json.data.status == 200) {
          this.setState({
            dataDenom: dataDenom.filter(
              (dataDenom) => dataDenom.id !== iddenom
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "success",
            confirmButtonText: "Tutup",
          });
        } else {
          this.setState({
            dataDenom: dataDenom.filter(
              (dataDenom) => dataDenom.id !== iddenom
            ),
          });
          Swal.fire({
            title: "Sukses",
            text: json.data.message,
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      });
  };
  render() {
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>ID Produk</div>
        ),
        accessor: "product_id",
        maxWidth: "5%",
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Denom</div>
        ),
        accessor: "denom",
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (props) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(props.value),
      },

      {
        Header: () => (
          <div style={{ textAlign: "left", color: "black" }}>Deskripsi</div>
        ),
        accessor: "description",
        style: {
          textAlign: "left",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Status</div>
        ),
        accessor: "active",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.active == 1 ? (
              <span
                className="badge badge-success"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}
              >
                Aktif
              </span>
            ) : (
              <span
                className="badge badge-danger"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}
              >
                Nonaktif
              </span>
            )}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
        ),
        accessor: "id",
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => this.showModal("Edit", row.original)}
            >
              Edit
            </button>
            &nbsp;
            <button
              onClick={() => this.confirmDelete(row.original.id)}
              className="btn btn-flat btn-sm btn-danger"
            >
              Hapus
            </button>
          </div>
        ),
      },
    ];

    return (
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mt-2 mb-5">
                  <div className="card-header bg-light">
                    <div>
                      <table className="text-primary">
                        <tr>
                          <td rowspan="2"><i className="fa fa-bolt text-primary h3 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold">SETTING DENOM PLN PREPAID</span> <br />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    <h4>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.showModal("Tambah")}
                      >
                        <i className="fa fa-plus"></i> Denom
                      </button>
                    </h4>
                    {this.state.loading ? (
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="text-center"
                      />
                    ) : (
                      <ReactTable
                        data={this.state.dataDenom}
                        // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                        defaultPageSize={this.state.dataDenom.length > 10 ? 10 : 10}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()}>
          <Modal.Header>
            <Modal.Title>{this.state.typeModal} Denom</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>
                Denom <sup className="text-danger">*</sup>
              </label>

              <input
                type="number"
                name="denom"
                id="denom"
                className="form-control"
                required="required"
                value={this.state.denom}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>
                Deskripsi <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="description"
                id="description"
                className="form-control"
                required=""
                value={this.state.description}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>Status</label>
              <br />
              <BootstrapSwitchButton
                checked={this.state.toogleBtn}
                onlabel="Aktif"
                offlabel="NonAktif"
                onstyle="success"
                offstyle="danger"
                width={105}
                onChange={(checked: boolean) => {
                  this.setState({ toogleBtn: checked });
                  {
                    this.state.toogleBtn
                      ? this.setState({ active: 0 })
                      : this.setState({ active: 1 });
                  }
                }}
              />
            </div>
            <input
              type="hidden"
              name="active"
              value={this.state.active}
              onChange={this.handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Batal
            </button>
            <LaddaButton
              loading={this.state.loading}
              onClick={() => {
                this.saveDenom(this.state.typeModal);
              }}
              className="btn btn-primary"
            >
              Simpan
            </LaddaButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DenomPrepaid;