import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../router/Router";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Axios from "axios";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { Redirect } from "react-router-dom";

const createHistory = require("history").createBrowserHistory;
// const token = localStorage.getItem("token");
function Dashboard(props) {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [closing, setClosing] = useState([]);
  const [datatrx, setDatatrx] = useState([]);
  const [isAuth, setAuth] = useState(0);
  const isBranch = localStorage.getItem("isBranch");
  let history = createHistory();
  const token = localStorage.getItem("token");
  const getData = async () => {
    await setLoading(true);
    // console.warn("state", state);
    await state;
    Axios.get(
      `${process.env.REACT_APP_URL}/loket/transactions/history`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).then(async (response) => {
      await setDatatrx(response.data.data.history.transactions);
      await setData(response.data.data.history.total);
    }).catch(async function (err) {
      if (err.response.data.status == 401) {
        await setAuth(401);
      }
    })
    await setLoading(false);
  };

  const getClosing = async () => {
    setLoadingButton(true);
    let configs = {
      headers: {
        Authorization: "Bearer " + state.token,
      },
    };
    try {
      let response = await Axios.post(
        `${process.env.REACT_APP_URL}/closing/proses`,
        null,
        configs
      );
      if (response.data.status === 200) {
        // alert(response.data.data.id_closing);
        await setClosing(response.data.data.id_closing);
        history.push("transaksi/closing/list");
        let pathUrl = window.location.href;
        window.location.href = pathUrl;
      } else {
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "info",
        });
        // alert(response.data.message);
      }
    } catch (e) {
      // console.log(e.response);
      // alert(e.response.data.message);
      Swal.fire({
        title: "",
        text: e.response.data.message,
        icon: "info",
      });
    }
    setLoadingButton(false);

  };
  const numberFormat = (num) => {
    let data = num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return `Rp. ` + data;
  };

  const ProcesClosing = async () => {
    await setLoadingButton(true);
    Swal.fire({
      title: "Anda akan mengakhiri session transaksi hari ini?",
      text: "",
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Lanjutkan",
      cancelButtonText: "Batalkan",
    }).then((result) => {
      if (result.value) {
        getClosing();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
    await setLoadingButton(false);
  };

  useEffect(() => {
    getData();
  }, [setData, setDatatrx]);

  if (isAuth === 401) {
    return <Redirect to="/login" />;
  }
  return (

    <div className="bg-admin">
      <section className="section p-0px-t bg-admin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="hidden-onphone">
                <div className="card shadow">
                  <div className="card-header d-flex justify-content-between bg-light">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-book text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">LAPORAN TRANSAKSI</span> <br />
                            Berikut laporan transaksi hari ini
                          </td>
                        </tr>
                      </table>
                    </div>
                    <LaddaButton
                      loading={loadingButton}
                      onClick={() => {
                        ProcesClosing();
                      }}
                      data-size={l}
                      className="btn btn-danger"
                      data-style={EXPAND_RIGHT}
                    >
                      Closing Transaksi
                    </LaddaButton>
                  </div>
                  <div className="card-body">
                    <div className="row d-flex justify-content-center">
                      <div className="table-responsive">
                        <table className="table small">
                          <thead>
                            <tr>
                              <th className="text-center">ID Loket</th>
                              <th className="text-center">Nama Loket</th>
                              <th className="text-center">Saldo</th>
                              <th className="text-center">Total Transaksi</th>
                              <th className="text-center">Total Pembayaran</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">{state.user}</td>
                              <td className="text-center">{state.fullname}</td>
                              <td className="text-center">
                                <NumberFormat
                                  value={state.balance != "" ? parseInt(state.balance) : 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp. "}
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </td>
                              <td className="text-center">{data.transaction}</td>
                              <td className="text-center">
                                <NumberFormat
                                  value={parseInt(data.amount)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp. "}
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3 shadow-sm">
                <div className="card-header bg-light">
                  <table>
                    <tr>
                      <td rowspan="2"><i className="fa fa-file-alt text-primary h1 mr-2"></i></td>
                      <td>
                        <span className="font-weight-bold text-primary">DATA TRANSAKSI</span> <br />
                        Berikut 10 transaksi terakhir hari ini
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="card-body text-dark">
                  {loading ? (
                    <div className="w-100 text-center justify-content-center">
                      Loading...
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <div className="hidden-onphone">
                        <table className="table small text-center">
                          <thead>
                            <tr>
                              <th>Tanggal Transaksi</th>
                              {isBranch == "false" ? (
                                <>
                                  <th className="text-left">Loket</th>
                                </>
                              ) : (<></>)}
                              <th>ID Transaksi</th>
                              <th className="text-left">Nama Produk</th>
                              <th className="text-left">ID Pelanggan</th>
                              <th>Jumlah Pembayaran</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {datatrx.length > 0 ? (
                            <>
                              <tbody>
                                {datatrx.map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Moment
                                          format="DD MMM YYYY HH:mm"
                                          tz="Asia/Jakarta"
                                        >
                                          {moment(value.transaction_date).add(0, "hours")}
                                        </Moment>
                                      </td>
                                      {isBranch == "false" ? (
                                        <td className="text-left">{value.loket_name}</td>
                                      ) : (<></>)}
                                      <td>{value.trx_id}</td>
                                      <td className="text-left">{value.product_name.toUpperCase()}</td>
                                      <td className="text-left">{value.idpel}</td>
                                      <td className="text-right">
                                        {numberFormat(value.total_amount)}
                                      </td>
                                      <td>
                                        <label className="badge badge-info">
                                          {value.status}
                                        </label>
                                      </td>

                                    </tr>
                                  );
                                })}

                              </tbody>
                              <tfoot >
                                <tr>
                                  <td colSpan={state.role == 1 ? "6" : "5"} className="bg-white text-right h6">
                                    <b>Total Pembayaran</b>
                                  </td>
                                  {data.amount ? (
                                    <td className="text-right bg-white h6">
                                      <b> {numberFormat(parseFloat(data.amount))}</b>
                                    </td>
                                  ) : (
                                    <td className="bg-white h6">Rp.0</td>
                                  )}
                                </tr>
                              </tfoot>
                            </>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan={6} className="font-weight-bold">Belum ada transaksi hari ini atau transaksi sudah diclosing</td>
                              </tr>
                            </tbody>)}
                        </table>
                      </div>
                      <div className="show-onphone">
                        {datatrx.length > 0 ? (
                          <>
                            <div className="row">
                              <div className="col-12 m-auto">
                                <LaddaButton
                                  loading={loadingButton}
                                  onClick={() => {
                                    ProcesClosing();
                                  }}
                                  data-size={l}
                                  className="btn btn-danger mb-3 btn-block"
                                  data-style={EXPAND_RIGHT}
                                >
                                  Closing Transaksi
                                </LaddaButton>
                              </div>
                            </div>
                            <div className="mb-5">
                              {datatrx.map((value, index) => {
                                return (
                                  <>
                                    <div key={index}>
                                      <div className="row">
                                        <div className="col-10">
                                          <span className="font-weight-bold">{value.product_name.toUpperCase()}</span>
                                        </div>
                                        <div className="col-2">
                                          <small className="float-right badge badge-info">
                                            {value.status}
                                          </small>
                                        </div>
                                        <div className="col-12">
                                          <small>
                                            <span><Moment format="DD MMM YYYY HH:mm" tz="Asia/Jakarta">{moment(value.transaction_date).add(0, "hours")}</Moment></span>
                                            <span className="float-right mt-1">ID Trx : {value.trx_id}</span>
                                          </small>
                                        </div>
                                        <table className="table table-borderless ml-1 mr-1" style={{ lineHeight: "15px" }}>
                                          <tbody>
                                            {isBranch == "false" ? (
                                              <tr>
                                                <td width="40%">Loket</td>
                                                <td className="text-right">{value.loket_name}</td>
                                              </tr>
                                            ) : (<></>)}

                                            <tr>
                                              <td>Idpel</td>
                                              <td className="text-right">{value.idpel}</td>
                                            </tr>
                                            <tr>
                                              <td>Jml Bayar</td>
                                              <td className="text-right">
                                                <NumberFormat
                                                  value={value.total_amount}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"Rp"}
                                                  renderText={(value) => <>{value}</>}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )
                              })
                              }
                            </div>
                            <div className="show-onphone mb-5">
                              {/* <div className="card-body mb-5"> */}
                              <div className="row">
                                <div className="col-6">
                                  <span className="font-weight-bold h6">Total Bayar</span>
                                </div>
                                <div className="col-6">
                                  <span className="font-weight-bold float-right h6">
                                    <NumberFormat
                                      value={data.amount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"Rp"}
                                      renderText={(value) => <>{value}</>}
                                    />
                                  </span>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </>) : (
                          <div className="row pb-5">
                            <span className="col-12 text-center">Belum ada transaksi hari ini atau transaksi sudah diclosing</span>
                          </div>)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
