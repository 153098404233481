import React, { useContext, useEffect, useState } from "react";
import { Redirect } from 'react-router-dom'
import { PlnPostpaidInq } from "../Element/PlnPostpaidInq";
import { PlnPrepaidInq } from "../Element/PlnPrepaidInq";
import { BpjsInq } from "../Element/BpjsInq";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { PulsaInq } from "../Element/PulsaInq";
import { DataInq } from "../Element/DataInq";
import { TelkomInq } from "../Element/TelkomInq";
import { PDAMInq } from "../Element/PDAMInq";
import axios from "axios";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import { AuthContext, CartContext } from "../../router/Router";
import Modal from "react-bootstrap/Modal";
import KeyboardEventHandler from "react-keyboard-event-handler";
import CurrencyInput from "react-currency-input-field";
// import { CartContext } from "./CartContext";

// import bcrypt from 'bcryptjs';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
const createHistory = require("history").createBrowserHistory;
const ProdukInquiry = ({ produk, data, fulldata, adminBank = 0 }) => {
  if (!produk || produk == null) {
    produk = "";
  }
  if (produk.toUpperCase().includes("PLNPOSTPAID") || produk.toUpperCase().includes("POSTPAID")) {
    return <PlnPostpaidInq struk={data} />;
  } else if (produk.toUpperCase().includes("TOKENPLN") || produk.toUpperCase().includes("PREPAID")) {
    return <PlnPrepaidInq struk={fulldata} />;
  } else if (produk.toUpperCase().includes("BPJSK")) {
    return <BpjsInq struk={data} />;
  } else if (produk.toUpperCase().includes("SPEEDY") || produk.toUpperCase().includes("INTERNET")) {
    return <TelkomInq struk={data} />;
  } else if (produk.toUpperCase().includes("RUMAH") || produk.toUpperCase().includes("TELEPON") || produk.toUpperCase().includes("TELKOM")) {
    return <TelkomInq struk={data} />;
  } else if (produk == "PDAM") {
    // console.log(data.blth.substr(4, 2));
    return <PDAMInq struk={data} />;
  } else {
    // console.warn("data", data);
    if (data.product_type == "PULSA") {
      return <PulsaInq struk={fulldata} adminBank={adminBank} />;
    } else if (data.product_type == "PAKET DATA") {
      return <DataInq struk={fulldata} adminBank={adminBank} />;
    }
  }

  return null;
};

function Checkoutv2(props) {
  let history = createHistory();
  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const { value, setValue } = useContext(CartContext)
  const [isLoading, setIsLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const token = localStorage.getItem("token");
  const [produkName, setProdukName] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [transaction_id, setTransaction_id] = useState("");
  const [metode_bayar, setMetode_bayar] = useState("");
  const [bayar, setBayar] = useState("0");
  const [kembalian, setKembalian] = useState("0");
  const [idpel, setIdpel] = useState("");
  const [hash, setHash] = useState("");
  const [admin_charge, setAdmin_carge] = useState("");
  const [total, setTotal] = useState("");
  const [harga, setHarga] = useState("");
  const [email, setEmail] = useState("");
  const [productNomination, setProductNomination] = useState("");
  const [periodePayment, setPeriodePayment] = useState("");
  const [handling_fee, setHandling_fee] = useState("");
  const [promo, setPromo] = useState([]);
  const [usePromo, setUsePromo] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataCart, setDataCart] = useState([]);
  const emailSave = localStorage.getItem("email_save");
  const [statusSave, setStatusSave] = useState(false);
  const struk = JSON.parse(localStorage.getItem("private_inquiry"));
  const [dataPay, setDataPay] = useState([{ "card_id": "" }]);
  const list_idpel = JSON.parse(localStorage.getItem("list_impor_idpel"));
  const last_idpel = list_idpel ? list_idpel.slice(-1) : '';

  const fetchCart = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/cart/list`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        await setDataCart([...res.data.data]);
        // console.log(res.data.data);
      }).catch((err) => {
        history.push("/login");
        let pathUrl = window.location.href;
        window.location.href = pathUrl;
      })
  };
  const getData = async () => {
    // console.warn('struk',struk);

    await setIsLoading(true);
    await setTransaction_id(struk.data.trx_id);
    if (!struk.produk || struk.produk == null) {
      struk.produk = "";
    }
    try {
      if (struk.produk.toUpperCase().includes("PLNPOSTPAID")) {
        setProdukName("PLN Postpaid");
        setProduct_id(struk.product_id);
        setIdpel(struk.data.subscriber_id);
        setHarga(parseInt(struk.data.amount) - parseInt(struk.data.admin_charge));
        setAdmin_carge(struk.data.admin_charge);
        setTotal(parseInt(struk.data.amount) + parseInt(struk.data.loket_fee));
        setHandling_fee(struk.data.handling_fee);
        // console.warn('total bayar',total);
      } else if (struk.produk.toUpperCase().includes("TOKENPLN")) {
        // setTransaction_id(struk.trx_id);
        setProdukName("PLN Prepaid");
        setProduct_id(struk.product_id);
        setIdpel(struk.data.subscriber_id);
        setHarga(struk.product_nomination);
        setProductNomination(struk.product_nomination);
        setAdmin_carge(struk.data.admin_charge);
        setTotal(
          parseInt(struk.product_nomination) + parseInt(struk.data.admin_charge) + parseInt(struk.data.loket_fee)
        );
        setHandling_fee(struk.data.handling_fee);
      } else if (struk.produk.toUpperCase().includes("BPJSK")) {
        setProdukName("BPJS Kesehatan");
        setProduct_id(struk.product_id);
        setIdpel(struk.data.no_va);
        setHarga(struk.data.premi);
        setPeriodePayment(struk.periode);
        setAdmin_carge(struk.data.admin_charge);
        setTotal(
          parseInt(struk.data.premi) + parseInt(struk.data.admin_charge) + parseInt(struk.data.loket_fee)
        );
        setHandling_fee(struk.data.handling_fee);
      } else if (struk.produk.toUpperCase().includes("SPEEDY") || struk.produk.toUpperCase().includes("INTERNET")) {
        setProdukName("Internet (Indihome/Speedy)");
        setProduct_id(struk.product_id);
        setIdpel(struk.data.id_pelanggan);
        setHarga(struk.data.jumlah_tagihan);
        setPeriodePayment(struk.data.bulan_thn);
        setAdmin_carge(struk.data.jumlah_adm);
        setTotal(
          parseInt(struk.data.jumlah_tagihan) + parseInt(struk.data.jumlah_adm) + parseInt(struk.data.loket_fee)
        );
        setHandling_fee(struk.data.handling_fee);
      } else if (struk.produk.toUpperCase().includes("RUMAH") || struk.produk.toUpperCase().includes("TELEPON") || struk.produk.toUpperCase().includes("TELKOM")) {
        setProdukName("Telepon Rumah");
        setProduct_id(struk.product_id);
        setIdpel(struk.data.id_pelanggan);
        setHarga(struk.data.jumlah_tagihan);
        setPeriodePayment(struk.bulan_thn);
        setAdmin_carge(struk.data.jumlah_adm);
        setTotal(
          parseInt(struk.data.jumlah_tagihan) + parseInt(struk.data.jumlah_adm) + parseInt(struk.data.loket_fee)
        );
        setHandling_fee(struk.data.handling_fee);
      } else if (struk.produk == "PDAM") {
        setProdukName("PDAM");
        setProduct_id(struk.product_id);
        setIdpel(struk.data.idpel);
        setHarga(parseInt(struk.data.rp_tag));
        setPeriodePayment(struk.blth);
        setAdmin_carge(parseInt(struk.data.admin_charge));
        setTotal(
          parseInt(struk.data.rp_tag) +
          parseInt(struk.data.admin_charge) + parseInt(struk.data.loket_fee)
        );
        setHandling_fee(struk.data.handling_fee);
      } else {
        if (struk.data.product_type == "PULSA" || struk.data.product_type == "PAKET DATA") {
          if (struk.data.product_type == "PULSA") {
            setProdukName("Pulsa");
          } else {
            setProdukName("Paket Data");
          }
          setProduct_id(struk.product_id);
          setIdpel(struk.data.msisdn);
          setHarga(struk.data.price);
          setPeriodePayment(struk.data.blth);
          setAdmin_carge(struk.admin_fee);
          setTotal(parseInt(struk.data.price) + parseInt(struk.admin_fee) + parseInt(struk.data.loket_fee||0));
          setHandling_fee(struk.handling_fee);
        }
      }
      // await console.warn('total bayar', total);
    } catch (e) {
      console.warn("error", e);
    }

    setIsLoading(false);
  };
  const checkIdpel = (idpel, product_id) => {
    return dataCart.some(item => idpel === item.idpel) && dataCart.some(item => product_id === item.prod_id);
  }
  const saveToCart = async (type) => {
    await setIsLoading(true);
    if (statusSave) {
      await localStorage.setItem("email_save", JSON.stringify({ status_save: statusSave, email: email }))
    } else {
      await localStorage.setItem("email_save", JSON.stringify({ status_save: statusSave, email: '' }))
    }

    if (checkIdpel(idpel, product_id) && type == "cart" && !list_idpel) {

      Swal.fire({
        title: "Informasi",
        text: "Tagihan produk " + produkName + " untuk idpel " + idpel + " sudah ada dikeranjang",
        icon: "info",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Tagihan Lainnya",
        cancelButtonText: "Lihat Keranjang",
      }).then((result) => {

        if (result.value) {
          history.push("/pln");
          let pathUrl = window.location.href;
          window.location.href = pathUrl;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          history.push("/cart");
          let pathUrl = window.location.href;
          window.location.href = pathUrl;
        }
      });
    } else {

      if (type == "pay" && dataCart.length > 0) {
        await deleteItemCart(0, "all");
      }
      await axios
        .post(
          `${process.env.REACT_APP_URL}/cart/create`,
          {
            trx_type: "2100",
            idpel: idpel,
            product_id: product_id,
            product_nomination: struk.product_nomination || "0",
            periode_payment: struk.periode_payment || "0",
            info_email: email || state.email,
            info_name: state.user,
            info_rp_tag: harga,
            info_admin: admin_charge,
            info_total_bayar: total
          },
          {
            headers: {
              Authorization: "Bearer " + state.token,
              "Content-Type": "application/json",
            },
          }
        ).then(async (json) => {
          if (json.data.status == 200) {
            // console.warn("res save cart", json.data);
            if (type == "cart") {
              setValue(value + 1);
              if (list_idpel) {
                let indexOfIdpel = "";
                if (struk.produk == "BPJSK") {
                  indexOfIdpel = list_idpel.filter(p => p.idpel == struk.prev_idpel);
                } else {
                  indexOfIdpel = list_idpel.filter(p => p.idpel == idpel);
                }
                if (last_idpel[0].idpel == idpel) {
                  history.push("/cart");
                  let pathUrl = window.location.href;
                  window.location.href = pathUrl;
                } else {
                  // console.log("produk : "+struk.produk)
                  // if (indexOfIdpel.length == 0) {
                  //   history.push("/cart");
                  //   let pathUrl = window.location.href;
                  //   window.location.href = pathUrl;
                  // } else {
                  list_idpel[indexOfIdpel[0].key] = await {
                    'idpel': list_idpel[indexOfIdpel[0].key].idpel,
                    'key': list_idpel[indexOfIdpel[0].key].key,
                    'produk': list_idpel[indexOfIdpel[0].key].produk,
                    'status': 'sukses'
                  }
                  await localStorage.setItem('list_impor_idpel', JSON.stringify(list_idpel))
                  let newProduk = list_idpel[indexOfIdpel[0].key + 1];
                  let nextProduk = newProduk.produk;
                  let nextIdpel = newProduk.idpel;
                  let pathProduk = "";
                  if (nextProduk.toString().toUpperCase().includes("PREPAID")) {
                    pathProduk = 'plnprepaid/' + nextIdpel
                  }
                  if (nextProduk.toString().toUpperCase().includes("POSTPAID")) {
                    pathProduk = 'plnpostpaid/' + nextIdpel
                  }
                  if (nextProduk.toString().toUpperCase().includes("BPJS")) {
                    pathProduk = 'bpjs/' + nextIdpel
                  }
                  if (nextProduk.toString().toUpperCase().includes("TELEPON")) {
                    pathProduk = 'telepon/' + nextIdpel
                  }
                  if (nextProduk.toString().toUpperCase().includes("SPEEDY")) {
                    pathProduk = 'speedy/' + nextIdpel
                  }
                  if (nextProduk.toString().toUpperCase().includes("PDAM")) {
                    pathProduk = 'pdam/' + nextIdpel
                  }
                  if (nextProduk.toString().toUpperCase().includes("PULSA")) {
                    pathProduk = 'pulsa/' + nextIdpel
                  }
                  if (nextProduk.toString().toUpperCase().includes("DATA")) {
                    pathProduk = 'data/' + nextIdpel
                  }

                  history.push(history.push('/users/transaksi/importidpel/cektagihan/' + pathProduk));
                  let pathUrl = window.location.href;
                  window.location.href = pathUrl;
                  // }
                }
              } else {
                Swal.fire({
                  title: "Sukses",
                  text: "Tagihan berhasil dimasukkan ke keranjang",
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Tagihan Lainnya",
                  cancelButtonText: "Lihat Keranjang",
                }).then((result) => {

                  if (result.value) {
                    history.push("/pln");
                    let pathUrl = window.location.href;
                    window.location.href = pathUrl;
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    history.push("/cart");
                    let pathUrl = window.location.href;
                    window.location.href = pathUrl;
                  }
                });
              }
            } else {
              await setMetode_bayar("");
              await fetchCart();
              await handleShow();
            }
          } else {
            Swal.fire({
              title: "Maaf",
              text: "Silahkan dicoba beberapa saat lagi",
              icon: "error",
              confirmButtonText: "Oke",
            });
          }

        }).catch(async (err) => {
          // console.warn("err", err)
          if (err.response.data.status == 402) {
            Swal.fire({
              title: "Maaf",
              text: err.response.data.message[0].msg,
              icon: "error",
              confirmButtonText: "Oke",
            });
          } else {
            Swal.fire({
              title: "Maaf",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Oke",
            });
          }
        })
    }
    await setIsLoading(false);
  };
  useEffect(() => {
    if (!emailSave) {
      setStatusSave(false)
      localStorage.setItem("email_save", JSON.stringify({ status_save: false, email: '' }))
      // console.warn("status save 1", statusSave)
    } else {
      let status = JSON.parse(localStorage.getItem("email_save"));
      setStatusSave(status.status_save);
      setEmail(status.email)
      // console.warn("status save 2", statusSave)
    }
    getData();
    fetchCart();
  }, []);


  const ProcesPayment = async () => {
    Swal.fire({
      title: 'Lanjut Pembayaran ?',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Lanjut",
      cancelButtonText: "Nanti Dulu",
      showLoaderOnConfirm: true,
      preConfirm: async (response) => {
        if (statusSave) {
          await localStorage.setItem("email_save", JSON.stringify({ status_save: statusSave, email: email }))
        } else {
          await localStorage.setItem("email_save", JSON.stringify({ status_save: statusSave, email: '' }))
        }
        let url = `${process.env.REACT_APP_URL}/loket/transactions/payment`;
        for (var i = 0; i < dataCart.length; i++) {
          dataPay[i] = await { card_id: dataCart[i].id };
        }
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Barier " + token,
            Accept: "application/json",
          },
          body: JSON.stringify({
            data: dataPay,
            type_payment: metode_bayar,
            uang_bayar: bayar,
            uang_sisa: kembalian,
            pin: pin,
          }),
        }).then(response => {
          return response.json();
        }).catch((error) => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        // console.warn("result", result)
        if (result.value.status == "200") {
          await Swal.fire({
            title: "Pembayaran Berhasil",
            icon: 'success',
            confirmButtonText: 'oke'
          });
          await history.push("/invoice/" + result.value.data[0].order_id);
          let pathUrl = await window.location.href;
          window.location.href = await pathUrl;
        } else {
          Swal.fire({
            title: "Maaf",
            text: result.value.message.data ? result.value.message.data.trx.desc : result.value.message,
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
      }
    })
  };

  const [showKon, setShowKon] = useState(false);
  const handleCloseKon = () => setShowKon(false);
  const handleShowKon = () => setShowKon(true);

  const [pin, setPin] = useState('');

  const handlePinChange = (e) => {
    const { value } = e.target;
    setPin(value);
  };

  const handleShowForm = () => {
    handleShowKon();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Lakukan apa pun yang ingin Anda lakukan dengan nilai PIN yang dimasukkan
    console.log('PIN:', pin);
  };

  const deleteItemCart = async (id_cart, type) => {
    await setIsLoading(true);
    let url = "";
    if (type == "all") {
      url = `${process.env.REACT_APP_URL}/cart/all/delete`;
    } else {
      url = `${process.env.REACT_APP_URL}/cart/delete/` + id_cart;
    }
    await axios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        // console.log("res", res);
        // if (res.data.status == 200) {
        if (value > 0) {
          await setValue(value - 1);
        } else {
          await setValue(0);
        }
        // await fetchCart();
        // }
        // console.warn("res cart", res.data.data);
      });
    await setIsLoading(false);
  };

  const Kembalian = async (value) => {
    await setBayar(value);
    await setKembalian(value - total);
    if (parseInt(value) >= parseInt(total)) {
      await setBtnDisabled(false)
    } else {
      await setBtnDisabled(true)
    }
  };
  const setEmailSave = async (e) => {
    let newStatus = e.target.checked;
    await setStatusSave(newStatus)
    // await console.warn("checked", newStatus)
    // await localStorage.setItem("email_save", JSON.stringify({ status_save: newStatus }))
  }
  var metode_pembayaran = [
    { name: "Bayar Tunai", prename: "tunai" },
    { name: "Mesin EDC", prename: "edc" },
    { name: "Transfer Bank", prename: "transfer_bank" },
  ];
  return (
    <div>
      {!struk ? (
        <Redirect to='/pln' />
      ) : (
        <>
          <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            // className="bg-secondary"
            xaria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header>
              <Modal.Title>
                Pembayaran {produkName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="card border-0">
                    <div className="card-body text-center">
                      <NumberFormat
                        value={total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp. "}
                        renderText={(value) => <h1>{value}</h1>}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row mt-2">
                    <div className="tab-style-6 p-0 m-0 w-100">
                      <div className="row w-100">
                        <div className="col-md-12 col-12">
                          <ul className="nav nav-fill nav-tabs row m-2 w-100">
                            {metode_pembayaran.map((value, index) => {
                              return (
                                <li
                                  className="nav-item col-md-4 col-6 m-auto p-1"
                                  key={index}
                                  onClick={async (e) => {
                                    await setMetode_bayar(value.prename);
                                    if (value.prename.toLowerCase() != "tunai") {
                                      await setBtnDisabled(false);
                                    } else {
                                      await setBtnDisabled(true);
                                    }
                                  }}
                                >
                                  <a href="#" data-toggle="tab" className="nav-link" style={{ width: "100%" }}>
                                    <span>{value.name}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {metode_bayar == "tunai" ? (
                  <div className="col-md-12">
                    <div className="row mt-5">
                      <div className="col-md-6 mt-2">
                        <div className="card border-0">
                          <div className="mx-1">
                            <label htmlFor="" className="font-weight-bold text-dark">
                              Input Jumlah Uang (Rp)
                            </label>
                            <CurrencyInput
                              id="kembalian"
                              name="kembalian"
                              placeholder="Input Jumlah Uang"
                              // defaultValue={props.value}
                              className="w-100 form-control input-xl font-weight-bold"
                              decimalsLimit={2}
                              onValueChange={async (value) => { await Kembalian(value); }}
                            />
                            {
                              parseInt(kembalian) < 0 ? (
                                <><span className="small text-danger">Nominal uang kurang dari total pembayaran</span></>
                              ) : (<></>)
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-2">
                        <div className="card border-0">
                          <div className="mx-1">
                            <label htmlFor="" className="font-weight-bold text-dark">
                              Kembalian
                            </label>
                            <br />
                            <NumberFormat
                              value={kembalian}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp"}
                              renderText={(value) => <h1>{value}</h1>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="col-md-12 p-2 text-right mt-5">
                  <div className="row">
                    <div className="col-md-12">
                      <LaddaButton
                        loading={isLoading}
                        disabled={btnDisabled}
                        // onClick={() => {
                        //   ProcesPayment();
                        // }}
                        onClick={ handleShowForm }
                        data-color="#008aff"
                        data-size={l}
                        className="m-btn m-btn-theme m-btn-radius w-100"
                        data-style={EXPAND_RIGHT}
                      >
                        Bayar Sekarang
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={showKon}
            onHide={handleCloseKon}
            size="sm"
            className="bg-secondary"
            xaria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header>
              <Modal.Title>
                <div className="row-md-12">
                  <div className="col-md-12">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        Konfirmasi Keamanan
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="card border-0">
                    <div className="card-body text-center">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="pinForm">
                        <Form.Label>Sebelum melakuan Transaksi silahkan masukkan PIN:</Form.Label>
                          <Form.Control
                            type="password"
                            value={pin}
                            onChange={handlePinChange}
                            maxLength={6}
                            pattern="[0-9]*"
                            placeholder="Masukkan PIN"
                            required
                            style={{ textAlign: 'center' }}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 p-2 text-right mt-5">
                  <div className="row">
                    <div className="col-md-12">
                      <LaddaButton
                        loading={isLoading}
                        disabled={btnDisabled}
                        onClick={() => {
                          ProcesPayment();
                        }}
                        data-color="#008aff"
                        data-size={l}
                        className="m-btn m-btn-theme m-btn-radius w-100"
                        data-style={EXPAND_RIGHT}
                      >
                        Proses Payment
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <KeyboardEventHandler handleKeys={["ctrl+b"]} onKeyEvent={handleShow} />
          <div>
            <section className="section g-bg-gray p-0px-t bg-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="card border-radius-20 table-responsive mt-2">
                      <div className="card-header">
                        <h5 className="m-auto">Detail Tagihan {produkName}</h5>
                      </div>
                      <div className="card-body">
                        <table className="table small ">
                          <tbody>
                            <ProdukInquiry
                              produk={struk.produk}
                              data={struk.data}
                              fulldata={struk}
                              adminBank={state.adminBank}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="hidden-onphone">
                      <div className="card mt-2">
                        <div className="card-header">
                          <table className="table table-borderless small">
                            <tbody>
                              <tr>
                                <td className="font-weight-bold"><h5>Total Bayar</h5></td>
                                <td className="text-right">
                                  <NumberFormat
                                    value={total}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"Rp"}
                                    renderText={(value) => <h5>{value}</h5>}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row">
                      {/* email */}
                      <div className="col-md-12">
                        <div className="card mt-2">
                          <div className="card-header">
                            <h5 className="my-auto">Pembayaran</h5>
                          </div>
                          <div className="card-body">
                            <div className="tab-style-5">
                              <div className="row justify-content-center">
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <label>Email</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={email}
                                      placeholder="example@email.com"
                                      onKeyUp={(e) => {
                                        setEmail(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input type="checkbox" name="email_save" checked={statusSave} onChange={(e) => setEmailSave(e)} value="simpan" /> Simpan Email Untuk Transaksi Berikutnya
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {state.balance ? (
                          <>
                            <div className="show-onphone">
                              <div className="card mt-2 mb-2">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row my-3">
                                        <div className="col-md-6">Saldo Sekarang</div>
                                        <div className="col-md-6 font-weight-bold h6">
                                          <NumberFormat
                                            value={state.balance}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"Rp"}
                                            className="font-weight-bold"
                                            renderText={(value) => <div>{value}</div>}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div></div>
                        )}
                        <div className="hidden-onphone">
                          <div className="card mt-2">
                            <div className="card-body">
                              <div className="row">
                                {!list_idpel ? (
                                  <>
                                    <div className="col-md-6 col-6">
                                      <LaddaButton
                                        onClick={() => saveToCart("cart")}
                                        data-color="#008aff"
                                        data-size={l}
                                        className="m-btn btn-success m-btn-radius w-100 text-white"
                                        data-style={EXPAND_RIGHT}
                                      >
                                        Masukkan Keranjang
                                      </LaddaButton>
                                    </div>
                                    <div className="col-md-6 col-6">
                                      <LaddaButton
                                        onClick={() => saveToCart("pay")}
                                        data-color="#008aff"
                                        data-size={l}
                                        className="m-btn m-btn-theme m-btn-radius w-100"
                                        data-style={EXPAND_RIGHT}
                                      >
                                        Bayar Sekarang
                                      </LaddaButton>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-md-12">
                                      <LaddaButton
                                        onClick={() => saveToCart("cart")}
                                        data-color="#008aff"
                                        data-size={l}
                                        className="m-btn btn-success m-btn-radius w-100 text-white"
                                        data-style={EXPAND_RIGHT}
                                      >
                                        Selanjutnya
                                      </LaddaButton>
                                    </div>
                                  </>
                                )}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="show-onphone">
                          <div className="card mb-5">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-5">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="col-md-12">Total Bayar<br />
                                          <span className="h6">
                                            <NumberFormat
                                              value={total}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"Rp"}
                                              renderText={(value) => <div>{value}</div>}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4 m-auto">
                                  <div className="row">
                                    <LaddaButton
                                      onClick={() => saveToCart("pay")}
                                      data-color="#008aff"
                                      data-size={l}
                                      className="btn btn-primary btn-block m-auto"
                                      data-style={EXPAND_RIGHT}
                                    >
                                      Bayar
                                    </LaddaButton>
                                  </div>
                                </div>
                                <div className="col-3 m-auto">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <LaddaButton
                                        onClick={() => saveToCart("cart")}
                                        data-color="#008aff"
                                        data-size={l}
                                        className="btn btn-outline-danger btn-block m-auto"
                                        data-style={EXPAND_RIGHT}
                                      >
                                        <i className="fa fa-shopping-cart"></i>
                                      </LaddaButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </section>
          </div>
        </>
      )}
    </div>
  );
}

export default Checkoutv2;
