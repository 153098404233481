import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../router/Router";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Axios from "axios";
import ReactLoading from "react-loading";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from "moment-timezone";
import Select from "react-select";
import Swal from "sweetalert2";
import ReactTable from "react-table";
import { Redirect } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import * as XLSX from 'xlsx';



const createHistory = require("history").createBrowserHistory;
const token = localStorage.getItem("token");

function UpdateStatusTransferDana(props) {
  const { dispatch, state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataBank, setDataBank] = useState([]);
  const [selectBank, setSelectBank] = useState([]);
  const [idBank, setIdBank] = useState();
  const [dataStatus, setDataStatus] = useState([]);
  const [status, setStatus] = useState('');
  const [admin, setAdmin] = useState(0);
  const [detailData, setDetailData] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [filIdpel, setFilIdpel] = useState();
  const [isAuth, setAuth] = useState(0);
  const [show, setShow] = useState(false);
  const [newMessageResponse, setNewResponse] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"3%"}
      width={"3%"}
      className="d-flex justify-content-center"
    />
  );
  let history = createHistory();

  const handleChangeStatus = (data) => {
    setStatus(data.value);
    setFilterStatus(data);
  };

  const handleChangeBank = (data) => {
    setIdBank(data.value);
    setSelectBank(data.fulldata);
    // console.log(data);
  };

  const generateExcel = (data,fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, fileName + '.xlsx');
  };

  const GenerateExcel = () =>{
    
    let excel = [];

    data.map((dt)=>{
      let row = {
        id : dt.transferdana_transactions_id,
        nominal : dt.sender_nominal,
        pengirim : dt.sender_nama,
        bank_penerima : dt.receiver_nama_bank,
        penerima_atas_nama : dt.receiver_nama_rekening,
        transaksi_transfer_bank : dt.trx_nama_bank,
        transaksi_transfer_atas_nama : dt.trx_nama_rekening,
        biaya_admin : dt.trx_admin,
        status : dt.trx_status,
      }
      excel.push(row);
    });


    generateExcel(excel,'report_transfer_dana')
  }

  const getData = async () => {
    await setIsLoading(true);
    await state;
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_URL}/transferdana/list?start_time=`+ startDate +`&end_time=`+ endDate,
        {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        }
      );
      if (response.data.status == "200") {
        await setData(response.data.data);
      }
    } catch (e) {
      if (e.response.data.status == 401) {
        await setAuth(401);
      } else {
        Swal.fire({
          title: "",
          text: e.response.data.message,
          icon: "info",
        })
      }
    }
    await setIsLoading(false);
  };

  const getDataBankApi = async () => {
    await setIsLoading(true);
    await state;
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_URL}/list/ptd/bank`,
        {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        }
      );
      // console.warn('response', response.data)
      if (response.data.status == "200") {
        await setDataBank(response.data.data);
      }
    } catch (e) {
      if (e.response.data.status == 401) {
        await setAuth(401);
      } else {
        Swal.fire({
          title: "",
          text: e.response.data.message,
          icon: "info",
        })
      }
    }
    await setIsLoading(false);
  };

  const getDataStatusApi = async () => {
    await setIsLoading(true);
    await state;
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_URL}/list/ptd/status`,
        {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        }
      );
      if (response.data.status == "200") {
        await setDataStatus(response.data.data);
      }
    } catch (e) {
      if (e.response.data.status == 401) {
        await setAuth(401);
      } else {
        Swal.fire({
          title: "",
          text: e.response.data.message,
          icon: "info",
        })
      }
    }
    await setIsLoading(false);
  };

  async function Filter() {
    await setIsLoading(true);
    await state;
    if (startDate == null || endDate == null || startDate == '' || endDate == '') {
      Swal.fire({
        title: 'Maaf',
        text: "Lengkapi filter tanggal",
        icon: 'info',
        confirmButtonText: 'oke'
      });
    } else {
      fetch(process.env.REACT_APP_URL + '/transferdana/list?status=' + status + '&start_time=' + startDate + '&end_time=' + endDate, {
        method: "GET",
        headers: {
          'Authorization': "Barier " + state.token,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }).then(async (response) => {
        await response.json().then(async (result) => {
          // console.warn("result", result);
          if (result.status == "200") {
            await setData(result.data);
          } else {
            Swal.fire({
              title: 'Maaf',
              text: result.message,
              icon: 'error',
              confirmButtonText: 'oke'
            });
          }
        })
      }).catch((err) => {
        Swal.fire({
          title: 'Maaf',
          text: "Internal Sistem Error",
          icon: 'error',
          confirmButtonText: 'oke'
        });
      })
    }
    await setIsLoading(false);
  };

  async function updateConfirm(id) {
    await state;
    let formatRequest = JSON.stringify({
      trx_nama_bank: selectBank.code,
      trx_nama_rekening: selectBank.name,
      trx_nomor_rekening: selectBank.nomer_rekening,
      trx_admin: admin,
      trx_status: status,
    });
    // console.log(formatRequest);
    Swal.fire({
      title: 'Perbarui Status Transfer dana ?',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Lanjut",
      cancelButtonText: "Batal",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(process.env.REACT_APP_URL + '/transferdana/update/status/' + detailData.transferdana_transactions_id, {
          method: "PUT",
          headers: {
            'Authorization': "Barier " + state.token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: formatRequest,
        }).then(response => {
          return response.json();
        }).catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      await handleClose()
      if (result.isConfirmed) {
        // await getData();
        if (result.value.status == "200") {
          await Swal.fire({
            title: "Berhasil",
            text: result.value.data,
            icon: 'success',
            confirmButtonText: 'oke'
          });
          // Filter();
          await getData();
        } else {
          Swal.fire({
            title: "Gagal",
            text: result.value.status + " [ " + result.value.message + " ]",
            icon: "error",
            confirmButtonText: "Oke",
          });
        }
      }
    })
  }
  async function Reset() {
    await setIsLoading(true);
    // await setFilIdpel();
    await setStartDate('');
    await setEndDate('');
    await setStatus('');
    await setFilterStatus('');
    await getData();
    await setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    getData();
    getDataBankApi();
    getDataStatusApi();
    setIsLoading(false);

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Tambahkan 1 karena bulan dimulai dari 0
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
    setEndDate(formattedDate);

  }, []);

  const columns = [
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Tanggal Transaksi</div>
      ),
      accessor: "created_at",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <Moment format="YYYY-MM-DD - HH:mm">{row.original.created_at}</Moment>
        </div>
      ),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Nominal</div>
      ),
      accessor: "sender_nominal",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Pengirim</div>
      ),
      accessor: "sender_nama",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "left", color: "black" }}>Bank Penerima</div>
      ),
      accessor: "receiver_nama_bank",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },

    },
    {
      Header: () => (
        <div style={{ textAlign: "left", color: "black" }}>Penerima A/N</div>
      ),
      accessor: "receiver_nama_rekening",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: () => (
        <div style={{ textAlign: "left", color: "black" }}>Trx Transfer Bank</div>
      ),
      accessor: "trx_nama_bank",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <span>{row.original.trx_nama_bank} - {row.original.trx_nama_rekening}</span>
        </div>
      )
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Admin</div>
      ),
      accessor: "trx_admin",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Status</div>
      ),
      accessor: "trx_status",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          {row.original.trx_status == "SUCCESS" ? (
            <>
              <span className="badge badge-success">SUCCESS</span>
            </>
          ) : row.original.trx_status == "ONPROCESS" ? (
            <>
              <span className="badge badge-warning">ONPROCESS</span>
            </>
          ) : row.original.trx_status == "FORCEPAYMENT" ? (
            <>
              <span className="badge badge-danger">FORCEPAYMENT </span>
            </>
          ):(
            <>
              <span className="badge badge-info">PENDING </span>
            </>
          )}
        </div>
      ),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
      ),
      maxWidth: "100",
      accessor: "id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <LaddaButton
            loading={isLoading}
            className="badge bg-warning small"
            data-size='s'
            onClick={async () => {
              await setSelectBank([]);
              await setDetailData(row.original);
              await setAdmin(row.original.trx_admin);
              await setStatus(row.original.trx_status);
              await setIdBank(row.original.trx_nama_bank);
              await handleShow();
            }}
          >Update Status</LaddaButton>
        </div>
      )
    },
  ];
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  }

  const optBank = dataBank.map((d) => ({
    fulldata : d,
    value: d.code,
    label: d.code.toUpperCase() +' - '+d.name.toUpperCase()+' - '+d.nomer_rekening.toUpperCase(),
  }));

  const optStatus = dataStatus.map((d) => ({
    value: d.code,
    label: d.name.toUpperCase(),
  }));

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
      >
        <Modal.Header>
          <Modal.Title>
            Update Transfer Dana
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
          <div className="col-md-12 col-sm-12">
              <div className="form-group">
                  <label>Transaksi Transfer Bank</label>
                  <Select
                    name="bank_list"
                    options={optBank}
                    value = {
                      optBank.filter(option => 
                         option.value === idBank)
                   }
                    onChange={(value) => handleChangeBank(value)}
                    placeholder={<div>Pilih Bank</div>}
                  />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                  <label>Admin</label>
                  <input className="form-control" type="number" onChange={(e) => setAdmin(e.target.value)} value={admin} min={0}></input>
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                  <label>Status</label>
                  <Select
                      name="bank_list"
                      options={optStatus}
                      // value={detailData.trx_status}
                      // value={isProdId}
                      value={optStatus.filter(
                        (obj) => obj.value === status
                      )}
                      onChange={(value) => handleChangeStatus(value)}
                      placeholder={<div>Pilih Status</div>}
                    />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>Tutup</button>
          <button className="btn btn-primary" onClick={() => updateConfirm()}>Update Status Transfer Dana</button>
        </Modal.Footer>
      </Modal>
      <div className="bg-admin">
        <section className="section p-0px-t bg-admin">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mb-3 mt-2">
                  <div className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-filter text-primary h1 mr-2"></i></td>
                          <td>
                            <span className="font-weight-bold text-primary">FILTER DATA</span> <br />
                            Filter data yang akan dicari
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Awal</label>
                          <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} value={startDate} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Tanggal Akhir</label>
                          <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} value={endDate} />
                        </div>
                      </div>
                    <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-dark">Status</label>
                          <Select
                            name="bank_list"
                            options={optStatus}
                            // value={detailData.trx_status}
                            // value={optStatus.filter(
                            //   (obj) => obj.value === detailData.trx_status
                            // )}
                            value={filterStatus}
                            onChange={(value) => handleChangeStatus(value)}
                            placeholder={<div>Pilih Status</div>}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 d-none">
                        <div className="form-group">
                          <label className="text-dark">Kode Bank</label>
                          <Select
                            name="bank_list"
                            options={optBank}
                            // value={isProdId}
                            // value={opt.filter(
                            //   (obj) => obj.value === this.state.role_f
                            // )}
                            // onChange={(value) => this.handleChangeRole_f(value)}
                            placeholder={<div>Pilih Bank</div>}
                          />
                        </div>
                      </div>
                    </div>
                  {/* </div>
                  <div className="card-footer"> */}
                    <div className="row mt-2">
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={isLoading}
                          className="btn btn-primary m-btn-radius w-100"
                          data-size='s'
                          onClick={() => Filter()}
                        >Terapkan Filter</LaddaButton>
                      </div>
                      <div className="col-md-2 col-6">
                        <LaddaButton
                          loading={isLoading}
                          className="btn btn-danger m-btn-radius w-100"
                          data-size='s'
                          onClick={Reset}
                        >Reset Filter</LaddaButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow">
                  <div
                    className="card-header bg-light d-flex justify-content-between">
                    <div>
                      <table>
                        <tr>
                          <td rowspan="2"><i className="fa fa-sync text-primary h1 mr-2"></i></td>
                          <td>
                              <span className="font-weight-bold text-primary">UPDATE TRANSFER DANA</span> <br />
                              Cek dan update status transaksi transfer dana
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="my-auto">
                      <button className="btn btn-success btn-sm"   onClick={() => GenerateExcel()}>Export Excel</button>
                    </div>
                  </div>
                  <div className="card-body pb-5">

                    {isLoading ? (
                      <Loading type="spinningBubbles" color="#91091e" />
                    ) : (
                      <div>
                        <ReactTable
                          // defaultSorted={[{ id: "tanggal", desc: true }]}
                          data={data}
                          //   defaultPageSize={isDataSaldo.length >= 10 ? 10 : 5}
                          defaultPageSize={5}
                          columns={columns}
                          className="mt-3 table-striped w-100"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default UpdateStatusTransferDana;
