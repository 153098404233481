import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import ReactLoading from "react-loading";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import LaddaButton from "react-ladda";
import Swal from "sweetalert2";
import axios from "axios";

const PaymentMethods = () => {
  const token = localStorage.getItem("token");
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"10%"}
      width={"10%"}
    />
  );
  const [isLoading, setLoading] = useState(false);
  const [toogleType, setToogleType] = useState(true);
  const [toogleStatus, setToogleStatus] = useState(true);
  const [isOpenModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodInput, setPaymentMethodInput] = useState({
    type: "otomatis",
    status: "Normal",
  });
  const showModal = async (type, bool, data) => {
    setModalType(type);
    setOpenModal(bool);
    if (type === "Edit") {
      await setPaymentMethodInput((prev) => ({
        ...prev,
        ["id"]: data?.id,
      }));
      await setPaymentMethodInput((prev) => ({
        ...prev,
        ["name"]: data?.bankName,
      }));
      await setPaymentMethodInput((prev) => ({
        ...prev,
        ["no_rekening"]: data?.accountNumber,
      }));
      await setPaymentMethodInput((prev) => ({
        ...prev,
        ["type"]: data?.senderType,
      }));
      if (data?.senderType === "otomatis") {
        setToogleType(true);
      } else {
        setToogleType(false);
      }
      await setPaymentMethodInput((prev) => ({
        ...prev,
        ["status"]: data?.status,
      }));
      if (data?.status === "Maintenance") {
        setToogleStatus(false);
      } else {
        setToogleStatus(true);
      }
    }
  };
  const getPaymentMethods = async () => {
    setLoading(true)
    await fetch(`${process.env.REACT_APP_URL}/rekening`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setLoading(false)
        response.json().then((result) => {
          setLoading(false);
          setPaymentMethods(result.data);
        });
      })
      .catch((err) => {
        setLoading(false)
        alert(err);
      });
  };
  const handleChange = async (e) => {
    await setPaymentMethodInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const confirmSubmit = () => {
    let bankName = paymentMethodInput?.name;
    let accountNumber = paymentMethodInput?.no_rekening;
    let type = paymentMethodInput?.type;
    let status = paymentMethodInput?.status;
    let images = paymentMethodInput?.images;

    if (!bankName || !accountNumber || !type || !status) {
      Swal.fire({
        title: "Maaf",
        text: "Lengkapi form input yang masih kosong",
        icon: "info",
        confirmButtonText: "oke",
      });
    } else {
      if (modalType === "Edit") {
        Swal.fire({
          title: "",
          html: "Apakah perubahan data sudah sesuai ?",
          icon: "info",
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
        }).then(async (result) => {
          if (result.value) {
            await savePaymentMethod();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
        });
      } else {
        if (!images) {
          Swal.fire({
            title: "Maaf",
            text: "Lengkapi form input yang masih kosong",
            icon: "info",
            confirmButtonText: "oke",
          });
        } else {
          if (images[0].size > 3000000) {
            Swal.fire({
              title: "Error Ukuran File",
              text: "Ukuran maksimal file 3 MB",
              icon: "info",
              confirmButtonText: "oke",
            });
          } else {
            if (
              images[0].type != "image/png" &&
              images[0].type != "image/jpg" &&
              images[0].type != "image/jpeg"
            ) {
              Swal.fire({
                title: "Error Tipe File",
                html:
                  "Tipe file <b>" +
                  images[0].type +
                  "</b> tidak diizinkan. Gunakan file PNG,JPG,JPEG",
                icon: "info",
                confirmButtonText: "oke",
              });
            } else {
              Swal.fire({
                title: "",
                html: "Apakah data sudah sesuai ?",
                icon: "info",
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Lanjutkan",
              }).then(async (result) => {
                if (result.value) {
                  await savePaymentMethod();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
              });
            }
          }
        }
      }
    }
  };

  const savePaymentMethod = async () => {
    await setLoading(true);
    const formData = new FormData();
    await formData.append("name", paymentMethodInput?.name);
    await formData.append("no_rekening", paymentMethodInput?.no_rekening);
    await formData.append("type", paymentMethodInput?.type);
    await formData.append("status", paymentMethodInput?.status);
    await formData.append(
      "images",
      paymentMethodInput?.images ? paymentMethodInput?.images[0] : ""
    );

    if (modalType === "Tambah") {
      axios
        .post(`${process.env.REACT_APP_URL}/rekening/add`, formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((json) => {
          setLoading(false);
          if (json.data.status == 200) {
            Swal.fire({
              title: "Sukses",
              text: "Berhasil Menambahkan Metode Pembayaran",
              icon: "success",
              confirmButtonText: "Oke",
            }).then(async (result) => {
              if (result.isConfirmed) {
                await showModal(modalType, false);
                await getPaymentMethods();
              }
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          Swal.fire({
            title: "Gagal",
            text: "Gagal Menambahkan Metode Pembayaran",
            icon: "info",
            confirmButtonText: "oke",
          });
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_URL}/rekening/${paymentMethodInput?.id}`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((json) => {
          setLoading(false);
          if (json.data.status == 200) {
            Swal.fire({
              title: "Sukses",
              text: "Berhasil Memperbarui Metode Pembayaran",
              icon: "success",
              confirmButtonText: "Oke",
            }).then(async (result) => {
              if (result.isConfirmed) {
                await showModal(modalType, false);
                await getPaymentMethods();
              }
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          Swal.fire({
            title: "Gagal",
            text: "Gagal Memperbarui Metode Pembayaran",
            icon: "info",
            confirmButtonText: "oke",
          });
        });
    }
  };
  const confirmDelete = (paramId) => {
    Swal.fire({
      title: "Hapus Metode Pembayaran?",
      text: "Mitra tidak akan dapat menggunakan metode pembayaran ini lagi",
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        deletePaymentMethod(paramId);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  const deletePaymentMethod = (paramId) => {
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_URL}/rekening/` + paramId, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((json) => {
        setLoading(false);
        if (json.data.status == 200) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil dihapus",
            icon: "success",
            confirmButtonText: "Tutup",
          });
          getPaymentMethods();
        } else {
          Swal.fire({
            title: "Gagal",
            text: "Data gagal dihapus",
            icon: "error",
            confirmButtonText: "Tutup",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: "Gagal",
          text: `Data gagal dihapus [ ${err} ]`,
          icon: "error",
          confirmButtonText: "Tutup",
        });
      });
  };
  useEffect(() => {
    getPaymentMethods();
  }, []);
  const columns = [
    {
      Header: "#",
      maxWidth: 50,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Logo</div>
      ),
      accessor: "image_bank",
      maxWidth: "5%",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell:(row) => (
        <img className="img-thumbnail border-0 mb-2" src={row.original.url_images} width="80px" height="40px" />
      ),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Bank</div>
      ),
      accessor: "bankName",
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>No Rekening</div>
      ),
      accessor: "accountNumber",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Deskripsi</div>
      ),
      accessor: "payment_method",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Tipe</div>
      ),
      accessor: "senderType",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
        textTransform: "uppercase",
      },
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Status</div>
      ),
      accessor: "status",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          {row.original.status === "Normal" ? (
            <span
              className="badge badge-success"
              style={{
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              {row.original.status?.toUpperCase()}
            </span>
          ) : (
            <span
              className="badge badge-danger"
              style={{
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              {row.original.status?.toUpperCase()}
            </span>
          )}
        </div>
      ),
    },
    {
      Header: () => (
        <div style={{ textAlign: "center", color: "black" }}>Opsi</div>
      ),
      accessor: "id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      filterable: false,
      Cell: (row) => (
        <div>
          <button
            className="btn btn-flat btn-sm btn-warning"
            onClick={() => showModal("Edit", true, row.original)}
          >
            Edit
          </button>
          &nbsp;
          <button
            onClick={() => confirmDelete(row.original.id)}
            className="btn btn-flat btn-sm btn-danger"
          >
            Hapus
          </button>
        </div>
      ),
    },
  ];
  return (
    <div className="bg-admin">
      <section className="section p-0px-t bg-admin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow mt-2 mb-5">
                <div className="card-header bg-light">
                  <div>
                    <table className="text-primary">
                      <tr>
                        <td rowspan="2">
                          <i className="fa fa-money-bill-alt text-primary h3 mr-2"></i>
                        </td>
                        <td>
                          <span className="font-weight-bold">
                            SETTING METODE PEMBAYARAN
                          </span>{" "}
                          <br />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="card-body">
                  <h4>
                    <button
                      className="btn btn-primary"
                      onClick={() => showModal("Tambah", true)}
                    >
                      <i className="fa fa-plus"></i> Metode Pembayaran
                    </button>
                  </h4>
                  {isLoading ? (
                    <Loading
                      type="bubbles"
                      color="#008aff"
                      className="text-center"
                    />
                  ) : (
                    <ReactTable
                      data={paymentMethods}
                      // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                      // defaultPageSize={this.state.dataDenom.length > 10 ? 10 : 10}
                      columns={columns}
                      className="mt-3 table-striped w-100"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={isOpenModal}
        onHide={() => showModal(modalType, false)}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title> {modalType} Metode Pembayaran</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>
              Nama Bank <sup className="text-danger">*</sup>
            </label>

            <input
              type="text"
              name="name"
              className="form-control"
              required="required"
              value={paymentMethodInput?.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group">
            <label>
              No Rekening <sup className="text-danger">*</sup>
            </label>

            <input
              type="number"
              name="no_rekening"
              className="form-control"
              required="required"
              value={paymentMethodInput?.no_rekening}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Tipe</label>
                <br />
                <BootstrapSwitchButton
                  checked={toogleType}
                  onlabel="Otomatis"
                  offlabel="Manual"
                  onstyle="info"
                  offstyle="secondary"
                  width={130}
                  onChange={(checked: boolean) => {
                    setToogleType(checked);
                    {
                      toogleType
                        ? setPaymentMethodInput((prev) => ({
                            ...prev,
                            ["type"]: "manual",
                          }))
                        : setPaymentMethodInput((prev) => ({
                            ...prev,
                            ["type"]: "otomatis",
                          }));
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <br />
                <BootstrapSwitchButton
                  checked={toogleStatus}
                  onlabel="Normal"
                  offlabel="Maintenance"
                  onstyle="success"
                  offstyle="danger"
                  width={130}
                  onChange={(checked: boolean) => {
                    setToogleStatus(checked);
                    {
                      toogleStatus
                        ? setPaymentMethodInput((prev) => ({
                            ...prev,
                            ["status"]: "Maintenance",
                          }))
                        : setPaymentMethodInput((prev) => ({
                            ...prev,
                            ["status"]: "Normal",
                          }));
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>
              Logo Bank <sup className="text-danger">*</sup>
            </label>

            <input
              type="file"
              name="images"
              className="form-control"
              required="required"
              onChange={(e) =>
                setPaymentMethodInput((prev) => ({
                  ...prev,
                  ["images"]: e.target.files,
                }))
              }
            />
            {modalType === "Edit" ? (
              <span className="text-danger text-small">
                Kosongkan logo bank jika tidak diubah
              </span>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => showModal(modalType, false)}
            className="btn btn-outline-secondary"
          >
            Batal
          </button>
          <LaddaButton
            loading={isLoading}
            onClick={() => {
              confirmSubmit();
            }}
            className="btn btn-primary"
          >
            Simpan
          </LaddaButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentMethods;
