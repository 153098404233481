import React, { Component ,useContext } from "react";
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
// import ReactMomentCountDown from 'react-moment-countdown';
import { AuthContext } from '../../router/Router';
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      struk: JSON.parse(localStorage.getItem("private_payment")),
      noVirtual: "8870895421436134",
      chekstatus: false,
    };
  }

  componentDidMount() {
    console.log(this.state.struk.data.reprint_id);
    this.updateMe();
  }

  updateMe() {
    setInterval(()=>{
      if(this.state.chekstatus==false)
        this.chekstatus();
      },5000)        
  }

  chekstatus() {
    let key = process.env.REACT_APP_TOKEN;
    let token ="Bearer "+key
    const { dispatch , state } = useContext(AuthContext)
    fetch(process.env.REACT_APP_URL+'/merchant/status/'+this.state.struk.data.transaction_id,{
        method: "GET",
        headers:{
            'Authorization':token
        }
    }).then((response)=>{
        response.json().then((result)=>{
            if(result.status==404){
              this.setState({chekstatus: false});
            }else{
              localStorage.setItem(
                "private_struk",
                JSON.stringify({
                  struk: this.state.struk,
                })
              );
              this.setState({chekstatus: true});
            }
        })
    })
  }  

  render() {
    let date = this.state.struk.data.expiry_date;
    return (
      <div>
        {this.state.chekstatus ? (
          <>
            <Redirect
              to={{
                pathname: "/invoice/" + this.state.struk.data.transaction_id,
                state: {
                  harga: this.state.struk.data.harga,
                  amount: this.state.struk.data.amount,
                  adminBank: this.state.struk.data.adminBank
                },
              }}
            />
          </>
        ) : (
          <></>
        )}
        <section className="g-bg-gray p-0px-t section-top-up-100">
          <div className="container" style={{ maxWidth: "600px" }}>
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-12 m-15px-tb">
                <div className="card border-radius-2 text-center m-auto">
                  <img
                    src="https://cdn.dribbble.com/users/415089/screenshots/14359077/media/12e60e1d4f5556b56310908bc9e6c2ea.jpg"
                    style={{ width: "300px", margin: "auto" }}
                  />
                  <label className="font-weight-bold mt-3">
                    Selesaikan Pembayaran Sebelum
                  </label>
                  <label className="font-weight-bold text-danger">
                  <Moment format="DD MMM YYYY - HH:mm">{date}</Moment>
                  </label>
                  <label className="font-weight-bold mt-3">
                    Sisa Waktu Pembayaran
                  </label>
                  <Moment format="HH:mm:ss" date={date} durationFromNow  interval={1000}/>
                  <label htmlFor="">
                  </label>
                  <small className="m-3">kami menggunakan xendit untuk kenyamanan dan kemudahan pembayaran anda silahkan bayar tagihan anda sebelum waktu yang pembayaran akhir</small>
                </div>
              </div>

              <div className="col-sm-12 col-lg-12 col-12">
                <div className=" mt-3 mb-3">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12 col-12 mb-3">
                        <a
                          href={this.state.struk.data.invoice_url}
                          target="_blank"
                          onClick={() => this.updateMe()}
                          className="btn btn-primary btn-lg border w-100 font-weight-bold text-white shadow"
                        >
                          Bayar Invoice
                        </a>
                        <small className="p-3 m-3">halaman akan reload otomatis ketika anda sudah melakukan pembayaran invoice</small>
                      </div>
                      <div className="col-md-12 col-12">
                        {/* <button
                          className="btn btn-white btn-lg w-100 border-primary"
                          onClick={() => {
                            this.chekstatus();
                          }}
                        >
                          Chek Manual
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>

                <b>Panduan Pembayaran</b>

                <div className="accordion accordion-03 m-30px-b mt-3">
                  <div className="acco-group">
                    <a href="#" className="acco-heading text-muted">
                      ATM
                    </a>
                    <div className="acco-des" style={{ display: "none" }}>
                      <p className="small text-dark">
                        1.Masukkan kartu ATM dan PIN
                        <br />
                        2.Pilih menu "Bayar/Beli"
                        <br />
                        3.Pilih menu "Lainnya", hingga menemukan menu
                        "Multipayment"
                        <br />
                        4.Masukkan Kode Biller Tokopedia (88708), lalu pilih
                        Benar
                        <br />
                        5.Masukkan "Nomor Virtual Account" Tokopedia, lalu pilih
                        tombol Benar
                        <br />
                        6.Masukkan Angka "1" untuk memilih tagihan, lalu pilih
                        tombol Ya
                        <br />
                        7.Akan muncul konfirmasi pembayaran, lalu pilih tombol
                        Ya
                        <br />
                        8.Simpan struk sebagai bukti pembayaran Anda
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="acco-group">
                    <a href="#" className="acco-heading text-muted">
                      Internet Banking / Mandiri Online
                    </a>
                    <div className="acco-des" style={{ display: "none" }}>
                      <p className="small text-dark">
                        1.Login Mandiri Online dengan memasukkan Username dan
                        Password
                        <br />
                        2.Pilih menu "Pembayaran"
                        <br />
                        3.Pilih menu "Multipayment"
                        <br />
                        4.Pilih penyedia jasa "Tokopedia"
                        <br />
                        5.Masukkan "Nomor Virtual Account" dan "Nominal" yang
                        akan dibayarkan, lalu pilih Lanjut
                        <br />
                        6.Setelah muncul tagihan, pilih Konfirmasi
                        <br />
                        7.Masukkan PIN / Challenge Code Token
                        <br />
                        8.Transaksi selesai, simpan bukti bayar Anda
                        <br />
                        Jangan gunakan fitur "Simpan Daftar Transfer" untuk
                        pembayaran melalui Internet Banking karena dapat
                        mengganggu proses pembayaran berikutnya.
                        <br />
                        1.Untuk menghapus daftar transfer tersimpan ikuti
                        langkah berikut:
                        <br />
                        2.Login Mandiri Online
                        <br />
                        3.Pilih ke menu Pembayaran
                        <br />
                        4.Pilih menu Daftar Pembayaran
                        <br />
                        5.Pilih pada pembayaran yang tersimpan, lalu pilih menu
                        untuk hapus
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomePage;
